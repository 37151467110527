// errorHandling.js

export function checkErrors({
  projectFormDetails,
  edited,
  isProjectExits,
  projectId,
  files,
  isSaveDraft,
}) {
  console.log("projectFormDetails12334455", projectFormDetails);
  const conditions = [
    {
      cond: projectFormDetails?.projectName?.length > 50,
      msg: "Project Name to be less than 50 characters!",
    },

    {
      cond: !projectFormDetails?.logoURL && !edited,
      msg: "Please Upload Thumbnail",
    },
    {
      cond: !projectFormDetails?.projectName,
      msg: "Please Enter Project Name.",
    },
    { cond: !projectFormDetails?.sector, msg: "Please Select Sector." },
    { cond: !projectFormDetails?.subSector, msg: "Please Select Sub-Sector." },
    {
      cond:
        !files?.apk &&
        !files.exe &&
        !files.sh &&
        !edited &&
        !projectFormDetails?.buildURL,
      msg: "Please Upload Project file.",
    },
    {
      cond: !projectFormDetails?.mediafileURLs?.projectCardImage,
      msg: "Please Upload Project Cover Image.",
    },
    {
      cond: !projectFormDetails?.mediafileURLs?.featuredCardImage,
      msg: "Please Upload Poster Image.",
    },
    {
      cond: !projectFormDetails?.mediafileURLs?.coverImage,
      msg: "Please Upload Cover Image.",
    },
    {
      cond: !projectFormDetails?.mediafileURLs?.video,
      msg: "Please Upload Project Video.",
    },
    {
      cond: !projectFormDetails?.mediafileURLs?.videothumbnail,
      msg: "Please Upload Video Thumbnail.",
    },
    {
      cond: !projectFormDetails?.projectBrief,
      msg: "Please Enter Brief Information about Project.",
    },

    {
      cond:
        projectFormDetails?.projectBrief &&
        projectFormDetails?.projectBrief?.length > 250,
      msg: "Project Brief Should be less than 250 characters!",
    },

    {
      cond: !projectFormDetails?.description,
      msg: "Please Enter Project Description.",
    },

    {
      cond:
        projectFormDetails?.description &&
        projectFormDetails?.description?.length > 2500,
      msg: "Project Description Should be  less than 2500 characters!",
    },

    {
      cond: projectFormDetails?.hashtags === "",
      msg: "Please Enter Hashtags.",
    },
    // if any of ar or vr or mr is selected then only we need to check for type of experience
    {
      cond: ["ar", "vr", "mr"]?.includes(!projectFormDetails?.tag),
      msg: "Please Select Type of Experience.",
    },

    {
      cond: !projectFormDetails?.tag,
      msg: "Please Select Type of Project.",
    },
    {
      cond: projectFormDetails?.suitableForAge === "Select Age Group",
      msg: "Please Select Suitable Age.",
    },
    {
      cond:
        Array.isArray(projectFormDetails?.languageSupported) &&
        projectFormDetails?.languageSupported[0] === "Select Languages",
      msg: "Please Select Suitable Language.",
    },
    {
      cond:
        !projectFormDetails?.languageSupported ||
        projectFormDetails?.languageSupported?.length === 0 ||
        (projectFormDetails?.languageSupported?.length === 1 &&
          projectFormDetails?.languageSupported[0] === ""),
      msg: "Please Select Supported Languages.",
    },
    {
      cond: isProjectExits && !projectId,
      msg: "Project name already exists. Please choose another name.",
    },
    // {
    //   cond: projectFormDetails?.tag === "ar",
    //   msg: "Please Select Type of Experience.",
    // },
    {
      cond: projectFormDetails?.suitableForAge === "",
      msg: "Please Select Suitable Age.",
    },
    {
      cond:
        !projectFormDetails?.languageSupported ||
        projectFormDetails?.languageSupported?.length === 0 ||
        (projectFormDetails?.languageSupported?.length === 1 &&
          projectFormDetails?.languageSupported[0] === ""),
      msg: "Please Select Supported Languages.",
    },
    {
      cond: isProjectExits && !projectId,
      msg: "Project name already exists. Please choose another name.",
    },
  ];

  // Check if any of the conditions are true
  for (const condition of conditions) {
    if (condition.cond) {
      return condition.msg;
    }
  }

  // If no errors were found, return null
  return null;
}

// suitableForAge: "",
//   languageSupported: [""],
