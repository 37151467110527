import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const BarChart = ({ data, options }) => {
  const chartRef = useRef(null);

  const hasData = data.labels.length !== 0;

  useEffect(() => {
    if (chartRef?.current) {
      // Render the chart
      const chartInstance = new Chart(chartRef.current, {
        type: "bar",
        data: data,
        options: options,
      });

      return () => {
        // Clean up the chart instance
        chartInstance.destroy();
      };
    }
  }, [data, options]);

  return (
    <>
      {hasData ? (
        <canvas ref={chartRef} style={{ width: "100%", height: "100%" }} />
      ) : (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          No Data Available
        </div>
      )}
    </>
  );
};

export default BarChart;
