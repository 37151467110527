import { CustomTabs } from "components/Tabs/CustomTabs";
import { CustomPane } from "components/Tabs/customTabs.styled";
import { CardBlackWhite } from "components/card/Card";
import React from "react";

export const PurchaseDetails = () => {
  const upperContent = ["Fitter Trade Breakdown Document "];
  const lowerContent = (
    <div className="px-3">
      <CustomTabs
        tabTitles={["About", "Revenue Breakdown"]}
        tabs={""}
        page={[""]}
        type={"plane"}>
        <CustomPane eventKey="About">
          <div className="p-4">About</div>
        </CustomPane>
        <CustomPane eventKey="Revenue Breakdown">
          <div className="p-4">Revenue Breakdown</div>{" "}
        </CustomPane>
      </CustomTabs>
    </div>
  );
  return (
    <div className="m-4 p-2">
      <CardBlackWhite upperContent={upperContent} lowerContent={lowerContent} />
    </div>
  );
};
