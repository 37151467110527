import {
  Active,
  CustomInput,
  DiscountPrice,
  TextBlack,
} from "components/styled/common.styled";
import React, { useState } from "react";
import { UpgradeData, data, paymentimgTitle } from "./constant";
import {
  BlackWhiteDiv,
  LowerCard,
  LowerWhite,
  UpperBlack,
} from "components/card/card.styled";
import {
  BuyStorageCont,
  CustomBtn,
  GbUsed,
  RightCont,
  ScrobalbeDiv,
  StorageFlex,
  StorageMessageTxt,
  Upgrade,
  UpgradeCont,
  UserDataCont,
} from "components/styled/Buystorage.style";
import {
  CustomLabel,
  PaymentCont,
  PaymentTitleImg,
} from "components/styled/payment.styled";
import CustomProgressBar from "components/CustomProgressbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllProjects,
  getAllStoragedata,
  getAllowedStorage,
  getAllowedStorageProjectwise,
} from "redux/Project/project.action";
import { convertBytesToGB, getUserToken } from "helper";
import { useLocation, useNavigate } from "react-router-dom";
import { ProgressBar, Spinner } from "react-bootstrap";
import axios from "axios";
import danger from "../../assets/danger.svg";
import CustomBreadcrumb from "components/CustomBreadScrum";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { PUBLISHER_URL } from "redux/api";

export const Storage = () => {
  const [paymentComp, setpaymentComp] = useState(false);
  const { storageData } = useSelector((data) => data?.projectReducer);
  const [loading, setLoading] = useState(false);
  const [skletonLoading, setSkletonLoading] = useState(true);

  const { allProjects, getstorageProjectwise, getAllstorageData } = useSelector(
    (state) => state?.projectReducer
  );
  const navigate = useNavigate();

  const token = getUserToken();
  const dispatch = useDispatch();
  const location = useLocation();
  const [active, setActive] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(getAllstorageData[0]?._id);
  const [isActivePlan, setIsActivePlan] = useState(false);
  const [message, setMessage] = useState("");
  const general = {
    email: token?.email,
    firstname: token?.family_name,
    lastname: token?.given_name,
    phone: token?.preferred_username,
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      toast.info("Razorpay SDK failed to load. Are you online?");
      return;
    }

    //TODO: Replace: https://pay.ajnavidya.com/order

    try {
      const result = await axios.post(
        `${PUBLISHER_URL}/publisher-dev/storageOrder/create-order` +
          `?storagePlanID=${selectedPlan}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!result) {
        toast.info("Server error. Are you online?");
        return;
      }

      const { amount, id: order_id, currency } = result.data.data;
      setLoading(false);

      const options = {
        key: "rzp_test_a3T0ZmW17dhEvU", // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: "Ajnalens",
        description: "Buy Ajnalens headset",
        order_id: order_id,
        handler: async function (response) {
          const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            storageID: selectedPlan,
          };

          // const result = await axios.post("https://pay.ajnavidya.com/success", data);
          const result = await axios.post(
            `${PUBLISHER_URL}/publisher-dev/storageOrder/success`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          toast.success("Payment Successful");
          let timer = 5;
          setTimeout(() => {
            timer--;
            if (timer === 0) {
            }
            setMessage(
              `Payment Successful. Redirecting to home page in ${timer} seconds`
            );
          }, 1000);

          // navigate(`/success/${data.orderCreationId}`);
        },
        prefill: {
          name: general.firstname + " " + general.lastname,
          email: general.email,
          contact: general.phone,
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (e) {
      console.log(e);
    }
  }

  const ChangePaymentComponent = () => {
    setpaymentComp(true);
    setLoading(true);
    displayRazorpay();
    setpaymentComp(false);
  };

  useEffect(() => {
    dispatch(getAllStoragedata()).then((res) => {
      if (res) {
        setSkletonLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getAllowedStorage(getUserToken()?.sub));
  }, [location.pathname]);

  const handleseletedPlanId = (id) => {
    setSelectedPlan(id);
  };

  useEffect(() => {
    if (getAllstorageData && getAllstorageData.length > 0) {
      const isPlanActive = getAllstorageData.some(
        (item) => item.isStoragePlanActive === true
      );
      const selectPlan = getAllstorageData.find(
        (item) => item.isStoragePlanActive !== true
      );
      setSelectedPlan(selectPlan?._id);
      setIsActivePlan(isPlanActive);
    }
  }, [getAllstorageData]);

  useEffect(() => {
    dispatch(getAllowedStorageProjectwise()).then((res) => {
      if (res) {
        setSkletonLoading(false);
      }
    });
  }, []);

  const handleNavigate = (item) => {
    if (item.slug === "From App Section") {
      navigate("/home");
    } else if (item.slug === "In Review") {
      navigate("/projects_reviews");
    } else if (item.slug === "In Draft") {
      navigate("/home?infoType=Draft");
    }
  };

  const usedDataInNum = +storageData?.totalUsed;
  const percentageUsed = usedDataInNum ? (usedDataInNum / 30) * 100 : 0;
  return (
    <BuyStorageCont>
      <CustomBreadcrumb items={[{ text: "Storage", link: "/storage" }]} />
      <div className="row gap-4">
        <div className="col-8 left">
          {skletonLoading ? (
            <>
              <div
                style={{
                  background: "#fff",
                  borderRadius: "10px",
                  display: "flex",
                  gap: "8px",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Skeleton height={"35px"} width={"100px"} />
                <Skeleton height={"35px"} width={"70px"} />
              </div>

              <Skeleton height={"5px"} />
              <Skeleton
                height={"28px"}
                width={"40%"}
                style={{ marginTop: "25px" }}
              />
              <Skeleton height={"5px"} />
              <div>
                {[...Array(11)].map((_, index) => (
                  <div
                    style={{
                      background: "#fff",
                      borderRadius: "10px",
                      display: "flex",
                      gap: "8px",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "10px",
                    }}
                    key={index}
                  >
                    <Skeleton height={"20px"} width={"100px"} />
                    <Skeleton height={"20px"} width={"70px"} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              {storageData && (
                <GbUsed>
                  <span>{`${usedDataInNum.toFixed(2) || 0} `}GB used</span>
                  <span>30 GB</span>
                </GbUsed>
              )}
              {console.log("storageData", storageData)}
              <ProgressBar
                now={percentageUsed || 0}
                animated={true}
                className="mt-1"
              />
              {/*storage message */}
              {/* <StorageMessageTxt>
              <TextBlack className="fs-14">
                Your cloud storage subscription is set to expire on
                [Subscription Expiry Date]. To avoid interruptions in your app
                services, it's crucial to renew the subscription before the
                expiration date.
              </TextBlack>
              <TextBlack className="danger">
                <span>
                  <img src={danger} alt="danger" />
                </span>
                Marked Apps utilizing this storage will be temporarily
                unavailable if the subscription is not renewed in time.
              </TextBlack>
            </StorageMessageTxt> */}

              <TextBlack className="usedIn">Storage Used By Projects</TextBlack>
              <ScrobalbeDiv>
                {getstorageProjectwise &&
                  getstorageProjectwise?.map((item, i) => (
                    <UserDataCont
                      key={item.id}
                      className="onclickrow px-2"
                      onClick={() => handleNavigate(item)}
                    >
                      <TextBlack className="fs-14-500">
                        {item.projectName}
                      </TextBlack>
                      <TextBlack className="fs-14-500">
                        {item.storageUsed}
                      </TextBlack>
                    </UserDataCont>
                  ))}
              </ScrobalbeDiv>
            </>
          )}
        </div>

        <Upgrade>
          <BlackWhiteDiv>
            {skletonLoading ? (
              <div className="bg-white p-4">
                <Skeleton
                  height={"50px"}
                  width={"100%"}
                  borderRadius={"10px"}
                />
                <div
                  style={{
                    background: "#fff",
                    borderRadius: "10px",
                    height: "100%",
                    display: "flex",
                    gap: "8px",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <Skeleton height={"35px"} width={"100px"} />
                  <Skeleton height={"35px"} width={"70px"} />
                </div>
                <div
                  style={{
                    background: "#fff",
                    borderRadius: "10px",
                    height: "100%",
                    display: "flex",
                    gap: "8px",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "10px",
                  }}
                >
                  <Skeleton height={"35px"} width={"100px"} />
                  <Skeleton height={"35px"} width={"70px"} />
                </div>

                <Skeleton
                  height={"40px"}
                  borderRadius={"15px"}
                  width={"20%"}
                  style={{ marginTop: "25px" }}
                />
              </div>
            ) : (
              <>
                {" "}
                {/* <UpperBlack>Upgrade Storage</UpperBlack> */}
                {/* <UpgradeCont>
                  {Array.isArray(getAllstorageData) &&
                    getAllstorageData?.map((item) => (
                      <div
                        className={`rigthCont ${
                          selectedPlan === item._id ? "selected-plan" : ""
                        }`}
                        onClick={() =>
                          !isActivePlan && handleseletedPlanId(item?._id)
                        }
                      >
                        <TextBlack className="fs-16-gb-rupees">
                          {convertBytesToGB(item?.storageSize)}
                          <Active className="text-success">
                            {item.isStoragePlanActive && "Active"}
                          </Active>
                        </TextBlack>
                        <TextBlack className="fs-16-gb-rupees">
                          ₹{item?.storagePlanPrice}
                          {item?.discountPrice < item?.storagePlanPrice ? (
                        <DiscountPrice>{item?.storagePlanPrice}</DiscountPrice>
                      ) : null}
                        </TextBlack>
                      </div>
                    ))}

                  <CustomBtn
                    disabled
                    onClick={() => ChangePaymentComponent()}
                    style={{
                      display: paymentComp ? "none" : "display",
                      cursor: "not-allowed",
                    }}
                    className="buy"
                  >
                    {loading ? <Spinner size="sm" /> : "Buy"}
                  </CustomBtn>
                </UpgradeCont> */}
              </>
            )}
          </BlackWhiteDiv>
        </Upgrade>
      </div>
    </BuyStorageCont>
  );
};
