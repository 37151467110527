import LineChart from "components/Graphs/LineChart";
import {
  DownloadCard,
  GraphCard,
  TextBlack,
  TextWhite,
} from "components/styled/common.styled";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ToggleButtons from "components/ToggleButtons/ToggleButtons";
import { useDispatch, useSelector } from "react-redux";
import {
  getLiveProjects,
  getOverallActiveUsersData,
  getOverallDownloads,
  getOverallEarningData,
  getOverallUsersByRegionData,
} from "redux/Project/project.action";
import { lgDataGreen, lgDataRed, periods } from "./constants";
import { Spinner } from "react-bootstrap";
import CustomBreadcrumb from "components/CustomBreadScrum";
import Skeleton from "react-loading-skeleton";
import {
  RegionLabelAndNumData,
  fetColour,
  generateLabels,
  optionsData,
} from "helper";
import GraphWithLoading from "./GraphWithLoading";
import InfoCardWithLoading from "./InfoCardWithLoading";

export const Analytics = () => {
  const navigate = useNavigate();
  const [selectedPeriod, setSelectedPeriod] = useState("Weekly");
  const [isUserLabelDataLoaded, setIsUserLabelDataLoaded] = useState(false);
  const [isRegionDataLoaded, setIsRegionDataLoaded] = useState(false);
  const [downloadClick, setDownloadClick] = useState(false);
  const [earningClick, setEarningClick] = useState(false);
  const [graphScreen, setGraphScreen] = useState("users");

  const projectsData = useSelector(
    (state) => state.projectReducer.allLiveProjects
  );
  const breadcrumbItems = [{ text: "Analytics", link: "/analytics" }];
  const [isgetAllProjectsloading, setgetAllProjectsloading] = useState(true);

  const activeUsersData = useSelector(
    (state) => state.projectReducer.overallUsers
  );

  const { totalCountsDownload, totalCountsEarnings, totalCountsUsers } =
    useSelector((state) => state.projectReducer);

  const overallDownloadData = useSelector(
    (state) => state.projectReducer.overallDownloads
  );

  const appEarningData = useSelector(
    (state) => state.projectReducer.overallEarnings
  );

  const usersByRegionData = useSelector(
    (state) => state.projectReducer.overallUsersByRegion
  );

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // active users Data
  const userLabelData = generateLabels(
    selectedPeriod,
    activeUsersData,
    monthNames,
    dayNames
  );
  const userNumData = activeUsersData?.map((item) => item.count);

  // Earnings label data
  const earningLabelData = generateLabels(
    selectedPeriod,
    appEarningData,
    monthNames,
    dayNames
  );
  const earningNumData = appEarningData?.map((item) => item.earnings);

  // Download label data
  const downloadLabelsData = generateLabels(
    selectedPeriod,
    overallDownloadData,
    monthNames,
    dayNames
  );
  const downloadNumData = overallDownloadData?.map((item) => item.count);

  // Users by region label data
  const usersByRegionLabel = usersByRegionData?.map((item) => item.label);
  const usersByRegionNumData = usersByRegionData?.map((item) => item.count);

  const dispatch = useDispatch();

  const data = RegionLabelAndNumData(usersByRegionLabel, usersByRegionNumData);

  const options = optionsData();

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  useEffect(() => {
    setIsUserLabelDataLoaded(false);

    Promise.all([
      dispatch(getOverallActiveUsersData(selectedPeriod)),
      dispatch(getOverallDownloads(selectedPeriod)),
      dispatch(getOverallEarningData(selectedPeriod, "Fixed")),
    ])
      .then((res) => {
        setIsUserLabelDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [selectedPeriod, dispatch]);

  useEffect(() => {
    setIsRegionDataLoaded(false);

    Promise.all([
      dispatch(getOverallUsersByRegionData()),
      dispatch(getLiveProjects()).then((res) => {
        if (res) {
          setgetAllProjectsloading(false);
        }
      }),
    ])
      .then(() => {
        setIsRegionDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const updateData = (data) => {
    return data;
  };

  return (
    <div className="px-4" style={{ overflowY: "scroll", height: "85.5vh" }}>
      <CustomBreadcrumb items={breadcrumbItems} />

      <div className="row" style={{ paddingRight: "10px" }}>
        <div className="row col-md-3 mx-0">
          {/* Downloads box */}
          <InfoCardWithLoading
            isDataLoaded={isUserLabelDataLoaded}
            type={!downloadClick ? "downloads" : "users"}
            totalCount={totalCountsDownload}
            totalDownloads={totalCountsDownload}
            lgDataGreen={lgDataGreen}
            color={fetColour({
              type: "downloads",
              downloadClick,
              earningClick,
              lgDataGreen,
              lgDataRed,
            })}
            userLabelData={userLabelData}
            userNumData={userNumData}
            setEarningClick={setEarningClick}
            setGraphScreen={setGraphScreen}
            setDownloadClick={setDownloadClick}
            userDownloadData={downloadLabelsData}
            downNumData={downloadNumData}
            downloadNumData={downloadNumData}
            totalCountsEarnings={totalCountsEarnings}
            totalCountsUsers={totalCountsUsers}
            labelData={downloadLabelsData}
          />
          {/* Earning box */}
          <InfoCardWithLoading
            isDataLoaded={isUserLabelDataLoaded}
            type={!earningClick ? "earnings" : "users"}
            setGraphScreen={setGraphScreen}
            color={fetColour({
              type: "earnings",
              downloadClick,
              earningClick,
              lgDataGreen,
              lgDataRed,
            })}
            userLabelData={userLabelData}
            userNumData={userNumData}
            setEarningClick={setEarningClick}
            setDownloadClick={setDownloadClick}
            totalCountsEarnings={totalCountsEarnings}
            totalCount={totalCountsEarnings}
            earningLabelData={earningLabelData}
            lgDataRed={lgDataRed}
            earningNumData={earningNumData}
            totalCountsUsers={totalCountsUsers}
            labelData={earningLabelData}
          />
        </div>
        {/* Users box */}

        <div className="col-md-4">
          {!isUserLabelDataLoaded ? (
            <div
              style={{
                background: "#fff",
                borderRadius: "10px",
                height: "100%",
                padding: "13px",
                display: "flex",
                gap: "8px",
              }}
            >
              <Skeleton height={"20px"} width={"100px"} />
              <Skeleton height={"20px"} width={"70px"} />
              <Skeleton height={"20px"} width={"70px"} />
              <Skeleton height={"20px"} width={"70px"} />
            </div>
          ) : (
            <GraphCard>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <TextBlack className="fw-600">
                    {graphScreen === "downloads" && downloadClick
                      ? "Downloads"
                      : graphScreen === "earnings" && earningClick
                      ? "Earnings"
                      : "Active Users"}
                  </TextBlack>
                </div>
                <div>
                  <ToggleButtons
                    options={periods}
                    selectedOption={selectedPeriod}
                    onOptionChange={handlePeriodChange}
                  />
                </div>
              </div>
              <div style={{ height: "90%" }}>
                {isUserLabelDataLoaded &&
                  userLabelData &&
                  userNumData &&
                  updateData(
                    graphScreen === "downloads"
                      ? downloadNumData
                      : graphScreen === "earnings"
                      ? earningNumData
                      : userNumData
                  ) && (
                    <LineChart
                      isAxisData={true}
                      labelData={
                        graphScreen === "downloads" && downloadClick
                          ? downloadLabelsData
                          : graphScreen === "earnings" && earningClick
                          ? earningLabelData
                          : userLabelData
                      }
                      numData={
                        graphScreen === "downloads" && downloadClick
                          ? downloadNumData
                          : graphScreen === "earnings" && earningClick
                          ? earningNumData
                          : userNumData
                      }
                      lgData={
                        downloadClick
                          ? lgDataGreen
                          : earningClick
                          ? lgDataRed
                          : ""
                      }
                      label={
                        graphScreen === "downloads" && downloadClick
                          ? "Downloads"
                          : graphScreen === "earnings" && earningClick
                          ? "Earnings"
                          : "Active Users"
                      }
                    />
                  )}
                {!isUserLabelDataLoaded && (
                  <div className="d-flex justify-content-center align-items-center w-100 h-100 mt-2">
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                )}
              </div>
            </GraphCard>
          )}
        </div>

        <div className="col-md-5  px-lg-3 px-md-4">
          <GraphWithLoading
            isDataLoaded={isUserLabelDataLoaded}
            data={data}
            options={options}
          />
        </div>
      </div>
      <div className="row my-4 mx-1">
        {isgetAllProjectsloading ? (
          <div
            className="col-2 rounded-left"
            style={{ height: "150px", width: "270px" }}
          >
            <Skeleton height={"100%"} width={"100%"} />
          </div>
        ) : (
          <DownloadCard bg="#242528" className="col-3 py-4" bor={false}>
            <div>
              <TextWhite className="fs-16">All Published Projects</TextWhite>
              <TextWhite className="fs-14">
                Check Analytics & Reviews for published projects
              </TextWhite>
            </div>
          </DownloadCard>
        )}

        <DownloadCard className="col-9" bor={true}>
          {isgetAllProjectsloading ? (
            <div className="col-12 h-100 rounded d-flex">
              <div className="col-2 mb-2 rounded">
                <Skeleton height={"100%"} width={"80%"} />
              </div>
              <div className="col-2 mb-2 rounded">
                <Skeleton height={"100%"} width={"80%"} />
              </div>
              <div className="col-2 mb-2 rounded">
                <Skeleton height={"100%"} width={"80%"} />
              </div>
              <div className="col-2 mb-2 rounded">
                <Skeleton height={"100%"} width={"80%"} />
              </div>{" "}
              <div className="col-2 mb-2 rounded">
                <Skeleton height={"100%"} width={"80%"} />
              </div>
            </div>
          ) : (
            <div className="d-flex gap-4 px-3 py-2 analyticsSroll position-relative">
              {projectsData.length !== 0 &&
                projectsData?.map((item, index) => (
                  <div
                    key={item.id}
                    className="rounded cursor-pointer"
                    onClick={() => {
                      navigate(`/analytics/${item._id}/${item.appID}`);
                    }}
                  >
                    <img
                      height={"100px"}
                      width={"100px"}
                      src={item.logoURL}
                      onError={(e) =>
                        (e.target.src =
                          "https://cdn.ajnavidya.com/ajnavidya/no%20thumbnail.png")
                      }
                      alt="img"
                      className="rounded"
                    />
                  </div>
                ))}
              {projectsData.length === 0 && (
                <div className="m-auto">No Published Projects</div>
              )}
            </div>
          )}
        </DownloadCard>
      </div>
    </div>
  );
};
