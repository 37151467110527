import {
  CustomProfileContainer,
  OverFlow,
  ProfileImg,
  TextBlack,
} from "components/styled/common.styled";
import { useEffect, useState } from "react";
import avatarImage from "assets/profile-circle.svg";
import { publisher } from "redux/api";
import { CustomTabs2 } from "components/Tabs/CustomTabs2";
import { CustomPane } from "components/Tabs/customTabs.styled";
import { Organization } from "./Organization";
import { Members } from "./Members";
import edit from "../../assets/edit-2.svg";
import CustomBreadcrumb from "components/CustomBreadScrum";
import { loginconfig } from "ui/auth/Config";
import Skeleton from "react-loading-skeleton";

export const Profile = () => {
  const [profileData, setProfileData] = useState({});
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await publisher.getUserDetails();
      const { data: userData } = await publisher.getUserDetailsFromSSO();
      setProfileData({
        ...data,
        name: userData?.data?.firstName + " " + userData?.data?.lastName,
        profilePic: userData?.data?.profilePhotoURL,
      });
      setIsloading(false);
    };
    fetchUser();
  }, []);

  const tabs =
    profileData?.enrolledAs === "organization"
      ? ["User Profile", "Members", "Organization"]
      : ["User Profile"];

  return (
    <OverFlow>
      <div className="px-4">
        <CustomBreadcrumb items={[{ text: "Profile", link: "/profile" }]} />
        <CustomProfileContainer className="p-4">
          <CustomTabs2 tabTitles={tabs} tabs={""} page={[""]} type={"plane"}>
            <CustomPane eventKey="User Profile">
              {isloading ? (
                <div className="py-2 row">
                  <div className="col-2">
                    {/* Skeleton for profile image */}
                    <Skeleton circle height={150} width={150} />
                  </div>
                  <div className="col-6 py-3 pt-0">
                    {/* Skeleton for user information */}
                    <div className="d-grid pb-4">
                      <Skeleton width={120} />
                      <Skeleton width={150} />
                    </div>
                    <div className="d-grid pb-4">
                      <Skeleton width={120} />
                      <Skeleton width={150} />
                    </div>
                    <div className="d-grid pb-4">
                      <Skeleton width={120} />
                      <Skeleton width={150} />
                    </div>
                    <div className="d-grid pb-4">
                      <Skeleton width={120} />
                      <Skeleton width={150} />
                    </div>
                  </div>
                  <div className="col-3">
                    {/* Skeleton for edit button */}
                    <Skeleton height={38} width={80} />
                  </div>
                </div>
              ) : (
                <div className="py-2 row ">
                  <div className="col-2">
                    {profileData?.profilePic ? (
                      <ProfileImg
                        src={profileData?.profilePic}
                        alt="img"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = avatarImage;
                        }}
                      />
                    ) : (
                      <ProfileImg src={avatarImage} alt={"noimg"} />
                    )}
                  </div>
                  <div className="col-6 py-3 pt-0">
                    <div className="d-grid pb-4">
                      <TextBlack className="fs-14">User Name</TextBlack>
                      <TextBlack>{profileData?.username}</TextBlack>
                      {/* )} */}
                    </div>
                    <div className="d-grid pb-4">
                      <TextBlack className="fs-14">Name</TextBlack>

                      <TextBlack>{profileData?.name}</TextBlack>
                    </div>{" "}
                    <div className="d-grid pb-4">
                      <TextBlack className="fs-14">Phone Number</TextBlack>
                      <TextBlack>+91 {profileData?.contact}</TextBlack>
                    </div>
                    <div className="d-grid pb-4">
                      <TextBlack className="fs-14">Email</TextBlack>
                      <TextBlack style={{ textTransform: "none" }}>
                        {profileData?.email}
                      </TextBlack>
                    </div>
                  </div>

                  <a
                    // href={
                    //   `${loginconfig.url}/profile/accountInfo?` +
                    //   `clientId=${
                    //     loginconfig.clientId
                    //   }&token=${localStorage.getItem("token")}`
                    // }
                    href={`${loginconfig.url}/profile/accountInfo?`}
                    target="_blank"
                    rel="noreferrer"
                    className="col-3"
                    style={{
                      width: "80px",  
                      height: "38px",
                      display: "flex",
                      gap: "5px",
                      borderRadius: "100px",
                      background: "none",
                      fontweight: "400",
                      border: "1px solid #000",
                      alignItems: "center",
                      padding: "5px 14px",
                      color: "#000",
                      textDecoration: "none",
                    }}
                  >
                    <img src={edit} alt="edit" /> <span>Edit</span>
                  </a>
                </div>
              )}
            </CustomPane>
            <CustomPane eventKey="Members">
              <Members profileData={profileData} isloading={isloading} />
            </CustomPane>
            <CustomPane eventKey="Organization">
              <Organization profileData={profileData} isloading={isloading} />
            </CustomPane>
          </CustomTabs2>
        </CustomProfileContainer>
      </div>
    </OverFlow>
  );
};
