import styled from "styled-components";

export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 8px;
  &.background {
    background: ${({ theme }) => theme.primaryDark};
  }
  &.padding-12-26 {
    padding: 12px 26px;
  }
  &.imgTxt {
    padding: 10px 0 10px 0;
    display: flex;
    align-items: center;
    gap: 15px;
    border: 1px solid #e5e5ee;
    margin-top: 5px;
  }
  &.imgTxt img {
    width: 70px;
    height: 70px;
    border-radius: 12px;
  }
`;

export const CardUpper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.background {
    background: ${({ theme }) => theme.primaryDark};
  }
`;
export const LowerCard = styled.div`
  padding-top: 15px;
  width: 100%;
  padding-bottom: 10px;
  background: ${({ theme }) => theme.white};
  background: #fff;
`;

export const CardVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 8px;
  &.padding-12-26 {
    padding: 12px 26px;
  }
`;

export const OverlayContainer = styled.div`
  width: 100%;
  height: 137px;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
`;

export const OverlayImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`;

export const CardSelect = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px;
  background: ${({ active }) => (active ? "#F2F2F2" : "transperant")};
  border-radius: 20px;
  align-self: center;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.lightText};
  font-size: 14px;
  font-weight: 400;
  width: 100%;
`;

//publisher hub components

export const CardBoxFlexible = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 5px;
  width: 224px;
  height: 149px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  border: ${({ theme, border }) => (border ? "2px dashed #bfbfbf" : "")};
  cursor: pointer;
  object-fit: fill;
  border-radius: 12px;
  border: 1px dashed var(--dark-mode-text-6, #bfbfbf);
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
`;

export const CardBoxImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 146px;
  width: 156px;
  background-color: #ffffff;
  border-radius: 8px;
  border: ${({ theme, border }) => (border ? "2px dashed #bfbfbf" : "")};
  color: #a6aab1;
  object-fit: contain;
`;

export const CardBoxProfile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 8px;
  overflow: hidden;
  border: ${({ theme, border }) => (border ? "2px dashed #bfbfbf" : "")};
  cursor: pointer;
`;

export const CardCheckBoxFlexible = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 1rem 0;
  border-radius: 8px;
`;

export const CardModalBody = styled.div`
  width: 100%;
  padding: 20px 10px;
  border: 2px dashed #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
`;

export const BlackWhiteDiv = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;
export const UpperBlack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #242528;
  color: ${({ theme }) => theme.primaryDark};
  padding: 12px 26px;
`;

export const LowerWhite = styled.div`
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.black};
`;

export const CardImageWrapper = styled.div`
  height: 250px;
  max-width: 300px;
  min-width: 50px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid;
`;

export const CardDescriptionWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
`;

export const ResultCard = styled.div`
  onhover {
    background-color: red;
  }
`;
