import React, { useEffect, useState } from "react";
import "./review.css";
import { publisher } from "redux/api";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import cross from "assets/crossForonboard.svg";

export const ReviewBox = () => {
  const [orgDetails, setOrgDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate()
  const fetchOrgDetails = async () => {
    setLoader(true);
    try {
      const data = await publisher.checkUser();
      setOrgDetails(data?.onboard);

      if (!data?.onboard) {
        navigate("/home");
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchOrgDetails();
  }, []);

  return (
    <div className="container">
      <div className="d-flex justify-content-center   ">
        <div
          className={`box ${orgDetails.status === "rejected" ? "centered-35" : "centered-30"
            }  `}>
          {loader ? (
            <Spinner animation="border" variant="primary" size="lg" />
          ) : (
            <div className="group">
              <div className="frame">
                <div className="div commonflex">
                  <div
                    className="frame-2"
                    style={{
                      borderRight: orgDetails?.comments
                        ? "1px solid #E6E6E6"
                        : "none",
                    }}>
                    <div className="div-wrapper">
                      <div className="text-wrapper w-auto">
                        Application Number : 000000
                        {orgDetails?.onBoardingNumber}
                      </div>
                    </div>
                    <div className="frame-wrapper">
                      <div className="frame-3">
                        <div className="div">
                          <div className="frame-4">
                            <div className="text-wrapper-2">
                              Application Submission
                            </div>
                          </div>
                          <div className="frame-5">
                            <div className="text-wrapper-2">In Review</div>
                          </div>
                          <div className="frame-6">
                            <div className="frame-7">
                              <img
                                className="img"
                                alt="Group"
                                src="https://generation-sessions.s3.amazonaws.com/db8b72124ccc2b6159d3f84a8cd4347c/img/group-3465169@2x.png"
                              />
                              <img
                                className="line"
                                alt="Line"
                                src="https://generation-sessions.s3.amazonaws.com/db8b72124ccc2b6159d3f84a8cd4347c/img/line-83.svg"
                              />
                              <img
                                className="img"
                                alt="Group"
                                src="https://generation-sessions.s3.amazonaws.com/db8b72124ccc2b6159d3f84a8cd4347c/img/group-3465169@2x.png"
                              />
                              <img
                                className="line"
                                alt="Line"
                                src="https://generation-sessions.s3.amazonaws.com/db8b72124ccc2b6159d3f84a8cd4347c/img/line-83.svg"
                              />
                              <img
                                className="group-2"
                                alt="Group"
                                src={
                                  orgDetails.status === "rejected"
                                    ? cross
                                    : "https://generation-sessions.s3.amazonaws.com/db8b72124ccc2b6159d3f84a8cd4347c/img/group-3465170@2x.png"
                                }
                              />
                            </div>
                            <div className="text-wrapper-2">
                              Account Created
                            </div>
                          </div>
                        </div>
                        <div className="button-lightmode-wrapper">
                          <div
                            className="button-lightmode"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {

                              localStorage.clear();
                              navigate("/");
                            }}>
                            <div className="text-wrapper-3">Logout</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* flex-elemnt div */}
                  <div className="flex-element mx-5">
                    {orgDetails.status === "rejected" && (
                      <>
                        <div className="rejected-message">
                          We regret to inform you that your account creation has
                          failed due to the following reason:
                          <p style={{ marginTop: "1rem" }}>
                            {orgDetails.comments}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="frame-8">
                <img
                  className="frame-9"
                  alt="Frame"
                  src="https://generation-sessions.s3.amazonaws.com/db8b72124ccc2b6159d3f84a8cd4347c/img/frame-697.svg"
                />
                <div className="text-wrapper-4">(Publisher hub)</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
