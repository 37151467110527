import ReacheckErrorst, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  ButtonPrimary,
  TextBlack,
  UploadText,
  UploadTextinfo,
} from "components/styled/common.styled";
import { useDispatch, useSelector } from "react-redux";
import { setFormDetails } from "redux/Project/project.action";
import { OverlayTrigger, Spinner, Tooltip, ProgressBar } from "react-bootstrap";
import Iicon from "assets/Iicon.svg";
import cross from "assets/crossNew.svg";
import imgIconWhite2 from "assets/imgIconWhite2.svg";
import { CustomAlertPopup } from "components/CustomAlert";
import { useParams } from "react-router-dom";
import { getUserToken } from "helper";
import Uploader from "ajnavidya-file-upload-client";
import { toast } from "react-toastify";
import ImageCroper from "components/ImageCroper/ImageCroper";
import { CustomModal } from "components/Modal/CustomModal";
import CaverInfoCard from "components/CaverInfoCard/CaverInfoCard";
import video from "assets/play.svg";
import videoPlay from "assets/videoPlay.svg";
import {
  ProgressCont,
  UploadFeatureVideocont,
  Uploading,
} from "components/styled/media.styled";
import imgIcon from "assets/imageIcon.svg";

const UploadCoverImg = () => {
  const [featurevideo, setFeaturevideo] = useState(false);
  const [aspectRatio, setAspectRatio] = useState("");
  const [croperImgwidth, setcroperImgwidth] = useState("");
  const [croperImgheight, setcroperImgheight] = useState("");
  const [coverImage, setCoverImage] = useState(null);

  const [isOpen, setIsOpen] = useState({
    modal: false,
    project: false,
    id: "",
  });
  const [isOpenSecondModal, setIsOpenSecondModal] = useState({
    modal: false,
    project: false,
    id: "",
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const { s3Key } = useParams();
  const [file, setFile] = useState({
    projectCardImage: "",
    featuredCardImage: "",
  });
  console.log("file111111", file);

  const [selectedVideoFile, setSelectedVideoFile] = useState(null);
  const [modal, setModal] = useState({ modal: false, text: "" });
  const [isImageuploading, setIsimageUploading] = useState(false);

  const { projectFormDetails } = useSelector((state) => state.projectReducer);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState({
    projectCardImage: false,
    featuredCardImage: false,
  });

  const validateAspectRatio = (file, fileType) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        let expectedAspectRatio;
        if (fileType === "projectCardImage") {
          // expectedAspectRatio = 16 / 17;
          expectedAspectRatio = 3 / 4;
          setcroperImgwidth(810);
          setcroperImgheight(1080);
        } else {
          expectedAspectRatio = 16 / 9;
          setcroperImgwidth(9120);
          setcroperImgheight(1080);
        }
        if (Math.abs(aspectRatio - expectedAspectRatio) > 0.5) {
          setIsOpen({
            ...isOpen,
            modal: true,
            file: file,
            id: fileType,
            name: file.name,
          });
          setAspectRatio(expectedAspectRatio);
        } else {
          resolve();
        }
      };
      img.onerror = () => {
        reject("Error loading image.");
      };
      img.src = file ? URL.createObjectURL(file) : "";
    });
  };

  const handleFileChange = async (e, imageType, id) => {
    // want to change file name from e.target.files[0].name
    const file = e.target.files[0];
    setCoverImage(file);
    const fileExtension = file?.name.split(".").pop().toLowerCase();

    const allowedImageExtensions = ["jpg", "jpeg", "png"];
    if (imageType === "Image" || imageType === "image") {
      if (!allowedImageExtensions.includes(fileExtension)) {
        toast.error("Please upload a valid image file.");
        return;
      }
    }

    // file name change random string to avoid cache

    // Check file size
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSizeInBytes) {
      return toast.warning("File size exceeds the 5MB limit.");
    }

    document.addEventListener("fileStateChanged", (event) => {
      const progress1 = event.progress;
      if (progress1 === 100) {
        setIsLoading({
          ...isLoading,
          [e.target.name]: false,
        });
      }
    });

    await validateAspectRatio(file, id || isOpen.id);

    try {
      setIsLoading({
        ...isLoading,
        [e.target.name]: true,
      });
      Uploader({
        file: e,
        url: {
          apiOrigin: "https://file-uploadv2.ajnavidya.com/",
          status: "/upload/status",
          upload: "/upload/files",
          complete: "/upload/complete",
        },
        projectID: s3Key,
        setFileURL: () => {},
        setProgress: () => {},
        userID: getUserToken()?.sub,
      });
      document.addEventListener(e.target.name, (event) => {
        const fileURL = event.data;
        setIsLoading({
          ...isLoading,
          [e.target.name]: false,
        });

        document.addEventListener("fileError", (event) => {
          setIsLoading({
            ...isLoading,
            [e.target.name]: false,
          });
          toast.error(event.error);
        });
        // Clear the input value after successfully handling the file change
        const fileInput = document.getElementsByName(e.target.name)[0];
        if (fileInput) {
          fileInput.value = "";
        }
        setIsOpen({
          modal: false,
          id: "",
          project: "",
        });
        dispatch(
          setFormDetails({
            mediafileURLs: {
              [e.target.name]: fileURL?.url,
            },
            fileSize: file?.size,
          })
        );
      });
    } catch (error) {
      setIsLoading({
        ...isLoading,
        [e.target.name]: false,
      });
    }
  };

  const handleVideoFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedVideoFile(file);
  };

  console.log("file", file);

  const handleFeaturevideoModal = () => {
    setFeaturevideo(true);
    setIsOpenSecondModal({ ...isOpenSecondModal, modal: true });
  };

  console.log("selectedVideoFile", selectedVideoFile);
  const handleUploadfeatureVideo = async () => {
    if (!selectedVideoFile) {
      toast.error("Please select a video file first.");
      return;
    }

    const file = selectedVideoFile;
    const fileExtension = file?.name.split(".").pop().toLowerCase();

    const allowedVideoExtensions = ["mp4", "webm", "ogg", "mov", "avi"];
    if (!allowedVideoExtensions.includes(fileExtension)) {
      toast.error("Please upload a valid video file.");
      return;
    }

    const maxSizeInBytes = 30 * 1024 * 1024; // 20MB
    if (file.size > maxSizeInBytes) {
      return toast.warning("File size exceeds the 30MB limit.");
    }

    try {
      setIsLoading({ ...isLoading, featuredCardImage: true });
      Uploader({
        file: { target: { files: [file] } },
        url: {
          apiOrigin: "https://file-uploadv2.ajnavidya.com/",
          status: "/upload/status",
          upload: "/upload/files",
          complete: "/upload/complete",
        },
        projectID: s3Key,
        setFileURL: (fileURL) => {
          // Set the file URL for preview
          setFile((prevFile) => ({
            ...prevFile,
            featuredCardImage: fileURL.url,
          }));
          dispatch(
            setFormDetails({
              mediafileURLs: { featuredCardImage: fileURL.url },
              fileSize: file?.size,
            })
          );
          setIsOpenSecondModal({ ...isOpenSecondModal, modal: false });
        },
        setProgress: (progress) => setUploadProgress(progress),
        userID: getUserToken()?.sub,
      });

      document.addEventListener("featuredCardImage", (event) => {
        setIsLoading({ ...isLoading, featuredCardImage: false });

        document.addEventListener("fileError", (event) => {
          setIsLoading({ ...isLoading, featuredCardImage: false });
          toast.error(event.error);
        });
        const fileInput = document.getElementsByName("featuredCardImage")[0];
        if (fileInput) {
          fileInput.value = "";
        }
        setIsOpenSecondModal({ modal: false, id: "", project: "" });
      });
    } catch (error) {
      setIsLoading({ ...isLoading, featuredCardImage: false });
    }
  };

  useEffect(() => {
    if (modal.modal) {
      const timeoutId = setTimeout(() => {
        setModal({ modal: false, text: "" });
      }, 2000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [modal]);
  console.log("files:coverImage", coverImage);

  return (
    <>
      {modal && (
        <div style={{ marginLeft: "5rem" }}>
          <CustomAlertPopup
            modal={modal}
            setModal={setModal}
            text={modal.text}
          />
        </div>
      )}
      {isOpen?.modal && (
        <CustomModal
          isOpen={isOpen?.modal}
          setIsOpen={setIsOpen}
          key={"abc"}
          size="lg"
          title={"Crop Your Image"}
          back={true}
          setIsLoading={setIsLoading}
          coverImage={coverImage}
          files={file}
          openFor={"FeaturevideoUpload"}
        >
          <ImageCroper
            file={isOpen?.file}
            id={isOpen.id}
            name={isOpen.name}
            handleFileChange={handleFileChange}
            expectedAspectRatio={aspectRatio}
            isLoading={isLoading[isOpen.id]}
            setIsLoading={setIsLoading}
            croperImgheight={croperImgheight}
            croperImgwidth={croperImgwidth}
          />
        </CustomModal>
      )}
      {featurevideo && isOpenSecondModal.modal && (
        <CustomModal
          isOpen={isOpenSecondModal.modal}
          setIsOpen={setIsOpenSecondModal}
          key={"def"}
          size="md"
          title={"Upload Feature Video"}
          back={true}
          setIsLoading={setIsLoading}
          setSelectedVideoFile={setSelectedVideoFile}
          selectedVideoFile={selectedVideoFile}
          openFor={"FeaturevideoUpload"}
        >
          <div className="d-flex flex-column align-items-center gap-4">
            <>
              <UploadFeatureVideocont
                onClick={() =>
                  document
                    .getElementById("file-upload_featuredCardImage")
                    .click()
                }
              >
                <div className="d-flex align-items-center flex-column justify-content-center">
                  <img
                    src={videoPlay}
                    alt="imgIcon"
                    style={{
                      background: "#6C56FB",
                      padding: "12px",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  />
                  <div className="d-flex flex-column py-2">
                    <UploadText className="videopaly">
                      Upload Feature Video
                    </UploadText>
                    <UploadTextinfo className="videopalyText">
                      {selectedVideoFile
                        ? selectedVideoFile?.name.slice(0, 50)
                        : "Max file size - 20MB Resolution - 1920x1080p Aspect Ratio- 16:9"}
                    </UploadTextinfo>
                  </div>
                </div>
              </UploadFeatureVideocont>
              <input
                type="file"
                name="featuredCardImage"
                id="file-upload_featuredCardImage"
                hidden
                accept="video/*"
                onChange={handleVideoFileChange}
                onClick={(e) => (e.target.value = null)}
              />
              {isLoading.featuredCardImage ? (
                <ProgressCont>
                  <Uploading> Uploading... </Uploading>
                  <ProgressBar now={uploadProgress} style={{ width: "100%" }} />
                </ProgressCont>
              ) : (
                <ButtonPrimary width={"25%"} onClick={handleUploadfeatureVideo}>
                  Upload
                </ButtonPrimary>
              )}
            </>
          </div>
        </CustomModal>
      )}
      <div className="px-2 d-flex" style={{ gap: "24px " }}>
        <div
          className="leftbox"
          style={{
            border:
              projectFormDetails?.mediafileURLs?.projectCardImage && "none",
          }}
        >
          {(file.projectCardImage ||
            projectFormDetails?.mediafileURLs?.projectCardImage) &&
          !isLoading?.projectCardImage ? (
            <div className="imgCont2" style={{ width: "100%", height: "100%" }}>
              <div style={{ width: "100%", height: "100%" }}>
                <img
                  width={"100%"}
                  height={"100%"}
                  src={
                    projectFormDetails?.mediafileURLs?.projectCardImage ||
                    file.projectCardImage
                  }
                  alt="imgIcon"
                  style={{ borderRadius: "12px" }}
                />
              </div>
              <div className="overlayDiv">
                <img height={"30px"} src={imgIconWhite2} alt="img" />
                <TextBlack
                  className="fs-16 text-white cursor-pointer"
                  onClick={() => {
                    document.getElementById("projectCardImage").click();
                    setFile((prevFile) => ({
                      ...prevFile,
                      projectCardImage: "",
                    }));
                  }}
                >
                  Replace Cover Image
                </TextBlack>
                <img
                  className="cross-sign cursor-pointer"
                  src={cross}
                  onClick={() => {
                    dispatch(
                      setFormDetails({
                        mediafileURLs: { projectCardImage: "" },
                        mediaSize:
                          file.size +
                            projectFormDetails?.mediafileURLs?.mediaSize || 0,
                      })
                    );
                    setFile((prevFile) => ({
                      ...prevFile,
                      projectCardImage: "",
                    }));
                  }}
                  alt="x"
                />
              </div>
            </div>
          ) : (
            <div
              className="leftchild2"
              onClick={() => {
                document.getElementsByName("projectCardImage")[0]?.click();
              }}
            >
              {isLoading?.projectCardImage && (
                <Spinner animation="border" role="status" size="sm" />
              )}
              {!isLoading?.projectCardImage && (
                <>
                  <input
                    type="file"
                    name="projectCardImage"
                    id="projectCardImage"
                    hidden
                    accept="Image/*"
                    onChange={(e) =>
                      handleFileChange(e, "Image", "projectCardImage")
                    }
                    onClick={(e) => (e.target.value = null)}
                  />
                  <img src={imgIcon} alt="imgIcon" />
                  <div className="d-flex flex-column py-2">
                    <UploadText>Upload Cover Image</UploadText>
                    <UploadTextinfo>
                      Max file size - 5MB Resolution - 810x1080p Aspect Ratio -
                      3:4
                    </UploadTextinfo>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div style={{ marginTop: "-35px" }}>
          <div className="d-flex align-items-baseline gap-2">
            <div className="fs-18 fw-400 pb-2">Feature Video </div>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip" className="custom-tooltip">
                  <CaverInfoCard
                    text={
                      "A feature video is a powerful way to showcase your experience when it's featured on the platform. It's essential to follow the suggested file size, resolution, and ratios to ensure the best display quality."
                    }
                    width={"346px"}
                  />
                </Tooltip>
              }
            >
              <img
                src={Iicon}
                alt="Iicon"
                style={{
                  width: "14px",
                  height: "14px",
                  cursor: "pointer",
                }}
              />
            </OverlayTrigger>
          </div>
          {(file?.featuredCardImage && !isLoading?.featuredCardImage) ||
          projectFormDetails?.mediafileURLs?.featuredCardImage ? (
            <div
              className="imgCont2  rightbox no-border"
              style={{ border: !isLoading.featuredCardImage ? "none" : "" }}
            >
              <div className="w-100 h-100">
                <video
                  width="100%"
                  height="100%"
                  controls
                  style={{ borderRadius: "12px", objectFit: "cover" }}
                >
                  <source
                    src={
                      projectFormDetails?.mediafileURLs?.featuredCardImage ||
                      file?.featuredCardImage
                    }
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="overlayDiv">
                <img height={"30px"} src={imgIconWhite2} alt="img" />
                <TextBlack
                  className="fs-16 text-white cursor-pointer"
                  onClick={() => {
                    document
                      .getElementById("file-upload_featuredCardImage")
                      ?.click();
                  }}
                >
                  Replace Poster Image
                </TextBlack>
                <img
                  className="cross-sign cursor-pointer"
                  src={cross}
                  onClick={() => {
                    setFile({
                      projectCardImage: file.projectCardImage,
                      featuredCardImage: "",
                    });
                    dispatch(
                      setFormDetails({
                        mediafileURLs: {
                          featuredCardImage: "",
                          mediaSize:
                            file.size +
                              projectFormDetails?.mediafileURLs?.mediaSize || 0,
                        },
                      })
                    );
                  }}
                  alt="x"
                />
              </div>
            </div>
          ) : (
            <>
              <div
                className="rightbox"
                style={{
                  border:
                    projectFormDetails?.mediafileURLs?.featuredCardImage &&
                    "none",
                }}
                onClick={handleFeaturevideoModal}
              >
                {isLoading?.featuredCardImage ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  <div>
                    <div className="d-flex align-items-center flex-column">
                      <img src={video} alt="imgIcon" />
                      <div className="d-flex flex-column py-2">
                        <UploadText>Upload Feature Video</UploadText>
                        <UploadTextinfo>
                          Max file size - 20MB Resolution - 1920x1080p Aspect
                          Ratio - 16:9
                        </UploadTextinfo>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <input
        type="file"
        name="featuredCardImage"
        id="file-upload_featuredCardImage"
        hidden
        accept="video/*"
        onChange={handleVideoFileChange}
        onClick={(e) => (e.target.value = null)}
      />
    </>
  );
};

export default UploadCoverImg;
