import { BoxWrapper, TextBlack } from "components/styled/common.styled";
import {
  AccNumber,
  AccountList,
  AddnewAccount,
  PaymentCard,
} from "components/styled/payment.styled";
import React, { useEffect } from "react";
import "./paymentSort.css";
import add1 from "assets/add1.png";
import deleteIcon from "assets/deleteIcon.svg";

import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";
import { Link } from "react-router-dom";
import { CustomAlertPopup } from "components/CustomAlert";
import { variants, buttonConfigs } from "components/CustomAlert/constant";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsList,
  delAccount,
  setPrimaryAccount,
} from "redux/Project/project.action";
import { maskAccountNumber } from "./AccountNumber";
import { CustomTabs } from "components/Tabs/CustomTabs";
import { CustomPane } from "components/Tabs/customTabs.styled";
import { TableContainer } from "components/Table/table.styled";
import { useState } from "react";
import { getUserToken } from "helper";
import CustomBreadcrumb from "components/CustomBreadScrum";
import search from "assets/search.svg";
import { toast } from "react-toastify";
import downloadIcon from "assets/document-download.svg";
import Skeleton from "react-loading-skeleton";

const Payment = () => {
  const dispatch = useDispatch();
  const [bankId, setBankId] = useState("");
  const bankList = useSelector((state) => state.projectReducer.bankAccounts);
  const [modal, setModal] = useState({
    modal: false,
    text: "",
    bankId: "",
    isPtimary: "",
  });
  const [variant, setVariant] = useState(1);
  const selectedVariant = variants[variant];

  const [sortOrder, setSortOrder] = useState("newToOld");
  const [earningsSortOption, setEarningsSortOption] = useState("newToOld");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [accountListLoading, setAccountListLoading] = useState(true);

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  // search function in payment hystory
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    dispatch(getAccountsList()).then((response) => {
      if (response) {
        setAccountListLoading(false);
      }
    });
  }, [dispatch]);

  const deleteAccount = () => {
    const bankId = modal.bankId;
    dispatch(delAccount(bankId))
      .then((response) => {
        if (response.error) {
          setVariant(0);
          setModal({
            modal: true,
            text: response.error,
            bankId: bankId,
          });
        } else {
          toast.success("Your Account has been deleted successfull");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchBankList = (data) => {
    return data;
  };

  let earnings = [];
  let ordersData = [];
  let sampleData = [];
  if (getUserToken()?.email === "pankaj@ajnalens.com") {
    sampleData = [
      {
        id: 1,
        Date: "2023-10-01",
        TransactionId: 12356,
        amount: 23370,
        status: "In Progress",
      },
      {
        id: 2,
        Date: "2023-09-01",
        TransactionId: 45689,
        amount: 18856,
        status: "Received",
      },
      {
        id: 3,
        Date: "2023-08-01",
        TransactionId: 85296,
        amount: 28284,
        status: "Received",
      },
      {
        id: 4,
        Date: "2023-07-01",
        TransactionId: 74185,
        amount: 16499,
        status: "Received",
      },
      {
        id: 5,
        Date: "2023-06-01",
        TransactionId: 45875,
        amount: 7071,
        status: "Received",
      },
      {
        id: 6,
        Date: "2023-05-01",
        TransactionId: 12480,
        amount: 30641,
        status: "Received",
      },
      {
        id: 7,
        Date: "2023-04-01",
        TransactionId: 74185,
        amount: 16499,
        status: "Received",
      },
      {
        id: 8,
        Date: "2023-03-01",
        TransactionId: 14524,
        amount: 28284,
        status: "Received",
      },
      {
        id: 9,
        Date: "2023-02-01",
        TransactionId: 45721,
        amount: 25927,
        status: "Received",
      },
      {
        id: 10,
        Date: "2023-01-01",
        TransactionId: 74185,
        amount: 14449,
        status: "Received",
      },
      {
        id: 11,
        Date: "2022-12-01",
        TransactionId: 47125,
        amount: 25927,
        status: "Received",
      },
      {
        id: 12,
        Date: "2022-11-01",
        TransactionId: 74862,
        amount: 23750,
        status: "Received",
      },
      {
        id: 13,
        Date: "2022-10-01",
        TransactionId: 74185,
        amount: 23750,
        status: "Received",
      },
    ];
    earnings = [
      {
        id: 1,
        Date: "2023-10-01",
        TransactionType: "Monthly Payroll",
        TransactionID: 12356,
        Amount: 23370,
        Receipt:
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      },
      {
        id: 2,
        Date: "2023-09-01",
        TransactionType: "Monthly Payroll",
        TransactionID: 45689,
        Amount: 18856,
        Receipt:
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      },
      {
        id: 3,
        Date: "2023-08-01",
        TransactionType: "Monthly Payroll",
        TransactionID: 85296,
        Amount: 28284,
        Receipt:
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      },
      {
        id: 4,
        Date: "2023-07-01",
        TransactionType: "Monthly Payroll",
        TransactionID: 74185,
        Amount: 16499,
        Receipt:
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      },
      {
        id: 5,
        Date: "2023-06-01",
        TransactionType: "Monthly Payroll",
        TransactionID: 45875,
        Amount: 7071,
        Receipt:
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      },
    ];
    ordersData = [
      {
        id: 1,
        Date: "2023-10-01",
        PurchaseName: "Fitter Training Story Board",
        TransactionID: 12356,
        Amount: 39370,
        Receipt:
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      },
      {
        id: 2,
        Date: "2023-09-01",
        PurchaseName: "Welding MarketStudy Data",
        TransactionID: 45689,
        Amount: 74856,
        Receipt:
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      },
      {
        id: 3,
        Date: "2023-08-01",
        PurchaseName: "Welding MarketStudy Data",
        TransactionID: 85296,
        Amount: 7284,
        Receipt:
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      },
    ];
  }

  const sortedEarnings = [...earnings].sort((a, b) => {
    if (earningsSortOption === "newToOld") {
      return new Date(b.Date) - new Date(a.Date);
    } else if (earningsSortOption === "oldToNew") {
      return new Date(a.Date) - new Date(b.Date);
    }
    return new Date(b.Date) - new Date(a.Date);
  });

  const sortedOrdersData = [...ordersData].sort((a, b) => {
    if (sortOrder === "newToOld") {
      return new Date(b.Date) - new Date(a.Date);
    } else if (sortOrder === "oldToNew") {
      return new Date(a.Date) - new Date(b.Date);
    }
    return new Date(b.Date) - new Date(a.Date);
  });
  return (
    <>
      {modal && (
        <div>
          <CustomAlertPopup
            modal={modal}
            setModal={setModal}
            selectedVariant={selectedVariant}
            text={modal.text}
            buttonType={
              selectedVariant.title === "Warning" ? buttonConfigs.YesNo : null
            }
            sendTo={"/payment"}
            triggerEvent={() => deleteAccount()}
            titleText="Do you want delete this account?"
          />
        </div>
      )}
      <div className="Paymentcont">
        <CustomBreadcrumb items={[{ text: "Payments", link: "/payment" }]} />
        {/* payment card */}
        <PaymentCard className="col-10">
          <CustomTabs
            tabTitles={["Earnings", "Order History", "Saved Bank Accounts"]}
            tabs={""}
            page={[""]}
            type={"plane"}
          >
            <CustomPane eventKey="Saved Bank Accounts">
              <AddnewAccount>
                <Link to="/AddAccount">
                  <div className="add">
                    <img
                      src={add1}
                      alt="add"
                      style={{
                        width: "21px",
                        height: "21px",
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </Link>
                <TextBlack className="fs-14-500">Add New account</TextBlack>
              </AddnewAccount>

              <div>
                {accountListLoading ? (
                  <>
                    <div className="border-bottom d-flex flex-column gap-2">
                      <Skeleton height={"15px"} width={"200px"} />
                      <Skeleton height={"15px"} width={"130px"} />
                      <Skeleton height={"15px"} width={"100px"} />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="border-bottom d-flex flex-column gap-2">
                        <Skeleton height={"15px"} width={"200px"} />
                        <Skeleton height={"15px"} width={"130px"} />
                      </div>
                      <div className="d-flex gap-2 mt-4">
                        <Skeleton height={"15px"} width={"80px"} />
                        <Skeleton height={"15px"} width={"40px"} />
                        <Skeleton height={"15px"} width={"40px"} />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {bankList &&
                      fetchBankList(
                        bankList?.sort((a, b) => b.isPrimary - a.isPrimary)
                      )?.map((bank, index) => (
                        <div className="addacc" key={bank?._id}>
                          <AccountList style={{ fontSize: "12px" }}>
                            <AccNumber style={{ marginBottom: "0px" }}>
                              {maskAccountNumber(bank?.accountNumber)}
                            </AccNumber>
                            <p>
                              {bank.accountType} <br />
                              {bank.isPrimary && "Primary"}
                            </p>
                          </AccountList>

                          {!bank.isPrimary && (
                            <div
                              className="del"
                              style={{
                                display: bank?.isPrimary ? "none" : "flex",
                              }}
                            >
                              <TextBlack className="fs-12-400">
                                Set primary
                              </TextBlack>
                              <ToggleSwitch
                                name={bank._id}
                                checked={bankId === bank._id ? true : false}
                                isPrimary={bank.isPrimary ? true : false}
                                setBankId={setBankId}
                                setPrimaryAccount={setPrimaryAccount}
                              />

                              <img
                                src={deleteIcon}
                                alt="deleteIcon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setModal({
                                    modal: true,
                                    text: "Are you sure you want to delete this bank Account? ",
                                    bankId: bank._id,
                                  });
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                  </>
                )}
              </div>
            </CustomPane>

            <CustomPane eventKey="Order History">
              {ordersData.length !== 0 && (
                <div className="date-sort-filter mx-2 pb-3">
                  <div style={{ margin: "0rem" }}>
                    <BoxWrapper
                      height={"34px"}
                      // width={"100%"}
                      className=" d-flex justify-content-start background  border-radius-50 mx-3"
                    >
                      <img
                        src={search}
                        height={"20px"}
                        alt="search"
                        style={{ height: "14px", width: "14px" }}
                      />
                      <input
                        placeholder="Search Product Name"
                        className="border-0"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        onChange={(event) => handleSearchChange(event)}
                      />
                    </BoxWrapper>
                  </div>

                  <div>
                    <label htmlFor="sortOrder" style={{ fontSize: "16px" }}>
                      Sort By :
                    </label>
                    <select
                      id="sortOrder"
                      value={sortOrder}
                      onChange={(event) => setSortOrder(event.target.value)}
                    >
                      <option value="newToOld">New to Old</option>
                      <option value="oldToNew">Old to New</option>
                    </select>
                  </div>
                </div>
              )}

              <TableContainer
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",
                }}
              >
                <thead
                  style={{
                    color: "#fff",
                    borderRadius: "8px 8px 0px 0px",
                    backgroundColor: "black",
                  }}
                >
                  <tr>
                    {ordersData.length !== 0 &&
                      Object.keys(ordersData?.[0])
                        .filter((key) => key !== "id")
                        .map((key) => (
                          <th key={key} className="col-3 py-3 px-4">
                            {key?.charAt(0)?.toUpperCase() + key?.slice(1)}
                          </th>
                        ))}
                  </tr>
                </thead>
                <tbody>
                  {ordersData.length !== 0 &&
                    sortedOrdersData
                      ?.filter((item) =>
                        item.PurchaseName.toLowerCase().includes(
                          searchTerm.toLowerCase()
                        )
                      )
                      .map((item) => (
                        <tr key={item.id}>
                          {Object.keys(item)
                            .filter((key) => key !== "id") // Exclude 'id' from rendering
                            .map((key) => {
                              let className = "";
                              if (key === "Receipt") {
                                className = "sam";
                                return (
                                  <td
                                    key={key}
                                    style={{
                                      fontSize: "15px",
                                      padding:
                                        key === "Receipt"
                                          ? "10px 1rem"
                                          : "10px 5px",
                                    }}
                                  >
                                    <div className="d-flex gap-2">
                                      <div
                                        className={className}
                                        style={{
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          window.open(
                                            item[key],
                                            "_blank",
                                            "noopener noreferrer"
                                          );
                                        }}
                                      >
                                        View
                                      </div>{" "}
                                      <div style={{ color: "#E6E6E6" }}>|</div>{" "}
                                      <a
                                        href={item[key]}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        download={item[key]} // Specify the desired file name
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <img src={downloadIcon} alt="" />
                                      </a>
                                    </div>
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={key}
                                    style={{
                                      fontSize: "15px",
                                      padding:
                                        key !== "status"
                                          ? "10px 26px"
                                          : "10px 5px",
                                    }}
                                    className={className}
                                  >
                                    {key === "Amount"
                                      ? `₹ ${item[key]}`
                                      : item[key]}
                                  </td>
                                );
                              }
                            })}
                        </tr>
                      ))}
                </tbody>
              </TableContainer>
              {ordersData.length === 0 && (
                <div style={{ textAlign: "center", color: "gray" }}>
                  No Transaction History
                </div>
              )}
            </CustomPane>

            <CustomPane eventKey="Earnings">
              {sortedEarnings.length !== 0 && (
                <div>
                  {" "}
                  <div className="date-sort-filter mx-2 pb-3">
                    <div>
                      <div>
                        <label htmlFor="sortOrder">Sort By: </label>
                        <select
                          id="sortOrder"
                          value={earningsSortOption}
                          onChange={(event) =>
                            setEarningsSortOption(event.target.value)
                          }
                        >
                          <option value="newToOld">New to Old</option>
                          <option value="oldToNew">Old to New</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label htmlFor="startDate">Filter By Date: </label>
                      <input
                        type="date"
                        id="startDate"
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                      <span className="to-text">To</span>
                      <input
                        type="date"
                        id="endDate"
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </div>
                  </div>
                  <TableContainer>
                    <thead
                      style={{
                        color: "#fff",
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: "black",
                        border: "1px solid red",
                      }}
                    >
                      <tr>
                        {earnings.length !== 0 &&
                          Object.keys(earnings?.[0])
                            .filter((key) => key !== "id")
                            .map((key) => (
                              <th key={key} className="col-3 py-3 px-4 ">
                                {key?.charAt(0)?.toUpperCase() + key?.slice(1)}
                              </th>
                            ))}
                      </tr>
                    </thead>
                    <tbody>
                      {earnings.length !== 0 &&
                        sortedEarnings?.map((item) => (
                          <tr key={item.id}>
                            {Object.keys(item)
                              .filter((key) => key !== "id") // Exclude 'id' from rendering
                              .map((key) => {
                                let className = "";
                                if (key === "Receipt") {
                                  className = "sam";
                                  return (
                                    <td
                                      key={key}
                                      style={{
                                        fontSize: "15px",
                                        padding:
                                          key === "Receipt"
                                            ? "10px 1rem"
                                            : "10px 5px",
                                      }}
                                    >
                                      <div className="d-flex gap-2">
                                        <div
                                          className={className}
                                          style={{
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            window.open(
                                              item[key],
                                              "_blank",
                                              "noopener noreferrer"
                                            );
                                          }}
                                        >
                                          View
                                        </div>{" "}
                                        <div style={{ color: "#E6E6E6" }}>
                                          |
                                        </div>{" "}
                                        <a
                                          href={item[key]}
                                          target="_blank"
                                          rel="noreferrer noopener"
                                          download={item[key]} // Specify the desired file name
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img src={downloadIcon} alt="" />
                                        </a>
                                      </div>
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td
                                      key={key}
                                      style={{
                                        fontSize: "15px",
                                        padding:
                                          key !== "status"
                                            ? "10px 26px"
                                            : "10px 5px",
                                      }}
                                      className={className}
                                    >
                                      {key === "Amount"
                                        ? `₹ ${item[key]}`
                                        : item[key]}
                                    </td>
                                  );
                                }
                              })}
                          </tr>
                        ))}
                    </tbody>
                  </TableContainer>
                </div>
              )}
              {earnings.length === 0 && (
                <div style={{ textAlign: "center", color: "gray" }}>
                  No Data Available
                </div>
              )}
            </CustomPane>
          </CustomTabs>
        </PaymentCard>
      </div>
    </>
  );
};

export default Payment;
