import {
  ButtonCancel,
  ButtonPrimary,
  OverFlow,
  PrimaryButton,
  TextBlack,
  TextWhite,
} from "components/styled/common.styled";
import React, { useEffect, useState } from "react";
import { LowerCont } from "./LowerCont";
import { CardBlackWhite } from "components/card/Card";
import CustomBreadcrumb from "components/CustomBreadScrum";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteApplicationVersion,
  getAppReviewStatus,
  makeAPPLive,
} from "redux/Project/project.action";
import { ProjectInfo } from "components/ProjectInfo/ProjectInfo";
import { Radio } from "components/Radio/Radio";
import editIcon from "assets/edit-2.svg";
import { CustomAlertPopup } from "components/CustomAlert";
import { toast } from "react-toastify";
import { UUIDGenerator, getDateByUpdate } from "helper";
import {
  CardContainer,
  KeyTxt,
  TextBtn,
  TextBtnCont,
} from "components/styled/ProjectDetails.style";
import { KeyValue } from "components/styled/projectreview.styled";

const ReviewStatus = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const { appReviewStatus } = useSelector((state) => state.projectReducer);
  const lowerContent = "";
  const now = new Date();
  const today = now.toISOString().split("T")[0];
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const currentTime = `${hours}:${minutes}`;
  const [modal, setModal] = useState({
    modal: false,
    text: "",
  });
  const [selectedValue, setSelectedValue] = useState("");
  const {
    inputValue: { channelRadio },
  } = useSelector((state) => state.projectReducer);

  // States to store the selected date and time
  // Handlers to update state
  const handleDateChange = (e) => {
    if (channelRadio === "Make App Live now") {
      return alert(
        "You cannot select date and time if you want to make app live now"
      );
    }
    setSelectedDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };
  useEffect(() => {
    if (id) {
      dispatch(getAppReviewStatus(id));
    } else {
      toast.error("App not in projects under review");
      navigate("/");
    }
  }, [id]);

  function isTimeDifferenceValid(selectedTime) {
    // Get current date
    const currentDate = new Date();

    // Parse selected time string and set it to the current date
    const selectedTimeParts = selectedTime.split(":");
    const selectedTimeObj = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      selectedTimeParts[0],
      selectedTimeParts[1]
    );

    // Calculate time difference in milliseconds
    const timeDifference = selectedTimeObj.getTime() - currentDate.getTime();

    // Convert time difference to minutes
    const timeDifferenceInMinutes = timeDifference / (1000 * 60);

    return timeDifferenceInMinutes >= 5;
  }
  const makeLiveProject = () => {
    let schedule = false;
    if (channelRadio === "") {
      return setModal({
        modal: true,
        text: "Please select the time you want to make your app live",
      });
    } else {
      if (channelRadio === "Make App Live on Scheduled Date and Time") {
        if (!selectedDate || !selectedTime) {
          return setModal({
            modal: true,
            text: "Please select a date and time",
          });
        }
        if (!isTimeDifferenceValid(selectedTime)) {
          return setModal({
            modal: true,
            text: "Time difference should be greater than 5 minutes to the current time",
          });
        }

        schedule = true;
      }
      if (id) {
        if (schedule) {
          // Combine date and time
          const combinedDateTime = `${selectedDate}T${selectedTime}:00`;

          // Create a date object
          const date = new Date(combinedDateTime);

          // Convert to UTC
          // Function to format the date in the desired format
          function formatDate() {
            // Add padding for single digit numbers
            const pad = (num) => num.toString().padStart(2, "0");

            // Format the date and time
            const year = date.getFullYear();
            const month = pad(date.getMonth() + 1); // Month is 0-indexed
            const day = pad(date.getDate());
            const hours = pad(date.getHours());
            const minutes = pad(date.getMinutes());
            const seconds = pad(date.getSeconds());
            const milliseconds = date
              .getMilliseconds()
              .toString()
              .padStart(3, "0");

            // Format timezone offset
            const offset = -date.getTimezoneOffset();
            const offsetSign = offset >= 0 ? "+" : "-";
            const offsetHours = pad(Math.floor(Math.abs(offset) / 60));
            const offsetMinutes = pad(Math.abs(offset) % 60);

            // Construct the formatted string
            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
          }
          setEdit(true);

          return dispatch(
            makeAPPLive(appReviewStatus?.projectID, id, schedule, {
              scheduleDate: formatDate(),
            })
          );
        }
        return dispatch(
          makeAPPLive(appReviewStatus?.projectID, id, schedule, {})
        );
      }
    }
  };

  function ReadableDateTime({ isoString }) {
    const dateObj = new Date(isoString);

    return (
      <>
        Date:{" "}
        {`${String(dateObj.getDate()).padStart(2, "0")}-${String(
          dateObj.getMonth() + 1
        ).padStart(2, "0")}-${dateObj.getFullYear()}`}{" "}
        Time:{" "}
        {`${String(dateObj.getHours()).padStart(2, "0")}:${String(
          dateObj.getMinutes()
        ).padStart(2, "0")}`}
      </>
    );
  }

  const breadcrumbItems = [
    { text: "Projects Under Review", link: "/projects_reviews" },
    {
      text: `${appReviewStatus?.projectName}`,
      link: `/review_details${appReviewStatus?.projectID}`,
    },
  ];

  const lowerContentReviewForApproved = (
    <div className="p-3">
      <TextBlack className="fs-18 fw-600 mb-3">Congratulations! </TextBlack>
      <TextBlack className="fs-16 mb-3 border-bottom pb-3">
        Your project has successfully cleared all the necessary review checks.{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M20.3936 11.0015C20.3756 10.3555 20.1786 9.72647 19.8236 9.18547C19.4696 8.64547 18.9716 8.21347 18.3856 7.93947C18.6086 7.33247 18.6556 6.67547 18.5256 6.04247C18.3946 5.40847 18.0886 4.82447 17.6436 4.35547C17.1736 3.91047 16.5906 3.60547 15.9566 3.47347C15.3236 3.34347 14.6666 3.39047 14.0596 3.61347C13.7866 3.02647 13.3556 2.52747 12.8146 2.17347C12.2736 1.81947 11.6446 1.62147 10.9976 1.60547C10.3516 1.62247 9.72456 1.81847 9.18456 2.17347C8.64456 2.52847 8.21556 3.02747 7.94456 3.61347C7.33656 3.39047 6.67756 3.34147 6.04256 3.47347C5.40756 3.60347 4.82256 3.90947 4.35256 4.35547C3.90756 4.82547 3.60356 5.41047 3.47456 6.04347C3.34456 6.67647 3.39456 7.33347 3.61856 7.93947C3.03156 8.21347 2.53156 8.64447 2.17556 9.18447C1.81956 9.72447 1.62056 10.3545 1.60156 11.0015C1.62156 11.6485 1.81956 12.2775 2.17556 12.8185C2.53156 13.3585 3.03156 13.7905 3.61856 14.0635C3.39456 14.6695 3.34456 15.3265 3.47456 15.9595C3.60456 16.5935 3.90756 17.1775 4.35156 17.6475C4.82156 18.0905 5.40556 18.3945 6.03856 18.5255C6.67156 18.6575 7.32856 18.6095 7.93556 18.3895C8.20956 18.9755 8.64056 19.4735 9.18156 19.8285C9.72156 20.1825 10.3516 20.3795 10.9976 20.3975C11.6446 20.3815 12.2736 20.1845 12.8146 19.8305C13.3556 19.4765 13.7866 18.9765 14.0596 18.3905C14.6636 18.6295 15.3256 18.6865 15.9626 18.5545C16.5986 18.4225 17.1826 18.1075 17.6426 17.6475C18.1026 17.1875 18.4186 16.6035 18.5506 15.9665C18.6826 15.3295 18.6256 14.6675 18.3856 14.0635C18.9716 13.7895 19.4696 13.3585 19.8246 12.8175C20.1786 12.2775 20.3756 11.6475 20.3936 11.0015ZM9.65956 14.8515L6.23056 11.4235L7.52356 10.1215L9.59556 12.1935L13.9956 7.39947L15.3426 8.64547L9.65956 14.8515Z"
            fill="#20A019"
          />
        </svg>
      </TextBlack>{" "}
      {appReviewStatus?.releaseDate && !edit ? (
        <div>
          <TextBlack className="fs-18 fw-600 mb-3">
            {" "}
            <button
              style={{ background: "none", border: "0px", marginRight: "5px" }}
              onClick={() => setEdit(!edit)}
            >
              {" "}
              <img src={editIcon} alt="" />
            </button>
            Your app is scheduled to go live on Date:{" "}
            <ReadableDateTime isoString={appReviewStatus?.releaseDate} />{" "}
          </TextBlack>
        </div>
      ) : (
        <div>
          <TextBlack className="fs-18 fw-600 mb-3">
            Choose When You Want to Publish Your App{" "}
          </TextBlack>
          <Radio name={"Make App Live now"} value={"Make App Live now"} />
          <Radio
            name={"Make App Live on Scheduled Date and Time"}
            value={"Make App Live on Scheduled Date and Time"}
          />
          <div className="d-flex justify-content-start gap-3 align-items-center my-3 mb-4">
            <TextBlack className="fs-14 fw-bold">Select Date</TextBlack>{" "}
            <input
              type="date"
              id="startDate"
              className="uneditable-input"
              value={selectedDate}
              min={today} // Sets the minimum selectable date to today
              onChange={handleDateChange}
              disabled={
                channelRadio === "Make App Live now" || channelRadio === ""
              }
            />
            <TextBlack className="fs-14 fw-bold">Select Time </TextBlack>
            <input
              type="time"
              id="startDate"
              className="uneditable-input"
              min={currentTime} // Sets the minimum selectable time based on the selected date
              onChange={handleTimeChange}
              disabled={!selectedDate || channelRadio === "Make App Live now"} // Disable time input if no date is selectedselectedDate === today ? currentTime : '23:59'
            />
          </div>
          <div className="col-2 d-flex">
            <ButtonPrimary
              className="px-4"
              height="43px"
              onClick={makeLiveProject}
            >
              Confirm
            </ButtonPrimary>
            <ButtonCancel
              className="px-4"
              height="43px"
              onClick={() => setEdit(!edit)}
            >
              Cancel
            </ButtonCancel>
          </div>
        </div>
      )}
    </div>
  );

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
  };

  const handleRejection = () => {
    const appID = appReviewStatus?.appID;
    const applicationType = appReviewStatus?.applicationType;
    const rcName = appReviewStatus?.rcName;
    const projectID = appReviewStatus?.projectID;

    if (selectedValue === "Discard" && appID) {
      dispatch(deleteApplicationVersion(appID)).then((res) => {
        alert(res.payload.message);
        navigate("/projects_reviews");
      });
    } else if (selectedValue === "Reupload") {
      navigate(
        `/appDetails/${UUIDGenerator()}?rcName=${rcName}&appId=${appID}&&projectId=${projectID}&applicationType=${applicationType}&infoType=new&query=null`
      );
    }
  };

  const lowerContentReviewForRejected = (
    <div className="p-3">
      <TextBlack className="fs-18 fw-600 mb-3">Sorry! </TextBlack>
      <TextBlack className="fs-16 mb-3 border-bottom pb-3">
        But you are failed in review check guideline careful.{" "}
      </TextBlack>{" "}
      <div>
        <TextBlack className="fs-18 fw-600 my-3">Take action </TextBlack>
        <div className="d-flex py-2 cursor-pointer flex-column gap-3">
          <label>
            <input
              type="radio"
              name="rejected"
              value="Discard"
              checked={selectedValue === "Discard"}
              onChange={handleRadioChange}
              className="form-check-input me-3"
            />
            Discard
          </label>
          <label>
            <input
              type="radio"
              name="rejected"
              value="Reupload"
              checked={selectedValue === "Reupload"}
              onChange={handleRadioChange}
              className="form-check-input me-3"
            />
            Reupload Version
          </label>
        </div>
        <div className="col-2 d-flex mt-3">
          <ButtonPrimary
            width={"100px"}
            height="39px"
            onClick={() => handleRejection()}
          >
            Confirm
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {modal && (
        <div style={{ marginTop: "-0.5rem", marginLeft: "0rem", top: "20px" }}>
          <CustomAlertPopup
            modal={modal}
            setModal={setModal}
            text={modal.text}
            titleText="App Live Information"
          />
        </div>
      )}
      <OverFlow>
        <div className="row mx-4 col-lg-10 col-md-10 col-12">
          <TextBlack
            className="fs-14-500"
            style={{ borderBottom: "1px solid #D9D9D9" }}
          >
            <CustomBreadcrumb items={breadcrumbItems} />
          </TextBlack>

          <div className="mb-3">
            {appReviewStatus?.projectName && (
              <CardContainer>
                <div className="imgCont">
                  <img
                    src={appReviewStatus?.logoURL}
                    alt="img"
                    onError={(e) =>
                      (e.target.src =
                        "https://cdn.ajnavidya.com/ajnavidya/no%20thumbnail.png")
                    }
                  />
                </div>
                <TextBtnCont>
                  <TextBtn>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "24px",
                          textTransform: "capitalize",
                          color: "#161719",
                          fontWeight: "600",
                          marginTop: "-6px",
                        }}
                      >
                        {appReviewStatus?.projectName}
                      </p>
                      <KeyValue style={{ marginTop: "-20px" }}>
                        <KeyTxt> Release Channel - &nbsp;</KeyTxt>
                        <div style={{ fontWeight: 300, padding: "4px" }}>
                          {appReviewStatus?.rcName}
                        </div>
                      </KeyValue>
                      <KeyValue>
                        <KeyTxt> Version - &nbsp;</KeyTxt>
                        <div style={{ fontWeight: "300" }}>
                          {appReviewStatus.version}
                        </div>
                      </KeyValue>
                      <KeyValue
                        style={{
                          paddingBottom: "1rem",
                        }}
                      >
                        <KeyTxt> APP ID - &nbsp;</KeyTxt>
                        <div style={{ fontWeight: "300" }}>
                          {appReviewStatus?.appID?.slice(-10)}
                        </div>
                      </KeyValue>
                    </div>
                  </TextBtn>
                  <div
                    style={{
                      fontWeight: "300",
                      borderTop: "1px solid #D9D9D9",
                    }}
                  >
                    <KeyValue
                      style={{
                        paddingTop: "0.6rem",
                      }}
                    >
                      <div> Last submitted On&nbsp;</div>
                      <div>{getDateByUpdate(appReviewStatus.submittedOn)}</div>
                    </KeyValue>
                  </div>
                </TextBtnCont>
              </CardContainer>
            )}
          </div>
          {appReviewStatus?.status === "Approved" ? (
            <div className="mb-3">
              <CardBlackWhite
                upperContent={[
                  <TextWhite className="fs-18 m-2" key={"Review Report"}>
                    Review Report
                  </TextWhite>,
                ]}
                lowerContent={lowerContentReviewForApproved}
              />
            </div>
          ) : appReviewStatus?.status === "Rejected" ? (
            <div className="mb-3">
              <CardBlackWhite
                upperContent={[
                  <TextWhite className="fs-18 m-2" key={"Review Report"}>
                    Review Report
                  </TextWhite>,
                ]}
                lowerContent={lowerContentReviewForRejected}
              />
            </div>
          ) : (
            <></>
          )}

          {/* card part */}
          <div>
            <CardBlackWhite
              upperContent={[
                <TextWhite className="fs-18 m-2" key={"Review Status"}>
                  Review Status
                </TextWhite>,
              ]}
              lowerContent={<LowerCont appReviewStatus={appReviewStatus} />}
            />
          </div>
          {/* <div className="my-4">
          {appReviewStatus[3]?.guidelineData && (
            <CardBlackWhite
              upperContent={[
                <TextWhite className="fs-18 m-0" key={"Review Report"}>
                  Review Report
                </TextWhite>,
              ]}
              lowerContent={lowerContentReview}
            />
          )}
        </div> */}
        </div>
      </OverFlow>
    </>
  );
};

export default ReviewStatus;
