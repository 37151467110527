import CustomBreadcrumb from "components/CustomBreadScrum";
import { CustomInput, TextBlack } from "components/styled/common.styled";
import {
  CustomDropDown,
  CustomLabel,
  CutomCancelBtn,
  PaymentCard,
} from "components/styled/payment.styled";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addAccountDetails,
  getBankDetaisFromIFSC,
} from "redux/Project/project.action";

const AddAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputValue, setInputinputValue] = useState({
    name: "",
    address1: "",
    address2: "",
    accountNumber: "",
    accountType: "",
    bankName: "",
    state: "",
    city: "",
    pincode: "",
    ifscCode: "",
  });

  const [isFieldsEmpty, setFieldsEmpty] = useState(false);

  const isAccountNumberValid = (accountNumber) => {
    return accountNumber.length >= 10;
  };

  const isIFSCCodeValid = (ifscCode) => {
    if (ifscCode.length !== 11) {
      setInputinputValue({
        ...inputValue,
        ifscCode: ifscCode,
        bankName: "",
        address1: "",
        state: "",
        city: "",
        pincode: "",
      });
      return false;
    }
    if (ifscCode[4] !== "0") {
      return false;
    }
    return /^[A-Z]{4}[0][0-9A-Z]{6}$/.test(ifscCode);
  };

  const InputValueHandle = (e) => {
    const { name, value } = e.target;
    setInputinputValue({ ...inputValue, [name]: value });

    // Check if the field being updated is ifscCode
    if (name === "ifscCode") {
      if (value.length > 10) {
        // Validate the IFSC code
        const upperCase = value.toUpperCase();
        if (isIFSCCodeValid(upperCase)) {
          // Call the API to get bank details
          dispatch(getBankDetaisFromIFSC(upperCase))
            .then((response) => {
              if (response?.response?.data?.message) {
                toast.error(response.response.data.messag);
                setInputinputValue({
                  ...inputValue,
                  ifscCode: upperCase,
                  bankName: "",
                  address1: "",
                  address2: "",
                  state: "",
                  city: "",
                });
                return;
              }
              // Set the bank details in the state
              setInputinputValue({
                ...inputValue,
                ifscCode: upperCase,
                bankName: response.payload.BANK,
                address1: response.payload.BRANCH,
                address2: response.payload.ADDRESS,
                state: response.payload.STATE,
                city: response.payload.DISTRICT,
              });
            })
            .catch((error) => {
              console.log("NO DATA", error);
            });
        } else {
          dispatch(getBankDetaisFromIFSC(upperCase)).then((bankDetais) => {
            if (bankDetais?.response?.data?.message) {
              toast.error(bankDetais?.response?.data?.message);
            }
          });
        }
        return;
      }
    }
  };
  const formSubmit = (e) => {
    e.preventDefault();

    if (
      !inputValue.name ||
      !inputValue.accountNumber ||
      !inputValue.ifscCode ||
      !inputValue.address1 ||
      !inputValue.acctType ||
      !inputValue.bankName ||
      !inputValue.state ||
      !inputValue.city
    ) {
      setFieldsEmpty(true);
      toast.error("Please fill the fields");
      return;
    }

    if (!isAccountNumberValid(inputValue.accountNumber)) {
      toast.info(
        "Invalid account number. Please enter a 10-digit account number."
      );
      return;
    }

    if (!isIFSCCodeValid(inputValue.ifscCode)) {
      toast.info("Invalid IFSC code. Please enter a valid IFSC code.");

      return;
    }

    // Prepare the data to send to the API
    const formData = {
      accountHolderName: inputValue.name,
      accountNumber: inputValue.accountNumber,
      accountType: inputValue.acctType, // Need to Add new inputValue.accountType actual account type
      bankName: inputValue.bankName,
      IFSCode: inputValue.ifscCode,
      bankAddress: inputValue.address1 + ", " + inputValue.address2,
      bankState: inputValue.state,
      bankCity: inputValue.city,
      bankPinCode: "0000000",
    };

    dispatch(addAccountDetails(formData))
      .then((response) => {
        if (response?.response?.status === 400) {
          toast.error(response.response.data.message);
          return;
        }
        if (response?.response?.status === 409) {
          toast.error(response.response.data.message);
          return;
        }
        setInputinputValue({
          name: "",
          address1: "",
          address2: "",
          accountNumber: "",
          accountType: "",
          bankName: "",
          state: "",
          city: "",
          pincode: "",
          ifscCode: "",
        });
        toast.success("Data saved successfully");
        setTimeout(() => {
          navigate("/payment");
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="Paymentcont">
        <CustomBreadcrumb
          items={[
            { text: "Payments", link: "/payment" },
            { text: "Add New Account", link: "/AddAccount" },
          ]}
        />
        <PaymentCard>
          <TextBlack className="fs-14-400">Add New Account</TextBlack>
          {/* form */}
          <div className="row py-6 time">
            <div className="col-6 ">
              <CustomLabel>
                Name <span style={{ color: "red", marginLeft: "2px" }}>*</span>
              </CustomLabel>
              <CustomInput
                type="text"
                required
                placeholder="Account Holder’s Name"
                name="name"
                value={inputValue.name}
                onChange={InputValueHandle}
              />
            </div>
            <div className="col-6">
              <CustomLabel>
                Account Number{" "}
                <span style={{ color: "red", marginLeft: "2px" }}>*</span>
              </CustomLabel>
              <CustomInput
                type="text"
                required
                placeholder="XXXX XXXX XXXX XXXX"
                name="accountNumber"
                value={inputValue.accountNumber}
                onChange={InputValueHandle}
                maxLength={16}
              />
            </div>
            <div className="col-6 mt-4">
              <CustomLabel>
                IFS Code{" "}
                <span style={{ color: "red", marginLeft: "2px" }}>*</span>
              </CustomLabel>
              <CustomInput
                type="text"
                required
                placeholder="Ex. AAAA0123456"
                name="ifscCode"
                value={inputValue.ifscCode}
                onChange={InputValueHandle}
                maxLength={11}
              />
            </div>
            <div className="col-6 mt-4">
              <CustomLabel>
                Type of Account{" "}
                <span style={{ color: "red", marginLeft: "2px" }}>*</span>
              </CustomLabel>
              <CustomDropDown
                className="col-12"
                name="acctType"
                value={inputValue.acctType}
                onChange={InputValueHandle}
              >
                <option value="">Select a Account Type</option>
                <option value="Saving Account">Saving Account</option>
                <option value="Current Account">Current Account</option>
              </CustomDropDown>
            </div>
            <div className="col-6 mt-4">
              <CustomLabel>
                Bank Name<span className="text-danger">*</span>
              </CustomLabel>
              <CustomInput
                type="text"
                required
                placeholder="Ex. HDFC Bank"
                name="bankName"
                value={inputValue.bankName}
                onChange={InputValueHandle}
                Only
              />
            </div>
            <div className="col-6 mt-4"></div>
            <div className="col-6 mt-4">
              <CustomLabel>
                Bank Branch Address<span className="text-danger">*</span>
              </CustomLabel>
              <CustomInput
                type="text"
                bg="#fff"
                required
                placeholder="Address Line 1"
                name="address1"
                value={inputValue.address1}
                onChange={InputValueHandle}
                readOnly
              />
            </div>
            <div className="col-6 mt-4">
              <CustomLabel>
                Bank Branch Address<span className="text-danger">*</span>
              </CustomLabel>
              <CustomInput
                type="text"
                bg="#fff"
                required
                placeholder="Address Line 2"
                name="address2"
                value={inputValue.address2}
                onChange={InputValueHandle}
                readOnly
              />
            </div>
            <div className="col-6 mt-4">
              <CustomLabel>
                State<span className="text-danger">*</span>
              </CustomLabel>
              <CustomInput
                type="text"
                name="state"
                bg="#fff"
                value={inputValue.state}
                onChange={InputValueHandle}
                readOnly
              />
            </div>
            <div className="col-6 mt-4">
              <CustomLabel>
                City<span className="text-danger">*</span>
              </CustomLabel>
              <CustomInput
                bg="#fff"
                type="text"
                name="city"
                value={inputValue.city}
                onChange={InputValueHandle}
                readOnly
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "24px",
            }}
          >
            <CutomCancelBtn
              style={{ fontSize: "14px", fontWeight: "600" }}
              onClick={() => navigate("/payment")}
            >
              Cancel
            </CutomCancelBtn>
            <CutomCancelBtn className="mx-4 bg1" onClick={formSubmit}>
              Save
            </CutomCancelBtn>
          </div>
        </PaymentCard>
      </div>
    </>
  );
};

export default AddAccount;
