import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { radioButton, setFormDetails } from "redux/Project/project.action";

export const Radio = ({
  name,
  value,
  // setVersion,
}) => {
  const dispatch = useDispatch();

  const {
    inputValue: { channelRadio },
    projectFormDetails: { tag },
  } = useSelector((state) => state.projectReducer);
  const isChecked = value === tag || value === channelRadio;
  const radioButtonHandler = (e) => {
    dispatch(radioButton(e));
    dispatch(
      setFormDetails({
        tag: e,
      })
    );
  };
  return (
    <div
      id={value === "production" ? "production" : null}
      className="d-flex align-items-center py-2 cursor-pointer"
      onClick={() => radioButtonHandler(value)}
    >
      <input
        type="radio"
        id={value}
        checked={isChecked}
        value={value}
        className="form-check-input"
        name="example1"
      />
      <span
        style={{
          color: "#161719",
          textTransform: "capitalize",
        }}
      >
        &nbsp;&nbsp;{name || "Project 1"}
      </span>
    </div>
  );
};
