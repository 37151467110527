import text from "assets/text.svg";
import password from "assets/password.svg";
import login from "assets/login.svg";
import security3 from "assets/security3.svg";
import { loginconfig } from "ui/auth/Config";

export const data = [
  // {
  //   id: 1,
  //   img: text,
  //   title: "Open Source Licenses",
  // },
  {
    id: 2,
    img: text,
    title: "Privacy",
    link: "https://ajnalens.com/privacy",
  },
  {
    id: 3,
    img: text,
    title: "Terms Of use",
  },
];

export const Secdata = [
  {
    id: 1,
    img: password,
    title: "Change Password",
    chip: "",
    link:
      `${loginconfig.url}/profile/changePassword?` +
      `clientId=${loginconfig.clientId}&token=${localStorage.getItem("token")}`,
  },
  {
    id: 2,
    img: login,
    title: "Login Activity",
    chip: "",
    link:
      `${loginconfig.url}/profile/sessions?` +
      `clientId=${loginconfig.clientId}&token=${localStorage.getItem("token")}`,
  },
  // {
  //   id: 3,
  //   img: security3,
  //   title: "Two Factor Authentication",
  //   active: true,
  //   chip: "Turn On",
  // link: `${loginconfig.url}/profile/accountInfo?` +`clientId=${loginconfig.clientId}&token=${localStorage.getItem("token")}`,
  // },
];
