import { CustomModal } from "components/Modal/CustomModal";
import {
  ButtonPrimary,
  CheckboxLarge,
  TextWhite,
} from "components/styled/common.styled";
import { useState } from "react";
import mac from "assets/mac.svg";
import importIcon from "assets/import.svg";
import window from "assets/window.svg";
import { toast } from "react-toastify";

export const DownloadADS = ({ isOpen, setIsOpen }) => {
  const [isActive, setIsActive] = useState({
    mac: false,
    windows: false,
  });
  const [download, setDownload] = useState(false);
  const donwloadHandler = () => {
    if (isActive.mac) {
      const a = document.createElement("a");
      a.href = "https://cdn.ajnavidya.com/cdn/Ajna%20WorkStation.pkg?14082023";
      a.download = "AjnaVidyaWorkStation.pkg";
      a.click();
      setDownload(false);
      a.target = "_blank";
      // toast.error("Mac version is not available", {
      //   position: toast.POSITION.TOP_CENTER,
      //   autoClose: 2000,
      // });
    }
    if (isActive.windows) {
      const a = document.createElement("a");
      a.href = "https://cdn.ajnavidya.com/cdn/Ajna%20WorkStation.exe";
      a.download = "AjnaVidyaWorkStation.exe";
      a.click();
      setDownload(false);
      a.target = "_blank";
    }
    if (!isActive.mac && !isActive.windows) {
      toast.warn("Please select platform", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  };
  return (
    <>
      {isOpen && (
        <CustomModal
          title="Select Platform"
          openFor={"download"}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          size="md"
          key={"download"}>
          <div
            className="d-flex justify-content-between align-items-center gap-4"
            style={{ marginTop: "15px" }}>
            <CheckboxLarge
              className={isActive.mac ? "active" : ""}
              style={{
                display: "grid",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                boxshadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
              onClick={() => {
                setIsActive({
                  mac: !isActive.mac,
                });
              }}>
              <img src={mac} alt="os" />
              <TextWhite
                className="fs-18 my-0 text-center"
                style={{ color: "#000" }}>
                Mac
              </TextWhite>
            </CheckboxLarge>
            <CheckboxLarge
              style={{ display: "grid", backgroundColor: "#fff" }}
              className={isActive.windows ? "active" : ""}
              onClick={() => {
                setIsActive({
                  windows: !isActive.windows,
                });
              }}>
              <img src={window} alt="os" />
              <TextWhite
                className="fs-18 my-0 text-center"
                style={{ color: "#000" }}>
                Windows
              </TextWhite>
            </CheckboxLarge>
          </div>

          {/* {download && ( */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "2rem",
              gap: "20px",
              justifyContent: "end",
            }}>
            <ButtonPrimary
              width={"150px"}
              style={{
                background: "#fff",
                color: "#000",
                border: "1px solid #808080",
                fontSize: "16px",
                fontWeight: "600",
              }}
              onClick={() => setIsOpen(false)}>
              Discard
            </ButtonPrimary>
            <ButtonPrimary onClick={donwloadHandler} width={"150px"}>
              <img
                src={importIcon}
                height={20}
                width={20}
                className="me-1"
                alt="import"
              />
              Download
            </ButtonPrimary>
          </div>
        </CustomModal>
      )}
    </>
  );
};
