import { Breadcrumb } from "react-bootstrap";
import styled from "styled-components";

export const StyledBreadcrumb = styled(Breadcrumb.Item)`
  a {
    text-decoration: none;
    font-size:16px
  }
  
  &:not(:first-child):before {
    content: ">" !important;
  }
`;
