import {
  legacy_createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from "redux";
import thunk from "redux-thunk";
import authReducer from "./Auth/reducer";
import appReducer from "./App/reducer";
import projectReducer from "./Project/project.reducer";

export const rootReducer = combineReducers({
  authReducer,
  appReducer,
  projectReducer,
});
const composeEnhancers =
  window.location.hostname === "localhost"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

export const store = legacy_createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
