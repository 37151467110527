import { CustomBtn } from "components/styled/Buystorage.style";
import { TextBlack } from "components/styled/common.styled";
import { CustomLabel } from "components/styled/payment.styled";
import React, { useState } from "react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import toolIcon from "assets/toolIcon.svg";
import {
  AddmanualBox,
  CustomInputAddManual,
} from "components/styled/Invites.style";
import { publisher } from "redux/api";
import { useDispatch, useSelector } from "react-redux";
import { getAllMembers } from "redux/App/action";
import { toast } from "react-toastify";

const AddManually = ({ setIsOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputData, setInputData] = useState({
    name: "",
    username: "",
    role: "",
    email: "",
  });
  const dispatch = useDispatch();
  const [formData, setFormData] = useState([]);
  const { allMembers } = useSelector((state) => state.appReducer);

  const InputValueChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      inputData.name === "" ||
      inputData.username === "" ||
      inputData.email === "" ||
      inputData.role === ""
    ) {
      toast.info("Please fill all the fields");
      setIsLoading(false);
      return;
    }
    const phoneValidate = /^[0-9]{10}$/;
    if (!phoneValidate.test(inputData.username)) {
      setIsLoading(false);
      toast.info("Please enter valid phone number");
      return;
    }
    const emailValidate = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (!emailValidate.test(inputData.email)) {
      setIsLoading(false);

      toast.info("Please enter valid email");
      return;
    }
    try {
      const addUser = await publisher.addMember({
        firstName: inputData.name.split(" ")[0],
        lastName: inputData.name.split(" ")[1] || "   ",
        username: inputData.username,
        email: inputData.email,
        role: inputData.role,
      });
      setIsLoading(false);
      toast.success("User added successfully");
      setIsOpen(false);
      setTimeout(() => {
        dispatch(getAllMembers());
      }, 1000);
    } catch (e) {
      setIsLoading(false);
      toast.error(e.response.data.message);
    }

    setFormData({ ...inputData });
  };
  return (
    <AddmanualBox>
      <TextBlack className="addText">Add Manually </TextBlack>
      <CustomLabel>Name</CustomLabel>
      <CustomInputAddManual
        placeholder="Username"
        name="name"
        value={inputData.name}
        onChange={InputValueChange}
      />
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CustomLabel>User’s Email ID </CustomLabel>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              Enter the email address of the user you want to add to your
              organization. The user will receive an email with a link to join
              your organization.
            </Tooltip>
          }
        >
          <img src={toolIcon} alt="toolIcon" />
        </OverlayTrigger>
      </div>
      <CustomInputAddManual
        placeholder="User@Email.com "
        name="email"
        value={inputData.email}
        onChange={InputValueChange}
      />
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CustomLabel>User’s Phone</CustomLabel>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              Enter 10 digit phone number of the user you want to add to your
            </Tooltip>
          }
        >
          <img src={toolIcon} alt="toolIcon" />
        </OverlayTrigger>
      </div>
      <CustomInputAddManual
        placeholder="9999999999"
        name="username"
        value={inputData.username}
        onChange={InputValueChange}
      />

      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex align-items-center">
          <TextBlack className="d-flex align-items-center pe-2">
            {" "}
            <div className="me-1">
              <input
                type="radio"
                onClick={() =>
                  setInputData({ ...inputData, role: "org_admin" })
                }
                value={"org_admin"}
                className="form-check-input"
                name={"radioBtn"}
              />
              <span></span>
            </div>
            Admin
          </TextBlack>

          <TextBlack className="d-flex align-items-center">
            <div className="me-1">
              <input
                type="radio"
                onClick={() => {
                  setInputData({ ...inputData, role: "org_member" });
                }}
                value={"org_member"}
                className="form-check-input"
                name={"radioBtn"}
              />
              <span></span>
            </div>
            Member
          </TextBlack>
        </div>
      </div>
      <div className="btnCont mt-4 w-100">
        <CustomBtn
          onClick={() => {
            setInputData({
              name: "",
              username: "",
              role: "",
              email: "",
              
            });
            setIsOpen(false);
          }}
          disabled={isLoading}
          className="cancel"
        >
          Cancel
        </CustomBtn>
        <CustomBtn className="invites" onClick={formSubmit}>
          <span>{isLoading ? <Spinner size="sm" /> : "Invites"}</span>
        </CustomBtn>
      </div>
      {/* added peoplelist here */}
      {/* <UsersList data={formData} setFormData={setFormData} /> */}
    </AddmanualBox>
  );
};

export default AddManually;
