import { ProfileImg, TextBlack } from "components/styled/common.styled";
import profile from "assets/profile.jpg";
import avatarImage from "assets/profile-circle.svg";
import Skeleton from "react-loading-skeleton";

export const Organization = ({ profileData, isloading }) => {
  const organizationData = {
    website: profileData?.organization?.website,
    name: profileData?.organization?.name,
    contactNo: profileData?.organization?.contact,
    address:
      profileData?.organization &&
      profileData?.organization?.address?.city +
        ", " +
        profileData?.organization?.address?.state +
        ", " +
        profileData?.organization?.address?.country +
        ", " +
        profileData?.organization?.address?.pincode,
    profile_photo: profileData?.organization?.profilePic,
  };

  return (
    <div>
      {isloading ? (
        <div className="py-2 row">
          <div className="col-2">
            {/* Skeleton for profile image */}
            <Skeleton  height={150} width={150} borderRadius={10} />
          </div>
          <div className="col-6 py-3 pt-0">
            {/* Skeletons for organization information */}
            <div className="d-grid pb-4">
              <TextBlack className="fs-14">Organization Name</TextBlack>
              <Skeleton width={200} />
            </div>
            <div className="d-grid pb-4">
              <TextBlack className="fs-14">Website</TextBlack>
              <Skeleton width={200} />
            </div>
            <div className="d-grid pb-4">
              <TextBlack className="fs-14">Phone Number</TextBlack>
              <Skeleton width={150} />
            </div>
            <div className="d-grid pb-4">
              <TextBlack className="fs-14">Address</TextBlack>
              <Skeleton width={300} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="py-2 row">
            <div className="col-2">
              <div>
                {organizationData?.profile_photo ? (
                  <ProfileImg src={organizationData?.profile_photo} alt="img" />
                ) : (
                  <ProfileImg src={avatarImage} alt={"noimg"} />
                )}
              </div>
            </div>

            <div className="col-6 py-3 pt-0">
              <div className="d-grid pb-4">
                <TextBlack className="fs-14">Organization Name</TextBlack>
                <TextBlack>{organizationData?.name}</TextBlack>
                {/* )} */}
              </div>
              <div className="d-grid pb-4">
                <TextBlack className="fs-14">Website</TextBlack>

                <a
                  href={
                    organizationData?.website
                      ? organizationData.website.startsWith("http")
                        ? organizationData.website
                        : `http://${organizationData.website}`
                      : "#"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {organizationData?.website}
                </a>
              </div>
              <div className="d-grid pb-4">
                <TextBlack className="fs-14">Phone Number</TextBlack>
                <TextBlack>+91 {organizationData?.contactNo}</TextBlack>
              </div>
              <div className="d-grid pb-4">
                <TextBlack className="fs-14">Address</TextBlack>
                <TextBlack>{organizationData?.address}</TextBlack>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
