import React from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

const getButtonStyle = (selectedOption, option) => {
  let backgroundColor, color;

  if (selectedOption === option.value) {
    if (option.keyName === "period") {
      backgroundColor = "black";
    } else if (option.keyName === "rating" || option.keyName === "appType") {
      backgroundColor = "#6C56FB";
    }
    color = "white";
  } else {
    backgroundColor =
      option.keyName === "rating" || option.keyName === "appType"
        ? "white"
        : "transparent";
    color = "black";
  }

  return {
    backgroundColor,
    color,
    border: "none",
    height: "fit-content",
  };
};

const ToggleButtons = ({
  options,
  selectedOption,
  onOptionChange,
  defaultValue = "",
}) => {
  return (
    <ButtonGroup className="gap-2">
      {options.map((option) => (
        <ToggleButton
          key={option.value}
          id={`toggle-${option.value}`}
          defaultChecked={defaultValue?.length ? true : false}
          type="radio"
          className={`toggle-buttons`}
          name="option"
          value={option.value}
          checked={selectedOption === option.value}
          onChange={() => onOptionChange(option.value)}
          style={getButtonStyle(selectedOption, option)}>
          {option.name}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
};

export default ToggleButtons;
