import ImageCroper from "components/ImageCroper/ImageCroper";
import { CustomModal } from "components/Modal/CustomModal";
import { CardModalBody } from "components/card/card.styled";
import {
  ButtonPrimary,
  TextBlack,
  TextMiddle,
} from "components/styled/common.styled";
import React from "react";
import { Spinner } from "react-bootstrap";

export const ThumnailUpload = ({
  isOpen,
  setIsOpen,
  handleThumbnailChange,
  fileName,
  error,
  percentCompleted,
  successMessage,
  fileUploader,
  files,
  isLoading,
  setIsLoading,
  setFiles,
  handleMouseEnter2,
  handleMouseLeave2,
  isHovered2,
  imgIconColor,
  imgIconWhite,
}) => {
  console.log("filesnamemememem", fileName);
  return (
    <CustomModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      key={"abc"}
      size="md"
      title={"Upload Thumbnail"}
      // openFor={"thumbnail"}
      back={true}
      setIsLoading={setIsLoading}
      files={files}
      fileName={fileName}
      openFor="FeaturevideoUpload"
    >
      <div className="d-flex flex-column align-items-center gap-4 px-4  mt-5">
        <CardModalBody>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex justify-content-center gap-4 py-2">
              <input
                type="file"
                hidden
                id="thumbnail_3d"
                onChange={(e) => {
                  setFiles({
                    ...files,
                    logoURL: e,
                  });
                }}
                accept=".glb, .gltf"
              />
              <div className="d-flex flex-column justify-content-center align-items-center gap-2 ">
                <input
                  type="file"
                  hidden
                  name="logoURL"
                  onChange={handleThumbnailChange}
                  id="thumbnail_2d"
                  accept=".png, .jpg, .jpeg"
                />
                <div
                  onClick={() => {
                    document.getElementById("thumbnail_2d")?.click();
                  }}
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      height: "60px",
                      width: "60px",
                      background: isHovered2 ? "#6C56FB" : "transparent",
                    }}
                  >
                    <img
                      height={"100%"}
                      src={isHovered2 ? imgIconColor : imgIconWhite}
                      alt=""
                    />
                  </div>
                  <TextBlack
                    className="fs-18 fw-600"
                    style={{
                      color: isHovered2 ? "#6C56FB" : "#161719",
                    }}
                  >
                    Upload Image
                  </TextBlack>
                  {fileName.length !== 0 && <TextBlack>{fileName}</TextBlack>}
                </div>
              </div>
            </div>

            {error ? (
              <TextMiddle style={{ color: "red" }}>{error}</TextMiddle>
            ) : (
              <TextMiddle>
                Max file size for image: 5MB <br /> Suggested upload ratio: 1:1
              </TextMiddle>
            )}
          </div>
        </CardModalBody>
        {percentCompleted > 0 && percentCompleted < 100 && (
          <progress
            id="file"
            max="100"
            value={percentCompleted}
            style={{ width: "100%" }}
          ></progress>
        )}

        {percentCompleted === 100 && (
          <TextBlack className="fs-18 fw-600">
            {successMessage?.logoURL}
          </TextBlack>
        )}
        <TextBlack className="fs-18 fw-600">{files.logoURL?.name}</TextBlack>
        <ButtonPrimary
          onClick={() => {
            fileUploader(files?.logoURL, "logoURL");
          }}
          disabled={!files?.logoURL || error}
          width="150px"
          style={{
            cursor: !files.logoURL || error ? "not-allowed" : "",
            opacity: !files.logoURL || error ? 0.5 : "",
          }}
        >
          {(isLoading["logoURL"] && (
            <Spinner animation="border" variant="light" size="sm" />
          )) ||
            "Upload"}
        </ButtonPrimary>
      </div>
    </CustomModal>
  );
};
