import { CardBlackWhite } from "components/card/Card";
import { TextWhite } from "components/styled/common.styled";
import React from "react";
import { Modal } from "react-bootstrap";
import cross from "assets/crossModal.svg";
import { UUIDGenerator } from "helper";
import { setFormDetails } from "redux/Project/project.action";
import { useDispatch } from "react-redux";
import Uploader from "ajnavidya-file-upload-client";

export const CustomModal = ({
  isOpen = false,
  size = "md",
  setIsOpen,
  title = "Modal",
  children,
  back = false,
  openFor,
  isLoading,
  setIsLoading,
  files,
  setFiles,
  setSelectedVideoFile,
  selectedVideoFile,
  filesName,
  coverImage,
  fileName,
}) => {
  const dispatch = useDispatch();
  const cancleUpload = () => {
    if (
      !files?.name &&
      !selectedVideoFile?.name &&
      !coverImage?.name &&
      !fileName
    ) {
      setIsLoading(false);
      return;
    }
    //  console.log("hit");

    Uploader({
      cancel: true,
      url: {
        apiOrigin: "https://file-uploadv2.ajnavidya.com/",
        // apiOrigin: "http://localhost:9008/",
        status: "/upload/status",
        upload: "/upload/files",
        complete: "/upload/complete",
      },
    });
    setIsLoading(false);
    setSelectedVideoFile(null);
    setFiles({ ...files, apk: "", exe: "", sh: "", name: "" });
    //  reset all the values
  };

  // handle modal close button.
  const onHandleClose = () => {
    // if (selectedVideoFile) {
    //   setSelectedVideoFile(null);
    // }

    if (!openFor) {
      setIsOpen({ ...isOpen, modal: false });
      return;
    }
    if (
      openFor === "download" ||
      openFor === "termsOfUse" ||
      openFor === "thumbnail"
    ) {
      setIsOpen(false);
      return;
    }
    const isConfirm = window.confirm("Are you sure you want to close?");
    if (!isConfirm) return;
    if (openFor === "fileUpload") {
      dispatch(
        setFormDetails({ executableFileName: "", apkSupportedPlatforms: "" })
      );
      setIsOpen(false);
      if (isConfirm) {
        cancleUpload();
      }
    } else if (openFor === "FeaturevideoUpload") {
      setIsOpen(false);
      setIsLoading(false);
      if (isConfirm) {
        cancleUpload();
      }
    } else if (openFor === "thumbnail") {
      setIsOpen(false);
      setIsLoading(false);
      if (isConfirm) {
        cancleUpload();
      }
    } else if (openFor === "videoUpload") {
      setIsOpen(false);
      setIsLoading(false);
      if (isConfirm) {
        cancleUpload();
      }
    } else {
      setIsLoading(false);
      setIsOpen({ ...isOpen, modal: false });
      if (isConfirm) {
        cancleUpload();
      }
    }
  };
  return (
    <Modal
      show={isOpen}
      id="custom-modal"
      size={size}
      centered
      backdrop="static"
      onHide={() => {
        //;
        setIsOpen(false);
        //;
        Uploader({
          cancel: true,
        });
      }}
    >
      <CardBlackWhite
        back={back}
        upperContent={[
          <Modal.Header
            key={UUIDGenerator()}
            className="w-100 d-flex align-items-center"
            style={{ padding: 0 }}
          >
            <TextWhite className="fs-18 m-0">{title}</TextWhite>
            <div onClick={() => onHandleClose()}>
              <img src={cross} alt="df" />
            </div>
          </Modal.Header>,
        ]}
        lowerContent={<Modal.Body>{children}</Modal.Body>}
      />
    </Modal>
  );
};
