import React from "react";
import {
  MarkTitle,
  NotificationCont,
  SingleBox,
  Time,
} from "components/styled/Notification.style";
import { data } from "./constant";
import go from "assets/go.svg";
import { useState } from "react";
import { TermsOfUse } from "components/termsOfUse/TermsOfUse";

const PrivacyGuideLine = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <NotificationCont className="settingCont">
      {data.map((item, ind) => (
        <a
          target="_blank"
          href={item.link}
          onClick={() => item.title === "Terms Of use" && setIsOpen(true)}
          rel="noreferrer"
          style={{ cursor: "pointer" ,textDecoration:"none" }}
          key={item.id}
        >
          <SingleBox className="settings" key={item.id}>
            <div className="main">
              <img
                src={item.img}
                alt="item"
                style={{ width: "24px", height: "24px" }}
              />
              <div>
                <MarkTitle>{item.title}</MarkTitle>

                <Time>{item.time}</Time>
              </div>
            </div>
            <div className="btnCont">
              <img src={go} alt="go" />
            </div>
          </SingleBox>
        </a>
      ))}
      <TermsOfUse isOpen={isOpen} setIsOpen={setIsOpen} />
    </NotificationCont>
  );
};

export default PrivacyGuideLine;
