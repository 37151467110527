import flash from "assets/flash.svg";
import sun from "assets/sun.svg";
import timer from "assets/timer.svg";

export const options1 = [
  { value: "Country", label: "Country" },
  { value: "India", label: "India" },
  { value: "China", label: "China" },
  { value: "USA", label: "USA" },
  { value: "UAE", label: "UAE" },
];

export const options2 = [
  { value: "State", label: "State" },
  { value: "MH", label: "MH" },
  { value: "Goa", label: "Goa" },
  { value: "Karnataka", label: "Karnataka" },
];

export const options3 = [
  { value: "Users", label: "Users" },
  { value: "Highest", label: "Highest" },
  { value: "Lowest", label: "Lowest" },
];

export const options5 = [
  { value: "Subscription", label: "Subscription" },
  { value: "1month", label: "1 Month" },
  { value: "3months", label: "3 Months" },
  { value: "6months", label: "6 Months" },
  { value: "12months", label: "12 Months" },
];

export const options6 = [
  { value: "releaseChannels", label: "Release Channels" },
  { value: "production", label: "Production" },
  { value: "alpha", label: "Alpha" },
  { value: "beta", label: "Beta" },
];

export const options7 = [
  { value: "versions", label: "Versions" },
  { value: "1.0.1", label: "1.0.1" },
  { value: "1.0.2", label: "1.0.2" },
  { value: "1.0.3", label: "1.0.3" },
  { value: "1.0.4", label: "1.0.4" },
];

export const options8 = [
  { value: "withreferal", label: "With Referrals" },
  { value: "", label: "1.0.1" },
  { value: "1.0.2", label: "1.0.2" },
  { value: "1.0.3", label: "1.0.3" },
  { value: "1.0.4", label: "1.0.4" },
];

export const periods = [
  { name: "Weekly", value: "Weekly", keyName: "period" },
  { name: "Monthly", value: "Monthly", keyName: "period" },
  { name: "Yearly", value: "Yearly", keyName: "period" },
];

export const AppType = [
  {
    name: "APK",
    value: ".apk",
    keyName: "appType",
  },
  {
    name: "EXE",
    value: ".exe",
    keyName: "appType",
  },
];

export const lgDataRed = {
  top: 41.5,
  right: 0,
  bottom: 0,
  left: 15,
  color1: "#FF7666",
  color2: "rgba(255, 119, 102, 0)",
  borderColor: "#FF7666",
};

export const lgDataGreen = {
  top: 20.5,
  right: 0,
  bottom: 0,
  left: 15,
  color1: "#20A019",
  color2: "#fff",
  borderColor: "#20A019",
};

export const activeUsersData = {
  January: 800,
  February: 500,
  March: 300,
  April: 500,
  May: 200,
  June: 100,
};

// analytics data

export const analyticsData = [
  {
    id: 1,
    name: "Average Frame Rate",
    spec: "60 FPS",
    icon: flash,
  },
  {
    id: 2,
    name: "Average Time Spent ",
    spec: "03 Hrs. 30 Min.",
    icon: timer,
  },
  {
    id: 3,
    name: "Average Overheating ",
    spec: "15° C /  59 F",
    icon: sun,
  },
];
