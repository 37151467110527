import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppReviewStatus, makeAPPLive } from "redux/Project/project.action";
import styles from "./review.module.css";
import pass from "assets/pass.svg";
import fail from "assets/fail.svg";
import { useParams } from "react-router-dom";
import { addSpaceAndCapitalize } from "helper";
import { CardBlackWhite } from "components/card/Card";
import { ButtonPrimary, TextWhite } from "components/styled/common.styled";

export const LowerCont = ({ appReviewStatus }) => {
  // const dispatch = useDispatch();
  // const { id } = useParams();
  // const { appReviewStatus } = useSelector((state) => state.projectReducer);

  // useEffect(() => {
  //   if (id) {
  //     dispatch(getAppReviewStatus(id));
  //   }
  // }, []);

  return (
    <div className={styles.reviewBox}>
      <h6>Technical Review</h6>
      <ul className={styles.ulBox}>
        {appReviewStatus?.reviews?.length > 0 &&
          appReviewStatus?.reviews[0]?.parameters?.map((items, i) => (
            <ReviewList key={items?.id} item={items} />
          ))}
      </ul>
      <h6>Content Review</h6>
      <ul className={styles.ulBox}>
        {appReviewStatus?.reviews?.length > 0 &&
          appReviewStatus?.reviews[1]?.parameters?.map((items, i) => (
            <ReviewList key={items?.id} item={items} />
          ))}
      </ul>
      <h6>GuideLine Review</h6>
      <ul className={styles.ulBox}>
        {appReviewStatus?.reviews?.length > 0 &&
          appReviewStatus?.reviews[2]?.parameters?.map((items, i) => (
            <ReviewList key={items?.id} item={items} />
          ))}
      </ul>
    </div>
  );
};

const Status = () => {
  return (
    <div>
      <p>
        <img src={pass} alt="pass" /> Pass
      </p>
    </div>
  );
};
const Failed = () => {
  return (
    <div>
      <p>
        <img src={fail} alt="pass" /> Fail
      </p>
    </div>
  );
};

const getStatusText = (status) => {
  if (status === 0) {
    return "in-review";
  } else if (status === 1) {
    return <Status />;
  } else if (status === -1) {
    return <Failed />;
  } else {
    return null;
  }
};

const ReviewList = ({ item }) => {
  return (
    <li className={styles.liBox}>
      {!item.value ? (
        <div className={styles.liContent}>
          <p>{addSpaceAndCapitalize(item.key)}</p>
          <p>{getStatusText(item.status)}</p>
        </div>
      ) : (
        <div>
          {item.key}
          <ul className={styles.ulBox}>
            {item.value.map((items, i) => (
              <li key={items?.key} className={styles.liBox}>
                <div className={styles.liContent}>
                  <p>{addSpaceAndCapitalize(items.key)}</p>
                  <p>{getStatusText(items.status)}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};
