import {
  LogoConatiner,
  ProjectName,
  PrpojectCardConatiner,
  TextConatiner,
} from "components/styled/home.styled";
import Skeleton from "react-loading-skeleton";

export const CustomSkeleton = () => (
  <PrpojectCardConatiner>
    <LogoConatiner>
      <Skeleton height={"100%"} />
    </LogoConatiner>

    <TextConatiner>
      <ProjectName>
        <Skeleton height={"100%"} width={"100%"} />
      </ProjectName>
      <ProjectName className="dateText">
        <Skeleton height={"100%"} width={"100%"} />
      </ProjectName>
    </TextConatiner>
  </PrpojectCardConatiner>
);
