const { ads, publisher } = require("redux/api");
const {
  GET_ALL_SDK,
  GET_ALL_PLATFORM_VERSION,
  CREATE_PROJECT,
  GET_ALL_PROJECT,
  UPDATE_PROJECT,
  GET_ALL_TUTORIAL,
  GET_TUTORIAL,
  GET_RELATED_ARTICLES,
  GET_ALL_VIDEOS,
  GET_RESOURSE_BY_ID,
  GET_ALL_FAQs,
  CREATE_TICKET,
  GET_ALL_DOWNLOADS,
  GET_PROJECT_BY_ID,
  GET_ALL_TOOL,
  DELETE_PROJECT_BY_ID,
  GET_ALL_SEARCH,
  GET_ALL_CHILD_REFERENCES,
  GET_CHILD_DETAILS,
  CREATE_DOWNLOAD_HISTORY,
  CREATE_FAQ_INTERACTION,
  CHECKBOX,
  REMOVE_CHECKBOX,
  MOVE_SDK,
  GET_ALL_INTERACTIONS,
  REMOVE_SDK,
  REMOVE_ALL_CHECKBOX,
  CLEAR_ALL_PLATFORM_VERSION,
  CLEAR_CHECKBOX,
  GET_USER_PROFILE,
  GET_TERMS_OF_USE,
  UPDATE_USER_PROFILE,
  GET_ALL_MEMBERS,
} = require("./action.type");
const { ERROR, LOADING } = require("redux/Auth/action.type");

const getALLSDK = (id, type) => async (dispatch) => {
  try {
    const { data } = await ads.getAllSDKs(id, type);
    if (type === "sdk") {
      dispatch({ type: GET_ALL_SDK, payload: data });
    } else {
      dispatch({ type: GET_ALL_TOOL, payload: data });
    }
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};
const getAllPlatformVersion = (name) => async (dispatch) => {
  try {
    const { data } = await ads.get_platform_version(name);
    dispatch({ type: GET_ALL_PLATFORM_VERSION, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const createProject = (payload) => async (dispatch) => {
  try {
    const { data } = await ads.createProject(payload);
    dispatch({ type: CREATE_PROJECT, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const getAllProjects = () => async (dispatch) => {
  try {
    const { data } = await ads.getAllProjects();
    dispatch({ type: GET_ALL_PROJECT, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const clearPlatformVersion = () => async (dispatch) => {
 return dispatch({ type: CLEAR_ALL_PLATFORM_VERSION, payload: null });
};

const updateProjectById = (payload, id) => async (dispatch) => {
  try {
    const { data } = await ads.updateProjectById(payload, id);
    dispatch({ type: UPDATE_PROJECT, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const getTutorialByType =
  (type = "article") =>
  async (dispatch) => {
    try {
      const { data } = await ads.tutorialByType(type);
      if (type === "article") {
        dispatch({ type: GET_ALL_TUTORIAL, payload: data });
        return;
      } else {
        dispatch({ type: GET_ALL_VIDEOS, payload: data });
        return;
      }
    } catch (error) {
      dispatch({ type: ERROR, payload: error });
    }
  };

const getTutorialById = (id) => async (dispatch) => {
  try {
    const { data } = await ads.tutorialById(id);
    dispatch({ type: GET_TUTORIAL, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const getRelatedArticlesById = (id) => async (dispatch) => {
  try {
    const { data } = await ads.relatedArticlesById(id);
    dispatch({ type: GET_RELATED_ARTICLES, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const getResourseByID = (id) => async (dispatch) => {
  try {
    const { data } = await ads.getResourceById(id);
    dispatch({ type: GET_RESOURSE_BY_ID, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const getAllFAQsByCategory = (category) => async (dispatch) => {
  try {
    const { data } = await ads.getFAQsByCategory(category);
    dispatch({ type: GET_ALL_FAQs, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const getAllInteractions = (userid) => async (dispatch) => {
  try {
    const { interaction } = await ads.getAllInteractions(userid);
    dispatch({ type: GET_ALL_INTERACTIONS, payload: interaction });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const createTicket = (ticket) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  try {
    await ads.getPostTicket(ticket);
    return dispatch({ type: CREATE_TICKET });
  } catch (error) {
    return dispatch({ type: ERROR, payload: error });
  }
};

const getAllDownloads = () => async (dispatch) => {
  try {
    const data = await ads.getAllDownloads();
    dispatch({ type: GET_ALL_DOWNLOADS, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const makeDownloadsHistory =
  ({ resource_version_id }) =>
  async (dispatch) => {
    try {
      const data = await ads.makeDownloadsHistory({ resource_version_id });
      dispatch({ type: CREATE_DOWNLOAD_HISTORY, payload: data });
      return;
    } catch (error) {
      dispatch({ type: ERROR, payload: error });
    }
  };

const makeFaqIntraction =
  ({ faq_id, interaction }) =>
  async (dispatch) => {
    try {
      return dispatch({
        type: CREATE_FAQ_INTERACTION,
        payload: {
          faq_id,
          interaction,
        },
      });
    } catch (error) {
      return dispatch({ type: ERROR, payload: error });
    }
  };

const getProjectById = (id) => async (dispatch) => {
  try {
    const { data } = await publisher.getProjectById(id);
    dispatch({ type: GET_PROJECT_BY_ID, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const deleteProjectById = (id) => async (dispatch) => {
  try {
    await ads.deleteProjectById(id);
    return dispatch({ type: DELETE_PROJECT_BY_ID, payload: id });
  } catch (error) {
    return dispatch({ type: ERROR, payload: error });
  }
};
// action function for search
const getSearch = (payload) => async (dispatch) => {
  try {
    const { data } = await ads.search(payload);
    dispatch({ type: GET_ALL_SEARCH, payload: data });
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const getALLChildReferences = (id) => async (dispatch) => {
  try {
    const { data } = await ads.getAllAPIRefrencesById(id);
    dispatch({ type: GET_ALL_CHILD_REFERENCES, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const getChildDetails = (id, chiildName) => async (dispatch) => {
  try {
    const { data } = await ads.getChildDetails(id, chiildName);
    dispatch({ type: GET_CHILD_DETAILS, payload: data });
    return;
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

const platformCheckbox = (id) => async (dispatch) => {
  dispatch({
    type: CHECKBOX,
    payload: id,
  });
};
const removeCheckbox = (id) => async (dispatch) => {
  dispatch({
    type: REMOVE_CHECKBOX,
    payload: id,
  });
};

const clearCheckbox = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CHECKBOX,
    payload: [],
  });
};

const moveSDKProject = (payload) => async (dispatch) => {
  try {
    const { data } = await ads.moveSDKById(payload);
    return dispatch({ type: MOVE_SDK, payload: data });
  } catch (error) {
    return dispatch({ type: ERROR, payload: error });
  }
};

const removeSDKProject = (payload) => async (dispatch) => {
  try {
    const { data } = await ads.removeSDKById(payload);
    return dispatch({ type: REMOVE_SDK, payload: data });
  } catch (error) {
    return dispatch({ type: ERROR, payload: error });
  }
};

const removeAllCheckbox = () => async (dispatch) => {
  dispatch({ type: REMOVE_ALL_CHECKBOX, payload: "" });
};

const getUserProfile = () => async (dispatch) => {
  try {
    const { data } = await ads.getUserProfile();
    return dispatch({ type: GET_USER_PROFILE, payload: data });
  } catch (error) {
    return dispatch({ type: ERROR, payload: error });
  }
};

const termsOfUseCheck = (value) => async (dispatch) => {
  dispatch({
    type: GET_TERMS_OF_USE,
    payload: value,
  });
};

const updateUserProfile = (profile) => async (dispatch) => {
  try {
    const { data } = await ads.updateUserProfile(profile);
    return dispatch({ type: UPDATE_USER_PROFILE, payload: data });
  } catch (error) {
    return dispatch({ type: ERROR, payload: error });
  }
};

export const getAllMembers = () => async (dispatch) => {
  try {
    const { data } = await publisher.getAllMembers();
    return dispatch({ type: GET_ALL_MEMBERS, payload: data });
  } catch (error) {
    return dispatch({ type: ERROR, payload: error });
  }
};

export const updateMember = (payload) => async (dispatch) => {
  try {
    const { data } = await publisher.get_platform_version();
    return dispatch({ type: GET_ALL_MEMBERS, payload: data });
  } catch (error) {
    return dispatch({ type: ERROR, payload: error });
  }
};
export default {
  getALLSDK,
  getAllPlatformVersion,
  createProject,
  updateProjectById,
  getTutorialByType,
  removeCheckbox,
  platformCheckbox,
  getResourseByID,
  clearCheckbox,
  getAllProjects,
  getTutorialById,
  clearPlatformVersion,
  getRelatedArticlesById,
  getAllFAQsByCategory,
  createTicket,
  getAllDownloads,
  getProjectById,
  getAllInteractions,
  deleteProjectById,
  getSearch,
  getChildDetails,
  getALLChildReferences,
  makeDownloadsHistory,
  makeFaqIntraction,
  moveSDKProject,
  removeSDKProject,
  removeAllCheckbox,
  getUserProfile,
  termsOfUseCheck,
  updateUserProfile,
  getAllMembers,
  updateMember,
};
