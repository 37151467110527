import { styled } from 'styled-components';

export const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px dashed #d9d9d9;
  background: #fff;
  height: 350px;
`;

export const DeleteBTn = styled.button`
  color: var(--text-color-text-1, #161719);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.56px;
  text-decoration-line: underline;
  border: none;
  outline: none;
  background: non;
`;
export const AddmanualBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
`;

export const CustomInputAddManual = styled.input`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: none;
  border-bottom: 1px solid var(--dark-mode-neutral-color-neutral-5, #3c3e43);
  outline: none;
  background: none;
`;
