import {
  DeleteBtn,
  ImageContainer,
  MainCont,
} from "components/styled/ProjectDetails.style";
import {
  ProjectName,
  TextCon,
  TextConatiner,
} from "components/styled/home.styled";
import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeltonProjectDetails = () => {
  return (
    <>
      <div className="d-flex gap-4">
        <ImageContainer>
          <Skeleton height={"100%"} width={"100%"} />
        </ImageContainer>
        <MainCont>
          <TextConatiner className="mb-4">
            <ProjectName>
              <Skeleton height={"40%"} width={"100%"} />
            </ProjectName>
            <ProjectName className="dateText">
              <Skeleton height={"40%"} width={"100%"} />
            </ProjectName>{" "}
            <ProjectName className="dateText">
              <Skeleton height={"40%"} width={"100%"} />
            </ProjectName>{" "}
            <ProjectName className="dateText">
              <Skeleton height={"40%"} width={"100%"} />
            </ProjectName>
          </TextConatiner>
          <DeleteBtn>
            <Skeleton height={"100%"} width={"100%"} />
          </DeleteBtn>
        </MainCont>
      </div>
    </>
  );
};

export default SkeltonProjectDetails;
