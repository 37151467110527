const { auth, publisher } = require("redux/api");
const {
  LOGIN,
  ERROR,
  LOGOUT,
  LOADING,
  LOADED,
  SUCCESS,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  CLEAR_SIGNUP,
  PROFILE_INFO,
} = require("./action.type");

export const loginAction = (userData) => async (dispatch) => {
  try {
    const payload = {};
    const authLocal = JSON.parse(localStorage.getItem("user"));
    dispatch({ type: LOADING, payload: true });
    if (!authLocal) {
      const response = await auth.login(userData);
      payload.refreshtoken = response.data?.refresh_token;
      payload._id = response.data.user._id;
      payload.email = response.data.user.email;
      payload.profile = response.data.user.profile;
      payload.name = response.data.user.name;
      payload.token = response.data.token;
      dispatch({ type: LOGIN, payload });
      dispatch({ type: LOADED, payload: false });
      dispatch({ type: SUCCESS, payload: "" });
      window.localStorage.setItem("user", JSON.stringify(payload));
      window.location.href = "/home";
      return;
    } else {
      dispatch({ type: LOGIN, payload: authLocal });
      dispatch({ type: SUCCESS, payload: "" });
      dispatch({ type: LOADED, payload: false });
      window.location.href = "/home";
      return;
    }
  } catch (error) {
    dispatch({ type: LOADED, payload: false });
    dispatch({ type: SUCCESS, payload: "" });
    dispatch({ type: ERROR, payload: error.response.data.message });
  }
};

export const signupAction = (userData, setError) => async (dispatch) => {
  try {
    setError({
      error: "",
      loader: true,
      success: "",
    });
    const payload = {};
    dispatch({ type: LOADING, payload: true });
    try {
      const response = await auth.signup(userData);
      dispatch({ type: LOADED, payload: false });
      dispatch({ type: SUCCESS, payload: response.data.message });
      setError({
        success: response.data.message,
        loader: false,
      });
      dispatch({ type: SIGNUP_SUCCESS, payload: true });
    } catch (e) {
      dispatch({ type: LOADED, payload: false });
      setError({
        error: e.response.data.message,
        loader: false,
        success: "",
      });
      dispatch({ type: SUCCESS, payload: "" });
      dispatch({ type: SIGNUP_FAILURE, payload: false });
      dispatch({ type: ERROR, payload: e.response.data.message });
      return;
    }
  } catch (error) {
    dispatch({ type: ERROR, payload: error });
  }
};

export const logoutAction = () => async (dispatch) => {
  window.localStorage.removeItem("user");
  dispatch({ type: LOGOUT });
  window.location.href = "/";
};

export const resendToken = (email) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  try {
    const response = await auth.resendToken(email);
    dispatch({ type: SUCCESS, payload: response.data.message });
    dispatch({ type: LOADED, payload: false });
    dispatch({ type: ERROR, payload: "" });
  } catch (error) {
    dispatch({ type: LOADED, payload: false });
    dispatch({ type: SUCCESS, payload: "" });
    dispatch({ type: ERROR, payload: error.response.data.message });
  }
};
export const forgotPassword = (email) => async (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  try {
    const response = await auth.forgotPassword(email);
    dispatch({ type: SUCCESS, payload: response.data.message });
    dispatch({ type: LOADED, payload: false });
    dispatch({ type: ERROR, payload: "" });
  } catch (error) {
    dispatch({ type: LOADED, payload: false });
    dispatch({ type: SUCCESS, payload: "" });
    dispatch({ type: ERROR, payload: error.response.data.message });
  }
};

export const regeneratePassword =
  (password, email, token) => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    try {
      const response = await auth.regeneratePassword(password, email, token);
      dispatch({ type: SUCCESS, payload: response.data.message });
      dispatch({ type: LOADED, payload: false });
      dispatch({ type: ERROR, payload: "" });
    } catch (error) {
      dispatch({ type: LOADED, payload: false });
      dispatch({ type: SUCCESS, payload: "" });
      dispatch({ type: ERROR, payload: error.response.data.message });
    }
  };

export const clearSignup = () => {
  return {
    type: CLEAR_SIGNUP,
    payload: null,
  };
};

export const sendOTP = (contactNo, setError) => async (dispatch) => {
  setError({
    error: "",
    loader: true,
    success: "",
  });
  try {
    const response = await auth.sendOTP({ contactNo });
    setError({
      success: response.data.message,
      loader: false,
      error: "",
    });
    dispatch({ type: SUCCESS, payload: response.data.message });
    dispatch({ type: LOADED, payload: false });
    dispatch({ type: ERROR, payload: "" });
  } catch (error) {
    setError({
      error: error.response.data.message,
      loader: false,
      success: "",
    });
    dispatch({ type: LOADED, payload: false });
    dispatch({ type: SUCCESS, payload: "" });
    dispatch({ type: ERROR, payload: error.response.data.message });
  }
};
export const otpValidation = (contactNo, otp, setError) => async (dispatch) => {
  setError({
    error: "",
    loader: true,
    success: "",
  });
  try {
    const response = await auth.verifyOTP({ contactNo, otp });
    setError({
      success: response.data.message,
      loader: false,
      error: "",
    });
    setTimeout(() => {
      localStorage.setItem("user", JSON.stringify(response.data.data));
      window.location.href = "/home";
    }, 1000);
    dispatch({ type: SUCCESS, payload: response.data.message });
    dispatch({ type: LOADED, payload: false });
    dispatch({ type: ERROR, payload: "" });
  } catch (error) {
    setError({
      error: error.response.data.message,
      loader: false,
      success: "",
    });
    dispatch({ type: LOADED, payload: false });
    dispatch({ type: SUCCESS, payload: "" });
    dispatch({ type: ERROR, payload: error.response.data.message });
  }
};

export const getProfileInfo = () => async (dispatch) => {
  try {
    const response = await publisher.getUserDetailsFromSSO();
    if (response.data.status === 200) {
      dispatch({ type: PROFILE_INFO, payload: response.data.data });
    }
    if (response.data.status >= 400) {
      dispatch({ type: PROFILE_INFO, payload: {} });
       
    }
  } catch (error) {
    
    dispatch({ type: ERROR, payload: error.response.data.message });
  }
};
