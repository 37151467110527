import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // If using React Router
import { StyledBreadcrumb } from "./breadcrumb.styled";

const CustomBreadcrumb = ({ items }) => {
  const navigate = useNavigate();
  return (
    <Breadcrumb style={{ display: "flex", alignItems: "center", }}>
      {items?.map((item, index) => (
        <StyledBreadcrumb
          key={item?._id || index}
          className="fs-18 cursor-pointer"
          onClick={() => {
            navigate(`${item?.link}`);
          }}>
          {item?.text}
        </StyledBreadcrumb>
      ))}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
