import React, { useEffect, useState } from "react";
import { FixedPrice } from "./FixedPrice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Subscription } from "./Subscription";
import { FreeCost } from "./FreeCost";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "redux/Auth/action";

export const PriceTabs = ({
  isProjectPage,
  activeTab,
  appId,
  projectId,
  priceInfo,
}) => {
  const [searchParams] = useSearchParams();
  const activeTAB = searchParams.get("tab") || activeTab;
  const dispatch = useDispatch();
  // const projectId = searchParams.get("projectId");
  // const appId = searchParams.get("appId");
  const { projectFormDetails, singleProjectDetails } = useSelector(
    (state) => state.projectReducer
  );

  const [searchQuery] = useSearchParams();
  const realeaseChannel = searchQuery.get("rcName");
  // projectFormDetails?.rcName ||
  // singleProjectDetails?.releaseChannel ||
  // singleProjectDetails?.rcName;
  // Define your tabs
  const tabs = realeaseChannel !== "production" ? ["Free"] : ["Fixed", "Free"];

  // Initialize the activeTabs state with the first tab
  const [activeTabs, setActiveTabs] = useState({});

  const getButtonClass = (currentTab) => {
    if (activeTabs.tab === currentTab) {
      return activeTabs.active;
    } else if (activeTAB === currentTab) {
      return "active rounded-pill";
    } else {
      return "";
    }
  };

  const [tabContent, setTabContent] = useState(null);

  useEffect(() => {
    if (activeTabs.tab === "Fixed") {
      setTabContent(
        isProjectPage ? (
          priceInfo?.PriceModelType && <FixedPrice priceDetails={priceInfo} />
        ) : (
          <FixedPrice priceDetails={priceInfo} />
        )
      );
    } else if (activeTabs.tab === "Free") {
      setTabContent(<FreeCost />);
    }
  }, [activeTabs?.tab, activeTAB?.active]);

  useEffect(() => {
    // Set the activeTabs state based on the URL parameter (activeTAB)
    if (realeaseChannel === "beta") {
      setActiveTabs({
        tab: tabs[0], // Use activeTAB if available, otherwise use the first tab
        active: "active rounded-pill",
      });
    } else {
      setActiveTabs({
        tab: activeTAB || tabs[0], // Use activeTAB if available, otherwise use the first tab
        active: "active rounded-pill",
      });
    }
  }, [activeTAB, searchParams]);

   useEffect(() => {
     dispatch(getProfileInfo());
   }, []);
  const navigate = useNavigate();


  return (
    <div className="p-4" style={{ height: "61vh", overflowY: "scroll" }}>
      <div className="btn-group tabs-btn">
        {tabs.map((tab, index) => (
          <button
            id="priceBtn"
            key={tab.id}
            type="button"
            className={`btn activeBlue ${getButtonClass(tab)}`}
            onClick={() => {
              isProjectPage
                ? navigate({
                    pathname: window.location.pathname,
                    search: window.location.search + `& ${tab}`,
                  })
                : navigate(
                    `/createProject/newProject/setPrice?tab=${tab}&projectId=${projectId}&appId=${appId}&rcName=${realeaseChannel}`
                  );
              setActiveTabs({ tab: tab, active: "active rounded-pill" });
            }}
          >
            {tab}
          </button>
        ))}
      </div>
      {tabContent}
    </div>
  );
};
