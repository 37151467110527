import styled from "styled-components";

export const SidePanelPrimaryBox = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.black};
  height: fit-content;
  background: ${({ theme }) => theme.secondary};
  overflow-y: auto;
  border-radius: 12px;
  padding-bottom: 12px;
  &::-webkit-scrollbar {
    width: 0px;
    height: 3px;
    background-color: ${({ theme }) => theme.primaryBlue};
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 8px;
  }
  overflow-x: hidden;
  // box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const SidePanelSecondaryBox = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.black};
  background-color: #f7f7f7;
  height: 70px;
  border: 1px solid;
  &.aligncenter {
    display: flex;
    align-items: center;
  }
`;

export const ButtonPrimary = styled.button`
  width: ${({ width }) => (width ? width : "100%")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
  border: none;
  outline: none;
  color: ${({ theme, color }) => (color ? color : theme.primary)};
  height: ${({ height }) => (height ? height : "50px")};
  background: ${({ theme, bg }) => (bg ? bg : theme.primaryBlue)};
  border-radius: 50px;
  cursor: pointer;
  &:hover {
    background: ${({ theme, hover }) => (hover ? hover : theme.primaryBlue)};
    opacity: 0.8;
  }
`;
export const ButtonSecondary = styled.button`
  width: ${({ width }) => (width ? width : "100%")};
  border: 1px solid ${({ theme, border }) => (border ? border : theme.black)};
  outline: none;
  color: ${({ theme, color }) => (color ? color : theme.black)};
  height: ${({ height }) => (height ? height : "50px")};
  background: transparent;
  border-radius: 50px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.secondary};
    opacity: 0.8;
  }
`;

export const BoxWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ theme, width }) => (width ? width : "410px")};
  height: ${({ theme, height }) => (height ? height : "90%")};
  border-radius: 8px;
  padding: 12px 26px;
  &.w-fit {
    width: fit-content;
  }
  &.background {
    background: #f2f2f2;
    backdrop-filte: blur(38px);
  }
  &.border-radius-50 {
    border-radius: 500px;
  }
  input {
    background: transparent;
    border: none;
    width: ${({ width }) => width}px;
    outline: none;
    margin-left: 10px;
    color: ${({ theme }) => theme.black};
  }
`;

export const TextWhite = styled.h6`
  color: ${({ theme }) => theme.secondary};
  font-weight: normal;
  &.fs-14 {
    font-size: 14px;
    font-weight: 300;
  }
  &.text-underlined {
    width: fit-content;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.black};
  }
  &.fs-16 {
    font-size: 16px;
  }
  &.fs-18 {
    font-size: 18px;
  }
  &.fs-20 {
    font-size: 20px;
  }

  &.fs-24 {
    font-size: 24px;
    font-weight: 700;
  }
  &.fw-600 {
    font-weight: 600;
  }
  &.opacity-5 {
    opacity: 0.5;
  }
  &.fs-32 {
    font-size: 32px;
    font-weight: 700;
  }
`;

export const TextBlack = styled.h6`
  color: ${({ theme }) => theme.black};
  font-weight: 400;
  text-transform: capitalize;
  &.fs-14 {
    font-size: 14px;
    font-weight: 400;
  }

  &.light-black{
    color: ${({ theme }) => theme.lightBlack};
  }

  &.text-underlined {
    width: fit-content;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.black};
  }
  &.fs-16 {
    font-weight: 600;
    font-size: 16px;
  }
  &.fs-18 {
    font-size: 18px;
  }
  &.fs-20 {
    font-size: 20px;
    font-weight: 500;
  }

  &.fs-24 {
    font-size: 24px;
    font-weight: 700;
  }
  &.fw-600 {
    font-weight: 600;
  }
  &.opacity-5 {
    opacity: 0.5;
  }
  &.fs-32 {
    font-size: 32px;
    font-weight: 700;
  }
  &.fs-12 {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: var(--dark-mode-neutral-color-neutral-1, #0c0c0d);
    padding: 0.5rem;
  }
  &.fs-18-500 {
    color: var(--text-color-text-1, #161719);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 0.5rem;
  }
  &.fs-12-400 {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: var(--dark-mode-neutral-color-neutral-1, #0c0c0d);
    cursor: pointer;
  }
  &.fs-14-500 {
    color: var(--text-color-text-2, #333437);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
  }

  &.fs-14-600 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &.fs-14-400 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &.fs-14-upper {
    color: var(--text-color-text-1, #161719);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    padding-top: 12px;
  }
  &.fs-12-400-undeline {
    border-bottom: 1px solid #d9d9d9;
    font-size: 16px;
    font-style: normal;
    line-height: 18px;
    color: var(--dark-mode-neutral-color-neutral-1, #0c0c0d);
    padding: 0.5rem;
  }
  .fs-12-noti {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid var(--border-color-border-2, #ebebeb);
  }
  &.fs-18-storage {
    color: var(--text-color-text-1, #161719);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 100% */
  }
  &.usedIn {
    color:  #161719;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 2rem 0 0.5rem 0;
    border-bottom: 1px solid #ebebeb;
  }
  &.usedData {
    color: var(--text-color-text-1, #161719);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    padding:0.5rem 0;
  }
  &.fs-16-gb-rupees {
    display: flex;
    flex-direction: column;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 0.3rem;
  
  }
  &.fs-14-700 {
    color: var(--text-color-text-1, #161719);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0.5rem 0;
  }
  &.title {
    color: var(--dark-mode-neutral-color-neutral-1, #0c0c0d);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &.upload {
    padding-top: 16px;
    color: var(--text-color-text-1, #161719);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    cursor: pointer;
  }
  &.pleaseUpload {
    color: #bfbfbf;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    padding: 0.5rem;
  }
  &.addText {
    color: #000;
    text-align: center
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  &.fs-16-400{
    color: rgba(31, 31, 31, 0.87);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  &.fs-14-co{
    color: #000;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px;
width:143px;
  }
  &.fs-18-500{
    color: #000;
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 29.124px;
  }
  &.fs-10-300{
    color: #A6AAB1;
font-family: Roboto;
font-size: 9.766px;
font-style: normal;
font-weight: 300;

  }
  &.live{
    color:#6688FF;
    text-decoration: underline;
    text-align: right;
  }
  &.danger{
    display: inline;
    
   & span{
    margin-right: 10px;
   }
   &.Discount{

   }
  
  }
 
`;

export const DiscountPrice = styled.span`
  font-size: 12px;
  text-decoration: line-through;
`;

export const Active = styled.span`
  font-size: 12px;
`;

export const TextMiddle = styled.div`
  color: ${({ theme }) => theme.lightGrey};
  text-align: center;
  &.fs-14 {
    font-size: 14px;
    font-weight: 400;
  }
  &.text-underlined {
    width: fit-content;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.black};
  }
  &.fs-16 {
    font-weight: 500;
    font-size: 16px;
  }
  &.fs-18 {
    font-size: 18px;
  }
  &.fs-20 {
    font-size: 20px;
    font-weight: 700;
  }

  &.fs-24 {
    font-size: 24px;
    font-weight: 700;
  }
  &.fw-600 {
    font-weight: 600;
  }
  &.opacity-5 {
    opacity: 0.5;
  }
  &.fs-32 {
    font-size: 32px;
    font-weight: 700;
  }
`;

export const OverFlow = styled.div`
  height: 84.5vh;
  margin-bottom: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.black};
  background: ${({ theme }) => theme.primaryDark};
  background-color: ${({ theme }) => theme.primaryDark};
  accent-color: ${({ theme }) => theme.primaryBlue};
  outline: none;
  cursor: pointer;
  &:checked {
    background: ${({ theme }) => theme.primaryBlue};
    accent-color: ${({ theme }) => theme.primaryBlue};
  }
`;

export const CheckboxLarge = styled.div`
  width: 100%;
  display: flex;
  // height: 140px;
  aspect-ratio: 1/0.7;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  // border: 1px solid ${({ theme }) => theme.border};
  background: ${({ theme }) => theme.white};
  & img {
    width: 94.21px;
    height: 69.94px;
    object-fit: contain;
  }
  &.active {
    border: 1px solid ${({ theme }) => theme.primaryBlue};
  }
`;

export const SelectBox = styled.div`
  position: relative;
  width: ${({ theme, width }) => (width ? width : "100%")};
  // margin-top: 10px;
  cursor: pointer;
  padding: 2px ${({ pad }) => (pad ? "8px" : "0")};
  border-radius: 8px;
  // margin-bottom: 10px;
  border-bottom: 1px solid
    ${({ theme, border }) => (border ? theme.border : "none")};
  border: 1px solid ${({ theme, border }) => (border ? theme.border : "none")};
  & img {
    cursor: pointer;
  }
`;
export const OptionBox = styled.div`
  width: auto;
  position: absolute;
  animation: ${({ show }) => (show ? "show" : "hide")} 0.5s ease-in-out;
  padding: 10px 10px;
  background: ${({ theme }) => theme.primary};
  border-radius: 8px;
  height: auto;
  margin-top: 5px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
  z-index: 1;
  margin-bottom: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.border};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scroll};
    width: 3px;
    height: 3px;
    border-radius: 0
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  @keyframes show {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export const CustomInput = styled.input`
  width: 100%;
  height: auto;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  background: ${({ bg }) => bg || "transparent"};
  outline: none;
  color: ${({ theme }) => theme.black};
  &::placeholder {
    color: ${({ theme }) => theme.lightGrey};
  }
  &.color-grey {
    color: ${({ theme }) => theme.lightGrey};
    font-weight: 500;
  }
`;

export const Label = styled.label`
  width: 100%;
  border-right: none;
  color: ${({ theme }) => theme.black};
`;

export const UpoloadHeading = styled.h1`
  color: ${({ theme }) => theme.black};
  font-weight: 400;
  font-size: 32px;
`;

export const PriceInputWrapper = styled.div`
  display: flex;
  border: 1px solid;
  height: 38px;
  padding-right: 10px;
  background: #fff;
  border-radius: 8px;
`;

export const CLickBtn = styled.button`
  background: transparent;
  border: 0;
`;

export const FileUploadInput = styled.button`
  width: 100%;
  height: 136px;
  background: #242528;
  border-radius: 10px;
  border: 0;
  display: column;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.black};
`;

export const UploadBtn = styled.button`
  border: 1px solid #514d4d;
  border-radius: 50px;
  font-weight: 400;
  font-size: 14px;
  padding: 12px 26px;
  color: ${({ theme }) => theme.black};
  background: transparent;
`;

export const CardContainer = styled.div`
  padding: 25px;
  background: #ffffff;
  border-radius: 12px;
`;

export const CardHeading = styled.div`
  display: flex;
  justify-content: space-between;
  text-decoration: underline;
  padding: 5px;
`;

export const KeyBtn = styled.button`
  padding: 5px 16px;
  background: #303136;
  border-radius: 20px;
  color: #999999;
  font-weight: 400;
  font-size: 14px;
  border: 0;
`;

export const TrashBtn = styled.button`
  background: #242528;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  border: 0;
  padding-bottom: 8px;
`;

export const Box = styled.div`
  width: 100%;
  padding: 10px 20px;
  border-radius: 8px;
  // height: 500px;
  background-color: ${({ theme }) => theme.secondary};
  z-index: -1;
`;

export const MoveProjectBtn = styled.button`
  color: #cccccc;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #cccccc;
  align-items: center;
  padding: 4px 0px;
  font-size: 15px;
`;

export const DeleteProjectBtn = styled.button`
  margin-left: 15px;
  background: #fff;
  border: 0;
  align-items: center;
  color: #ff7666;
  padding: 4px 0px;
  font-size: 15px;
`;

export const ButtonCancel = styled.button`
  margin-left: 15px;
  width: ${({ width }) => (width ? width : "100%")};
  border: 1px solid ${({ theme }) => theme.lightGrey};
  outline: none;
  color: ${({ theme }) => theme.black};
  height: ${({ height }) => (height ? height : "50px")};
  background: transparent;
  border-radius: 50px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.primaryDark};
    opacity: 0.8;
  }
`;

export const DropdownBtn = styled.button`
  background: transparent;
  border: 0;
  width: 100%;
  padding: 10px;
  padding-top: 0;
  margin-bottom: 10px;
  border-bottom: 0.5px solid #3c3e43;
  text-align: left;
`;

export const Chips = styled.div`
  background: ${({ theme, active }) =>
    active ? theme.primaryBlue : theme.secondary};
  border-radius: 50px;
  color: ${({ theme }) => theme.black};
  padding: 10px 15px;
  margin-left: 10px;
  height: 45px;
`;

export const CustomLi = styled.li`
  padding: 8px;
  color: #6c6f74;
`;

export const SelectBoxVersion = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  & img {
    cursor: pointer;
  }
`;

export const SelectVersionOptions = styled.div`

  position: absolute;
  width: 100%;
  animation: ${({ show }) => (show ? "show" : "hide")} 0.5s ease-in-out;
  padding: 5px 10px;
  background: ${({ theme }) => theme.border};
  border-radius: 8px;
  height: 100px;
  z-index: 1;
  margin-bottom: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.border};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scroll};
    width: 3px;
    height: 3px;
    border-radius: 0
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  @keyframes show {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export const CustomProfileContainer = styled.div`
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
  // width: fit-content !important;
`;

export const ProfileImg = styled.img`
  object-fit: fill;
  width: 150px;
  height: 150px;
  border-radius: 8px;
`;

export const NotificationContainer = styled.div`
  padding: 25px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  width: 412px;
  height: auto;
  max-height: 70vh;
  overflow-y: scroll;
`;

export const UploadCircle = styled.div`
  border: 2px solid #161719;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
`;

export const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  transition: all 0.3s ease; /* Add transition for smooth effect */

  /* Styles for hover */
  :hover {
    /* Change text color and border color on hover */
    color: red;
    border-color: red;
  }
`;

export const DownloadCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  background: ${({ theme, bg }) => (bg ? bg : "#ffffff")};
  border-radius: ${({ bor }) => (bor ? "8px" : "8px 0 0 8px")};
`;

export const DownloadGreenWrapper = styled.div`
  display: flex;
  gap: 5px;
  alignitems: center;
  justifycontent: center;
  color: ${({ theme, color }) => (color ? color : theme.green)};
  background: ${({ theme, bg }) => (bg ? bg : theme.lightGreen)};
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  margin-top: 20px;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  // gap: 1rem;
  alignitems: center;
  padding: 5px;
  border-radius: 5px;
  width: 200px;
  font-size: 20px;
  margin-top: 5px;
`;

export const GraphCard = styled.div`
  height: 320px;
  width: ${({ theme, width }) => (width ? width : "350px")};
  background: ${({ theme }) => theme.primary};
  border-radius: 8px;
  padding: 1rem;
`;

export const BlackCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #242528;
`;

export const WhiteCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #75777d;
  overflow: hidden;
  object-fit: cover;
`;

export const MediaWapper = styled.div`
  display: flex;
  border:"1px solid";
  overflowX: scroll !important;
  gap; 20px;
  paddingbottom: 16px;
  &::-webkit-scrollbar {
    width: 5px;
  }

`;
export const TagWrapper = styled.div`
  width: auto;
  padding: 0.25rem 1rem;
  border-radius: 16px;
  background-color: #fff;
`;

export const DetailsWrapper = styled.div`
  height: 200px;
  overflow: scroll;
`;

export const ProjectName = styled.div`
  color: white;
  font-size: 13.022px;
  font-style: normal;
  font-weight: 500;
`;

export const Price = styled.div`
  color: white;
  font-family: Roboto;
  font-size: 11.394px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.161px; /* 185.714% */
`;

export const ButtonMember = styled.button`
  border-radius: 500px;
  border: 1px solid #6c56fb;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
  display: flex;
  padding: 7px 14px;
  align-items: center;
  gap: 10px;
  color: #6c56fb;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
`;

export const BadgePrimary = styled.div`
  display: flex;
  padding: 4px 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: rgba(92, 127, 255, 0.1);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #5c7fff;
`;

// middelware css

export const LoginConatiner = styled.div`
  position: relative;
  height: 100vh;
  display: flex;

  @media (max-width: 1280px) {
    height: 100%;
  }
`;

export const LeftContainer = styled.div`
  background: ${(props) => props.background || `transparent`};
  box-shadow: 9px 6px 33px 0px rgba(0, 0, 0, 0.25);
  font-family: "Roboto";
  background-color: #6c56fb;
  width: 66%;
  height: 100vh;
  @media (max-width: 1280px) {
    width: 67%;
    height: 100vh;
   
  }
  @media (max-width: 800px) {
    display: none;
 

`;
export const ImgLogo = styled.div`
  text-align: left;
  margin: 30px 0 0 58px;
  height: 31px;
  width: 163px;
`;
export const TextContainer = styled.div`
  text-align: left;
  margin: 10% 0 0 10%;
`;
export const TextWhiteDiv = styled.div`
  color: ${(props) => props.color || `transparent`};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  minheight: 200px;

  &.customTitle {
    font-size: 33px;
    @media screen and (max-width: 1280px) {
      font-size: 23px;
    }
    @media screen and (max-width: 500px) {
      font-size: 28px;
    }
  }
  &.dotWithTxt {
    display: flex;
    align-items: center;
    color: #fff;
  }
  &.backtologin {
    color: #0088ce;
    cursor: pointer;
  }
  &.backtologin:hover {
    text-decoration: underline;
  }
  &.Back {
    cursor: pointer;
    margin-top: 8px;
    font-size: 18px;
    color: #161719;
    font-weight: 500;
  }
  &.right-align {
    text-align: right;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    color: #808080;
  }
  & span {
    font-size: 20px;
    font-weight: 600;
    @media screen and (max-width: 1540px) {
      font-size: 24px;
    }
  }
  // @media screen and (max-width: 1280px) {
  //   font-size: 10px;
  // }
`;

export const CreateDisExp = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 500;
`;
export const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 50%;
  fill: none;
  margin: 0 30px;
`;
export const AjnaSymbole = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 380px;
  & img {
    height: 100%;
    width: 100%;
  }
`;
// right container
export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1280px) {
    width: 32%;
  }
  @media (max-width: 1020px) {
    width: 38%;
  }
  @media (max-width: 800px) {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  @media (max-width: 500px) {
    width: 100%;
    // height: 100%;
  }
`;

export const Input = styled.input`
  padding: 2px 0;
  width: 100%;
  border: none;
  border-bottom: 1.5px solid #bfbfbf;
  outline: none;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 500;
  color: #333437;
`;

export const RememberMe = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 13px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 0;
  gap: 15px;
`;
export const PrimaryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 48px;
  background: #6c56fb;
  color: #fff;
  border-radius: 72px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  border: ${(props) => props.border};
  @media (max-width: 1280px) {
    padding: 6px 48px;
  }
`;

export const NewUserTxt = styled.p`
  color: #3c3e43;
  font-size: 18px;
  font-weight: 600;
  & span {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const AjnaLogo = styled.div`
  display: none;
  height: 100px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const InfoMainCard = styled.div`
  width: ${(props) => props.width || "251px"};
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
`;

export const InfoText = styled.p`
  color: var(--functional-colors-info, #5c7fff);
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: start;
`;

export const UploadText = styled.p`
  color: #a6aab1;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  &.videopaly {
    color: #6c56fb;
  }
`;

export const UploadTextinfo = styled.span`
  color: #a6aab1;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 152px;
  &.videopalyText {
    font-weight: 400;
    line-height: 18px;
  }
`;

export const NsdcText = styled.div`
  color: var(--text-color-text-1, #161719);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 100% */
`;
