import React from "react";
import {
  CardContainer,
  CardContainer2,
  KeyTxt,
  TextBtn,
  TextBtnCont,
} from "components/styled/ProjectDetails.style";
import {
  KeyValue,
  ProgressBarBox,
} from "components/styled/projectreview.styled";
import HStepProgress from "./HStepProgress";
import { useNavigate } from "react-router-dom";
import { getDateByUpdate } from "helper";
import { ButtonPrimary } from "components/styled/common.styled";

const ProjectViewDetails = ({ appsInreview = [] }) => {
  const navigate = useNavigate();
  const showHstepperFuction = (id, status) => {
    // if(!id){
    navigate("/review_details/" + id);
    // }
  };

  return (
    <div className="leftCont mb-5">
      <div className="reviewHeader">
        <div
          className="text-white bg-dark rounded-top p-3"
          style={{ fontSize: "22px" }}
        >
          Under review
        </div>
        <div className=" my-2 py-3 border-bottom d-flex justify-content-between">
          <div className="col-4 px-4">
            <KeyTxt>Project Name</KeyTxt>
          </div>
          <div className="d-flex align-items-center col-4 justify-content-between">
            <KeyValue>
              <KeyTxt>Platform</KeyTxt>
            </KeyValue>
            <KeyValue>
              <KeyTxt>Release Channel</KeyTxt>
            </KeyValue>
            <KeyValue>
              <KeyTxt>Version</KeyTxt>
            </KeyValue>
          </div>
          <div className="col-3 d-flex justify-content-center">
            <KeyTxt>Status</KeyTxt>
          </div>
        </div>
      </div>
      {appsInreview?.length > 0 &&
        appsInreview?.map((item, i) => (
          <div key={item?._id} className="">
            <CardContainer2
              className="my-2 py-2 border-bottom justify-content-between reviewProj"
              onClick={() => showHstepperFuction(item?._id, item?.status)}
            >
              <div className=" d-flex align-items-center col-4  ps-3">
                <div className="imgReviewThubnail">
                  <img
                    src={item.logoURL}
                    alt="img"
                    onError={(e) => {
                      e.target.src =
                        "https://cdn.ajnavidya.com/ajnavidya/no%20thumbnail.png";
                    }}
                  />
                </div>
                <div className="px-2">
                  <p
                    style={{
                      fontSize: "24px",
                      color: "#161719",
                      fontWeight: "600",
                      lineHeight: 0.5,
                    }}
                  >
                    {item?.projectID?.projectName.length > 12 ? (
                      <>{item?.projectID?.projectName.slice(0, 12)}...</>
                    ) : (
                      <>{item?.projectID?.projectName}</>
                    )}
                  </p>
                  <KeyValue>
                    <KeyTxt>Submitted On :&nbsp;</KeyTxt>
                    <KeyTxt>{item?.submittedOn?.split("T")[0]}</KeyTxt>
                  </KeyValue>
                </div>
              </div>
              <div
                className="d-flex align-items-center col-4 justify-content-between"
                style={{ fontSize: "18px" }}
              >
                <KeyValue>
                  <KeyTxt>
                    {" "}
                    {item?.applicationType === ".apk"
                      ? "Android"
                      : item?.applicationType === ".exe"
                      ? "Window"
                      : item?.applicationType === ".sh"
                      ? "Linux"
                      : ""}
                  </KeyTxt>
                  {/* <div></div> */}
                </KeyValue>
                <KeyValue>
                  {/* <KeyTxt> Release Channel - &nbsp;</KeyTxt> */}
                  <KeyTxt>{item.rcName}</KeyTxt>
                </KeyValue>
                <KeyValue>
                  {/* <KeyTxt>Version - &nbsp;</KeyTxt> */}
                  <KeyTxt>{item.version}</KeyTxt>
                </KeyValue>
              </div>

              <div className="col-3 pe-3 d-flex justify-content-end align-items-center gap-3 ">
                {item?.status === "Approved" ? (
                  <>
                    <div
                      className="statusTab"
                      style={{
                        color: "#20A019",
                        background: "rgba(32, 160, 25, 0.10)",
                      }}
                    >
                      Approved
                    </div>
                    <ButtonPrimary
                      width={"130px"}
                      style={{
                        borderRadius: "10px",
                      }}
                    >
                      Make Live
                    </ButtonPrimary>
                  </>
                ) : item?.status === "Rejected" ? (
                  <>
                    <div
                      className="statusTab"
                      style={{
                        color: "#FF7666",
                        background: "rgba(255, 118, 102, 0.10)",
                      }}
                    >
                      Rejected
                    </div>
                    <ButtonPrimary
                      width={"130px"}
                      style={{
                        borderRadius: "10px",
                        padding: "10px 20px",
                      }}
                    >
                      Reupload
                    </ButtonPrimary>
                  </>
                ) : (
                  <>
                    <div
                      className="statusTab"
                      style={{
                        color: "#68F",
                        background: "rgba(102, 136, 255, 0.10)",
                      }}
                    >
                      Under Review
                    </div>
                  </>
                )}
              </div>
            </CardContainer2>
            {/* <ProgressBarBox
              onClick={() => showHstepperFuction(item?._id, item?.status)}
            >
              <HStepProgress appID={item._id} status={item.status} />
            </ProgressBarBox> */}
          </div>
        ))}
    </div>
  );
};

export default ProjectViewDetails;
