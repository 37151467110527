import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "./context/theme";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import { EraseAllCookies, EraseCookies } from "helper";
// import apm from "elastic-apm-node";

const root = ReactDOM.createRoot(document.getElementById("root"));

const logoutURL = window.location.search;

if (logoutURL.includes("logout")) {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("tokenExpired");
  EraseAllCookies();
  EraseCookies("token");
  window.location.replace("/");
}

// Token Handling

root.render(
  // Main Component
  <Provider store={store}>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
