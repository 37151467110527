import { CustomBtn } from "components/styled/Buystorage.style";
import { ButtonMember, TextBlack } from "components/styled/common.styled";
import { CustomLabel } from "components/styled/payment.styled";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toolIcon from "assets/toolIcon.svg";
import {
  AddmanualBox,
  CustomInputAddManual,
} from "components/styled/Invites.style";
import { publisher } from "redux/api";
import { useDispatch, useSelector } from "react-redux";
import UsersList from "./UsersList";
import { addUser } from "redux/Project/project.action";
import { toast } from "react-toastify";

const AddManually2 = ({ setIsOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputData, setInputData] = useState({
    name: "",
    emailId: "",
  });
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.projectReducer.users);

  const InputValueChange = (e) => {
    const { name, value } = e.target;
    if (name === "emailId") {
      setInputData({ ...inputData, [name]: value.toLowerCase() });
      return;
    }
    setInputData({ ...inputData, [name]: value });
  };
  const isDuplicateUser = () => {
    return formData?.some((user) => user.emailId === inputData.emailId);
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (inputData.name === "" || inputData.emailId === "") {
      toast.info("Please fill all the fields");
      setIsLoading(false);
      return;
    }
    const emailValidate = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (!emailValidate.test(inputData.emailId)) {
      setIsLoading(false);

      toast.info("Please enter valid email");
      return;
    }
    try {
      if (isDuplicateUser()) {
        setIsLoading(false);
        toast.error("User with the same email already exists");
        return;
      }
      // Reset the inputData state to clear the input fields
      dispatch(addUser({ ...inputData }));

      setIsLoading(false);
      toast.success("User added successfully");
      setInputData({
        name: "",
        emailId: "",
      });
    } catch (error) {
      setIsLoading(false);

      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while processing your request");
      }
    }
  };
  return (
    <AddmanualBox>
      <TextBlack className="addText">Add Manually </TextBlack>
      <CustomLabel>Name</CustomLabel>
      <CustomInputAddManual
        placeholder="Username"
        name="name"
        value={inputData.name}
        onChange={InputValueChange}
        autoComplete="off"
      />
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CustomLabel>User’s Email ID </CustomLabel>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              Enter the email address of the user you want to add to your
              organization. The user will receive an email with a link to join
              your organization.
            </Tooltip>
          }
        >
          <img src={toolIcon} alt="toolIcon" />
        </OverlayTrigger>
      </div>
      <CustomInputAddManual
        placeholder="User@Email.com "
        name="emailId"
        value={inputData.emailId}
        autoComplete="off"
        onChange={InputValueChange}
      />

      <ButtonMember className="mt-2" onClick={formSubmit}>
        Add
      </ButtonMember>

      <UsersList data={formData} />
    </AddmanualBox>
  );
};

export default AddManually2;
