import BarChart from "components/Graphs/BarChart";
import LineChart from "components/Graphs/LineChart";
import {
  BlackCircle,
  DownloadCard,
  DownloadGreenWrapper,
  DownloadWrapper,
  GraphCard,
  TextBlack,
} from "components/styled/common.styled";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import arrowUp from "assets/arrow-up.svg";
import arrowDown from "assets/arrow-down-red.svg";
import download from "assets/download.svg";
import users from "assets/users-white.svg";
import arrowUpWhite from "assets/arrow-up-white.svg";
import money from "assets/money.svg";
import { RatingBoxWrapper } from "../../components/Rating Box/rating.styled";
import Star from "../../assets/Star.svg";
import arrowRight from "assets/arrRight.svg";
import { SelectInput } from "components/Select/SelectInput";
import ToggleButtons from "components/ToggleButtons/ToggleButtons";
import { ProjectInfo } from "components/ProjectInfo/ProjectInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveUsersData,
  getAllVersionHistoryData,
  getAppDownloadsData,
  getAppEarningData,
  getApplicationInfo,
  getAppratingsData,
  getMiscellaneousData,
  getRCWithLiveApps,
  getSingleProjectDetails,
  getUsersByRegion,
} from "redux/Project/project.action";
import {
  AppType,
  analyticsData,
  lgDataGreen,
  lgDataRed,
  periods,
} from "./constants";
import { Spinner } from "react-bootstrap";
import CustomBreadcrumb from "components/CustomBreadScrum";
import { toast } from "react-toastify";
import { ProjectName, TextConatiner } from "components/styled/home.styled";
import Skeleton from "react-loading-skeleton";
import {
  RegionLabelAndNumData,
  fetColour,
  generateLabels,
  getNumDataHelper,
  optionsData,
} from "helper";
import InfoCardWithLoading from "./InfoCardWithLoading";

export const DetailedAnalytics = () => {
  const navigate = useNavigate();
  const [setSelectedOption] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("Weekly");
  const [isUserLabelDataLoaded, setIsUserLabelDataLoaded] = useState(false);
  const [isRegionDataLoaded, setIsRegionDataLoaded] = useState(false);
  const [downloadClick, setDownloadClick] = useState(false);
  const [earningClick, setEarningClick] = useState(false);
  const [selectAppType, setSelectAppType] = useState("");
  const [graphScreen, setGraphScreen] = useState("users");
  const [selectedVersion, setSelectedVersion] = useState(null);

  const activeUsersData = useSelector(
    (state) => state.projectReducer?.activeUsers
  );

  const appDownloadData = useSelector(
    (state) => state.projectReducer?.appDownloads
  );

  const appEarningData = useSelector(
    (state) => state.projectReducer?.appEarnings
  );

  const usersByRegionData = useSelector(
    (state) => state.projectReducer?.usersByRegion
  );

  const appRatingsData = useSelector(
    (state) => state.projectReducer?.ratingsData
  );

  const releaseChannels = useSelector(
    (state) => state.projectReducer?.releaseChannelsApps
  );

  const versionHistory = useSelector(
    (state) => state.projectReducer?.getAllversionHistory
  );
  const applicationInfo = useSelector((state) => state.projectReducer?.appInfo);
  // releaseChannelsApps is array of object. each object has rcName. rcName is the name of the release channel. get it stored in a variable and pass it to the api call.
  const rcName = releaseChannels?.map((item) => item.rcName);
  const allVersions = versionHistory?.map((item) => item.version);

  const {
    totalCountsDownloadApp,
    totalCountsEarningsApp,
    totalCountsUsersApp,
  } = useSelector((state) => state?.projectReducer);
  let { id, appID } = useParams();

  const projectSingle = useSelector(
    (state) => state.projectReducer.singleProjectDetails
  );

  const miscData = useSelector((state) => state.projectReducer.miscData);

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const skeletonFn = [1, 2, 3, 4].map((_, index) => (
    <Skeleton height={"20px"} width={index === 0 ? "100px" : "60px"} />
  ));

  const breadcrumbItems = [
    { text: "Analytics", link: "/analytics" },
    {
      text: projectSingle?.projectName,
      link: `/analytics/${id}/${appID}`,
    },
  ];

  const dispatch = useDispatch();

  // active users Data
  const userLabelData = generateLabels(
    selectedPeriod,
    activeUsersData,
    monthNames,
    dayNames
  );
  const userNumData = activeUsersData?.map((item) => item.count);

  // Earnings label data
  const earninglabelData = generateLabels(
    selectedPeriod,
    appEarningData,
    monthNames,
    dayNames
  );
  const earningNumData = appEarningData?.map((item) => item.earnings);

  // Download label data
  const downloadLabelsData = generateLabels(
    selectedPeriod,
    appDownloadData,
    monthNames,
    dayNames
  );
  const downloadNumData = appDownloadData?.map((item) => item.count);

  // Users by region label data
  const usersByRegionLabel = usersByRegionData?.map((item) => item.label);
  const usersByRegionNumData = usersByRegionData?.map((item) => item.count);

  const data = RegionLabelAndNumData(usersByRegionLabel, usersByRegionNumData);

  const options = optionsData();

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  const handleAppTypeChange = (AppType) => {
    setSelectAppType(AppType);
  };

  const [ratings] = useState([
    { stars: 5, count: appRatingsData?.ratingCounts?.["5"] },
    { stars: 4, count: appRatingsData?.ratingCounts?.["4"] },
    { stars: 3, count: appRatingsData?.ratingCounts?.["3"] },
    { stars: 2, count: appRatingsData?.ratingCounts?.["2"] },
    { stars: 1, count: appRatingsData?.ratingCounts?.["1"] },
  ]);

  const getTotalCount = () => {
    return ratings.reduce((total, rating) => total + rating.count, 0);
  };

  const getStarRatingClass = (stars) => {
    if (stars >= 5) {
      return "five-star";
    } else if (stars >= 4) {
      return "four-star";
    } else if (stars >= 3) {
      return "three-star";
    } else if (stars >= 2) {
      return "two-star";
    } else {
      return "one-star";
    }
  };

  const getChartData = () => {
    if (graphScreen === "downloads" && downloadClick) {
      return downloadNumData;
    } else if (graphScreen === "earnings" && earningClick) {
      return earningNumData;
    } else {
      return userNumData;
    }
  };

  const getLabelData = () => {
    if (graphScreen === "downloads" && downloadClick) {
      return downloadLabelsData;
    } else if (graphScreen === "earnings" && earningClick) {
      return earninglabelData;
    } else {
      return userLabelData;
    }
  };

  const getNumData = getNumDataHelper(
    graphScreen,
    downloadClick,
    downloadNumData,
    userNumData,
    earningClick,
    earningNumData
  );

  const getLgData = () => {
    return downloadClick ? lgDataGreen : earningClick ? lgDataRed : "";
  };

  useEffect(() => {
    setIsUserLabelDataLoaded(false);

    Promise.all([
      dispatch(getActiveUsersData(appID, selectedPeriod)),
      dispatch(getAppDownloadsData(appID, selectedPeriod)),
      dispatch(getAppEarningData(appID, selectedPeriod, "Fixed")),
    ])
      .then(() => {
        setIsUserLabelDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    document
      .getElementById(
        `toggle-${selectAppType || applicationInfo?.applicationType}`
      )
      ?.click();
  }, [selectedPeriod, appID, applicationInfo]);

  useEffect(() => {
    setIsRegionDataLoaded(false);
    Promise.all([
      dispatch(getSingleProjectDetails("", id)),
      dispatch(getUsersByRegion(appID)),
      dispatch(getMiscellaneousData(appID)),
      dispatch(getAppratingsData(appID)),
      // dispatch(getApp)
    ])
      .then(() => {
        setIsRegionDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appID, id]);

  const updateData = (data) => {
    return data;
  };
  const handleReleaseChannelChange = (selectedChannel) => {
    // Call the API to get version history based on the selected release channel
    dispatch(
      getAllVersionHistoryData(id, selectedChannel, selectAppType, appID)
    );
  };

  const handleVersionChange = (version) => {
    // Use the version to find the corresponding appId from versionHistory
    const versionData = versionHistory.find((item) => item.version === version);

    if (versionData) {
      const newAppID = versionData._id;

      // Use the existing appID variable directly if it's mutable
      appID = newAppID;

      // Additional logic to fetch data using the newAppId
      setIsUserLabelDataLoaded(false);

      Promise.all([
        dispatch(getActiveUsersData(newAppID, selectedPeriod)),
        dispatch(getAppDownloadsData(newAppID, selectedPeriod)),
        dispatch(getAppEarningData(newAppID, selectedPeriod, "Fixed")),
        dispatch(getUsersByRegion(newAppID)),
        dispatch(getMiscellaneousData(newAppID)),
        dispatch(getAppratingsData(newAppID)),
      ])
        .then(() => {
          setIsUserLabelDataLoaded(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  useEffect(() => {
    setIsUserLabelDataLoaded(false);

    dispatch(getRCWithLiveApps("", id, selectAppType)).then(() => {
      setIsUserLabelDataLoaded(true);
    });
    dispatch(getApplicationInfo(appID)).then(() => {
      dispatch(
        getAllVersionHistoryData(id, "production", selectAppType, appID)
      );
    });
  }, [selectAppType, id, appID]);
  return (
    <div className="px-4" style={{ overflowY: "scroll", height: "85vh" }}>
      <div className="d-flex w-100 border-bottom pb-1 w-100">
        <CustomBreadcrumb items={breadcrumbItems} />
        <div
          className="d-flex justify-content-between align-items-center w-75"
          style={{
            paddingLeft: "20px",
            marginLeft: "20px",
            borderLeft: "1px solid #E6E6E6",
          }}
        >
          <div
            className="d-flex"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div>
              <TextBlack className="fw-600" style={{ padding: "10px 0 0 0" }}>
                For &nbsp;
              </TextBlack>
            </div>
            <div
              style={{
                borderRadius: "100px",
                border: "2px solid #EFEFEF",
                background: "white",
                // padding: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ToggleButtons
                defaultValue={applicationInfo?.applicationType}
                options={AppType}
                selectedOption={selectAppType}
                onOptionChange={handleAppTypeChange}
              />
            </div>
          </div>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="d-flex gap-4">
            <select
              className="analyticsSelect"
              name="Release Channels"
              id="Release Channels"
              onChange={(e) => handleReleaseChannelChange(e.target.value)}
              defaultValue="production"
              style={{ textTransform: "capitalize" }}
            >
              <option value="">Release Channels</option>
              {/* map rcName here as options */}
              {rcName?.map(
                (item) =>
                  item !== "alpha" && (
                    <option
                      style={{ textTransform: "capitalize" }}
                      key={item.id}
                      value={item}
                    >
                      {item}
                    </option>
                  )
              )}
            </select>
            <select
              className="analyticsSelect"
              name="Versions"
              id="Versions"
              onChange={(e) => {
                const version = e.target.value;
                setSelectedVersion(version);
                // Additional logic to fetch data based on the selected version
                handleVersionChange(version);
              }}
            >
              <option value="">Versions</option>
              {allVersions?.map((item) => (
                <option key={item.id} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="mt-3 mb-2">
        <ProjectInfo />
      </div>

      <div className="row py-2" style={{ paddingRight: "10px" }}>
        <div className="row col-md-3 mx-0">
          {/* DownloadBox */}
          <InfoCardWithLoading
            isDataLoaded={isUserLabelDataLoaded}
            type={!downloadClick ? "downloads" : "users"}
            totalCount={totalCountsDownloadApp}
            totalDownloads={totalCountsDownloadApp}
            lgDataGreen={lgDataGreen}
            color={fetColour({
              type: "downloads",
              downloadClick,
              earningClick,
              lgDataGreen,
              lgDataRed,
            })}
            userLabelData={userLabelData}
            userNumData={userNumData}
            setEarningClick={setEarningClick}
            setGraphScreen={setGraphScreen}
            setDownloadClick={setDownloadClick}
            userDownloadData={downloadLabelsData}
            downNumData={downloadNumData}
            downloadNumData={downloadNumData}
            totalCountsEarnings={totalCountsEarningsApp}
            totalCountsUsers={totalCountsUsersApp}
            labelData={downloadLabelsData}
          />
          {/* Earning box */}
          <InfoCardWithLoading
            isDataLoaded={isUserLabelDataLoaded}
            type={!earningClick ? "earnings" : "users"}
            setGraphScreen={setGraphScreen}
            color={fetColour({
              type: "earnings",
              downloadClick,
              earningClick,
              lgDataGreen,
              lgDataRed,
            })}
            userLabelData={userLabelData}
            userNumData={userNumData}
            setEarningClick={setEarningClick}
            setDownloadClick={setDownloadClick}
            totalCountsEarnings={totalCountsEarningsApp}
            totalCount={totalCountsEarningsApp}
            earningLabelData={earninglabelData}
            lgDataRed={lgDataRed}
            earningNumData={earningNumData}
            totalCountsUsers={totalCountsUsersApp}
            labelData={earninglabelData}
          />
          {/* Earnings box */}
        </div>
        {/* Users box */}
        <div className="col-md-4">
          {!isUserLabelDataLoaded ? (
            <div
              style={{
                background: "#fff",
                borderRadius: "10px",
                height: "100%",
                padding: "13px",
                display: "flex",
                gap: "8px",
              }}
            >
              {skeletonFn}
            </div>
          ) : (
            <GraphCard>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <TextBlack className="fw-600">
                    {(() => {
                      if (graphScreen === "downloads" && downloadClick) {
                        return "Downloads";
                      } else if (graphScreen === "earnings" && earningClick) {
                        return "Earnings";
                      } else {
                        return "Active Users";
                      }
                    })()}
                  </TextBlack>
                </div>
                <div>
                  <ToggleButtons
                    options={periods}
                    selectedOption={selectedPeriod}
                    onOptionChange={handlePeriodChange}
                  />
                </div>
              </div>
              <div style={{ height: "90%" }}>
                {isUserLabelDataLoaded &&
                  userLabelData &&
                  userNumData &&
                  updateData(getChartData()) && (
                    <LineChart
                      isAxisData={true}
                      labelData={getLabelData()}
                      numData={getNumData}
                      lgData={getLgData()}
                      label={
                        graphScreen === "downloads" && downloadClick
                          ? "Downloads"
                          : graphScreen === "earnings" && earningClick
                          ? "Earnings"
                          : "Active Users"
                      }
                    />
                  )}
                {!isUserLabelDataLoaded && (
                  <div className="d-flex justify-content-center align-items-center w-100 h-100 mt-2">
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                )}
              </div>
            </GraphCard>
          )}
        </div>

        <div className="col-md-5 px-md-4 px-lg-3">
          {!isRegionDataLoaded ? (
            <div
              className="p-3"
              style={{
                background: "#fff",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <Skeleton height={"20px"} width={"100px"} />
            </div>
          ) : (
            <GraphCard width="100%">
              <div className="d-flex justify-content-between">
                <div>
                  <TextBlack className="fw-600">Users By Region </TextBlack>
                </div>
              </div>
              <div
                className="d-flex justify-content-center align-items-center w-100 h-100"
                style={{ height: "100%" }}
              >
                {isRegionDataLoaded && usersByRegionData && (
                  <BarChart data={data} options={options} />
                )}
                {!isRegionDataLoaded && (
                  <div>
                    <Spinner animation="border" size="sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                )}
              </div>
            </GraphCard>
          )}
        </div>
      </div>

      <div
        className="w-100 d-flex mt-2 gap-4"
        style={{ paddingRight: "1.5rem" }}
      >
        <div
          className="d-flex my-2 mx-0 py-2 rounded col-6"
          style={{ background: "#FFF", justifyContent: "space-evenly" }}
        >
          {analyticsData.map((item, index) => (
            <div
              style={{
                borderRight:
                  index !== analyticsData.length - 1
                    ? "1px solid #F2F2F2"
                    : "none",
                padding: "0.5em 1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              key={item.id}
            >
              <div>
                <TextBlack>{item.name}</TextBlack>
              </div>
              <div
                className="d-flex gap-2 my-2"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <BlackCircle>
                  <img height={"20"} src={item.icon} alt="" />
                </BlackCircle>
                <div className="spec border">
                  {Object.values(miscData || "")[index]}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="d-flex col-6 my-2 p-2 rounded"
          style={{ background: "#FFF" }}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center px-2"
            style={{ borderRight: "1px solid #F2F2F2 " }}
          >
            <TextBlack className="fs-16">Check Reviews</TextBlack>
            <div className="d-flex gap-1 align-items-center">
              <img height={"30px"} src={Star} alt="star" />
              <TextBlack className="fs-22">
                {appRatingsData?.averageRating
                  ? Number(appRatingsData?.averageRating).toFixed(1)
                  : "0"}
                /5
              </TextBlack>
            </div>
          </div>
          <div style={{ borderRight: "1px solid #F2F2F2" }}>
            <RatingBoxWrapper>
              {appRatingsData && isRegionDataLoaded ? (
                ratings.map((rating) => {
                  const totalCount = getTotalCount();
                  const percentage =
                    totalCount > 0 ? (rating.count / totalCount) * 100 : 0;
                  const filledPercentage = `${percentage}%`;

                  return (
                    <div
                      key={rating.stars}
                      className="px-1"
                      data-testid="rating"
                    >
                      <div className="d-flex gap-2">
                        <div className="d-flex gap-1">
                          <img src={Star} alt="img" />
                          <span>{rating.stars}</span>
                        </div>

                        <div className="rating-bar">
                          <div
                            className={`filled-bar ${getStarRatingClass(
                              rating.stars
                            )}`}
                            style={{ width: filledPercentage }}
                          ></div>
                        </div>
                      </div>
                      <div className="mx-2">
                        <span>{rating.count}</span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center align-items-center w-100 h-100 mt-2">
                  <Spinner animation="border" size="sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
            </RatingBoxWrapper>
          </div>
          <div
            className="d-flex flex-column justify-content-center px-2"
            style={{ justifyContent: "center", alignItems: "center" }}
            onClick={() =>
              appRatingsData?.averageRating !== 0
                ? navigate(`/ratings/${appID}`)
                : toast.info("No ratings found")
            }
          >
            <BlackCircle>
              <img src={arrowRight} alt="img" />
            </BlackCircle>
            <TextBlack className="fs-16 mt-2">View Details</TextBlack>
          </div>
        </div>
      </div>
    </div>
  );
};
