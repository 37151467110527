import { RatingWrapper } from "./rating.styled";
import {
  TextMiddle,
  TextBlack,
  WhiteCircle,
} from "components/styled/common.styled";
import dot from "assets/dot.svg";
import star from "assets/Star.svg";
import like from "assets/like.svg";
import dislike from "assets/dislike.svg";
import heart from "assets/heart.svg";
import heartLiked from "assets/heart-red.svg";
import flag from "assets/flag-2.svg";
import flagged from "assets/flag-enabled.svg";
import RatingBox from "components/Rating Box/Rating";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createComment,
  getAppReviewsData,
  getAppratingsData,
} from "redux/Project/project.action";
import profileIcon from "assets/profile-circle2.svg";
import { getDateByUpdate } from "helper";
import { publisher } from "redux/api";
import { CustomAlertPopup } from "components/CustomAlert";
import { buttonConfigs, variants } from "components/CustomAlert/constant";

export const ProjectRating = () => {
  const [profileData, setProfileData] = useState({});
  const [isReply, setIsReply] = useState(false);
  const [reply, setReply] = useState("");
  const [replyStates, setReplyStates] = useState({});
  const [likedComments, setLikedComments] = useState({});
  const [variant, setVariant] = useState(0);
  const selectedVariant = variants[variant];
  const [modal, setModal] = useState({
    modal: false,
    text: "",
  });

  const dispatch = useDispatch();

  const { 1: appID } = window.location.pathname.split("/").filter(Boolean);

  const appReviewsData = useSelector(
    (state) => state.projectReducer.appReviewsData
  );

  const handleReplySubmit = async (replyText, commentId) => {
    try {
      dispatch(
        createComment(commentId, {
          action: "reply",
          replyText: replyText,
        })
      ).then(() => {
        dispatch(getAppReviewsData(appID));
      });
      setIsReply(false);
    } catch (error) {
      console.error("An error occurred while sending the reply:", error);
    }
  };

  const handleReplyInputChange = (e, commentId) => {
    setReply(e.target.value);
  };

  const handleReplyInputKeyDown = (e, commentId) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Prevent the default behavior of Enter (e.g., line break in textarea)
      e.preventDefault();
      // Call the function to send the reply to the API
      handleReplySubmit(reply, commentId);

      // Clear the reply input field
      setReplyStates((prevReplyStates) => ({
        ...prevReplyStates,
        [commentId]: !prevReplyStates[commentId],
      }));
      setReply("");
      setIsReply(false);

      // You can add additional logic here, such as updating the UI to show the new reply immediately
    }
  };

  const handleEditReplyClick = (commentId, publisherReply) => {
    // Hide the publisher's reply when editing a comment
    if (appReviewsData) {
      const updatedReviewsData = appReviewsData.map((item) => {
        if (item._id === commentId) {
          return {
            ...item,
            publisherReply: "", // Reset the publisher's reply
          };
        }
        return item;
      });

      // Update the appReviewsData state with the modified data
      dispatch({ type: "SET_APP_REVIEWS_DATA", payload: updatedReviewsData });
    }
    setReply(publisherReply || "");

    // Toggle the reply input box for the clicked comment
    setReplyStates((prevReplyStates) => ({
      ...prevReplyStates,
      [commentId]: !prevReplyStates[commentId],
    }));
  };

  const handleLike = async (commentId) => {
    try {
      // Dispatch the action to update the like status on the server
      await dispatch(createComment(commentId, { action: "like" }));

      // Update the likedComments state based on the previous state
      setLikedComments((prevLikedComments) => ({
        ...prevLikedComments,
        [commentId]: !prevLikedComments[commentId],
      }));
    } catch (error) {
      console.error("An error occurred while updating like status:", error);
    }
  };

  const handleFlag = (commentId) => {
    setModal({
      modal: true,
      text: "Are you sure you want to flag this comment? This action cannot be undone.",
      commentId: commentId,
    });
  };

  const handleFlagSubmit = async (commentId) => {
    try {
      dispatch(
        createComment(commentId, {
          action: "flag",
        })
      ).then(() => {
        dispatch(getAppReviewsData(appID));
      });
      setModal({
        modal: false,
        text: "",
      });
    } catch (error) {
      console.error("An error occurred while sending the reply:", error);
    }
  };

  useEffect(() => {
    Promise.all([
      dispatch(getAppReviewsData(appID)),
      dispatch(getAppratingsData(appID)),
    ])
      .then(() => {
        console.log("Data fetched successfully");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setReply(appReviewsData?.publisherReply || "");
  }, [appID]);

  useEffect(() => {
    // Initialize likedComments based on initial data
    const initialLikedComments = {};
    appReviewsData.forEach((item) => {
      initialLikedComments[item._id] = item.isPublisherLiked;
    });
    setLikedComments(initialLikedComments);
  }, [appReviewsData]);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await publisher.getUserDetails();
      setProfileData(data);
    };
    fetchUser();
  }, []);


  return (
    <div className="d-flex w-100">
      {modal && (
        <div style={{ marginTop: "-0.5rem", marginLeft: "0rem", top: "20px" }}>
          <CustomAlertPopup
            modal={modal}
            setModal={setModal}
            selectedVariant={selectedVariant}
            text={modal.text}
            buttonType={
              selectedVariant.title === "Warning" ? buttonConfigs.YesNo : null
            }
            triggerEvent={() => handleFlagSubmit(modal.commentId)}
            titleText="Flag Comment Warning"
          />
        </div>
      )}
      <div className="col-8" style={{ maxHeight: "84vh", overflowY: "scroll" }}>
        <RatingWrapper>
          <TextBlack className="fs-22">Check Reviews</TextBlack>

          <hr />
          {appReviewsData?.map((item, index) => (
            <div
              key={item._id}
              style={{ marginTop: index !== 0 ? "2rem" : "0" }}>
              <div
                className="d-flex align-content-center gap-2 my-2"
                style={{ alignItems: "center" }}>
                <WhiteCircle>
                  <img
                    src={item.profilePic ? item.profilePic : profileIcon}
                    height={"100%"}
                    width={"100%"}
                    alt="img"
                  />
                </WhiteCircle>
                <div>
                  <TextMiddle className="fs-16">{item.userName}</TextMiddle>
                </div>
                <div>
                  <img src={dot} alt="" />
                </div>
                <div>
                  <TextMiddle className="fs-14">
                    {getDateByUpdate(item.createdAt)}
                  </TextMiddle>
                </div>
              </div>
              {/* rating */}
              <div
                className="d-flex  gap-1 my-2"
                style={{ alignItems: "center", width: "auto" }}>
                <img src={star} alt="img" />
                <TextBlack className="fs-16 m-0">{item.rating}/5</TextBlack>
              </div>

              {/* comment */}
              <div className="my-2">
                <TextBlack>{item.comment}</TextBlack>
              </div>

              {/* like, reply,flag */}
              <div className="d-flex justify-content-between my-3">
                <div className="d-flex gap-3">
                  {/* like */}
                  <div className="d-flex gap-1">
                    <img height={"20px"} src={like} alt="like" />
                    <TextBlack>{item.commentLiekcount}</TextBlack>
                  </div>

                  {/* dislike */}
                  <div className="d-flex gap-1">
                    <img height={"20px"} src={dislike} alt="like" />
                    <TextBlack>{item.commentDislikecount}</TextBlack>
                  </div>
                </div>
                <div className="d-flex gap-3">
                  {/* like button */}
                  <div>
                    <img
                      onClick={() => handleLike(item._id)}
                      height={"20px"}
                      src={likedComments[item._id] ? heartLiked : heart}
                      alt="like"
                    />
                  </div>
                  {/* flag button */}
                  <div
                    style={{
                      cursor: item.ifPublisherflagged && "not-allowed",
                    }}>
                    <img
                      onClick={() => {
                        !item.ifPublisherflagged && handleFlag(item._id);
                      }}
                      height={"20px"}
                      src={item.ifPublisherflagged ? flagged : flag}
                      alt="flag"
                    />
                  </div>
                  <div
                    onClick={() => {
                      handleEditReplyClick(item._id, item.publisherReply);
                    }}>
                    <TextMiddle style={{ cursor: "pointer" }}>
                      {item.publisherReply ? "Edit Reply" : "Reply"}
                    </TextMiddle>
                  </div>
                </div>
              </div>
              {/* publisher reply */}

              {item.publisherReply && !replyStates[item._id] && (
                <div
                  style={{
                    borderLeft: "2px solid #A6A6A6",
                    paddingLeft: "2rem",
                    marginTop: "1rem",
                  }}>
                  <div
                    className="d-flex align-content-center gap-2 my-2"
                    style={{ alignItems: "center" }}>
                    <WhiteCircle>
                      <img
                        src={profileData?.profilePic || profileIcon}
                        height={"100%"}
                        width={"100%"}
                        alt="img"
                      />
                    </WhiteCircle>
                    <div>
                      <TextMiddle className="fs-16">
                        {`${item?.publisherName} (Publisher)`}
                      </TextMiddle>
                    </div>
                    <div>
                      <img src={dot} alt="" />
                    </div>
                    <div>
                      <TextMiddle className="fs-14">
                        {getDateByUpdate(item.replyDate)}
                      </TextMiddle>
                    </div>
                  </div>
                  {/* publisher comment */}
                  {/* comment */}
                  <div className="mt-2">
                    <TextBlack>{item.publisherReply}</TextBlack>
                  </div>
                </div>
              )}

              {/* Reply input box */}
              {isReply && item._id && (
                <div className="w-100 border px-4 py-2 rounded-4">
                  <textarea
                    className="w-100 outline-none no-border"
                    type="text"
                    placeholder="Enter Your Reply ..."
                    autoFocus
                    key={item._id}
                    defaultValue={reply}
                    onChange={(e) => handleReplyInputChange(e, item._id)}
                    onKeyDown={(e) => handleReplyInputKeyDown(e, item._id)}
                    style={{
                      resize: "none",
                      height: "100px",
                    }}
                  />
                </div>
              )}

              {replyStates[item._id] && (
                <div className="w-100 border px-4 py-2 rounded-4">
                  <textarea
                    className="w-100 outline-none no-border"
                    type="text"
                    placeholder="Enter Your Reply ..."
                    autoFocus
                    key={item._id}
                    value={reply}
                    defaultValue={reply}
                    onChange={(e) => handleReplyInputChange(e, item._id)} // Pass the comment ID if needed
                    onKeyDown={(e) => handleReplyInputKeyDown(e, item._id)}
                    style={{
                      resize: "none",
                      height: "100px",
                    }}
                  />
                </div>
              )}
            </div>
          ))}
        </RatingWrapper>
      </div>
      <div className="my-2">
        <RatingBox />
      </div>
    </div>
  );
};
