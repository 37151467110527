import {
  GET_ALL_TUTORIAL,
  GET_RESOURSE_BY_ID,
  UPDATE_PROJECT,
  GET_TUTORIAL,
  GET_RELATED_ARTICLES,
  GET_ALL_VIDEOS,
  GET_ALL_FAQs,
  GET_ALL_DOWNLOADS,
  GET_PROJECT_BY_ID,
  GET_ALL_TOOL,
  GET_ALL_SEARCH,
  SUCCESS,
  ERROR,
  LOADING,
  GET_ALL_CHILD_REFERENCES,
  GET_CHILD_DETAILS,
  CREATE_TICKET,
  CHECKBOX,
  REMOVE_CHECKBOX,
  DELETE_PROJECT_BY_ID,
  GET_ALL_INTERACTIONS,
  REMOVE_ALL_CHECKBOX,
  CLEAR_ALL_PLATFORM_VERSION,
  CLEAR_CHECKBOX,
  CREATE_FAQ_INTERACTION,
  GET_USER_PROFILE,
  GET_TERMS_OF_USE,
  GET_ALL_MEMBERS,
} from "./action.type";

const initialState = {
  sdks: null,
  platform_version: null,
  error: null,
  projects: [],
  tutorials: [],
  tutorial: [],
  singleProject: null,
  related: [],
  singleResource: null,
  isLoading: false,
  videos: [],
  faqs: [],
  apiReference: [],
  childDetails: [],
  downloadsData: [],
  projectDetails: null,
  project: null,
  tools: null,
  searchData: [],
  success: null,
  checkbox: [],
  allMembers: [],
  interactions: [],
  checked: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROJECT:
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project._id === action.payload._id) {
            return action.payload;
          }
          return project;
        }),
      };
    case GET_ALL_TUTORIAL:
      return { ...state, tutorials: action.payload };
    case GET_TUTORIAL:
      return { ...state, tutorial: action.payload };
    case GET_RELATED_ARTICLES:
      return { ...state, related: action.payload };
    case GET_ALL_VIDEOS:
      return { ...state, videos: action.payload };
    case GET_RESOURSE_BY_ID:
      return { ...state, singleResource: action.payload };
    case GET_ALL_FAQs:
      return { ...state, faqs: action.payload };
    case GET_ALL_DOWNLOADS:
      return { ...state, downloadsData: action.payload };
    case GET_PROJECT_BY_ID:
      return { ...state, project: action.payload };
    case GET_ALL_TOOL:
      return { ...state, tools: action.payload };
    case GET_ALL_SEARCH:
      return { ...state, searchData: action.payload };
    case ERROR:
      return {
        ...state,
        error: action.payload,
        success: null,
        isLoading: false,
      };
    case SUCCESS:
      return {
        ...state,
        success: action.payload,
        error: null,
        isLoading: false,
      };
    case LOADING:
      return { ...state, isLoading: true };
    case GET_ALL_CHILD_REFERENCES:
      return { ...state, apiReference: action.payload };
    case GET_CHILD_DETAILS:
      return { ...state, childDetails: action.payload };
    case CREATE_TICKET:
      return {
        ...state,
        error: null,
        success: "Ticket created successfully",
      };
    case CHECKBOX:
      return {
        ...state,
        checkbox: [...state.checkbox, action.payload],
      };
    case REMOVE_CHECKBOX:
      return {
        ...state,
        checkbox: state.checkbox.filter((item) => item !== action.payload),
      };
    case DELETE_PROJECT_BY_ID:
      return {
        ...state,
        projects: state.projects.filter((item) => item._id !== action.payload),
      };
    case GET_ALL_INTERACTIONS:
      return {
        ...state,
        interactions: action.payload,
      };
    case REMOVE_ALL_CHECKBOX:
      return {
        ...state,
        checkbox: [],
      };
    case CLEAR_ALL_PLATFORM_VERSION:
      return {
        ...state,
        platform_version: action.payload,
        sdks: action.payload,
      };
    case CREATE_FAQ_INTERACTION:
      return {
        ...state,
        interactions: [
          ...state.interactions.filter(
            (item) => item.faq_id !== action.payload.faq_id
          ),
          action.payload,
        ],
      };
    case CLEAR_CHECKBOX:
      return {
        ...state,
        checkbox: action.payload,
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        profile: action.payload.data,
      };
    case GET_ALL_MEMBERS:
      return {
        ...state,
        allMembers: action.payload,
      };
    case GET_TERMS_OF_USE:
      return {
        ...state,
        checked: action.payload,
      };
    
    default:
      return state;
  }
};

export default appReducer;
