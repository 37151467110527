import { styled } from "styled-components";

export const BuyStorageCont = styled.div`
  margin-left: 2rem;
`;
export const StorageFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const UserDataCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.onclickrow {
    cursor: pointer;
  }
  &.onclickrow:hover {
    background: #f6f6f6;
    
  }
`;
export const UpgradeCont = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  padding: 1rem;
`;
export const RightCont = styled.div`
  background: #fff;
`;
export const CustomBtn = styled.button`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: var(--primary-color-primary-5, #6c56fb);
  border: none;
  outline: none;
  color: #fff;
  width: 40%;
  cursor: pointer;
  &.paymnetBtn {
    width: 13%;
    margin-top: 1rem;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
  &.addUser {
    background: none;
    border-radius: 500px;
    border: 1px solid var(--primary-color-primary-5, #6c56fb);
    background: var(--dark-mode-text-11, #fff);
    color: var(--primary-color-primary-5, #6c56fb);
    width: 20%;
    padding: 5px 12px;
    margin-top: 0.5rem;
  }
  &.invites {
    width: 17%;
    padding: 12px 24px;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 500px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
  &.cancel {
    width: 17%;
    padding: 12px 24px;
    border: none;
    outline: none;
    color: #000;
    border-radius: 500px;
    background: none;
    border-radius: 500px;
    border: 1px solid #898d93;
    background: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
  &.EditDele {
    width: 10%;
    padding: 7px 14px;
    height: 37px;
    border-radius: 500px;
    background: var(--dark-mode-text-11, #fff);
    color: #000;
  }
  &.buy {
    margin-top: 1.2rem;
    width: fit-content;
    padding: 8px 24px;
  }
`;

export const SeenText = styled.div`
  color: #989ba2;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
`;
export const GbUsed = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #161719;
  font-weight: 500;
`;
export const StorageMessageTxt = styled.div`
  background: #f6e8cd;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  border: 1px solid #d48b05;
`;

export const ScrobalbeDiv = styled.div`
  overflow-y: scroll;
  height: 400px;
  padding: 0.5rem;
`;

export const Upgrade = styled.div`
  width: 31%;
`;
