import React from "react";
import { SidePanelPrimaryBox } from "../styled/common.styled";

export const SidePanel = ({ children }) => {
  return (
    <div className="sidebar">
      <SidePanelPrimaryBox>{children}</SidePanelPrimaryBox>
    </div>
  );
};
