import { OptionBox, SelectBox } from "components/styled/common.styled";
import React, { useEffect, useRef } from "react";
import down from "assets/down.svg";

export const Select = ({
  pad,
  children,
  isOpen,
  setIsOpen,
  title,
  width,
  data,
  border,
}) => {
  const selectRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isOpen &&
        selectRef.current &&
        !selectRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, setIsOpen]);

  return (
    <div className="position-relative" ref={selectRef}>
      <SelectBox
        onClick={() => setIsOpen(!isOpen)}
        width={width}
        border={border}
        pad={pad}>
        <div className="d-flex justify-content-between align-items-center">
          {title}
          <img src={down} alt="img" />
        </div>
      </SelectBox>
      {isOpen && (
        <div>
          {data?.map((item) => (
            <OptionBox key={item?.id} show={isOpen}>
              {item?.role}
            </OptionBox>
          ))}
        </div>
      )}
    </div>
  );
};
