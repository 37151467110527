import React from "react";

export const ModelViewer = ({ url, name }) => {
  return (
    <model-viewer
      width="100%"
      style={{ width: "100%", height: "100%" }}
      alt={name}
      src={url}
      ar
      shadow-intensity="1"
      camera-controls
      touch-action="pan-y"
    ></model-viewer>
  );
};
