import { CardBlackWhite } from "components/card/Card";
import { TableContainer } from "./table.styled";
import { ButtonPrimary, TextBlack } from "components/styled/common.styled";
import { ChannelUsersData, InviteStatus } from "contants/constants";
import { Select } from "components/Select/Select";
import refresh from "assets/refresh.svg";
import { useState } from "react";
import { CustomModal } from "components/Modal/CustomModal";
import UploadFiles from "ui/InviteUser/UploadFiles";
import { CustomBtn } from "components/styled/Buystorage.style";
import AddManually2 from "ui/InviteUser/AddManually2";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import {
  addUserApi,
  deleteInvitedUser,
  getInvitedUsers,
} from "redux/Project/project.action";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

export const ChannelUsers = ({ projectID, rcApplicationType, appId }) => {
  // const [IsInvite, setInvite] = useState(false);
  const [searchParam, setSearchParams] = useSearchParams();
  const releaseChannel = searchParam.get("rcName");

  console.log(releaseChannel, "releaseChannel---->");
  const [isOpen, setIsOpen] = useState({
    modal: false,
    project: false,
    id: "",
  });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [file, setFile] = useState(null);

  const dispatch = useDispatch();
  const inviteData = useSelector((state) => state.projectReducer.users);
  const getInvitedUsersData = useSelector(
    (state) => state.projectReducer.getUsers
  );

  useEffect(() => {
    dispatch(getInvitedUsers(releaseChannel, projectID, rcApplicationType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectID, rcApplicationType, dispatch]);

  const handleUserSelection = (e, userId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, userId]);
    } else {
      setSelectedUsers((prevSelectedUsers) =>
        prevSelectedUsers.filter((id) => id !== userId)
      );
    }
  };

  const handleFileUpload = () => {
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          checkColumns(result.data);
        },
        header: true,
      });
    }
  };

  const checkColumns = (data) => {
    if (data.length > 0) {
      const columns = Object.keys(data[0]);
      if (columns.includes("name") && columns.includes("emailId")) {
        return;
        // Process the data as needed
      } else {
        return toast.error("CSV file does not have the required columns.");
      }
    }
  };

  const checkColumnsValidity = () => {
    // Perform the check for required columns
    if (file && file.length > 0) {
      const data = Papa.parse(file, { header: true }).data;

      if (data.length > 0) {
        const columns = Object.keys(data[0]);
        return columns.includes("name") && columns.includes("emailId");
      }
    }

    return false;
  };

  const handleDelete = async () => {
    try {
      if (getInvitedUsersData.length > 0) {
        if (selectedUsers.length === 0) {
          toast.info("Please select at least one");
          return;
        }

        await dispatch(
          deleteInvitedUser(
            releaseChannel,
            projectID,
            rcApplicationType,
            selectedUsers
          )
        );
        toast.success("user/users removed Successfully");
        await dispatch(
          getInvitedUsers(releaseChannel, projectID, rcApplicationType)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvite = async () => {
    setIsOpen({ ...isOpen, modal: false });
    try {
      const formData = new FormData();
      formData.append("file", file);
      handleFileUpload();

      // Check if the CSV file has the required columns
      const isValidCSV = checkColumnsValidity();

      await dispatch(
        addUserApi(
          releaseChannel,
          projectID,
          appId,
          rcApplicationType,
          inviteData,
          file ? formData : null
        )
      );
      isValidCSV && toast.success("Invited Successfully");
      await dispatch(
        getInvitedUsers(releaseChannel, projectID, rcApplicationType)
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectAllChecked) {
      const allUserIds = getInvitedUsersData.map((item) => item._id);
      setSelectedUsers(allUserIds);
    } else {
      setSelectedUsers([]);
    }
  }, [selectAllChecked, getInvitedUsersData]);

  if (releaseChannel === "production" || releaseChannel === "beta") {
    return null;
  }
  return (
    <div className="my-4 col-12">
      <CardBlackWhite
        upperContent={[
          `${releaseChannel} Release Channel Users`,
          <ButtonPrimary
            key="inviteButton"
            width="150px"
            bg="#f2f2f2"
            color="#3c3e43"
            hover="light"
            height="40px"
            onClick={() => setIsOpen({ ...isOpen, modal: true })}
          >
            + &nbsp; Invite Users
          </ButtonPrimary>,
        ]}
        lowerContent={
          <div>
            <TableContainer>
              <table>
                <thead className="border-bottom mt-0">
                  <tr>
                    <th style={{ textAlign: "left" }}>Name</th>

                    <th style={{ textAlign: "center" }}>Email ID</th>

                    <th style={{ textAlign: "end" }}>
                      <span
                        className="mx-2 text-decoration-underline"
                        style={{
                          cursor:
                            getInvitedUsersData.length > 0
                              ? "pointer"
                              : "default",
                          color:
                            getInvitedUsersData.length === 0
                              ? "#aea797"
                              : "inherit", // You can also change the color when disabled
                        }}
                        onClick={
                          getInvitedUsersData.length > 0 ? handleDelete : null
                        }
                      >
                        Delete
                      </span>{" "}
                      &nbsp;
                      {getInvitedUsersData.length > 0 && (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="selectAll"
                          checked={selectAllChecked}
                          onChange={() =>
                            setSelectAllChecked(!selectAllChecked)
                          }
                        />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getInvitedUsersData.map((item) => (
                    <tr className="channel-user" key={item.id}>
                      <td style={{ textAlign: "left" }}>
                        <TextBlack>{item.name}</TextBlack>{" "}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <TextBlack className="text-lowercase">
                          {item.userEmail}
                        </TextBlack>
                      </td>
                      <td
                        style={{
                          textAlign: "end",
                        }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={item?._id}
                          checked={
                            selectAllChecked ||
                            selectedUsers.includes(item?._id)
                          }
                          onChange={(e) => handleUserSelection(e, item?._id)}
                        />
                      </td>
                    </tr>
                  ))}
                  {getInvitedUsersData.length === 0 && (
                    <tr style={{ cursor: "default" }}>
                      <td
                        colSpan="3"
                        style={{
                          textAlign: "center",
                          paddingTop: "2rem",
                          paddingBottom: "2rem",
                        }}
                      >
                        <p style={{ color: "#A6AAB1", cursor: "default" }}>
                          No users are added to this channel
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TableContainer>
          </div>
        }
      />
      <CustomModal
        isOpen={isOpen?.modal}
        setIsOpen={setIsOpen}
        key={"abc"}
        size="lg"
        title={"Invite Users"}
      >
        <div className="row">
          {/* Upload files */}
          <div className="col-6">
            <UploadFiles setFile={setFile} />
          </div>
          {/* add manual */}
          <div className="col-6">
            <AddManually2 />
          </div>
        </div>
        <div className="d-flex justify-content-center my-4 gap-4">
          <CustomBtn
            className="cancel"
            onClick={() => setIsOpen({ ...isOpen, modal: false })}
          >
            Cancel
          </CustomBtn>
          <CustomBtn className="invites" onClick={handleInvite}>
            Invites
          </CustomBtn>
        </div>
      </CustomModal>
    </div>
  );
};
