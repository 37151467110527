import React from "react";
import { RatingBoxWrapper } from "./rating.styled";
import Star from "../../assets/Star.svg";
import { TextBlack } from "components/styled/common.styled";
import { useSelector } from "react-redux";

const RatingBox = ({ appRating }) => {
  const appRatingsData = useSelector(
    (state) => state.projectReducer.ratingsData
  );

  const ratings = [
    { stars: 5, count: appRatingsData?.ratingCounts?.["5"] },
    { stars: 4, count: appRatingsData?.ratingCounts?.["4"] },
    { stars: 3, count: appRatingsData?.ratingCounts?.["3"] },
    { stars: 2, count: appRatingsData?.ratingCounts?.["2"] },
    { stars: 1, count: appRatingsData?.ratingCounts?.["1"] },
  ];

  const getTotalCount = () => {
    return ratings.reduce((total, rating) => total + rating.count, 0);
  };

  const getStarClassName = (stars) => {
    switch (true) {
      case stars >= 5:
        return "five-star";
      case stars >= 4:
        return "four-star";
      case stars >= 3:
        return "three-star";
      case stars >= 2:
        return "two-star";
      default:
        return "one-star";
    }
  };

  return (
    <RatingBoxWrapper>
      <div className="d-flex justify-content-between align-items-center">
        <h4>User Ratings</h4>
        <div className="d-flex gap-1 align-items-center">
          <img height={"30px"} src={Star} alt="star" />
          <TextBlack className="fs-22 m-0">
            {Number(
              appRatingsData?.averageRating > 0
                ? appRatingsData?.averageRating
                : 0
            ).toFixed(appRatingsData?.averageRating > 0 && 1)}
            /5
          </TextBlack>
        </div>
      </div>
      <hr />

      {ratings.map((rating) => {
        const totalCount = getTotalCount();
        const percentage =
          totalCount > 0 ? (rating.count / totalCount) * 100 : 0;
        const filledPercentage = `${percentage}%`;
        const starClassName = getStarClassName(rating.stars);

        return (
          <div key={rating.stars} className="p-1">
            <div className="d-flex gap-2">
              <div className="d-flex gap-1">
                <img src={Star} alt="img" />
                <span>{rating.stars}</span>
              </div>
              <div className="rating-bar">
                <div
                  className={`filled-bar ${starClassName}`}
                  style={{ width: filledPercentage }}
                ></div>
              </div>
            </div>
            <div className="mx-2">
              <span>{rating.count}</span>
            </div>
          </div>
        );
      })}
    </RatingBoxWrapper>
  );
};

export default RatingBox;
