import axios from "axios";
import { getUserToken } from "helper";
import { loginconfig } from "ui/auth/Config";

export const PUBLISHER_URL = "https://publisher-backend.ajnavidya.com/api/v1";
// export const PUBLISHER_URL = "http://localhost:8082/api/v1";
// const PUBLISHER_URL = "http://192.168.174.248:8082/api/v1";
// export const PUBLISHER_URL = "https://publisherhub-dev-backend.ajnavidya.com/api/v1";

const UPLOAD_URL = "https://file-upload.ajnavidya.com/";

const STORAGE_URL = "https://file-uploadv2.ajnavidya.com/";

const token = localStorage.getItem("token");

const publisherApi = axios.create({
  baseURL: PUBLISHER_URL,
});

const storageApi = axios.create({ baseURL: STORAGE_URL });

const uploadApi = axios.create({
  baseURL: UPLOAD_URL,
});

publisherApi.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["authorization"] = `Bearer ${token}`;
  }
  return config;
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

publisherApi.interceptors.response.use(
  async (response) => response.data,
  async (error) => {
    console.log(error, "error");
    const originalRequest = error.config;

    if (
      [
        "jwt expired",
        "jwt malformed",
        "invalid token",
        "invalid signature",
        "jwt must be provided",
        "Unauthorized",
        "You are not authorized",
      ].some(
        (err) =>
          error?.response?.data?.error === err ||
          error?.response?.data?.message === err
      )
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;
      const refreshToken = localStorage.getItem("refreshToken");
      const clientId = loginconfig?.clientId;
      // const url = process.env.REACT_APP_NODE_ENV === "development" ? `https://dev.ajnavidya.com/api/v1/user/refresh-token` : `https://middleware.ajnavidya.com/api/v1/user/refresh-token`;
      const url = `https://sso.api.ajnavidya.com/api/v2/user/refresh-token`;
      return axios
        .post(url, {
          refresh_token: refreshToken,
          client_id: clientId,
        })
        .then((res) => {
          if (res.data.status !== 200) {
            localStorage.clear();
            // EraseAllCookies();
            const { url, clientId, platform, redirect } = loginconfig;
            window.location.replace(
              `${url}?clientId=${clientId}&platform=${platform}&redirect=${redirect}`
            );
            return Promise.reject();
          }

          localStorage.setItem("token", res.data.data.access_token);
          localStorage.setItem("refreshToken", res.data.data.refresh_token);
          publisherApi.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.data.access_token;
          processQueue(null, res.data.data.access_token);
        })
        .catch((err) => {
          processQueue(err, null);
          return Promise.reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    }

    return Promise.reject(error);
  }
);

export const publisher = {
  isUserExist: () => {
    return publisherApi.get(`/publisher-dev/user/isUserExist`);
  },

  userOnBoarding: (data) => {
    return publisherApi.post(`/publisher-dev/user/onBoarding`, data);
  },

  createProject: (data, projectId = "") => {
    return publisherApi.post(
      `/publisher-dev/project/submitAppVersion?projectID=${projectId}`,
      data
    );
  },
  editProject: (data, appId) => {
    return publisherApi.patch(
      `/publisher-dev/project/updateApplication?applicationID=${appId}`,
      data
    );
  },
  deleteProject: (projectId) => {
    return publisherApi.delete(
      `/publisher-dev/project/deleteProject?projectID=${projectId}`
    );
  },
  createPricePlan: (data, projectID, appID) => {
    return publisherApi.post(
      `/publisher-dev/project/setPriceModel?projectID=${projectID}&applicationID=${appID}`,
      data
    );
  },
  getAllProjects: (publisherId, projectID = "", projectName = "") => {
    return publisherApi.get(
      `/publisher-dev/project/getProjects?publisherID=${publisherId}&projectName=${projectName}`
    );
  },
  getProjectById: (publisherId, projectId, projectName) => {
    return publisherApi.get(
      `/publisher-dev/project/getProjects?publisherID=${publisherId}&projectID=${projectId}&projectName=${
        projectName || ""
      }`
    );
  },
  getProject: (publisherID = "", projectID = "", projectName = "") => {
    return publisherApi.get(
      `publisher-dev/project/getProjects?publisherID=${publisherID}&projectID=${projectID}&projectName=${projectName}`
    );
  },
  getLiveProject: (publisherID = "", projectID = "", projectName = "") => {
    return publisherApi.get(
      `publisher-dev/project/getLiveProjects?publisherID=${publisherID}&projectID=${projectID}&projectName=${projectName}`
    );
  },
  getSubscription: (publisherID = "", appID, priceModelType) => {
    return publisherApi.get(
      `publisher-dev/project/getYourPriceModels?publisherID=${publisherID}&applicationId=${appID}&priceModelType=${priceModelType}`
    );
  },
  getRCWithLiveApps: (publisherID, projectId, applicationType) => {
    try {
      return publisherApi.get(
        `publisher-dev/project/getRCWithLiveApps?publisherID=${publisherID}&rcProjectID=${projectId}&rcApplicationType=${applicationType}`
      );
    } catch (error) {}
  },
  getAppsDetails: (publisherID, projectId, applicationId, rcName, infoType) => {
    try {
      return publisherApi.get(
        `publisher-dev/project/getApps?publisherID=${publisherID}&projectID=${projectId}&applicationID=${applicationId}&rcName=${rcName}&infoType=${infoType}`
      );
    } catch (error) {
      return error;
    }
  },
  getReleaseChannels: (
    publisherID = "",
    projectID = "",
    applicationType = ""
  ) => {
    return publisherApi.get(
      `publisher-dev/project/releaseChannel/get?publisherID=${publisherID}&projectID=${projectID}&applicationType=${applicationType}`
    );
  },
  getAppReviewStatus: (appID) => {
    return publisherApi.get(
      `publisher-dev/project/getReviewStatus?appID=${appID}`
    );
  },

  createReleaseChannel: ({ publisherID, projectId, ...payload }) => {
    return publisherApi.post(
      `/publisher-dev/project/releaseChannel/create?rcProjectID=${projectId}&publisherID=${publisherID}`,
      payload
    );
  },

  getAllSectors: () => {
    return publisherApi.get(`/publisher-dev/project/getSectors`);
  },

  getSectorsById: (id) => {
    return publisherApi.get(`/publisher-dev/project/getSectors?sectorId=${id}`);
  },
  // get AppsInreviews
  getAppsInreview: (publisherID = "", projectId = "", applicationType = "") => {
    try {
      return publisherApi.get(
        `publisher-dev/project/getAppsinReview?publisherID=${publisherID}${
          projectId ? "&projectID=" + projectId : ""
        }${applicationType ? "&applicationType=" + applicationType : ""}`
      );
    } catch (error) {}
  },
  fetchStates: () => {
    return publisherApi.get(
      "publisher-dev/project/getLocationQuery?country=India&state=true"
    );
  },
  fetchCities: (state) => {
    return publisherApi.get(
      `publisher-dev/project/getLocationQuery/?country=India&state=${state}&city=true`
    );
  },

  createPublisher: (data) => {
    return publisherApi.post(`/publisher-dev/publisher/createPublisher`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },

  checkUser: (data) => {
    return publisherApi.get(`/publisher-dev/publisher/isProfileCompleted`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  getUserDetails: (data) => {
    return publisherApi.get(`/publisher-dev/publisher/getUserDetails`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  getUserDetailsFromSSO: () => {
    return axios.post(
      `${loginconfig.apiUrl}/api/v2/user/get-profile-info`,
      {
        email: getUserToken()?.email,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  },

  checkUsernameExist: (username) => {
    return publisherApi.get(
      `/publisher-dev/publisher/checkUsername?username=${username}`
    );
  },
  // saveDraft
  saveAndDraft: (data, draftId) => {
    return publisherApi.post(
      `/publisher-dev/project/submitAppVersionAsDraft${
        draftId ? "?appDraftId=" + draftId : ""
      }`,
      data
    );
  },

  // get savedraft data
  getSaveDraftById: (draftId = "") => {
    return publisherApi.get(
      `/publisher-dev/project/getDraftProject?appDraftId=${draftId}`
    );
  },

  makeAppLive: (projectID, appID, schedule, data) => {
    return publisherApi.patch(
      `/publisher-dev/project/makeAppLive?projectID=${projectID}&applicationID=${appID}&schedule=${schedule}`,
      data
    );
  },

  // draft deleteById
  deleteDraftProjectBYId: (data, isDelete = "true") => {
    return publisherApi.delete(`/publisher-dev/project/deleteDraftProject`, {
      data: {
        ...data,
        isDelete: isDelete,
      },
    });
  },

  deleteApplicationVersion: (appId) => {
    return publisherApi.delete(
      `publisher-dev/project/deleteApp?appID=${appId}`
    );
  },

  getAllRejectedApps: () => {
    return publisherApi.get(`/publisher-dev/project/getRejectedApps`);
  },

  // get app info
  getAppInfo: (appID) => {
    return publisherApi.get(`publisher-dev/project/getAppInfo?appId=${appID}`);
  },
  getActiveUsers: (appID, dataFor) => {
    return publisherApi.get(
      `/publisher-dev/analytics/getActiveUsers?appID=${appID}&dataFor=${dataFor}`
    );
  },

  getAppDownloads: (appID, dataFor) => {
    return publisherApi.get(
      `/publisher-dev/analytics/getAppDownloads?appID=${appID}&dataFor=${dataFor}`
    );
  },

  getAppEarnings: (appID, dataFor, priceModel) => {
    return publisherApi.get(
      `/publisher-dev/analytics/getAppEarnings?appID=${appID}&dataFor=${dataFor}&priceModel=${priceModel}`
    );
  },

  getAppUsersByRegion: (appID) => {
    return publisherApi.get(
      `/publisher-dev/analytics/getAppUserbyRegions?appID=${appID}`
    );
  },
  getMiscData: (appID) => {
    return publisherApi.get(
      `/publisher-dev/analytics/getAppMiscData?appID=${appID}`
    );
  },
  getOverallDownloadsData: (dataFor) => {
    return publisherApi.get(
      `/publisher-dev/analytics/getAllOverDownloads?dataFor=${dataFor}`
    );
  },
  getOverallEarnings: (dataFor, priceModel) => {
    return publisherApi.get(
      `/publisher-dev/analytics/getAllAppsEarnings?dataFor=${dataFor}&priceModel=${priceModel}`
    );
  },
  getOverallActiveUsers: (dataFor) => {
    return publisherApi.get(
      `/publisher-dev/analytics/getAllOverActiveUsers?dataFor=${dataFor}`
    );
  },
  getOverallUsersByRegion: () => {
    return publisherApi.get(`/publisher-dev/analytics/getAppsByRegion`);
  },
  getAppRatings: (appID) => {
    return publisherApi.get(
      `/ajna-vidya/project/getOverallRating?applicationID=${appID}`
    );
  },
  getAppReviews: (appID) => {
    return publisherApi.get(
      `/ajna-vidya/project/getAllComments?applicationID=${appID}`
    );
  },

  // Accounts API
  getAccountList: () => {
    return publisherApi.get(`publisher-dev/publisher/getAccounts`);
  },
  createPaymentAccount: (data) => {
    return publisherApi.post(`publisher-dev/publisher/createAccount`, data);
  },
  setPrimaryAccount: (accountId, isPrimary) => {
    return publisherApi.get(
      `publisher-dev/publisher/setPrimaryAccounts?accountId=${accountId}&isPrimary=${isPrimary}`
    );
  },
  deleteAccount: (accountId) => {
    return publisherApi.delete(
      `publisher-dev/publisher/deleteAccount?accountId=${accountId}`
    );
  },
  getBankDetails: (ifscCode) => {
    return publisherApi.get(
      `publisher-dev/publisher/getBankDetails?ifscCode=${ifscCode}`
    );
  },
  getAllMembers: () => {
    return publisherApi.get(`publisher-org/organization/getAllUsers`);
  },
  addMember: (data) => {
    return publisherApi.post(`publisher-org/organization/createUser`, data);
  },
  deleteMember: (data) => {
    return publisherApi.post(`publisher-org/organization/deleteUser`, data);
  },

  // Coupons Api

  // Create Coupon Api
  createCoupon: (data) => {
    return publisherApi.post(`ajna-private/coupon/createCoupon`, data);
  },
  // getAll Coupons Api ByAppId
  getCouponsByAppId: (appId) => {
    return publisherApi.get(
      `ajna-private/coupon/findCouponByApplicationId?applicationId=${appId}`
    );
  },

  deleteCouponByCouponId: (couponId) => {
    return publisherApi.delete(
      `ajna-private/coupon/deleteCoupon?couponId=${couponId}`
    );
  },
  findCouponById: (couponId) => {
    return publisherApi.get(
      `ajna-private/coupon/findCouponById?couponId=${couponId}`
    );
  },
  updateCouponById: (couponId, data) => {
    return publisherApi.patch(
      `ajna-private/coupon/updateCoupon?couponId=${couponId}`,
      data // Send the data in the request body
    );
  },
  createComment: (commentID, data) => {
    return publisherApi.post(
      `/ajna-private/project/publisherActions?commentID=${commentID}`,
      data
    );
  },

  getAllowedStorage: (userID, projectID) => {
    return storageApi.get(
      `/storage?userID=${userID}&projectID=${projectID ? projectID : ""}`
    );
  },

  //  getAll sdearch api
  globalSearch: (query) => {
    return publisherApi.get(`publisher-dev/project/q?search=${query}`);
  },

  // getAppsHistory
  getAllVersionsHistory: (projectID, rcName, applicationType, appID) => {
    return publisherApi.get(
      `publisher-dev/project/getVersions?projectID=${projectID}&rcName=${rcName}&applicationType=${applicationType}&appID=${appID}`
    );
  },
  inviteUsers: (rcName, projectID, appId, rcApplicationType, data) => {
    return publisherApi.post(
      `/publisher-dev/project/releaseChannel/addUsersToAlphaRc?rcName=${rcName}&projectID=${projectID}&rcApplicationType=${rcApplicationType}&appId=${appId}`,
      data
    );
  },
  getInvitedUsersApi: (rcName, projectID, rcApplicationType) => {
    return publisherApi.get(
      `/publisher-dev/project/releaseChannel/getAllUsersFromAccess?rcName=${rcName}&projectID=${projectID}&rcApplicationType=${rcApplicationType}`
    );
  },
  deleteInvitedUserApi: (rcName, projectID, rcApplicationType, userIds) => {
    const params = new URLSearchParams({
      rcName: rcName,
      projectID: projectID,
      rcApplicationType: rcApplicationType,
    });

    return publisherApi.delete(
      `/publisher-dev/project/releaseChannel/deleteUsersFromAccess?${params.toString()}`,
      { data: userIds } // Assuming userIds is an array of user IDs you want to delete
    );
  },

  // buystorage
  getAllStorage: () => {
    return publisherApi.get(`ajna-private/publisher/getAllStoragePlans`);
  },
  //  api projecwise gb storage
  getStorageProjectwise: () => {
    return publisherApi.get(`publisher-dev/project/getAllProjectsStorage`);
  },
};

// upload api
export const upload = {
  uploadImage: (image) => {
    return uploadApi.post("/upload", image);
  },
};
