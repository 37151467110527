import React, { useRef, useState } from "react";
import fileUploading from "assets/fileUploading.svg";
import fileuploaded from "assets/fileuploaded.svg";
import toolIcon from "assets/toolIcon.svg";
import { TextBlack } from "components/styled/common.styled";
import { DeleteBTn, UploadBox } from "components/styled/Invites.style";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const UploadFiles = ({ setFile }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    uploadFile(event.target.files[0]);
    setFile(event.target.files[0]);
  };

  const uploadFile = (file) => {
    if (file) {
      // Perform upload logic here
      const filenName = file.name;
      // Set fileUploaded state to true upon successful upload
      setFileUploaded(true);
    } else {
      console.log("No file selected.");
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handledelete = () => {
    setFileUploaded(null);
    setSelectedFile(false);
    setFile(null);
  };

  return (
    <UploadBox>
      <img
        src={fileUploaded ? fileuploaded : fileUploading}
        alt={fileUploaded ? "fileuploaded" : "fileUploading"}
        style={{ cursor: "pointer" }}
        onClick={handleImageClick}
      />
      <div>
        <TextBlack className="upload d-flex align-items-center">Upload File
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              Ensure your CSV file includes only two columns: 'name' and
              'emailId'. The 'name' column should contain the names of the
              individuals, and the 'emailId' column should contain their
              corresponding email addresses.
            </Tooltip>
          }>
          <img src={toolIcon}  className="ms-1" alt="toolIcon" />
        </OverlayTrigger></TextBlack>
      </div>

      {selectedFile ? (
        <>{selectedFile.name}</>
      ) : (
        <>
          {" "}
          <TextBlack className="pleaseUpload">
            Please upload your data file in CSV or XLSX format.
          </TextBlack>
        </>
      )}
      {selectedFile && <DeleteBTn onClick={handledelete}>Delete</DeleteBTn>}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    </UploadBox>
  );
};

export default UploadFiles;
