import React, { useEffect, useState } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import {
  HorizontalStepCont,
  StepWrapper,
  VerticleWrapper,
} from "components/styled/projectreview.styled";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAppReviewStatus } from "redux/Project/project.action";
import { statusSteps } from "helper";

const HStepProgress = ({ appID, status }) => {
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appStatusHandler = () => {
    if (status === "Review Initiated") {
      return;
    } else {
      dispatch(getAppReviewStatus(appID))?.then((res) => {
        if (res) {
          navigate(`/review_details/${appID}`);
        }
      });
    }
  };

  useEffect(() => {
    if (status) {
      if (status === "Review Initiated") {
        setProgress(0);
      } else {
        const progressByNumber = (statusSteps(status) + 1) * 25;
        // when progress 3 then it will be 68 percent
        const updateProgress = progressByNumber === 75 ? 68 : progressByNumber;
        setProgress(updateProgress);
      }
    }
  }, [status]);

  return (
    <HorizontalStepCont
      style={{
        cursor: status === "Review Initiated" ? "not-allowed" : "pointer",
      }}
      onClick={() => {
        appStatusHandler();
      }}
    >
      <ProgressBar
        percent={progress}
        filledBackground="#7A68E9"
        transform="none"
        height="2px"
        transitionDuration="0ms"
      >
        <Step>
          {({ accomplished }) => (
            <VerticleWrapper>
              <div
                style={{
                  border: `${
                    statusSteps(status) === 0 ||
                    status === "Technical Review" ||
                    status === "Content Review" ||
                    status === "Guidelines Review"
                      ? "3px solid #7A68E9"
                      : "2px solid gray"
                  }`,
                  borderRadius: "50%",
                  height: "14px",
                  zIndex: "1",
                  width: "14px",
                  position: "relative",
                  background: "white",
                  transform: "translateY(-30%)",
                  transitionduration: "500ms",
                }}
              ></div>
              <StepWrapper>Review Initiated</StepWrapper>
            </VerticleWrapper>
          )}
        </Step>
        <Step>
          {({ accomplished }) => (
            <VerticleWrapper>
              <div
                style={{
                  border: `${
                    statusSteps(status) === 1 ||
                    status === "Content Review" ||
                    status === "Guidelines Review"
                      ? "3px solid #7A68E9"
                      : "2px solid gray"
                  }`,
                  borderRadius: "50%",
                  height: "14px",
                  zIndex: "2",
                  width: "14px",
                  position: "relative",
                  background: "white",
                  transform: "translateY(-30%)",
                }}
              ></div>
              <StepWrapper>Technical Review</StepWrapper>
            </VerticleWrapper>
          )}
        </Step>
        <Step>
          {({ accomplished }) => (
            <VerticleWrapper>
              <div
                style={{
                  border: `${
                    statusSteps(status) === 2 || status === "Guidelines Review"
                      ? "3px solid #7A68E9"
                      : "2px solid gray"
                  }`,
                  borderRadius: "50%",
                  height: "14px",
                  width: "14px",
                  zIndex: "3",
                  position: "relative",
                  background: "white",
                  transform: "translateY(-30%)",
                }}
              ></div>
              <StepWrapper>Content review</StepWrapper>
            </VerticleWrapper>
          )}
        </Step>
        <Step>
          {({ accomplished }) => (
            <VerticleWrapper>
              <div
                style={{
                  border: `${
                    statusSteps(status) === 3
                      ? "3px solid #7A68E9"
                      : "2px solid gray"
                  }`,
                  borderRadius: "50%",
                  height: "14px",
                  width: "14px",
                  zIndex: "4",
                  position: "relative",
                  background: "white",
                  transform: "translateY(-30%)",
                }}
              ></div>
              <StepWrapper>Guidelines review</StepWrapper>
            </VerticleWrapper>
          )}
        </Step>
      </ProgressBar>
      {/* <button
        style={{
          marginLeft: "100px",
          marginTop: "100px",
          padding: "5px 10px",
          borderRadius: "8px",
        }}
        onClick={handleUpload}
      >
        Upload
      </button> */}
    </HorizontalStepCont>
  );
};

export default HStepProgress;
