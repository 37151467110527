import { styled } from "styled-components";

export const PaymentCard = styled.div`
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
  // margin-top: 24px;
  padding: 1rem;
  // width: 782px;
`;

export const AddnewAccount = styled.div`
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid var(--light-mode-neutral-color-neutral-7, #e6e6e6);
`;
export const AccountList = styled.div`
  display: flex;
  width: 350px;
  flex-direction: column;
  align-items: flex-start;
  // gap: 4px;
  padding: 0.25rem 0rem;
`;
export const AccNumber = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: #000000;
`;

export const CustomLabel = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #7b7e83;
  margin: 0;
  &.upi {
    color: var(--dark-mode-neutral-color-neutral-1, #0c0c0d);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &.app {
    padding: 1rem 0;
    color: var(--dark-mode-neutral-color-neutral-1, #0c0c0d);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &.other {
    color: var(--dark-mode-neutral-color-neutral-1, #0c0c0d);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 0.5rem;
  }
`;
export const CustomInput = styled.input`
  display: flex;
  width: 100%;
  padding: 10px 0px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 0;
  border-bottom: 1px solid var(--text-color-text-1, #161719);
  outline: none;
`;

export const PrimaryBtn = styled.button`
  padding: 14px 26px;
  background: #6c56fb;
  color: #fff;
  border: 0;
  border-radius: 44px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  gap: 7px;
  &.talktoBtn {
    color: var(--text-color-text-6, #898d93);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 50%;
    border-radius: 50px;
    background: var(--primary-color-primary-5, #6c56fb);
  }
`;

export const TextBlack = styled.h6`
  color: #ffff;
  margin: 0;
  text-decoration: none;
  // letter-spacing: 1px;
  &.fs-14 {
    font-size: 16px;
    font-weight: 300;
  }
`;

export const LeftFormCont = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const PrimaryCancelBtn = styled.button`
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid var(--secondary-color-secondary-1, #f5f7fa);
  background: var(--dark-mode-text-11, #fff);
`;
export const CustomDropDown = styled.select`
  border-bottom: 1px solid;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
`;

export const PaymentCont = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
`;

export const PaymentTitleImg = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #f8f8f8;
  padding-top: 0.5rem;
  padding: 10px;
  cursor: pointer;
`;

export const RightCont = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0.3rem;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
`;

export const CutomCancelBtn = styled.button`
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid var(--secondary-color-secondary-1, #f5f7fa);
  background: var(--dark-mode-text-11, #fff);

  &.bg1 {
    background: var(--primary-color-primary-5, #6c56fb);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    color: #fff;
  }
`;
