import { TableContainer } from "components/Table/table.styled";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import arrowdown1 from "../../assets/arrowdown1.png";

import { useLocation, useNavigate } from "react-router-dom";
import { getDateByUpdate } from "helper";
import { ShowVersionTxt } from "components/styled/Version.style";
import {
  getAllVersionHistoryData,
  makeAPPLive,
} from "redux/Project/project.action";
import { TextBlack } from "components/styled/common.styled";
import { toast } from "react-toastify";

const VersionHistory = () => {
  const [showVersion, setShowVersion] = useState(false);

  const { getAllversionHistory } = useSelector(
    (state) => state?.projectReducer
  );
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const paramsData = new URLSearchParams(location.search);

  const applicationType = paramsData.get("applicationType");
  const appId = paramsData.get("appId");
  const projectId = paramsData.get("projectId");
  const rcName = paramsData.get("rcName");

  useEffect(() => {
    if (showVersion) {
      dispatch(
        getAllVersionHistoryData(projectId, rcName, applicationType, appId)
      );
    }
  }, [showVersion]);


  return (
    <div>
      <ShowVersionTxt
        onClick={() => setShowVersion(!showVersion)}
        className="py-2">
        {showVersion ? "Hide version history" : "Show version history"}
      </ShowVersionTxt>
      {showVersion && (
        <div className="py-2">
          <TableContainer className="col-12">
            <table>
              {getAllversionHistory && getAllversionHistory?.length === 0 ? (
                <ShowVersionTxt className="text-doce">
                  No Data Found
                </ShowVersionTxt>
              ) : (
                getAllversionHistory?.map((item) => (
                  <tbody key={item._id}>
                    <tr>
                      <td style={{width:"90px"}}>
                        <TextBlack className="fs-14">{item?.version}</TextBlack>
                      </td>
                      <td>
                        <TextBlack className="fs-14 text-center ">
                          Fri Nov 03 2023
                        </TextBlack>
                      </td>
                      <td style={{width:"180px"}}>
                        <TextBlack className="fs-14">
                          {/* 2333 <img src={arrowdown1} alt="img" /> */}-
                        </TextBlack>
                      </td>
                      <td>
                        <TextBlack className="fs-14">{/* 5000 */}-</TextBlack>
                      </td>
                      <td>
                        <TextBlack
                          className="fs-14 live"
                          onClick={() => {
                            dispatch(makeAPPLive(projectId, item._id));
                          }}>
                          Set as Live Build
                        </TextBlack>
                      </td>
                    </tr>
                  </tbody>
                ))
              )}
            </table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default VersionHistory;
