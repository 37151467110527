import React from 'react';

export const maskAccountNumber=(accountNumber)=> {
  const last4Digits = accountNumber?.slice(-4);
  const maskedPortion = 'X'.repeat(12);

  // Add a space every 4 characters in the masked portion
  const maskedWithSpaces = maskedPortion.replace(/(.{4})/g, '$1 ');

  return maskedWithSpaces + last4Digits;
}




