import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";

const createGradient = (context, lgData) => {
  const chart = context.chart;
  const { ctx, chartArea } = chart;
  const {
    top = lgData?.top ?? 0,
    right = lgData?.right ?? 0,
    bottom = lgData?.bottom ?? 0,
    left = lgData?.left ?? 0,
  } = chartArea || {};

  const gradient = ctx.createLinearGradient(right, top, left, bottom);
  gradient.addColorStop(
    0,
    lgData?.color1 || "rgba(58, 101, 255, 1), rgba(58, 101, 255, 1)"
  );
  gradient.addColorStop(1, lgData?.color2 || "rgba(75, 192, 192, 0)");

  return gradient;
};

const LineChart = ({
  isAxisData,
  lgData,
  data,
  labelData,
  label,
  numData,
  noData,
}) => {
  const chartRef = useRef(null);
  const [chartDimensions, setChartDimensions] = useState({
    width: "100%",
    height: "100%",
  });

  const hasData = numData.some((value) => value !== 0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries && entries.length > 0) {
        const { width, height } = entries[0].contentRect;
        setChartDimensions({ width: `${width}px`, height: `${height}px` });
      }
    });

    if (chartRef?.current) {
      const chartInstance = new Chart(chartRef.current, {
        type: "line",
        data: {
          labels: labelData,
          datasets: [
            {
              label: label,
              data: numData,
              fill: true,
              backgroundColor: (context) => createGradient(context, lgData),
              borderColor: lgData?.borderColor || "#3A65FF",
              pointRadius: isAxisData ? 2 : 0,
              tension: 0.4,
              borderWidth: !isAxisData ? 1 : 2,
              // hidden: !isAxisData,
            },
          ],
        },
        options: {
          scales: {
            x: {
              display: isAxisData,
              grid: {
                display: true,
                drawOnChartArea: false,
              },
            },
            y: {
              display: isAxisData,
              grid: {
                display: true,
                drawOnChartArea: true,
              },
              min: 0,
              suggestedMin: 1,
            },
          },
          maintainAspectRatio: false, // Disable aspect ratio maintenance
          responsive: true, // Enable responsiveness
          plugins: {
            legend: {
              display: isAxisData,
            },
          },
        },
      });

      resizeObserver.observe(chartRef.current);

      return () => {
        // Clean up the chart instance and observer
        chartInstance.destroy();
        resizeObserver.disconnect();
      };
    }
  }, [labelData, numData]);

  let chartContent = null;

  if (hasData) {
    chartContent = (
      <canvas ref={chartRef} style={{ width: "100%", height: "100%" }} />
    );
  } else if (!noData) {
    chartContent = (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        No Data Available
      </div>
    );
  }

  // Later in your JSX, you can use {chartContent} to render it.

  return (
    <div
      style={{ width: chartDimensions.width, height: chartDimensions.height }}>
      {chartContent}
    </div>
  );
};

export default LineChart;
