import { styled } from "styled-components";

export const Custompdiv = styled.div`
  color: var(--text-color-text-1, #161719);
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
`;
export const KeyValue = styled.div`
  display: flex;
  align-items: center;
  margin-top: -5px;
`;
export const LeftCont = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
`;
export const TextCont = styled.div`
  width: 156px;
  height: 156px;
`;

export const VerticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 50%;
  margin-top: 52px;
`;
export const StepWrapper = styled.div`
  color: ${({ theme }) => theme.black};
  text-align: center;
  font-size: 12px;
  font-weight: 600;
`;
export const HorizontalStepCont = styled.div`
  padding: 1.7rem 2.3rem;
`;

export const CustomPtag = styled.p`
  color: var(--text-color-text-1, #161719);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
`;

export const ProgressBarBox = styled.div`
  width: 100%;
  height: 99px;
  flex-shrink: 0;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0rem 0.8rem 0.5rem 0.8rem;
`;

export const StatusCont = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgba(31, 31, 31, 0.87);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;
