import { CustomToast } from "components/CustomToast";
import { toast } from "react-toastify";
import { publisher } from "redux/api";

export const removeEmptyHTMLTags = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  let elements = doc.body.querySelectorAll("*");

  for (let i = elements?.length; i >= elements?.length - 1000; i--) {
    let el = elements[i];
    if (el?.innerHTML === "") {
      el.parentNode.removeChild(el);
    }
  }
  return doc.body.innerHTML;
};

export const htmlToString = (html) => {
  return html?.replace(/<[^>]*>?/gm, "");
};

export const APIMETHODSCOLORS = (method) => {
  switch (method) {
    case "GET":
      return "#0F9D58 ";
    case "POST":
      return "#4285F4 ";
    case "PATCH":
      return "#AB47BC ";
    case "DELETE":
      return "#DB4437 ";
    case "PUT":
      return "#F4B400";
    default:
      return "#00A8E8";
  }
};

export const newLineToBr = (text) => {
  return text?.replace(/\n/g, "<br />");
};

export const checkFileTypeImg = (file) => {
  const fileType = file?.type?.split("/")[0];
  if (fileType !== "image") {
    toast.dark(
      <CustomToast
        message={"Invalid file type. Please select an image file."}
        type={"error"}
      />,
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
      }
    );
    return true;
  }
  return false;
};

export const checkFileTypeImgVideos = (file) => {
  const fileType = file.type.split("/")[0];
  if (fileType !== "image" && fileType !== "video") {
    toast.dark(
      <CustomToast
        message={"Invalid file type. Please select an image or video file."}
        type={"error"}
      />,
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
      }
    );
    return true;
  }
  return false;
};

export const checkFileSize = (file) => {
  const fileType = file.type.split("/")[0];
  // Check file size
  const fileSizeInMB = file.size / (1024 * 1024);
  if (fileType === "video" && fileSizeInMB > 20) {
    toast.dark(
      <CustomToast
        message={"File size exceeds the limit of 20MB."}
        type={"error"}
      />,
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
      }
    );
    return true;
  }
  if (fileType === "image" && fileSizeInMB > 5) {
    toast.dark(
      <CustomToast
        message={"File size exceeds the limit of 5MB."}
        type={"error"}
      />,
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
      }
    );
    return true;
  }
  return false;
};

export const getDateByUpdate = (date) => {
  if (!date) return null;
  let update = new Date(date);

  // Get the current date
  const currentDate = new Date();

  // Calculate the time difference between current date and `updatedAt`
  // Calculate the time difference between current date and `updatedAt`
  const timeDiff = currentDate.getTime() - update.getTime();

  // Calculate the number of seconds since last edit
  const secondsSinceLastEdit = Math.floor(timeDiff / 1000);

  let timeAgo;

  if (secondsSinceLastEdit <= 0) {
    timeAgo = `Edited now`;
  } else if (secondsSinceLastEdit < 60) {
    timeAgo = `${secondsSinceLastEdit} seconds ago`;
  } else if (secondsSinceLastEdit < 3600) {
    const minutesSinceLastEdit = Math.floor(secondsSinceLastEdit / 60);
    timeAgo = `${minutesSinceLastEdit} minutes ago`;
  } else if (secondsSinceLastEdit < 86400) {
    const hoursSinceLastEdit = Math.floor(secondsSinceLastEdit / 3600);
    timeAgo = `${hoursSinceLastEdit} hours ago`;
  } else if (secondsSinceLastEdit < 604800) {
    const daysSinceLastEdit = Math.floor(secondsSinceLastEdit / 86400);
    timeAgo = `${daysSinceLastEdit} days ago`;
  } else {
    const weeksSinceLastEdit = Math.floor(secondsSinceLastEdit / 604800);
    timeAgo = `${weeksSinceLastEdit} weeks ago`;
  }
  return timeAgo;
};

export const MediaViewer = (url) => {
  const preview = URL.createObjectURL(url);
  return preview;
};

export const objectKeyAndValueValidator = (obj, skipKey) => {
  let isValid = true;

  Object.entries(obj).forEach(([key, value]) => {
    if (key === skipKey) return;
    if (key === "") {
      delete obj[key];
    }
    if (value === "") {
      isValid = false;
    }
  });
  return isValid;
};

export const fileType = (url) => {
  let fileType = "";
  // remove ? from url
  url = url?.split("?")[0];
  const type = url?.split(".").pop();
  const imagesTypes = ["jpg", "jpeg", "png", "gif", "svg", "png-"].toString();
  const videoTypes = ["mp4", "webm", "ogg"].toString();
  const threeDFiles = ["glb, gltf"].toString();
  if (imagesTypes.includes(type)) {
    fileType = "image";
    return fileType;
  }
  if (videoTypes.includes(type)) {
    fileType = "video";
    return fileType;
  }
  if (threeDFiles.includes(type)) {
    fileType = "3d";
    return fileType;
  }
  return fileType;
};

export const objectToArray = (obj) => {
  const values = Object.values(obj);

  let arrayFormat = Object.entries(obj);

  const filterEmpty = values.filter((item) => item !== "");
  return values;
};

export const statusSteps = (stepText) => {
  let step = 10;
  if (stepText === "Review Initiated") {
    step = 0;
  }
  if (stepText === "Technical Review") {
    step = 1;
  }
  if (stepText === "Content Review") {
    step = 2;
  }
  if (stepText === "Guidelines Review") {
    step = 3;
  }
  return step;
};

export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  localStorage.setItem(name, value);
  document.cookie =
    name + "=" + (value || "") + expires + "; path=/;domain=.ajnavidya.com;";
}

export function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return localStorage.getItem(name);
}

export const EraseCookies = (name) => {
  localStorage.removeItem(name);
  document.cookie = name + "=; Max-Age=-99999999;domain=.ajnavidya.com;path=/";
};
export const EraseAllCookies = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    EraseCookies(cookies[i].split("=")[0]);
  }
};

export const getUserToken = (access_token) => {
  const token = access_token || localStorage.getItem("token");
  if (!token || token === "undefined" || token == "null")
    return {
      name: "",
      email: "",
    };

  // validate token is valid or not
  const decodeToken =
    token.split(".")[1] && token && JSON.parse(atob(token?.split(".")[1]));
  if (!decodeToken) return;
  setCookie("publisherID", decodeToken.sub, 1);
  return decodeToken;
};

export const UUIDGenerator = () => {
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const r = array[0] % 16;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  return uuid;
};

export const addSpaceAndCapitalize = (inputString) => {
  // Split the input string into words
  if (inputString === "appID") return "App ID";
  if (inputString === "functionalChecks") return "Functional Checks";
  if (inputString === "streamableAppChecks") return "Streamable App Checks";
  const text = inputString;
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  if (finalResult.includes("S O P")) {
    return finalResult.replace("S O P", "SOP");
  }
  if (finalResult.includes("functionalChecks")) {
    return finalResult.replace("functionalChecks", "Functional Checks");
  }
  return finalResult;
};

// toconver byte to gb
export const convertBytesToGB = (bytes) => {
  if (!bytes || isNaN(bytes)) {
    return "";
  }

  const gigabytes = bytes / (1024 * 1024 * 1024);
  return `${gigabytes.toFixed(2)} GB`; // Displaying gigabytes up to two decimal places
};

// Common function to generate labels based on selectedPeriod

export const generateLabels = (selectedPeriod, data, monthNames, dayNames) => {
  if (selectedPeriod === "Yearly" || selectedPeriod === "Monthly") {
    return data
      ?.map((item) => {
        if (selectedPeriod === "Yearly") {
          const month = item.monthName ? item.monthName.slice(0, 3) : [];
          const mYear = item.year ? item.year.toString().slice(-2) : [];
          return `${month}-${mYear}`;
        } else {
          const dateParts = item.date ? item.date.split("-") : [];
          const month = dateParts[1];
          const day = dateParts[2];
          const monthInitial = monthNames[parseInt(month) - 1];
          return `${day}-${monthInitial}`;
        }
      })
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => {
        const [monthA, dayA] = a.split("-");
        const [monthB, dayB] = b.split("-");
        return (
          new Date(2023, monthA - 1, dayA) - new Date(2023, monthB - 1, dayB)
        );
      });
  } else {
    return data?.map((item) => dayNames[item.day]);
  }
};

export const RegionLabelAndNumData = (
  usersByRegionLabel,
  usersByRegionNumData
) => {
  return {
    labels: usersByRegionLabel,
    datasets: [
      {
        label: "Users",
        data: usersByRegionNumData,
        backgroundColor: ["rgba(255, 218, 122, 0.28)"],
        borderColor: ["rgba(255, 218, 122, 0.28)"],
        hoverBackgroundColor: ["#FFDA7A"],
        barThickness: 10,
        borderRadius: 25,
      },
    ],
  };
};

export const optionsData = () => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        beginAtZero: true,
      },
    },
  };
};

// User label data

export const getNumDataHelper = (
  graphScreen,
  downloadClick,
  downloadNumData,
  userNumData,
  earningClick,
  earningNumData
) => {
  const dataMap = {
    downloads: downloadClick ? downloadNumData : userNumData,
    earnings: earningClick ? earningNumData : userNumData,
    default: userNumData,
  };

  return dataMap[graphScreen] || dataMap.default;
};

export const mapPlatform = (extension) => {
  switch (extension) {
    case "Apk":
    case ".apk":
      return "Android";
    case "Exe":
    case ".exe":
      return "Window";
    case "Sh":
    case ".sh":
      return "Linux";
    default:
      return "Unknown";
  }
};

export const fetColour = ({
  earningClick,
  downloadClick,
  lgDataGreen,
  lgDataRed,
  type,
}) => {
  let color = "";
  if (!downloadClick && type === "downloads") {
    color = lgDataGreen;
  } else if (!earningClick && type === "earnings") {
    color = lgDataRed;
  }
  return color;
};
