import React, { useState } from "react";
import { TableContainer } from "./table.styled";
import { useDispatch } from "react-redux";
import more from "assets/more.svg";
import editImg from "assets/edit-2.svg";
import { setFormDetails } from "redux/Project/project.action";
import { TextBlack } from "components/styled/common.styled";
import { useLocation } from "react-router-dom";
import arrowdown1 from "../../assets/arrowdown1.png";

export const EmptyRow = ({ data = ["1"], type = false, newVersion }) => {
  const location = useLocation();
  const urlData = new URLSearchParams(location.search);

  const infoType = urlData.get("infoType");
  const dispatch = useDispatch();
  const [showShare, setShowShare] = useState(false);
  const [editShow, setEditShow] = useState(true);

  const editVersion = (e) => {
    const value = document.getElementById("version");
    dispatch(
      setFormDetails({
        version: value?.textContent || "-",
      }),
      setEditShow(true)
    );
  };
  return (
    <div>
      <TableContainer className="col-12">
        <table>
          {infoType === "Overview" && (
            <thead>
              <tr>
                <th>Version</th>
                <th>Date</th>

                <th>Download</th>

                <th>Earnings</th>
                {/* <th></th> */}
              </tr>
            </thead>
          )}
          {!type && (
            <thead>
              <tr>
                <th>Version</th>
                <th>Date</th>

                <th>Download</th>

                <th>Earnings</th>
                {/* <th></th> */}
              </tr>
            </thead>
          )}
          <tbody>
            {data?.map((item, i) => (
              <tr key={item?.id} className="position-relative">
                {infoType !== "Overview" && (
                  <td>
                    <TextBlack className="fs-14">{item?.name || "-"}</TextBlack>
                  </td>
                )}
                <td
                  contentEditable={infoType === "Overview" ? false : newVersion}
                  onBlur={editVersion}
                  onFocus={() => setEditShow(false)}
                  id="version"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "0",
                    padding: "0.5rem 1rem",
                    outline: "none",
                  }}
                >
                  {item?.version || "-"}
                  {item?.createAt !== "-" &&
                    editShow &&
                    infoType !== "Overview" && (
                      <img
                        src={editImg}
                        style={{ marginLeft: "1rem" }}
                        alt="edit"
                      />
                    )}
                </td>
                <td>{item?.createAt || "-"}</td>
                {/*    <td>
                  <div
                    className="d-flex align-items-center"
                    style={{ paddingLeft: "10px" }}
                  ></div>
                </td> */}
                {/* <td
                  onClick={() =>
                    document.getElementById(`uploadThumbnailModal`)?.click()
                  }
                >
                  <a>{projectFormDetails?.buildURL && "Replace"}</a>
                </td> */}
                {infoType === "Overview" && (
                  <>
                    {" "}
                    <td>
                      <TextBlack className="fs-14">
                        {/* 2333 <img src={arrowdown1} alt="img" /> */}-
                      </TextBlack>
                    </td>
                    <td>
                      <div style={{ color: "#20A019" }} className="fw-500">
                        {/* 2000 */}-
                      </div>
                    </td>
                    {/* <td>
                      <div
                        onClick={() => setShowShare(!showShare)}
                        style={{
                          width: "fit-content",
                          float: "right",
                        }}>
                        <img src={more} alt="img" />
                      </div>
                      {showShare && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            width: "fit-content",
                            padding: "8px 12px",
                            background: "#fff",
                            position: "absolute",
                            borderRadius: "8px",
                            zIndex: +1000,
                            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.20)",
                            bottom: "10%",
                            right: "5%",
                          }}>
                          <option value="share">
                            <TextBlack className="color-black">Share</TextBlack>
                          </option>
                          <option value="delete">
                            <TextBlack>Delete</TextBlack>
                          </option>
                        </div>
                      )}
                    </td> */}
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};
