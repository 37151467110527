import { Col, Nav, Tab } from "react-bootstrap";
import {
  CustomNav,
  CustomNavLink,
  CustomNavLink2,
  CustomNavPlane,
} from "./customTabs.styled";
import { useLocation, useNavigate } from "react-router-dom";

export const CustomTabs2 = ({ tabTitles = [], page, tabs, type, children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get("tab") || tabs;

  function handlePageChange(newPage) {
    queryParams.set("tab", newPage);
    navigate({ search: queryParams.toString() });
  }

  return (
    <>
      <Tab.Container activeKey={currentPage || tabTitles[0]}>
        {type === "plane" ? (
          <CustomNavPlane className="flex-row">
            {tabTitles?.map((title, index) => {
              const key = `${title}_${index + 1}`;
              return (
                <Nav.Item
                  key={key}
                  bsPrefix="custom-nav-item2"
                  onClick={() => {
                    handlePageChange(title);
                  }}>
                  <CustomNavLink2 eventKey={title}>{title}</CustomNavLink2>
                </Nav.Item>
              );
            })}
          </CustomNavPlane>
        ) : (
          <CustomNav className="flex-row">
            {tabTitles?.map((title, index) => {
              const key = `${title}_${index + 1}`;
              return (
                <Nav.Item
                  key={key}
                  bsPrefix="custom-nav-item"
                  onClick={() => {
                    handlePageChange(title);
                  }}>
                  <CustomNavLink eventKey={title}>{title}</CustomNavLink>
                </Nav.Item>
              );
            })}
          </CustomNav>
        )}

        <Col sm={12} className="pt-4">
          <Tab.Content>{children}</Tab.Content>
        </Col>
      </Tab.Container>
    </>
  );
};
