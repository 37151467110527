import { Select } from "components/Select/Select";
import { TableContainer } from "components/Table/table.styled";

import search from "assets/search.svg";
import {
  BadgePrimary,
  BoxWrapper,
  ButtonMember,
  TextBlack,
} from "components/styled/common.styled";
import { InviteStatus } from "contants/constants";
import { useEffect, useState } from "react";
import plus from "assets/plus.svg";
import deleteIcon from "assets/deleteIcon.svg";
import AddManually from "ui/InviteUser/AddManually";
import { BlackWhiteDiv, UpperBlack } from "components/card/card.styled";
import { getAllMembers } from "redux/App/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { publisher } from "redux/api";
import { getUserToken } from "helper";
import Skeleton from "react-loading-skeleton";

const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const Members = ({ profileData, isloading }) => {
  const [IsInvite, setInvite] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [Isloading, setIsloading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  const { allMembers } = useSelector((state) => state.appReducer);

  const handleSearchChange = debounce((value) => {
    setSearchTerm(value);
  }, 300);

  const filteredMembers = allMembers.filter((member) => {
    return (
      member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  useEffect(() => {
    dispatch(getAllMembers()).then((res) => {
      // console.log("getAllMemberssfsdfdsfds", res.payload);
      if (res.payload) {
        setIsloading(false);
      }
    });
  }, []);

  const deleteMember = async (email) => {
    const yes = window.confirm("Are you sure you want to delete this member?");
    if (!yes) return;
    try {
      await publisher.deleteMember({
        email,
      });
      toast.dark("Member deleted successfully", { autoClose: 2000 });
      setTimeout(() => {
        dispatch(getAllMembers());
      }, 1000);
    } catch (e) {
      return toast.dark("Something went wrong", { autoClose: 2000 });
    }
  };

  const getowner = getUserToken();

  return (
    <div>
      {!isOpen && (
        <>
          <div className="d-flex justify-content-between py-2 ">
            <BoxWrapper
              width={408}
              className="d-flex justify-content-end background w-fit border-radius-50 p-2 px-3"
            >
              <img src={search} height={"15px"} alt="search" />
              <input
                placeholder="Search"
                type="search"
                onChange={(e) => handleSearchChange(e.target.value)}
                className="border-0"
              />
            </BoxWrapper>
            <div>
              {profileData?.owner && (
                <ButtonMember onClick={() => setIsOpen(true)}>
                  <img src={plus} alt="plus" /> Add Member
                </ButtonMember>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-start align-items-center">
            <TextBlack className="py-2 m-0 pe-2">{profileData?.name}</TextBlack>
            <BadgePrimary>
              {(() => {
                let roleText = "";
                const role = profileData?.role;
                if (role === "org_admin") {
                  roleText = "Admin";
                } else if (role === "org_member") {
                  roleText = "Member";
                } else if (role === "org_owner") {
                  roleText = "Owner";
                } else {
                  roleText = "Owner";
                }
                return roleText;
              })()}
            </BadgePrimary>
          </div>
          <div
            style={{ overflowY: "scroll", height: "258px", marginTop: "10px" }}
          >
            <TableContainer>
              <table>
                <thead>
                  <tr className="border-bottom">
                    <th>Name</th>
                    <th>Email ID</th>
                    <th>Manage Role</th>
                    <th>
                      <Select
                        title={"Invite Status"}
                        isOpen={IsInvite}
                        setIsOpen={setInvite}
                        width="150px"
                      >
                        <div className="form-check">
                          {InviteStatus.map((item, index) => (
                            <div className="px-2" key={item.id}>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={item.inviteStatus}
                              />
                              <label htmlFor={item.inviteStatus}>
                                <TextBlack>{item.inviteStatus}</TextBlack>{" "}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Select>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isloading ? (
                    <>
                      {[...Array(5)].map((_, rowIndex) => (
                        <tr key={rowIndex}>
                          {[...Array(5)].map((_, colIndex) => (
                            <td key={colIndex}>
                              <Skeleton width={100} />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      {filteredMembers?.map((item, index) => (
                        <>
                          <tr
                            style={{
                              pointerEvents:
                                item.role === "org_owner" ? "none" : "auto",
                              cursor:
                                item.role === "org_owner"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            className="channel-user"
                            key={item?.userID}
                          >
                            <td>
                              <TextBlack className="m-0">{item.name}</TextBlack>{" "}
                            </td>
                            <td>
                              <TextBlack
                                className="m-0"
                                style={{ textTransform: "lowercase" }}
                              >
                                {item.email}
                              </TextBlack>
                            </td>
                            <td>
                              {(() => {
                                let roleText = "";
                                const role =
                                  profileData?.email === item.email
                                    ? "org_owner"
                                    : item.role;
                                if (role === "org_admin") {
                                  roleText = "Admin";
                                } else if (role === "org_member") {
                                  roleText = "Member";
                                } else if (role === "org_owner") {
                                  roleText = "Owner";
                                } else {
                                  roleText = "Owner";
                                }
                                return roleText === "Owner" ? (
                                  <>Owner</>
                                ) : (
                                  roleText
                                );
                              })()}
                            </td>
                            <td className="d-flex align-items-center">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "12px",

                                  background: "#EBFAEB",

                                  width: "fit-content",
                                  padding: "4px 10px",
                                  textTransform: "capitalize",
                                  color: "#20A019",
                                }}
                              >
                                {"Accepted"}
                              </div>
                            </td>
                            <td>
                              {(() => {
                                let roleText = "";
                                const role =
                                  profileData?.email === item.email
                                    ? "org_owner"
                                    : item.role;
                                if (role === "org_admin") {
                                  roleText = "Admin";
                                } else if (role === "org_member") {
                                  roleText = "Member";
                                } else if (role === "org_owner") {
                                  roleText = "Owner";
                                } else {
                                  roleText = "Owner";
                                }
                                return roleText === "Owner" ? (
                                  <></>
                                ) : (
                                  <img
                                    onClick={() => deleteMember(item?.email)}
                                    src={deleteIcon}
                                    alt="delete"
                                  />
                                );
                              })()}
                            </td>
                            {/* <td>
                    <div className="d-flex justify-content-end">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                    </div>
                  </td> */}
                          </tr>
                        </>
                      ))}
                    </>
                  )}

                  {filteredMembers?.length === 0 && (
                    <tr>
                      <td colSpan={5}>
                        <TextBlack
                          style={{ color: "#808080" }}
                          className="m-0 p-5 text-center"
                        >
                          No members found
                        </TextBlack>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TableContainer>
          </div>
        </>
      )}

      {isOpen && (
        <div className="row  border rounded pb-3">
          <BlackWhiteDiv className="p-0">
            <UpperBlack className="text-white">Invite Users</UpperBlack>
          </BlackWhiteDiv>
          <div className="row pt-4">
            {/* Upload files */}
            <div className="col-6">{/* <UploadFiles /> */}</div>
            {/* add manual */}
            <div className="col-12">
              <AddManually setIsOpen={setIsOpen} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
