import BarChart from "components/Graphs/BarChart";
import { GraphCard, TextBlack } from "components/styled/common.styled";
import React from "react";
import { Spinner } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import PropTypes from "prop-types";

const GraphWithLoading = ({
  isDataLoaded,
  data,
  options,
  skeletonHeight,
  skeletonWidth,
}) => {
  return (
    <div
      className="p-3"
      style={{ background: "#fff", borderRadius: "10px", height: "100%" }}
    >
      {!isDataLoaded ? (
        <div className="p-3">
          <Skeleton
            height={skeletonHeight || "20px"}
            width={skeletonWidth || "100px"}
          />
        </div>
      ) : (
        <GraphCard width="100%">
          <div className="d-flex justify-content-between">
            <div>
              <TextBlack className="fw-600">Users By Region</TextBlack>
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center w-100 h-100"
            style={{ height: "100%" }}
          >
            {isDataLoaded && data && <BarChart data={data} options={options} />}
            {!isDataLoaded && (
              <div>
                <Spinner animation="border" size="sm" role="output">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </div>
        </GraphCard>
      )}
    </div>
  );
};

GraphWithLoading.propTypes = {
  isDataLoaded: PropTypes.bool,
  data: PropTypes.array,
  options: PropTypes.object,
  skeletonHeight: PropTypes.string,
  skeletonWidth: PropTypes.string,
};

export default GraphWithLoading;
