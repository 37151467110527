import React from "react";

export const SelectInput = ({
  options,
  onChange,
  fs,
  onBlur = {},
  name,
  fieldName,
  defaultValue,
  title,
  value,
}) => {
  return (
    <div>
      <select
        name={name}
        className="custom-select w-100 py-1"
        onBlur={onBlur}
        onChange={(e) => {
          if (fieldName) {
            onChange((state) => ({
              ...state,
              [fieldName]: e.target.value,
            }));
          } else {
            onChange(e);
          }
        }}
        value={value}
        style={{ fontSize: fs }}
      >
        {defaultValue && (
        
          <option selected hidden value={defaultValue}>
            {defaultValue}
          </option>
        )}
        {/* <option>{title} </option> */}
        {options?.map((option, index) => (
          <>
            <option
              className="px-2"
              key={option?.id}
              value={option.sector || option?.name}
              id={option.id}
            >
              {option.sector || option.name}
            </option>
          </>
        ))}
      </select>
    </div>
  );
};
