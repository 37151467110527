import { TextArea } from "components/LimitedTextArea/TextArea";
import { SelectInput } from "components/Select/SelectInput";
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";
import {
  CardBoxFlexible,
  CardDescriptionWrapper,
} from "components/card/card.styled";
import {
  ButtonPrimary,
  NsdcText,
  TextBlack,
  TextMiddle,
} from "components/styled/common.styled";
import { ageGroups, languages } from "contants/constants";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";

import { CardBoxUpload } from "components/card/Card";
import { mediaData } from "./contant";
import { setFormDetails } from "redux/Project/project.action";
import { useDispatch, useSelector } from "react-redux";
import { ModelViewer } from "components/model-viewer/ModelViewer";
import { Radio } from "components/Radio/Radio";
import { experinces } from "ui/NewProject/constant";
import cross from "assets/crossNew.svg";
import imgIconWhite2 from "assets/imgIconWhite2.svg";
import { upload } from "redux/api";
import UploadCoverImg from "./UploadCoverImg";
import { toast } from "react-toastify";
import { getUserToken } from "helper";
import { useParams } from "react-router-dom";
import Uploader from "ajnavidya-file-upload-client";
import ImageCroper from "components/ImageCroper/ImageCroper";
import { CustomModal } from "components/Modal/CustomModal";
import Iicon from "assets/Iicon.svg";
import { InfoCard } from "ui/analytics/InfoCard";
import CaverInfoCard from "components/CaverInfoCard/CaverInfoCard";
import NsdcCertificate from "./NsdcCertificate";

export const Media = ({ handleChange, setProjectForm, saveDraft }) => {
  const { projectFormDetails } = useSelector((state) => state.projectReducer);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState({
    modal: false,
    project: false,
    id: "",
  });
  const [progress, setProgress] = useState(0);
  const [fileURL, setFileURL] = useState("");
  const { s3Key } = useParams();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [descriptionmessage, setdescriptionmessage] = useState("");
  const [disclaimermessage, setdisclaimermessage] = useState("");

  const [selectedExperience, setSelectedExperience] = useState("");
  const [hashtags, setHashtags] = useState("");
  const [croperImgwidth, setcroperImgwidth] = useState("");
  const [mediaFilename, setMediaFileName] = useState(null);
  console.log("croperimgwidth", croperImgwidth);

  // Initialize form fields with values from Redux state
  useEffect(() => {
    if (projectFormDetails) {
      setSelectedExperience(projectFormDetails.tag || "");
      setHashtags(
        Array.isArray(projectFormDetails?.hashtags)
          ? projectFormDetails?.hashtags.join(" ")
          : ""
      );
    }
  }, [projectFormDetails]);

  const validateAspectRatio = (file, name) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;

        if (Math.abs(aspectRatio - expectedAspectRatio) > 0.1) {
          const error = new Error("Aspect ratio should be 16:9.");
          if (error) {
            setIsOpen({ ...isOpen, modal: true, file: file, id: name });
          }
          reject(error);
        } else {
          resolve();
        }
      };
      img.onerror = () => {
        const error = new Error("Error loading image.");
        reject(error);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const [percentCompleted, setPercentCompleted] = useState(0);

  document.addEventListener("fileStateChanged", (event) => {
    const progress1 = event.progress;
    setPercentCompleted(Math.floor(progress1));
    if (progress1 === 100) {
      setIsLoading(false);
      setIsOpen({ modal: false, project: false, id: "" });
      setIsVideoModal(false);
      setPercentCompleted(0);
    }
  });

  const fileUploader = async (file, name, type = "image") => {
    setMediaFileName(file);
    const fileSize = file.target.files[0]?.size;
    const fileExtension = file.target.files[0]?.name
      .split(".")
      .pop()
      .toLowerCase();
    const allowedVideoExtensions = ["mp4", "webm", "ogg", "avi", "mov"];
    const allowedImageExtensions = ["jpg", "jpeg", "png"];
    if (type === "Video") {
      if (!allowedVideoExtensions.includes(fileExtension)) {
        toast.error("Please upload a valid video file.");
        return;
      }
    }

    if (type === "Image" || type === "image") {
      setcroperImgwidth(1920);
      if (!allowedImageExtensions.includes(fileExtension)) {
        toast.error("Please upload a valid image file.");
        return;
      }
    }

    if (type !== "Video") {
      await validateAspectRatio(file.target.files[0], name);
    }

    type === "Video" && setIsVideoModal(true);
    setIsLoading(true);
    try {
      Uploader({
        file: file,
        url: {
          apiOrigin: "https://file-uploadv2.ajnavidya.com/",
          status: "/upload/status",
          upload: "/upload/files",
          complete: "/upload/complete",
        },
        projectID: s3Key,
        setFileURL: setFileURL,
        setProgress: setProgress,
        userID: getUserToken()?.sub,
      });

      document.addEventListener(file.target.name, (event) => {
        const fileURL = event.data;
        dispatch(
          setFormDetails({
            mediafileURLs: {
              [name]: fileURL?.url,
            },
            fileSize: fileSize,
          })
        );
      });
    } catch (err) {
      toast.error(err && "somthing went wrong");
      return;
    }
  };

  const expectedAspectRatio = 16 / 10;
  // const croperImgwidth = 1920;

  const [isLoading, setIsLoading] = useState(false);
  const [isVideoModal, setIsVideoModal] = useState(false);
  const [isToggleChecked, setIsToggleChecked] = useState(false);
  // console.log(isToggleChecked, "isToggleChecked");

  const handleToggleChange = (checked) => {
    setIsToggleChecked(!isToggleChecked);
  };

  return (
    <div className="col-10 ">
      {/* media */}

      {isOpen?.modal && (
        <CustomModal
          isOpen={isOpen?.modal}
          setIsOpen={setIsOpen}
          key={"abc"}
          size="md"
          title={"Crop Your Image"}
          back={true}
          setIsLoading={setIsLoading}
          openFor={"FeaturevideoUpload"}
          files={mediaFilename}
        >
          <ImageCroper
            name={new Date().getTime() + isOpen?.id}
            file={isOpen?.file}
            id={isOpen?.id}
            handleFileChange={fileUploader}
            expectedAspectRatio={expectedAspectRatio}
            isLoading={isLoading}
            croperImgwidth={croperImgwidth}
            typeimg={"media"}
          />
        </CustomModal>
      )}

      {isVideoModal && (
        <CustomModal
          isOpen={isVideoModal}
          setIsOpen={setIsVideoModal}
          key={"VideoModal"}
          size="md"
          title={"Video Upload"}
          back={true}
          setIsLoading={setIsLoading}
          openFor={"videoUpload"}
        >
          <div className="my-2 d-flex justify-content-center">
            <CardBoxFlexible border={true} style={{ position: "relative" }}>
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center w-100 h-100 mt-2">
                  {percentCompleted > 0 && percentCompleted + "% completed..."}
                  <br />
                  <Spinner animation="border" size="sm" />
                </div>
              ) : (
                <>
                  <TextMiddle>
                    Upload Video
                    <span className="text-danger fw-bold">*</span>
                  </TextMiddle>
                </>
              )}
            </CardBoxFlexible>
            {error !== null && (
              <div className="text-danger">{error.toString()}</div>
            )}
          </div>
          <ButtonPrimary>
            {isLoading ? "Uploading..." + percentCompleted + "%" : "Upload"}
          </ButtonPrimary>
        </CustomModal>
      )}

      <div className="my-4">
        <div className="d-flex align-items-center gap-2 ">
          <div className="fs-18 fw-400 py-2" style={{ paddingLeft: "10px" }}>
            Cover Image
          </div>
          <div>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip" className="custom-tooltip">
                  {/* <strong>Aspect Ratio</strong> 16:17 */}
                  <CaverInfoCard
                    text={
                      "A cover image is the first visual impression users have of your experience. It's essential to follow the suggested file size, resolution, and ratios to ensure the best display quality."
                    }
                  />
                </Tooltip>
              }
            >
              <img
                src={Iicon}
                alt="Iicon"
                style={{
                  width: "14px",
                  height: "14px",
                  cursor: "pointer",
                }}
              />
            </OverlayTrigger>
          </div>
        </div>

        <UploadCoverImg />
        <div  style={{ marginTop: "1.8rem" }}>
          <div className="d-flex align-items-center pb-2">
            <div className="fs-18 fw-400 pl-2 mx-2">
              Upload Minimum 3 Previews
            </div>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip" className="custom-tooltip">
                  {/* <strong>Aspect Ratio</strong> 16:17 */}
                  <CaverInfoCard
                    text={
                      "Preview media allows users to get glimpses of your app, helping them understand its features and functionality. It's essential to follow the suggested file size, resolution, and ratios to ensure the best display quality."
                    }
                  />
                </Tooltip>
              }
            >
              <img
                src={Iicon}
                alt="Iicon"
                style={{
                  width: "14px",
                  height: "14px",
                  cursor: "pointer",
                }}
              />
            </OverlayTrigger>
          </div>

          <Container
            style={{
              display: "flex",
              overflowX: "scroll",
              gap: "18px",
              // border: "2px solid red",
            }}
          >
            {mediaData?.map((item) => (
              <Col key={item?.id} lg={3} md={6} ml={1} className="mb-4">
                {projectFormDetails?.mediafileURLs?.[item?.name] ? (
                  <CardBoxFlexible>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      {item?.type === "3D" && (
                        <ModelViewer
                          url={projectFormDetails.mediafileURLs[item?.name]}
                          name={
                            projectFormDetails?.mediafileURLs[item.name]?.name
                          }
                        />
                      )}{" "}
                      {item?.type === "Video" && (
                        <div className="imgCont3">
                          <video
                            src={projectFormDetails.mediafileURLs[item?.name]}
                            controls
                            poster={
                              projectFormDetails.mediafileURLs[item?.name]
                            }
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                          <div className="overlayDiv2">
                            <img
                              height={"30px"}
                              src={imgIconWhite2}
                              alt="img"
                            />
                            <input
                              id={`file-upload1_${item.name}`}
                              hidden
                              onChange={async (e) => {
                                fileUploader(e, item.name, "Video");
                              }}
                              onClick={(e) => {
                                e.target.value = null;
                              }}
                              accept={item.accept}
                              type="file"
                            />
                            <TextBlack
                              className="fs-16 text-white"
                              onClick={() => {
                                document
                                  .getElementById(`file-upload1_${item.name}`)
                                  .click();
                              }}
                            >
                              Replace
                              {item.thumbName.split(" ").slice(1).join(" ")}
                            </TextBlack>
                            <img
                              onClick={() =>
                                dispatch(
                                  setFormDetails({
                                    mediafileURLs: {
                                      [item.name]: "",
                                    },
                                  })
                                )
                              }
                              className="mediaCross"
                              src={cross}
                              alt="x"
                            />
                          </div>
                        </div>
                      )}
                      {item?.type === "Image" && (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <div className="imgCont3">
                            <img
                              src={projectFormDetails.mediafileURLs[item?.name]}
                              style={{ width: "100%", height: "100%" }}
                              alt="img"
                            />
                            <div className="overlayDiv2">
                              <img
                                height={"30px"}
                                src={imgIconWhite2}
                                alt="img"
                              />
                              <input
                                id={`file-upload1_${item.name}`}
                                hidden
                                onChange={async (e) => {
                                  fileUploader(e, item.name, "Image");
                                }}
                                onClick={(e) => {
                                  e.target.value = null;
                                }}
                                type="file"
                                accept={item?.accept}
                              />
                              <TextBlack
                                className="fs-16 text-white"
                                onClick={() => {
                                  document
                                    .getElementById(`file-upload1_${item.name}`)
                                    .click();
                                }}
                              >
                                Replace{" "}
                                {item.thumbName.split(" ").slice(1).join(" ")}
                              </TextBlack>

                              <img
                                onClick={() =>
                                  dispatch(
                                    setFormDetails({
                                      mediafileURLs: {
                                        [item.name]: "",
                                      },
                                    })
                                  )
                                }
                                className="mediaCross"
                                src={cross}
                                alt="x"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </CardBoxFlexible>
                ) : (
                  <CardBoxUpload
                    item={item}
                    name={item.name}
                    accept={item.accept}
                    type={item.type}
                    
                  />
                )}
              </Col>
            ))}
          </Container>
        </div>
      </div>

      {/* Project Brief */}
      <div className="row my-2 ">
        <div>
          <CardDescriptionWrapper>
            <TextBlack className="fs-18 fw-600 mb-3">
              Project Brief <span style={{ color: "red" }}>*</span>
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {message}
              </span>
            </TextBlack>
            <TextArea
              id={"projectBrief"}
              setProjectForm={setProjectForm}
              value={projectFormDetails.projectBrief || ""} // Initialize value
              onChange={(e) => handleChange(e, "projectBrief")}
              setMessage={setMessage}
              message={message}
            />
          </CardDescriptionWrapper>
        </div>
      </div>

      {/* Project description */}
      <div className="row my-4">
        <div>
          <CardDescriptionWrapper>
            <TextBlack className="fs-18 fw-600 mb-3">
              Project Description <span style={{ color: "red" }}>*</span>
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {descriptionmessage}
              </span>
            </TextBlack>
            <TextArea
              id={"description"}
              setProjectForm={setProjectForm}
              value={projectFormDetails.description || "  "} // Initialize value
              onChange={(e) => handleChange(e, "description")}
              setdescriptionmessage={setdescriptionmessage}
              descriptionmessage={descriptionmessage}
            />
          </CardDescriptionWrapper>
        </div>
      </div>

      {/* Set HashTags */}
      <div className="row my-4">
        <div className="w-100">
          <TextBlack className="fs-18">
            Set Hashtags <span style={{ color: "red" }}>*</span>
          </TextBlack>
          <input
            onChange={(e) => handleChange(e, "hashtags")} // Handle input changes
            name="hashtags"
            value={hashtags}
            style={{
              height: "35px",
              width: "100%",
              paddingLeft: "8px",
              border: "none",
              outline: "none",
              background: "white",
              borderRadius: "8px",
            }}
          />
        </div>
      </div>

      {/* Type of experience offered */}
      <div className="my-4">
        <TextBlack className="fs-18">
          Type of Experince Offered <span style={{ color: "red" }}>*</span>
        </TextBlack>
        <div className="bg-white rounded-2 py-3 px-3">
          <TextBlack className="fs-16">
            Select at least one from the list
          </TextBlack>

          {experinces.map((item, index) => (
            <div key={item?.id} className="col-1">
              <Radio
                type="radio"
                name={item.name}
                value={item.value}
                checked={selectedExperience === item.value}
                onClick={() => {
                  setSelectedExperience(item.value);
                  dispatch(setFormDetails({ tag: item.value }));
                }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* NSDC CERTIFICATE */}

      {/* <div className="my-4"  >
        <div className="bg-white rounded-2 py-3 px-3">
          <div className="d-flex align-items-center justify-content-between pb-2 gap-2">
            <div className="d-flex align-items-center gap-2">
              <NsdcText>Publish Experience as NSDC Certified</NsdcText>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip" className="custom-tooltip">
                    <CaverInfoCard
                      width={"346px"}
                      text={
                        "Selecting this option will mark your app as NSDC certified. NSDC (National Skill Development Corporation) certification ensures that your app meets the high standards of quality and relevance set by the NSDC for skill development and vocational training."
                      }
                    />
                  </Tooltip>
                }
              >
                <img
                  src={Iicon}
                  alt="Iicon"
                  style={{
                    width: "14px",
                    height: "14px",
                    cursor: "pointer",
                  }}
                />
              </OverlayTrigger>
            </div>

            <ToggleSwitch
              isToggleChecked={isToggleChecked}
              onChange={handleToggleChange}
            />
          </div>
          {isToggleChecked && <NsdcCertificate />}
        </div>
      </div> */}

      {/* internet,age,language, */}
      <div className="justify-content-between row px-3 gap-4">
        {/* internet */}
        <div className="d-flex flex-column justify-content-center align-items-center bg-white rounded-2 col ">
          <div className="d-flex w-100 justify-content-between px-2">
            <label className="form-check-label ">
              <TextBlack>Internet Requirement</TextBlack>
            </label>
            <div className="col-2">
              <ToggleSwitch
                name="internetRequired"
                onChange={handleChange}
                onBlur={saveDraft}
                checked={projectFormDetails?.internetRequired || false}
              />
            </div>
          </div>
        </div>
        {/* suitable age */}
        <div className="bg-white rounded-2 p-4 col">
          <TextBlack>
            Suitable for ages <span style={{ color: "red" }}>*</span>
          </TextBlack>
          <SelectInput
            name="suitableForAge"
            options={ageGroups}
            onChange={handleChange}
            title={"Select age group"}
            value={projectFormDetails.suitableForAge || ""} // Initialize value
          />
        </div>
        {/* language support */}
        <div className="bg-white rounded-2 p-4 col">
          <TextBlack>
            Language Support <span style={{ color: "red" }}>*</span>
          </TextBlack>
          <div>
            <SelectInput
              onChange={handleChange}
              name="languageSupported"
              options={languages}
              title={"Select Languages"}
              value={projectFormDetails.languageSupported || ""} // Initialize value
            />
          </div>
        </div>
      </div>

      {/* description */}
      <div className="row mt-4">
        <div className="col-12">
          <CardDescriptionWrapper>
            <TextBlack className="fs-18 fw-600 mb-3">
              Content Disclaimer <span style={{ color: "red" }}>*</span>
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {disclaimermessage}
              </span>
            </TextBlack>
            <TextArea
              id={"contentDisclaimer"}
              value={projectFormDetails.contentDisclaimer || "  "} // Initialize value
              setProjectForm={setProjectForm}
              setdisclaimermessage={setdisclaimermessage}
              disclaimermessage={disclaimermessage}
            />
          </CardDescriptionWrapper>
        </div>
      </div>
    </div>
  );
};
