import { Protected } from "components/ProtectedRoute/Protected";
import { ReviewBox } from "components/ReviewBox/ReviewBox";
import { navBarData } from "contants/constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Register from "ui/auth/SignUp";
import { Sidebar } from "ui/sidebar/Sidebar";
import ImageCroper from "components/ImageCroper/ImageCroper";
import { publisher } from "redux/api";
import { Spinner } from "react-bootstrap";
import { setCookie } from "helper";
import { ToastContainer, toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";

export const PageRoutes = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState();

  const isLoggedIn =
    useSelector((state) => state.authReducer.isLoggedIn) || user?._id;

  useEffect(() => {
    if (document.getElementById("webpack-dev-server-client-overlay")) {
      document.getElementById(
        "webpack-dev-server-client-overlay"
      ).style.display = "none";
    }
  }, []);

  const handleTokens = async (tokens) => {
    setIsLoading(true);
    if (tokens?.token && tokens?.token !== "undefined") {
      localStorage.setItem("token", tokens?.token);
      localStorage.setItem("refreshToken", tokens?.refreshToken);
      localStorage.setItem("tokenExpired", "false");
      if (tokens.token) {
        {
          setIsLoading(false);
        }
        try {
          window.location.replace("/home");
          // const data = await publisher.checkUser();
          // if (data?.onboard) {
          //   setIsLoading(false);
          //   window.location.replace("/review");
          // } else if (!data?.data) {
          //   setIsLoading(false);
          //   window.location.replace("/onboarding");
          // } else if (data?.data) {
          //   setIsLoading(false);
          //   setCookie("publisherID", data?.publisherID, 1);
          //   window.location.replace("/home");
          // }
        } catch (e) {
          setIsLoading(false);
          // window.location.replace("/onboarding");
        }
      }
    }
  };

  // Is Loading Check
  // const isLoading = (keycloak) => {
  //   return (
  //     keycloak?.initialized && !keycloak?.authenticated && keycloak?.loginRequired
  //   );
  // };

  const CheckTokenProvider = ({ children }) => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    const refresh_token = query.get("refresh_token");
    useEffect(() => {
      if (token) {
        const tokens = {
          token: token,
          refreshToken: refresh_token,
        };

        handleTokens(tokens);
      }
      return () => {};
    }, [window.location.search]);

    return <>{children}</>;
  };

  const [containerId, setContainerId] = useState("toast_container");

  //  how to check toast trigger
  // toast.dismiss();

  return (
    <BrowserRouter>
      <CheckTokenProvider>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          containerId={containerId}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          limit={1}
        />
        {isLoading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
            className="loader d-flex justify-content-center align-items-center"
          >
            <Spinner size="lg" animation="border" />
          </div>
        ) : (
          <Routes>
            <Route path="/*" element={<Sidebar data={navBarData} />} />
            <Route
              path="/"
              element={
                <Protected path={"login"}>
                  <ImageCroper />
                </Protected>
              }
            />
            <Route
              path="/review"
              element={
                <Protected>
                  <ReviewBox />
                </Protected>
              }
            />
            {/* <Route
              path="/onboarding"
              element={
                isLoggedIn ? (
                  <Navigate to="/onboarding" />
                ) : (
                  <Register type="signup" />
                )
              }
            /> */}
          </Routes>
        )}
      </CheckTokenProvider>
    </BrowserRouter>
  );
};
