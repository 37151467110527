import { ButtonPrimary } from "components/styled/common.styled";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function generateDownload(canvas, crop, handleChange, name, id) {
  if (!crop || !canvas) {
    return;
  }

  function compressCanvas(quality, maxAttempts, currentAttempt = 1) {
    canvas.toBlob(
      (blob) => {
        console.log(
          `Attempt ${currentAttempt}: Size = ${
            blob.size / 1024 / 1024
          } MB, Quality = ${quality}`
        );

        if (
          blob.size > 5000000 &&
          currentAttempt < maxAttempts &&
          quality > 0.1
        ) {
          compressCanvas(quality - 0.1, maxAttempts, currentAttempt + 1);
        } else {
          const blobFile = new Blob([blob], { type: "image/jpeg" });
          const fileName = name;
          const file = new File([blobFile], `${fileName}.jpeg`, {
            type: "image/jpeg",
          });
          const formatFile = {
            target: {
              name: id,
              files: [file],
            },
          };

          handleChange(formatFile, "Image");
        }
      },
      "image/jpeg",
      quality
    );
  }

  compressCanvas(1, 10);
}

function setCanvasImage(image, canvas, crop) {
  if (!crop || !canvas || !image) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext("2d");
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "low";

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );
}

const ImageCroper = ({
  file,
  expectedAspectRatio,
  handleFileChange,
  id,
  name,
  isLoading,
  setIsLoading,
  croperImgwidth,
  croperImgheight,
}) => {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [initialCrop, setInitialCrop] = useState(null);

  const fileExtension = file ? file.name.split(".").pop() : "png";

  const [crop, setCrop] = useState({
    unit: "px",
    aspect: expectedAspectRatio,
    width: croperImgwidth || 1920,
  });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback(
    (img) => {
      imgRef.current = img;
      const imageWidth = img.width;
      const imageHeight = img.height;
      const imageAspectRatio = imageWidth / imageHeight;

      let cropWidth, cropHeight;

      if (imageAspectRatio > expectedAspectRatio) {
        cropHeight = imageHeight;
        cropWidth = cropHeight * expectedAspectRatio;
      } else {
        cropWidth = imageWidth;
        cropHeight = cropWidth / expectedAspectRatio;
      }

      const initialCrop = {
        unit: "px",
        width: cropWidth,
        height: cropHeight,
        x: (imageWidth - cropWidth) / 2,
        y: (imageHeight - cropHeight) / 2,
        aspect: expectedAspectRatio,
      };

      setCrop(initialCrop);
      setInitialCrop(initialCrop);
    },
    [expectedAspectRatio]
  );

  const onChange = (newCrop) => {
    if (initialCrop) {
      setCrop((prevCrop) => ({
        ...prevCrop,
        x: newCrop.x,
        y: newCrop.y,
      }));
    }
  };

  useEffect(() => {
    if (imgRef.current && crop.width && crop.height) {
      setCompletedCrop(crop);
    }
  }, [crop]);

  useEffect(() => {
    setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(file);
    }
  }, [completedCrop, file]);

  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={onChange}
        onComplete={(c) => setCompletedCrop(c)}
        style={{
          maxHeight: "70vh",
          overflowY: "scroll",
          maxWidth: "100%",
        }}
        // Allow movement but control resizing manually
        disabled={false}
      />
      <div>
        <canvas
          ref={previewCanvasRef}
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
            display: "none",
          }}
        />
      </div>
      <ButtonPrimary
        className="mt-3"
        type="button"
        disabled={!completedCrop?.width || !completedCrop?.height}
        onClick={() =>
          generateDownload(
            previewCanvasRef.current,
            completedCrop,
            handleFileChange,
            name,
            id
          )
        }
      >
        {isLoading ? <Spinner /> : "Save Image"}
      </ButtonPrimary>
    </div>
  );
};

export default ImageCroper;
