// sidebar icons
import publishedApps from "assets/publishedApps.svg";
import review from "assets/review.svg";
import analytics from "assets/analytics.svg";
import settings from "assets/settings.svg";
import help from "assets/help.svg";
import payments from "assets/payments.svg";
import notification from "assets/notification.svg";
import profile from "assets/profile.svg";
import cloud from "assets/cloud.svg";
import logout from "assets/logout.svg";

//  projects icons

import box from "assets/box.svg";
import add from "assets/add.svg";
import { loginconfig } from "ui/auth/Config";

export const navBarData = [
  {
    id: 1,
    name: "Projects",
    icon: publishedApps,
    link: "/home",
  },
  {
    id: 2,
    name: "Projects under review",
    icon: review,
    link: "/projects_reviews",
  },
  {
    id: 3,
    name: "Analytics ",
    icon: analytics,
    link: "/analytics",
  },
  {
    id: 4,
    name: "Settings ",
    icon: settings,
    link: "/settings",
  },
  {
    id: 5,
    name: "Help",
    icon: help,
    link: `https://form.asana.com/?k=9v3P2VjEg2wpx2BK0hVVRA&d=1182542368366499`,
  },
  {
    id: 7,
    name: "Payments",
    icon: payments,
    link: "/payment",
  },
  {
    id: 8,
    name: "Notification",
    icon: notification,
    link: "/notification",
  },
  {
    id: 9,
    name: "Profile",
    link: `${loginconfig.url}?clientId=${loginconfig.clientId}`,
    icon: profile,
  },
  {
    id: 10,
    name: "Log out",
    icon: logout,
    // link:"/#",
    isLogout: true,
  },
  {
    id: 11,
    name: "Storage",
    link: "/storage",
    icon: cloud,
  },
];

// project mock data for testing

export const projectData = [
  {
    id: 1,
    name: "",
    thumbnail: add,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
  },
  {
    id: 2,
    name: "Project 1",
    thumbnail: box,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
  },
  {
    id: 3,
    name: "Project 2",
    thumbnail: box,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
  },
  {
    id: 4,
    name: "Project 3",
    thumbnail: box,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
  },
];

// market place mock data for testing

export const marketPlaceData = [
  {
    id: 1,
    name: "Get SOPs for your next project",
    thumbnail: "https://dev.ajnavidya.com/ajnavidya/unsplash_vs2t8EVr8nM.png",
    description: "Explore About 500+ SOPs",
  },
  {
    id: 2,
    name: "Beautiful Visual Assets",
    thumbnail:
      "https://dev.ajnavidya.com/ajnavidya/pngtree-wavy-3d-visual-assets-and-blue-gradations-png-image_2323441.jpg",
    description: "Beautiful Visual Assets for your next project",
  },
  {
    id: 3,
    name: "Market Place",
    thumbnail: "https://dev.ajnavidya.com/ajnavidya/FwfoupuXoAAafOO.jpg",

    description: "Explore market place for more assets",
  },
];

// Guide to Ajna Developer Suite (ADS)

export const tutorialData = [
  {
    id: 1,
    name: "What is ADS and How to Use it?",
    thumbnail: "https://dev.ajnavidya.com/ajnavidya/unsplash_tMI2_-r5Nfo.png",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
  },
  {
    id: 2,
    name: "What are SOPs and Why do you need them?",
    thumbnail: "https://dev.ajnavidya.com/ajnavidya/unsplash_vs2t8EVr8nM.png",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
  },
  {
    id: 3,
    name: "Learn more  on HOW To Create Experiences.",
    thumbnail: "https://dev.ajnavidya.com/ajnavidya/unsplash_vs2t8EVr8nM.png",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
  },
  {
    id: 4,
    name: "Market Place",
    thumbnail: "https://dev.ajnavidya.com/ajnavidya/unsplash_vs2t8EVr8nM.png",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
  },
  {
    id: 5,
    name: "What is ADS and How to Use it?",
    thumbnail: "https://dev.ajnavidya.com/ajnavidya/unsplash_tMI2_-r5Nfo.png",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
  },
];

// download mock data for testing

export const DownloadHeaders = [
  {
    id: 1,
    key: "name",
    name: "Item Name",
  },
  {
    id: 2,
    key: "date",
    name: "Date & Time",
  },
  {
    id: 3,
    key: "price",
    name: "Price",
  },
  {
    id: 4,
    key: "seats",
    name: "No. of Seats",
  },
  {
    id: 5,
    key: "used",
    name: "Used in Project",
  },
  {
    id: 6,
    key: "remaining",
    name: "No. of  Seats remaining ",
  },
];

export const downloadData = [
  {
    id: 1,
    name: "Item 1",
    date: "12/12/2020",
    price: "100",
    seats: "10",
    project: "Project 1",
    seatsRemaining: "5",
  },
  {
    id: 2,
    name: "Item 2",
    date: "17/03/2023",
    price: "100",
    seats: "10",
    project: "Project 1",
    seatsRemaining: "5",
  },
  {
    id: 1,
    name: "Item 1",
    date: "12/12/2020",
    price: "100",
    seats: "10",
    project: "Project 1",
    seatsRemaining: "5",
  },
  {
    id: 2,
    name: "Item 2",
    date: "12/12/2020",
    price: "100",
    seats: "10",
    project: "Project 1",
    seatsRemaining: "5",
  },
  {
    id: 1,
    name: "Item 1",
    date: "12/12/2020",
    price: "100",
    seats: "10",
    project: "Project 1",
    seatsRemaining: "5",
  },
  {
    id: 2,
    name: "Item 2",
    date: "12/12/2020",
    price: "100",
    seats: "10",
    project: "Project 1",
    seatsRemaining: "5",
  },
];

export const CategoryData = [
  { id: 1, name: "All" },
  { id: 2, name: "XR development" },
  { id: 3, name: "Unity" },
  { id: 4, name: "Unreal Engine" },
  { id: 5, name: "Performance optimization" },
  { id: 6, name: "User experience (UX)" },
];

// comment data
export const commentData = [
  {
    id: 1,
    name: "Ajinkya Ranade",
    profilePicture: "https://cdn-icons-png.flaticon.com/128/3011/3011270.png",
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum iure quis quod eius enim officia in provident ipsam voluptate nemo quam modi doloremque, repudiandae delectus veniam ad eaque deserunt inventore. quis quod eius enim officia in provident ipsam voluptate nemo quam modi.",
    likes: "21K",
    dislikes: "101K",
    time: "3 Day ago",
  },
  {
    id: 2,
    name: "Mrunal Thakur",
    profilePicture: "https://cdn-icons-png.flaticon.com/128/3011/3011270.png",
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum iure quis quod eius enim officia in provident ipsam voluptate nemo quam modi doloremque, repudiandae delectus veniam ad eaque deserunt inventore. quis quod eius enim officia in.",
    likes: "5K",
    dislikes: "159",
    time: "1 Day ago",
  },
];

export const languages = [
  { value: "", name: "Select Languages" },
  { value: "all", name: "All" },
  { value: "english", name: "English" },
  { value: "hindi", name: "Hindi" },
];

export const ageGroups = [
  { value: "", name: "Select Age Group" },
  { value: "all", name: "All ages" },
  { value: "13+", name: "13+" },
  { value: "18+", name: "18+" },
];

// project details data

export const projectDetails = [
  {
    id: 1,
    name: "Production",
    build: "V.1",
    date: "31/05/19",
    download: 2000,
    earnings: "₹ 50,00,0000",
  },
  {
    id: 2,
    name: "Beta",
    build: "-",
    date: "-",
    download: "-",
    earnings: "-",
  },
  {
    id: 3,
    name: "Alpha",
    build: "-",
    date: "-",
    download: "-",
    earnings: "-",
  },
  {
    id: 4,
    name: "Test",
    build: "-",
    date: "-",
    download: "-",
    earnings: "-",
  },
];

// ProjectMetadata

export const ChannelUsersData = [
  {
    id: 1,
    name: "Name",
    email: "UserName",
    inviteStatus: "accepted",
  },
  {
    id: 2,
    name: "Name",
    email: "UserName",
    inviteStatus: "sent",
  },
  {
    id: 3,
    name: "Name",
    email: "UserName",
    inviteStatus: "failed",
  },
  {
    id: 4,
    name: "Name",
    email: "UserName",
    inviteStatus: "accepted",
  },
  {
    id: 5,
    name: "Name",
    email: "UserName",
    inviteStatus: "sent",
  },
];

export const InviteStatus = [
  {
    id: 1,
    inviteStatus: "Accepted",
  },
  {
    id: 2,
    inviteStatus: "Sent",
  },
  {
    id: 3,
    inviteStatus: "Failed",
  },
];

export const LangData = [
  {
    id: 1,
    name: "English",
  },
  {
    id: 2,
    name: "Hindi",
  },
];

export const Duration = [
  { value: 1, label: "1 Month" },
  { value: 3, label: "3 Months" },
  { value: 6, label: "6 Months" },
  { value: 12, label: "12 Months" },
];

export const stateData = [
  {
    id: 1,
    name: "Maharashtra",
  },
  {
    id: 2,
    name: "Goa",
  },
  {
    id: 1,
    name: "Karnataka",
  },
];

export const orgTypeData = [
  {
    id: 2,
    name: "Public",
  },
  {
    id: 3,
    name: "Private",
  },
];

export const orgSizeData = [
  {
    id: 1,
    name: "10-50",
  },
  {
    id: 2,
    name: "50-200",
  },
  {
    id: 3,
    name: "201-500",
  },
  {
    id: 4,
    name: "500-1000",
  },
  {
    id: 5,
    name: "1000+",
  },
];
