import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

const StyledBreadcrumb = styled(Breadcrumb.Item)`
  &:not(:first-child):before {
    content: ">" !important;
  }
`;

export default function CustomBreadcrumbs({ index, page, link }) {
  const navigate = useNavigate();
  return (
    <Breadcrumb>
      <StyledBreadcrumb
        className="fs-18 cursor-pointer"
        active
        onClick={() => {
          navigate(`/${link}`);
        }}
      >
        {index}
      </StyledBreadcrumb>
      <StyledBreadcrumb className="fs-18">{page}</StyledBreadcrumb>
    </Breadcrumb>
  );
}
