import styled from "styled-components";

export const TableContainer = styled.div`
  border-radius: 8px;
  padding: 0px 0px;
  // border: 1px solid ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.black};
  z-index: 10;
  overflow: hidden;
  & table thead tr th {
    font-size: 14px;
    width: 16%;
    font-weight: 400;
    height: 50px;
    padding: 0px 15px;
    color: ${({ theme }) => theme.lightText};
    background: ${({ theme }) => theme.border};
  }
  & table {
    width: 100%;
  }
  & p {
    font-size: 14px;
    color: ${({ theme }) => theme.tableText};
    margin-bottom: 0px;
    margin-right: 10px;
    cursor: pointer;
  }
  & table tbody tr td {
    font-size: 14px;
    color: ${({ theme }) => theme.tableText};
    line-height: 29px;
    padding: 5px 15px;
    font-weight: 400;
  }
  & table tbody {
    background: #fff;
  }
  & table tbody tr {
    cursor: pointer;
  }
  & table tbody tr:hover {
    background: ${({ theme }) => theme.tableHover};
  }
`;
