import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import { setFormDetails } from "redux/Project/project.action";
import { CustomAlertPopup } from "components/CustomAlert";
import { variants } from "components/CustomAlert/constant";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";

export const TextArea = ({
  id,
  value,
  setMessage,
  setdescriptionmessage,
  setdisclaimermessage,
}) => {
  const [docURL, setDocURL] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const quill = useRef();
  const selectedVariant = variants[0];
  const dispatch = useDispatch();
  const { projectFormDetails } = useSelector((state) => state.projectReducer);
  // Initialize the editor content with data from Redux
  useEffect(() => {
    if (projectFormDetails[id]) {
      setDocURL(projectFormDetails[id] || " ");
      setIsLoaded(true);
    } else {
      setTimeout(() => {
        setIsLoaded(true);
      }, 2000);
    }
  }, [projectFormDetails, id]);

  const trimContent = (content, limit) => {
    if (!limit) return content;
    if (content.length > limit) {
      return content.substring(0, limit);
    } else {
      return content;
    }
  };
  const handleEditorChange = (content) => {
    if (id === "projectBrief") {
      const trimmedContent = trimContent(content, 250);
      // Update the message based on whether the limit is exceeded
      setMessage(
        content.length > 250
          ? "Text should not be more than 250 characters."
          : ""
      );
      setDocURL(trimmedContent);
      dispatch(setFormDetails({ [id]: trimmedContent }));
    }
    if (id === "description") {
      const trimmedContent = trimContent(content, 2500);
      setdescriptionmessage(
        content.length > 2500
          ? "Text should not be more than 2500 characters."
          : ""
      );
      setDocURL(content);
      dispatch(setFormDetails({ [id]: trimmedContent }));
    }
    if (id === "contentDisclaimer") {
      const trimmedContent = trimContent(content, 1000);
      setdisclaimermessage(
        content.length > 1000
          ? "Text should not be more than 1000 characters."
          : ""
      );
      setDocURL(content);
      dispatch(setFormDetails({ [id]: content }));
    } else {
      setDocURL(content);
      dispatch(setFormDetails({ [id]: content }));
    }
  };

  const [modal, setModal] = useState({
    modal: false,
    text: "",
  });

  useEffect(() => {
    if (modal.modal) {
      setTimeout(() => {
        toast.info("");
      }, 2000);
    }
  }, [modal]);

  //formatter
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "clean",
  ];

  // modules
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [2, 3, 4, false] }],
        ["bold", "italic", "underline", "blockquote"],
        [{ color: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link"],
        ["clean"],
      ],
    },
  }));

  return (
    <div className="wrapper" style={{ marginTop: "-5px" }}>
      {isLoaded && (
        <QuillEditor
          className="editor"
          theme="snow"
          // ref={(el) => (quill.current = el)}
          formats={formats}
          defaultValue={docURL}
          modules={modules}
          onChange={(value) => handleEditorChange(value)}
        />
      )}

      {/* <Editor
        onInit={(evt, editor) => (ref.current = editor)}
        value={docURL}
        apiKey="22u5tkxby39z7l6o2f67itvtlnzj49zlgo0ptcolzs711173"
        id={id}
        max_chars={4000}
        onBeforePaste={(e) => {
          const text = e.clipboardData.getData("text/plain");
          if (text.length > 4000) {
            e.preventDefault();
            toast.error("Text is too long");
          }
        }}
        onPaste={(e) => {
          const text = e.clipboardData.getData("text/plain");
          if (text.length > 4000) {
            e.preventDefault();
            toast.error("Text is too long");
          }
        }}
        onEditorChange={(e, editor) => handleEditorChange(editor.getContent())}
        // onChange={(e) => dispatch(projectAction.setFormDetails({ [id]: ref.current.getContent() }))}
        // onChange={() => saveDocument()}
        init={{
          selector: "textarea",
          height: 300,
          width: "100%",
          branding: false,
          menu: {
            file: {
              title: "File",
              items:
                "newdocument restoredraft | preview | export print | deleteallconversations",
            },
            edit: {
              title: "Edit",
              items:
                "undo redo | cut copy paste pastetext | selectall | searchreplace",
            },
            view: {
              title: "View",
              items:
                "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments",
            },
            insert: {
              title: "Insert",
              items:
                "image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime",
            },
            format: {
              title: "Format",
              items:
                "bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat",
            },
            tools: {
              title: "Tools",
              items:
                "spellchecker spellcheckerlanguage | a11ycheck code wordcount",
            },
            table: {
              title: "Table",
              items:
                "inserttable | cell row column | advtablesort | tableprops deletetable",
            },
            help: { title: "Help", items: "help" },
          },
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "powerpaste",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
            "editimage",
          ],
          toolbar: `undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify |
          bullist numlist outdent indent | link image | print preview media |  "removeformat | forecolor backcolor emoticons | 
          help`,
          file_picker_types: "image",
          //   images_upload_handler: imgUploadHandler,
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      /> */}
    </div>
  );
};
