import { InfoMainCard, InfoText } from "components/styled/common.styled";
import React from "react";

const CaverInfoCard = ({ text, width }) => {
  return (
    <InfoMainCard width={width}>
      <InfoText>{text}</InfoText>
    </InfoMainCard>
  );
};

export default CaverInfoCard;
