import React, { useEffect, useState } from "react";
import {
  CardContainer,
  KeyTxt,
  TextBtn,
  TextBtnCont,
} from "components/styled/ProjectDetails.style";
import editImg from "assets/edit-2.svg";
import Del from "assets/Del.svg";
import { KeyValue } from "components/styled/projectreview.styled";
import { useDispatch, useSelector } from "react-redux";
import { getDateByUpdate } from "helper";
import { deleteProjectCore } from "redux/Project/project.action";
import { CustomAlertPopup } from "components/CustomAlert";
import { buttonConfigs, variants } from "components/CustomAlert/constant";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EditBtn } from "components/EditButton/EditBtn";
import { toast } from "react-toastify";
import SkeltonProjectDetails from "ui/NewProject/SkeltonProjectDetails";

export const ProjectInfo = ({ editIcon, delICon, data }) => {
  const navigate = useNavigate();
  const [singleProject, setSingleProject] = React.useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const urlProjectId = searchParams.get("projectId");
  const { projectId, appID, id } = useParams();
  let projectIdNew = projectId || id || urlProjectId;
  const dispatch = useDispatch();

  const [loadSIngleProject, setLoadSingleProject] = useState(true);

  const projectSingle = useSelector(
    (state) => state.projectReducer.singleProjectDetails
  );

  const [variant, setVariant] = useState(1);
  const selectedVariant = variants[variant];

  const [modal, setModal] = useState({
    modal: false,
    text: "",
    projectId: "",
  });

  const deleteProject = () => {
    const projectId = modal.projectId;
    dispatch(deleteProjectCore(projectId))
      .then((response) => {
        if (response.error) {
          toast.error(response.error);
        } else {
          toast.success("Deleted Successfully.");
          navigate("/home");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    if (projectSingle?.logoURL && !data) {
      setSingleProject(projectSingle);
    } else {
      setSingleProject(data);
    }
  }, [projectSingle, data]);

  return (
    <div>
      {modal && (
        <div className="flex-center w-75">
          <CustomAlertPopup
            modal={modal}
            setModal={setModal}
            selectedVariant={selectedVariant}
            text={modal.text}
            buttonType={
              selectedVariant.title === "Warning" ? buttonConfigs.YesNo : null
            }
            sendTo={"/"}
            triggerEvent={() => deleteProject()}
            titleText="Delete Project"
          />
        </div>
      )}
      {!singleProject ? (
        <SkeltonProjectDetails />
      ) : (
        <>
          {" "}
          {singleProject?.projectName && (
            <CardContainer>
              <div className="imgCont">
                {singleProject?.logoURL && (
                  <img
                    src={singleProject?.logoURL}
                    alt="img"
                    onError={(e) =>
                      (e.target.src =
                        "https://cdn.ajnavidya.com/ajnavidya/no%20thumbnail.png")
                    }
                  />
                )}
              </div>

              <TextBtnCont>
                <TextBtn>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        fontSize: "24px",
                        textTransform: "capitalize",
                        color: "#161719",
                        fontWeight: "600",
                        marginTop: "-6px",
                      }}
                    >
                      <>{singleProject?.projectName}</>
                    </p>

                    <KeyValue style={{ marginTop: "-20px" }}>
                      <KeyTxt>Sector &nbsp;- &nbsp;</KeyTxt>
                      <div style={{ fontWeight: 300, padding: "4px" }}>
                        {singleProject?.sector}
                      </div>
                    </KeyValue>
                    <KeyValue>
                      <KeyTxt> Sub-sector&nbsp;- &nbsp;</KeyTxt>
                      <div style={{ fontWeight: 300, padding: "4px" }}>
                        {singleProject?.subSector}
                      </div>
                    </KeyValue>
                    <KeyValue>
                      <KeyTxt>{"Project Id"} &nbsp;- &nbsp;</KeyTxt>
                      <div style={{ fontWeight: 300, padding: "4px" }}>
                        ...{projectIdNew?.slice(-6)}
                      </div>
                    </KeyValue>

                    {appID && (
                      <KeyValue>
                        <KeyTxt>{"App Id"} &nbsp;- &nbsp;</KeyTxt>
                        <div style={{ fontWeight: 300 }}>
                          {appID?.slice(-6)}
                        </div>
                      </KeyValue>
                    )}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "8px",
                    }}
                  >
                    {appID && editIcon && (
                      <EditBtn>
                        <img src={editImg} alt="edit" />
                        Edit
                      </EditBtn>
                    )}
                    {delICon && (
                      <div>
                        <div
                          onClick={() => {
                            setVariant(3);
                            setModal({
                              modal: true,
                              text: "Are you sure you want to delete this Project?",
                              projectId: singleProject?._id,
                            });
                          }}
                          className="d-flex gap-2 px-3 py-2"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            background: "#fff",
                            borderRadius: "3rem",
                            cursor: "pointer",
                          }}
                        >
                          <img src={Del} alt="" />
                          <div>Delete</div>
                        </div>
                      </div>
                    )}
                  </div>
                </TextBtn>
                <div style={{ fontWeight: 300 }}>
                  <KeyValue
                    style={{
                      marginTop: "0.6rem",
                      borderTop: "1px solid #D9D9D9",
                    }}
                  >
                    <div>Last Edited On &nbsp; - &nbsp;</div>
                    <div>{getDateByUpdate(singleProject?.updatedAt)}</div>
                  </KeyValue>
                </div>
              </TextBtnCont>
            </CardContainer>
          )}
        </>
      )}
    </div>
  );
};
