import { CustomTabs2 } from "components/Tabs/CustomTabs2";
import { CustomPane } from "components/Tabs/customTabs.styled";
import { SettingCard } from "components/styled/settings.style";
import React from "react";
import PrivacyGuideLine from "./PrivacyGuideLine";
import Security from "./Security";
import CustomBreadcrumb from "components/CustomBreadScrum";

const Settings = () => {
  const breadcrumbItems = [{ text: "Settings", link: "/settings" }];
  return (
    <>
      <div className="mx-4">
        <CustomBreadcrumb items={breadcrumbItems} />
      </div>
      <div className="row mx-4">
        <SettingCard className="col-10">
          <div className="mx-3 px-0">
            <CustomTabs2
              tabTitles={["Privacy Guidelines", "Security"]}
              tabs={""}
              page={[""]}
              type={"plane"}>
              <CustomPane eventKey="Privacy Guidelines">
                <PrivacyGuideLine />
              </CustomPane>
              <CustomPane eventKey="Security">
                <Security />
              </CustomPane>
            </CustomTabs2>
          </div>
        </SettingCard>
      </div>
    </>
  );
};

export default Settings;
