import { styled } from 'styled-components';

export const SettingCard = styled.div`
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
  padding: 1rem 0rem;
`;

export const TurnOnbtn = styled.button`
  display: flex;
  padding: 4px 9px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 7px;
  background: #eeffed;
  outline: none;
  border: none;
`;
