import { styled } from "styled-components";

export const NotificationCont = styled.div`
  padding: 1rem;
  padding-top: 0rem;
  margin: 0rem 0.5rem;
  &.settingCont {
    height: 418px;
    padding: 0rem;
  }
`;
export const NotificationCard = styled.div`
  display: flex;
  padding: 0px 30px 44px 20px;
  flex-direction: column;
  border-radius: 8px;
  background: var(--dark-mode-text-11, #fff);
  padding: 1rem;
  width: 97%;
  height: 77vh;
`;
export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color-border-2, #ebebeb);
  padding-right: 1rem;
  padding-bottom: 1rem;
`;
export const BoxCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  min-height: 100px;
  position: relative;
`;
export const SingleBox = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color-border-2, #ebebeb);
  padding: 1rem;
  align-items: flex-start;
  &:nth-child(2) {
    border-bottom: none;
  }
  &:nth-child(4) {
    border-bottom: none;
    margin-top: 1rem;
    opacity: 0.5;
  }
  &.settings {
    border-bottom: 1px solid var(--border-color-border-2, #ebebeb);
    padding: 0.5rem;
    height: 58px;
    display: flex;
    align-items: center;
  }
`;

export const MarkAsreadAll = styled.div`
  color: var(--text-color-text-6, #898d93);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
`;

export const MarkTitle = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Time = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const MarkLine = styled.div`
  color: var(--text-color-text-6, #898d93);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
`;
