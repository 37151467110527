import { Nav, Tab } from "react-bootstrap";
import { styled } from "styled-components";

export const CustomPane = styled(Tab.Pane)`
  display: flex;
  background-color: ${(props) => props.theme.background};
`;

export const CustomNav = styled(Nav)`
  width: fit-content;
  border-radius: 50px;
  border: 1px solid #efefef;
  background: #fff;
  overflow: hidden;
`;

export const CustomNavLink = styled(Nav.Link)`
  color: #333437 !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 120px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomNavPlane = styled(Nav)`
  width: 100%;
  background: #transparent;
  overflow: hidden;
  border-bottom: 1px solid #e6e6e6;
`;

export const CustomNavLink2 = styled(Nav.Link)`
  color: #333437 !important;
  font-weight: 500;
  font-size: 16px;
  width: auto;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
