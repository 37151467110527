import { SidePanel } from "components/Sidebar/SidePanel";
import { ButtonPrimary } from "components/styled/common.styled";
import React, { useEffect } from "react";
import { ProgressBar, Spinner } from "react-bootstrap";
import {
  Link,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Home } from "ui/home/Home";
import { Profile } from "ui/profile/Profile";
import { NewProject } from "ui/NewProject/NewProject";
import { SelectChannel } from "ui/NewProject/SelectChannel";
import Payment from "components/Payment";
import AddAccount from "components/Payment/AddAccount";
import logo from "assets/logo.svg";
import ProjectUnderReview from "components/ProjectUnderReview";
import { Analytics } from "ui/analytics/Analytics";
import { ProjectRating } from "ui/rating/ProjectRating";
import { SetPrice } from "ui/SetPrice/SetPrice";
import Notification from "ui/notification/Notification";

import ProjectDetails from "ui/NewProject/ProjectDetails";
import { ChannelUsers } from "components/Table/ChannelUsers";
import Settings from "ui/settings";
import { useKeycloak } from "@react-keycloak/web";
import add from "assets/whitePlus.svg";
import { DetailedAnalytics } from "ui/analytics/DetailedAnalytics";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllowedStorage,
  getAllowedStorageProjectwise,
  resetState,
} from "redux/Project/project.action";
import ReviewStatus from "components/ProjectUnderReview/ReviewStatus";
import { Search } from "components/search/Search";
import { TermsOfUse } from "components/termsOfUse/TermsOfUse";
import { useState } from "react";
import { EraseAllCookies, EraseCookies, getCookie, getUserToken } from "helper";
import { Cart } from "ui/cart/Cart";
import { PurchaseComp } from "components/Purchase/PurchaseComp";
import { MyPurchase } from "ui/myPurchase/MyPurchase";
import { PurchaseDetails } from "ui/myPurchase/PurchaseDetails";
import { Storage } from "ui/BuyStorage/Storage";
import { buttonConfigs, variants } from "components/CustomAlert/constant";
import { CustomAlertPopup } from "components/CustomAlert";
import axios from "axios";
import { loginconfig } from "ui/auth/Config";
import { LoadingSpinner } from "ui/analytics/LoadingSpinner";

export const Sidebar = ({ data }) => {
  const token = !!localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarLoading, setIsSidebarLoading] = useState(null);
  const hideSidebar = false;
  const decodedToken = getUserToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { storageData } = useSelector((data) => data?.projectReducer);
  const [variant, setVariant] = useState(0);
  const selectedVariant = variants[variant];
  const [modal, setModal] = useState({
    modal: false,
    text: "",
  });

  const [tabs, setTabs] = useState();

  const logoutHandler = () => {
    try {
      window.location.replace(
        `${loginconfig.url}/logout?redirect=${loginconfig.redirect}&clientId=${loginconfig.clientId}`
      );
      localStorage.clear();
      EraseAllCookies();
    } catch (err) {
      localStorage.clear();
      EraseAllCookies();
      window.location.href = "/";
    }
  };

  const usedDataInNum = +storageData?.totalUsed;
  const percentageUsed = usedDataInNum ? (usedDataInNum / 30) * 100 : 0;
  useEffect(() => {
    setTimeout(() => {
      const userID = decodedToken?.sub;
      if (!userID) return;
      dispatch(getAllowedStorageProjectwise());
    }, 1000);
  }, [location.pathname, location]);

  useEffect(() => {
    setIsSidebarLoading(true);
    const fetchSidebar = async () => {
      try {
        const response = await axios.post(
          `${loginconfig.apiUrl}/api/v2/user/getTabs?type=single&clientID=${loginconfig.clientId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setIsSidebarLoading(false);
        setTabs(response?.data?.data);
      } catch (error) {
        setIsSidebarLoading(false);
      }
    };
    fetchSidebar();
  }, [token]);

  useEffect(() => {
    if (token) {
      setIsLoading(false);
    }
  }, [token]);

  if (tabs?.length === 0 && isSidebarLoading === false) {
    return (
      <div
        style={{
          height: "100vh",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <h1 className="text-uppercase">You don't have any tabs access</h1>
      </div>
    );
  }
  if (isSidebarLoading === null) {
    return null;
  }

  if (isSidebarLoading) {
    return (
      <div
        style={{
          height: "100vh",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      {tabs?.length > 0 &&
        (isLoading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
            className="loader d-flex justify-content-center align-items-center"
          >
            <Spinner size="lg" animation="border" />
          </div>
        ) : (
          <>
            {modal && (
              <div
                style={{
                  marginTop: "-0.5rem",
                  marginLeft: "0rem",
                  top: "20px",
                }}
              >
                <CustomAlertPopup
                  modal={modal}
                  setModal={setModal}
                  selectedVariant={selectedVariant}
                  text={modal.text}
                  buttonType={buttonConfigs.YesNo}
                  triggerEvent={logoutHandler}
                  titleText="Logout"
                />
              </div>
            )}
            <div
              className="d-flex px-3 py-3 mb-3 glass-effect"
              style={{ background: "#fff", top: "0" }}
            >
              <div
                className="d-flex w-100 justify-content-between mx-2"
                style={{ alignItems: "center" }}
              >
                <div style={{ height: "34px" }}>
                  <Link to={"/home"}>
                    <img src={logo} height={"100%"} alt="logo" />
                  </Link>
                </div>
                <div>
                  <Search />
                </div>
                {/* {window.location.href.includes("/createProject") ? "/createProject/production" || "/createProject/newProject/alpha"} */}
                <ButtonPrimary
                  width="180px"
                  height="45px"
                  onClick={() => {
                    dispatch(resetState());
                    navigate("/createProject");
                  }}
                  style={{
                    visibility: window.location.href.includes("/createProject")
                      ? "hidden"
                      : "visible",
                  }}
                >
                  <img src={add} alt="add" /> Create Project
                </ButtonPrimary>
              </div>
            </div>
            <div className="d-flex px-4">
              <div className={`${hideSidebar ? "col-w-6" : "col-2-5"}`}>
                <SidePanel>
                  <div className="px-3 pt-3">
                    <div className="min-height-sidebar">
                      {tabs?.map((item, itemIndex) => (
                        <NavLink
                          to={
                            item.tabName.includes("Log out")
                              ? null
                              : item?.tabUrl
                          }
                          className={`sidebar__link ${
                            item.tabName.includes("Log out")
                              ? "exclude-active-styles"
                              : ""
                          }`}
                          key={item.id}
                          target={
                            item.tabName.includes("Help") ||
                            item.tabName.includes("Profile")
                              ? "_blank"
                              : "_self"
                          }
                        >
                          <div
                            className={`${
                              itemIndex + 1 === 3 || itemIndex + 1 === 7
                                ? "border-bottom border-2"
                                : ""
                            }`}
                          >
                            <div
                              style={{ color: "#000" }}
                              onClick={() => {
                                if (item.tabName.includes("Log out")) {
                                  setModal({
                                    modal: true,
                                    text: "Are you sure you want to logout?",
                                  });
                                }
                              }}
                              role="button"
                              tabIndex="0"
                              className={`${
                                hideSidebar && itemIndex + 1 !== 11
                                  ? "wrapper__sidebar_hide"
                                  : (!item?.tabName?.includes("Storage") &&
                                      "sidebar__link_wrapper d-flex") ||
                                    (item?.tabName?.includes("Storage") &&
                                      "sidebar__link_wrapper__storage")
                              }  align-items-center py-2 wrapper__sidebar ${
                                item.tabName.includes("Log out")
                                  ? "exclude-active-styles"
                                  : ""
                              }`}
                            >
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  cursor: item?.tabName?.includes("Storage")
                                    ? "default"
                                    : "pointer",
                                }}
                              >
                                <img
                                  src={item?.tabIcon}
                                  alt="icon"
                                  height={"24px"}
                                  style={{
                                    cursor: item?.tabName?.includes("Storage")
                                      ? "default"
                                      : "pointer",
                                  }}
                                />
                                {!hideSidebar && (
                                  <h6
                                    className="sidebar__heading px-2"
                                    style={{
                                      cursor: item?.tabName?.includes("Storage")
                                        ? "default"
                                        : "pointer",
                                    }}
                                  >
                                    {item?.tabName}
                                  </h6>
                                )}
                              </div>
                              {item?.tabName?.includes("Storage") &&
                                (typeof percentageUsed !== "undefined" ||
                                  !isNaN(typeof percentageUsed)) && (
                                  <ProgressBar
                                    style={{
                                      width: "100%",
                                    }}
                                    now={percentageUsed || 0}
                                    animated={true}
                                    className="mt-1"
                                  />
                                )}

                              {item?.tabName?.includes("Storage") && (
                                <>
                                  <div
                                    className="d-flex justify-content-between mt-1"
                                    style={{
                                      cursor: item?.tabName?.includes("Storage")
                                        ? "default"
                                        : "pointer",
                                    }}
                                  >
                                    <span>
                                      {`${
                                        (usedDataInNum &&
                                          usedDataInNum?.toFixed(2)) ||
                                        0
                                      } GB of 30 GB used`}
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </SidePanel>
              </div>
              <div className={`${hideSidebar ? "col-w-94" : "col-7-5 "}`}>
                <div className="w-100">
                  {/* <NavbarCollegeStudent /> */}
                  <div className="main-content">
                    <Routes>
                      <Route path="/home" element={<Home />} />
                      <Route
                        path="/projects_reviews"
                        element={<ProjectUnderReview />}
                      />
                      <Route
                        path="/createProject"
                        element={<SelectChannel />}
                      />
                      <Route
                        path="/createProject/:name/:s3Key"
                        element={<NewProject />}
                      />
                      <Route
                        path="/appDetails/:s3Key"
                        element={<NewProject />}
                      />
                      <Route
                        path="/newVersion/projectId=:id/oldAppid=:appId/:s3Key"
                        element={<NewProject />}
                      />
                      <Route
                        path="/createProject/newProject/setPrice"
                        element={<SetPrice />}
                      />
                      <Route
                        path="/projectDetails/:projectId"
                        element={<ProjectDetails />}
                      />
                      <Route
                        path="/projectDetails"
                        element={<ProjectDetails />}
                      />
                      <Route path="/testUsers" element={<ChannelUsers />} />
                      <Route path="/purchase" element={<MyPurchase />} />
                      <Route
                        path="/purchaceDetails"
                        element={<PurchaseDetails />}
                      />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/payment" element={<Payment />} />
                      <Route path="/AddAccount" element={<AddAccount />} />
                      <Route path="/cart" element={<Cart />} />
                      <Route path="/analytics" element={<Analytics />} />
                      <Route
                        path="/analytics/:id/:appID"
                        element={<DetailedAnalytics />}
                      />
                      <Route path="/ratings/:id" element={<ProjectRating />} />
                      <Route
                        path="/projectsReviews"
                        element={<ReviewStatus />}
                      />
                      <Route
                        path="/review_details/:id"
                        element={<ReviewStatus />}
                      />

                      <Route path="/notification" element={<Notification />} />
                      <Route path="/storage" element={<Storage />} />

                      <Route path="/settings" element={<Settings />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
    </>
  );
};
