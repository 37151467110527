import React from "react";
import edit from "assets/edit-2.svg";
import { useSearchParams } from "react-router-dom";

export const EditBtn = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div
      onClick={() =>
        setSearchParams({
          appId: searchParams.get("appId"),
          rcName: searchParams.get("rcName"),
          projectId: searchParams.get("projectId"),
          applicationType: searchParams.get("applicationType"),
          infoType: "new",
          query:searchParams.get("query")
        })
      }
      className="d-flex gap-2 px-3 py-2"
      style={{
        justifyContent: "center",
        alignItems: "center",
        background: "#fff",
        borderRadius: "3rem",
        cursor: "pointer",
      }}
    >
      <img src={edit} alt="" />
      <div> Edit</div>
    </div>
  );
};
