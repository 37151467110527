import { DownloadCard, ProjectName } from "components/styled/common.styled";
import { TextConatiner } from "components/styled/home.styled";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { InfoCard } from "./InfoCard";
import money from "assets/money.svg";
import download from "assets/download.svg";
import users from "assets/users-white.svg";

import PropTypes from "prop-types";

const InfoCardWithLoading = ({
  isDataLoaded,
  earningClick,
  totalCountsUsers,
  totalCountsEarnings,
  setGraphScreen,
  setDownloadClick,
  setEarningClick,
  userDownloadData,
  color,
  totalDownloads,
  userLabelData,
  earningLabelData,
  type,
  userNumData,
  earningNumData,
  downNumData,
  lgDataRed,
}) => {
  const cardType = (type) => {
    if (type === "earnings") {
      return "Total Earning";
    } else if (type === "downloads") {
      return "Total Downloads";
    } else if (type === "users") {
      return "Active Users";
    }
  };

  const cardIcon = (type) => {
    if (type === "earnings") {
      return money;
    } else if (type === "downloads") {
      return download;
    } else if (type === "users") {
      return users;
    }
  };

  const handleClickType = (type) => {
    if (type === "earnings") {
      setEarningClick(true);
      setGraphScreen("earnings");
      setDownloadClick(false);
    } else if (type === "downloads") {
      setDownloadClick(true);
      setEarningClick(false);
      setGraphScreen("downloads");
    } else if (type === "users") {
      setGraphScreen("users");
      setDownloadClick(false);
      setEarningClick(false);
    }
  };

  const labelData = (type) => {
    if (type === "earnings") {
      return earningLabelData;
    } else if (type === "users") {
      return userLabelData;
    } else if (type === "downloads") {
      return userDownloadData;
    }
  };

  const numData = (type) => {
    if (type === "earnings") {
      return earningNumData;
    } else if (type === "users") {
      return userNumData;
    } else if (type === "downloads") {
      return downNumData;
    }
  };

  const totalCount = (type) => {
    if (type === "earnings") {
      return totalCountsEarnings;
    } else if (type === "users") {
      return totalCountsUsers;
    } else if (type === "downloads") {
      return totalDownloads;
    }
  };

  return (
    <div>
      {!isDataLoaded ? (
        <div
          style={{
            background: "#fff",
            borderRadius: "10px",
            height: "150px",
            marginTop: "20px",
          }}
        >
          <TextConatiner className="gap-1">
            <ProjectName className="analytics">
              <Skeleton height={"40%"} width={"100%"} />
            </ProjectName>
            <div>
              <Skeleton height={"30px"} width={"30px"} borderRadius={"50%"} />
            </div>
          </TextConatiner>
        </div>
      ) : (
        <DownloadCard className="mt-4">
          <InfoCard
            bor={true}
            text={cardType(type)}
            imgSrc={cardIcon(type)}
            totalCount={totalCount(type)}
            clickStatus={earningClick}
            onClick={() => {
              handleClickType(type);
            }}
            isDataLoaded={isDataLoaded}
            labelData={labelData(type)}
            numData={numData(type)}
            chartBorderColor="#FF7666"
            lgData={color}
          />
        </DownloadCard>
      )}
    </div>
  );
};

InfoCardWithLoading.propTypes = {
  isDataLoaded: PropTypes.bool,
  earningClick: PropTypes.bool,
  totalCountsUsers: PropTypes.number,
  totalCountsEarnings: PropTypes.number,
  setGraphScreen: PropTypes.func,
  setDownloadClick: PropTypes.func,
  setEarningClick: PropTypes.func,
  userLabelData: PropTypes.array,
  earningLabelData: PropTypes.array,
  userNumData: PropTypes.array,
  earningNumData: PropTypes.array,
  lgDataRed: PropTypes.array,
  downNumData: PropTypes.array,
  userDownloadData: PropTypes.array,
  totalDownloads: PropTypes.number,
  type: PropTypes.string,
  color: PropTypes.string,
};

export default InfoCardWithLoading;
