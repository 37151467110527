import React, { useState } from "react";
import vdibg from "assets/bgvdi.png";
import { ButtonPrimary, TextWhite } from "components/styled/common.styled";
import { DownloadADS } from "components/download/DownloadPc";

export const VdiLanding = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div
      className="w-100"
      style={{
        backgroundImage: `url(${vdibg})`,
        height: "300px",
        backgroundColor: "#ffffff",
        backgroundSize: "100% 100%",
        borderRadius: "10px",
        zIndex: "-1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "3rem",
      }}
    >
      <TextWhite
        className="fs-32"
        style={{
          lineHeight: "48px",
        }}
      >
        Get the access of unlimited <br /> power with AjnaCloud service
      </TextWhite>
      <div
        className="col-3"
        style={{
          bottom: "70%",
          left: "4.2%",
        }}
      >
        <ButtonPrimary
          width="250px"
          bg="#F5F5F5"
          color="#161719"
          hover="#fff"
          onClick={() => setModalShow(true)}
          style={{ fontWeight: "bold" }}
        >
           Ajna Remote Desktop 
        </ButtonPrimary>
      </div>
      {modalShow && (
        <DownloadADS
          url="https://cdn.ajnavidya.com/cdn/Ajna%20WorkStation.exe"
          url2="https://cdn.ajnavidya.com/cdn/Ajna%20WorkStation.pkg?14082023"
          name="Ajna Workstation"
          setIsOpen={setModalShow}
          isOpen={modalShow}
        />
      )}
    </div>
  );
};
