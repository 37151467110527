import { Radio } from "components/Radio/Radio";
import { CardBlackWhite } from "components/card/Card";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "components/styled/common.styled";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { radioButton } from "redux/Project/project.action";
import { publisher } from "redux/api";
import { variants } from "components/CustomAlert/constant";
import { UUIDGenerator } from "helper";
import CustomBreadcrumb from "components/CustomBreadScrum";
import { toast } from "react-toastify";

export const SelectChannel = () => {
  const selectedVariant = variants[1];
  const [markAsRead, setMarkAsRead] = useState(false);
  const [channels, setChannels] = useState([]);
  const [rcNote, setRCNote] = useState("");
  const [name, setName] = useState("");
  const [warningActive, setWarningActive] = useState(false);

  const segments = [
    { text: "Create Project", link: "/createProject" },
    { text: "Select Release Channel", link: "/createProject" },
  ];

  const [modal, setModal] = useState({ modal: false, text: "" });

  const {
    inputValue: { channelRadio },
  } = useSelector((state) => state.projectReducer);

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await publisher.getReleaseChannels("", "", "");
        setChannels(response?.globalReleaseChannels);
      } catch (err) {
        console.error(err);
      }
    };

    fetchChannels();
  }, []); // Empty dependency array

  useEffect(() => {
    if (!channels.length) return;

    // Handle DOM interaction: Consider refactoring this part for better practice
    const radioButtonRan = document.getElementById("production");
    if (radioButtonRan) {
      dispatch(radioButton("production"));
      radioButtonRan.click();
    }
  }, [channels]); // Added 'channels' and 'dispatch' to the dependency array

  useEffect(() => {
    const note = channels?.find((channel) => channel.rcName === channelRadio);
    setRCNote(note?.rcReleaseNotes); // Make sure 'setRCNote' is defined elsewhere in your component
  }, [channelRadio, channels]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateAndNavigate = () => {
    toast.dismiss();
    if (!channelRadio) {
      toast.warning("Please select a channel.");
      setWarningActive(true); // Set warning active
      return;
    }
    if (!markAsRead) {
      toast.warning("Please select mark as read.");
      setWarningActive(true); // Set warning active
      return;
    }
    navigate(`/createProject/${name}/${UUIDGenerator()}`, {
      state: { channelRadio },
    });
  };

  const handleMarkAsReadChange = (event) => {
    setMarkAsRead(event.target.checked);
    setWarningActive(false);
  };

  return (
    <>
      <div className="mx-4">
        <CustomBreadcrumb items={segments} />
        <div className="row gx-5">
          <div className="col-6">
            <CardBlackWhite
              upperContent={["Select Release Channel "]}
              lowerContent={
                <div>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="pb-2 px-4">Channel Name</td>
                        <td className="pb-2">Channel Description</td>
                      </tr>

                      {channels?.map(
                        (item) =>
                          item && (
                            <>
                              {item?.rcName?.trim()?.toLowerCase() !==
                              "beta" ? (
                                <tr>
                                  <td
                                    className="no-border px-4"
                                    onClick={() => setName(item?.rcName)}
                                  >
                                    <Radio
                                      type={"radio"}
                                      value={item?.rcName}
                                      name={item?.rcName}
                                    />
                                  </td>
                                  <td className="no-border">
                                    {item?.rcDescription}
                                  </td>
                                </tr>
                              ) : ""}
                            </>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              }
            />
          </div>
          <div className="col-6">
            <CardBlackWhite
              upperContent={["Upload Guidelines for selected release channel"]}
              lowerContent={
                <>
                  <div
                    style={{
                      overflowY: "scroll",
                      height: "200px",
                    }}
                    className="px-4 py-2"
                  >
                    {rcNote}
                  </div>
                  <Form.Group
                    className="py-2 mx-4"
                    controlId="formBasicCheckbox"
                  >
                    <div className="d-flex">
                      <Form.Check
                        type="checkbox"
                        label="Mark as Read"
                        name="checkbox"
                        checked={markAsRead}
                        onChange={handleMarkAsReadChange}
                      />
                      <span className="text-danger">&nbsp;*</span>
                    </div>
                  </Form.Group>
                </>
              }
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="d-flex w-100 justify-content-end gap-4 my-4 ">
            <ButtonSecondary width="150px" onClick={() => navigate("/")}>
              Discard
            </ButtonSecondary>
            <ButtonPrimary
              width="150px"
              style={{ cursor: markAsRead ? "pointer" : "not-allowed" }}
              onClick={() => validateAndNavigate()}
              disabled={warningActive}
            >
              Proceed
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </>
  );
};
