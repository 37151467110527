import React, { useState, useEffect } from "react";
import "./index.css";

import { Button, Modal } from "react-bootstrap";

export const CustomAlertPopup = ({
  titleText,
  modal,
  setModal,
  triggerEvent,
}) => {
  return (
    <Modal
      show={modal.modal}
      onHide={() => setModal({ ...modal, modal: false })}>
      <Modal.Header closeButton className="titleText">
        <Modal.Title className="fs-16" style={{ fontSize: "18px" }}>
          {titleText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-14 fw-500">{modal.text}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-start">
        <Button
          onClick={() => setModal({ ...modal, modal: false })}
          style={{
            background: "#fff",
            color: "#000",
            borderRadius: "50px",
            padding: "12px 24px",
            border: "1px solid #e5e5e5",
            fontSize: "14px",
            fontWeight: "500",
          }}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            triggerEvent && triggerEvent();
            setModal({ ...modal, modal: false });
          }}
          style={{
            background: "#6C56FB",
            color: "#FFFFFF",
            borderRadius: "50px",
            padding: "12px 24px",
            fontSize: "14px",
            fontWeight: "500",
          }}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
// <div
//   className={`alert-container  show `} // Add or remove "show" class based on the show state
//   style={{
//     background: selectedVariant.mainColor,
//     border: "1px solid #e5e5e5",
//     /* position: "absolute",
//                 left: "40%",
//                 top: "30%",
//                 zIndex: 999, */
//   }}
// >
//   <div
//     className="p-3"
//     style={{
//       background: "#242528",
//       width: "100%",
//       borderTopRightRadius: "8px",
//       borderTopLeftRadius: "8px",
//       color: "#fff",
//       display: "flex",
//       alignItems: "center",
//     }}
//   >
//     {titleText !== undefined && (
//       <>
//         <div>
//           <span
//             className="description-text2"
//             style={{
//               fontSize: "18px",
//               fontWeight: "400",
//               padding: "8px 8px 0 0 ",
//               height: "60px",
//             }}
//           >
//             {titleText}
//           </span>
//         </div>
//       </>
//     )}
//   </div>
//   {/* Render the progress bar */}
//   {/* Conditionally render buttons based on the current button configuration and count */}
//   <>
//     <div className="description-container">
//       <span className="description-text2">{text}</span>
//     </div>
//   </>
//   {buttonType && (
//     <div className="button-container">
//       {buttonType.slice(0, buttonType.length).map((button, index) => (
//         <button
//           key={button}
//           className={`outline-button mx-1 p-2 my-1`}
//           style={{
//             color: button.label === "Confirm" ? "#fff" : "",
//             backgroundColor: button.label === "Confirm" ? "#6C56FB" : "#fff",
//             fontWeight: 500,
//             fontSize: "14px",
//             border: button.label === "Confirm" ? "#fff" : "1px solid #898D93",
//           }}
//           onClick={() =>
//             button.label === "Cancel" || button.label === "No"
//               ? setModal({ modal: false })
//               : handleButtonClick(button.label, triggerEvent)
//           }
//         >
//           {button.label}
//         </button>
//       ))}
//     </div>
//   )}

//   {buttonType === null && titleText === undefined && timerDuration > 0 && (
//     <div className="progress-bar">
//       <div
//         className="progress-bar-fill"
//         style={{
//           width: `${(progress / (timerDuration / 100)) * 100}%`,
//           backgroundColor: "red",
//         }}
//       ></div>
//     </div>
//   )}
// </div>;
