import { CardBlackWhite } from "components/card/Card";
import React, { useEffect, useRef } from "react";
import { PriceTabs } from "components/PriceField/PriceTabs";
import { useLocation, useNavigate } from "react-router-dom";
import CustomBreadcrumbs from "components/breadcrumbs/Breadcrumb";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "components/styled/common.styled";
import { useDispatch, useSelector } from "react-redux";

import { createPricePlan } from "redux/Project/project.action";
import { toast } from "react-toastify";
import { useState } from "react";
import CustomBreadcrumb from "components/CustomBreadScrum";
import { buttonConfigs } from "components/CustomAlert/constant";
import { CustomAlertPopup } from "components/CustomAlert";
import { publisher } from "redux/api";

export const SetPrice = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let currentPage = queryParams.get("tab");
  const { price } = useSelector((state) => state.projectReducer);
  const { profilePayload } = useSelector((state) => state.authReducer);
  let appId = queryParams.get("appId");
  let projectId = queryParams.get("projectId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState({
    modal: false,
    text: "",
  });

  const handleFormSubmit = async (e) => {
    if (price !== null || currentPage === "Free") {
      if (currentPage === "Free") {
        try {
           const payload = {
             priceModelType: "Free",
             timePeriod: "Lifetime",
             priceModelDescription:
               "This is a dummy price for the application.",
             applicationPrice: "",
           };
           const response = await publisher.createPricePlan(
             payload,
             projectId,
             appId
           );
            if (response) {
              toast.success("Free Plan created successfully");
               window.location.href = "/projects_reviews";
               return;
            }
        } catch (error) {
              toast.error(error?.response?.data?.message || "Something went wrong");
        }        
      }
      const payload = {
        priceModelType: currentPage,
        timePeriod: currentPage === "Subscription" ? "Monthly" : null,
        priceModelDescription: "This is a dummy price for the application.",
        applicationPrice: price,
        priceModelCurrency: profilePayload.region === "IN" ? "INR" : "USD",
      };
      dispatch(createPricePlan(payload, projectId, appId))
        .then((res) => {
          if (res?.response?.status < 300 || res?.success) {
            toast.success("Price plan created successfully");
            window.location.href = "/projects_reviews";
          } else {
            toast.error(res?.response?.data?.message || "Something went wrong");
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || "Something went wrong");
        });
    } else {
      toast.warning("Please Enter the Price");
    }
  };
  return (
    <div>
      {modal && (
        <div style={{ marginTop: "-0.5rem", marginLeft: "0rem", top: "20px" }}>
          <CustomAlertPopup
            modal={modal}
            setModal={setModal}
            text={modal.text}
            buttonType={buttonConfigs.YesNo}
            triggerEvent={handleFormSubmit}
            titleText="Set Price"
          />
        </div>
      )}
      <div className="d-flex flex-column mx-4 gx-4">
        <CustomBreadcrumb
          items={[
            {
              text: "Set Price",
              link: window.location.pathname + window.location.search,
            },
            {
              text: currentPage,
              link: window.location.pathname + window.location.search,
            },
          ]}
        />
        <CardBlackWhite
          upperContent={["Set Price"]}
          lowerContent={[
            <PriceTabs
              key="priceTabsKey"
              appId={appId}
              projectId={projectId}
            />,
          ]}
        />
        <div className="d-flex justify-content-end gap-2 my-4">
          <ButtonSecondary width="150px" onClick={() => navigate("/")}>
            Discard
          </ButtonSecondary>
          <ButtonPrimary
            width={"150px"}
            onClick={() =>
              setModal({
                modal: true,
                text: "Are you sure you want to proceed?",
              })
            }>
            Proceed
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};
