import { OverFlow, TextBlack } from "components/styled/common.styled";

import ReviewHistory from "./ReviewHistory";
import projectEmpty from "../../assets/project-empty.png";
import ProjectInfo from "./ProjectViewDetails";
import { useDispatch, useSelector } from "react-redux";
import { getAppsInreview } from "redux/Project/project.action";
import { useEffect, useState } from "react";
import CustomBreadcrumb from "components/CustomBreadScrum";
import { CustomSkeleton } from "ui/home/CustomSkeleton";
import SkeltonProjectDetails from "ui/NewProject/SkeltonProjectDetails";
import {
  BackgroundWhite,
  ImageContainer,
  MainCont,
} from "components/styled/ProjectDetails.style";
import { ProjectName, TextConatiner } from "components/styled/home.styled";
import Skeleton from "react-loading-skeleton";

const ProjectUnderReview = () => {
  const dispatch = useDispatch();
  const { appsInreview } = useSelector((state) => state.projectReducer);
  const [isAppsInreviewLoading, setIsAppsInreviewLoading] = useState(true);
  
  useEffect(() => {
    dispatch(getAppsInreview()).then((res) => {
      if (res) {
        setIsAppsInreviewLoading(false);
      }
    });
  }, []);

  const breadcrumbItems = [
    {
      text: "Projects Under Review",
      link: `/projects_reviews`,
    },
  ];

  return (
    <OverFlow>
      <div className="mx-5 border-bottom">
        <CustomBreadcrumb items={breadcrumbItems} />
      </div>

      {isAppsInreviewLoading ? (
        [...Array(3)].map((_, index) => (
          <div className="d-flex gap-4">
            <div className="col-7 gy-3 " key={index}>
              <BackgroundWhite className="d-flex gap-4">
                <ImageContainer>
                  <Skeleton height={"100%"} width={"100%"} />
                </ImageContainer>
                <MainCont>
                  <TextConatiner className="mb-4">
                    <ProjectName>
                      <Skeleton height={"40%"} width={"100%"} />
                    </ProjectName>
                    <ProjectName className="dateText">
                      <Skeleton height={"40%"} width={"100%"} />
                    </ProjectName>{" "}
                    <ProjectName className="dateText">
                      <Skeleton height={"40%"} width={"100%"} />
                    </ProjectName>{" "}
                    <ProjectName className="dateText">
                      <Skeleton height={"40%"} width={"100%"} />
                    </ProjectName>
                  </TextConatiner>
                </MainCont>
              </BackgroundWhite>
            </div>
            <div className="col-4 gy-3 mb-4" key={index}>
              <BackgroundWhite className="d-flex gap-4">
                <ImageContainer>
                  <Skeleton height={"80%"} width={"100%"} />
                </ImageContainer>
                <MainCont>
                  <TextConatiner className="gap-1">
                    <ProjectName className="history">
                      <Skeleton height={"40%"} width={"100%"} />
                    </ProjectName>
                    <ProjectName className="dateText">
                      <Skeleton height={"40%"} width={"100%"} />
                    </ProjectName>{" "}
                    <ProjectName className="dateText">
                      <Skeleton height={"40%"} width={"100%"} />
                    </ProjectName>{" "}
                    <ProjectName className="dateText">
                      <Skeleton height={"40%"} width={"100%"} />
                    </ProjectName>
                  </TextConatiner>
                </MainCont>
              </BackgroundWhite>
            </div>
          </div>
        ))
      ) : (
        <div className="row mx-3">
          {appsInreview?.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100 mt-5">
              <TextBlack className="fs-22" style={{ color: "#808080" }}>
                No Projects Under Review
              </TextBlack>
            </div>
          ) : (
            <>
              <div className="col-12">
                <ProjectInfo appsInreview={appsInreview} />
              </div>
              {/* <div className="HistoryColumn col-4 mx-4 mb-4">
                <ReviewHistory appsInreview={appsInreview} />
              </div> */}
            </>
          )}
        </div>
      )}

      {/* {appsInreview?.length === 0 && (
        <div className="d-flex justify-content-center align-items-center w-100 h-50">
          <TextBlack className="fs-22" style={{ color: "#808080" }}>
            No Projects Under Review
          </TextBlack>
        </div>
      )} */}
    </OverFlow>
  );
};

export default ProjectUnderReview;
