import React, { useState } from "react";
import {
  TextMiddle,
  TextBlack,
  WhiteCircle,
} from "components/styled/common.styled";
import dot from "assets/dot.svg";
import star from "assets/Star.svg";
import like from "assets/like.svg";
import dislike from "assets/dislike.svg";
import heart from "assets/heart.svg";
import flag from "assets/flag-2.svg";
import RatingBox from "components/Rating Box/Rating";
import { RatingWrapper } from "ui/rating/rating.styled";
import ToggleButtons from "components/ToggleButtons/ToggleButtons";
import { useSelector } from "react-redux";
import { getDateByUpdate } from "helper";

export const Reviews = ({ appIDInitial }) => {
  const { projectFormDetails } = useSelector((state) => state.projectReducer);
  const [selectedRating, setSelectedRating] = useState("all");
  const handleRatingChange = (rating) => {
    setSelectedRating(rating);
    // Call your API here to update the data based on the selected rating
  };
  const reviewData = [];
  const ratingCount = projectFormDetails?.ratingCount;
  const ratings = [
    { name: "All", value: "all", keyName: "rating" },
    { name: "1 Star", value: "1", keyName: "rating" },
    { name: "2 Star", value: "2", keyName: "rating" },
    { name: "3 Star", value: "3", keyName: "rating" },
    { name: "4 Star", value: "4", keyName: "rating" },
    { name: "5 Star", value: "5", keyName: "rating" },

    // Add more rating options as needed
  ];
  return (
    <div>
      <div className="d-flex w-100">
        <div className="col-8">
          <RatingWrapper>
            <TextBlack className="fs-18">App Reviews</TextBlack>
            <div>
              <ToggleButtons
                options={ratings}
                selectedOption={selectedRating}
                onOptionChange={handleRatingChange}
              />
            </div>
            <hr />
            {/* comment box */}
            {Array.isArray(reviewData) &&
              reviewData?.map((item, index) => (
                <div key={item.id}>
                  <div
                    className="d-flex align-content-center gap-2 my-2"
                    style={{ alignItems: "center" }}>
                    <WhiteCircle>
                      <img
                        src={item.profilePicture}
                        height={"100%"}
                        width={"100%"}
                        alt="img"
                      />
                    </WhiteCircle>
                    <div>
                      <TextMiddle className="fs-18">{item.userName}</TextMiddle>
                    </div>
                    <div>
                      <img src={dot} alt="" />
                    </div>
                    <div>
                      <TextMiddle className="fs-14">
                        {getDateByUpdate(item?.commentID?.updatedAt)}
                      </TextMiddle>
                    </div>
                  </div>
                  {/* rating */}
                  {index === 0 && (
                    <div
                      className="d-flex  gap-1 my-2"
                      style={{ alignItems: "center", width: "auto" }}>
                      <img src={star} alt="img" />
                      <TextBlack className="fs-16">{item.rating}/5</TextBlack>
                    </div>
                  )}

                  {/* comment */}
                  <div
                    className="my-2"
                    style={{ paddingRight: index !== 0 && "10rem" }}>
                    <TextBlack>{item?.commentID?.comment}</TextBlack>
                  </div>

                  {/* like, reply,flag */}
                  {index === 0 && (
                    <div className="d-flex justify-content-between my-3">
                      <div className="d-flex gap-3">
                        {/* like */}
                        <div className="d-flex gap-1">
                          <img height={"20px"} src={like} alt="like" />
                          <TextBlack>
                            {item?.commentID?.commentLiekcount}
                          </TextBlack>
                        </div>

                        {/* dislike */}
                        <div className="d-flex gap-1">
                          <img height={"20px"} src={dislike} alt="like" />
                          <TextBlack>
                            {item?.commentID?.commentDislikecount}
                          </TextBlack>
                        </div>
                      </div>
                      <div className="d-flex gap-3">
                        <div>
                          <img height={"20px"} src={heart} alt="img" />
                        </div>
                        <div>
                          <img height={"20px"} src={flag} alt="flag" />
                        </div>
                        <div>
                          <TextMiddle>Reply</TextMiddle>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            {reviewData?.length === 0 && (
              <div className="d-flex justify-content-center my-3">
                <TextBlack>No Reviews Found</TextBlack>
              </div>
            )}
          </RatingWrapper>
        </div>
        <div className="my-2">
          <RatingBox appRating={ratingCount} />
        </div>
      </div>
    </div>
  );
};
