import { useKeycloak } from "@react-keycloak/web";
import { EraseAllCookies, EraseCookies, getCookie } from "helper";
import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { publisher } from "redux/api";

export const Protected = ({ children, path }) => {
  // const { keycloak } = useKeycloak();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const isLoggedIn = token === "undefined" || token === null ? false : true;

  const fetchUserExist = async () => {
    const windowPath = window.location.pathname;

    try {
      window.location.replace("/home");
    } catch (e) {
      if (e?.response?.data?.message === "Unauthorized") {
        window.location.href = "/";
        localStorage.removeItem("token");
        localStorage.clear();
        EraseCookies("token");
        EraseAllCookies();
      } else {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserExist();
    }
  }, [isLoggedIn]);

  if (isLoggedIn) {
    if (path === "login") {
      return <Navigate to="/" />;
    } else {
      return children;
    }
  } else {
    if (path === "login") {
      return <Navigate to="/" />;
    }
  }
};
