//  action file for project

import { toast } from "react-toastify";

const { publisher } = require("redux/api");

const {
  SET_PROJECT_FORM_DETAILS,
  SET_PRICE,
  GET_ALL_PROJECTS,
  GET_ALL_SECTORS,
  GET_PROJECT_BY_ID,
  GETRC_WITH_LIVE_APPLICATION,
  GET_APPLICATION_DATA,
  SET_RADIO_BUTTON,
  EMPTY_FORM_DETAILS,
  GET_APP_REVIEW_STATUS,
  GET_APPS_IN_REVIEW,
  GET_SECTOR_BY_ID,
  CREATE_RELEASE_CHANNEL,
  GET_ALL_SAVE_DRAFT_PROJECT,
  DELETE_DRAFT_PROJECT_BY_ID,
  GET_ACTIVE_USERS,
  GET_APP_DOWNLOADS,
  GET_APP_EARNINGS,
  GET_USERS_BY_REGION,
  GET_MISCELLANEOUS_DATA,
  GET_OVERALL_DOWNLOADS,
  GET_OVERALL_EARNINGS,
  GET_OVERALL_USERS,
  GET_OVERALL_USERS_BY_REGION,
  RESET_STATE,
  GET_APP_RATINGS,
  GET_APP_REVIEWS,
  GET_BANK_ACCOUNT_LIST,
  SET_PRIMARY_ACCOUNT,
  DELETE_ACCOUNT,
  GET_ALL_STATES,
  GET_BANK_DETAIL,
  CREATE_COUPON,
  GET_COUPONS_BYAPPID,
  DELETE_COUPONBYID,
  UPDATE_COUPON_BYID,
  GET_COUPONS_BYID,
  GET_ALL_CITIES,
  GET_STORAGE,
  DELETE_PROJECT,
  GET_ALL_SEARCH,
  GET_ALL_VERSIONS_HISTORY,
  SET_PRICE_DETAILS,
  ADD_USER,
  DELETE_USER,
  ADD_USER_API,
  GET_INVITED_USERS,
  DELETE_INVITED_USER_SUCCESS,
  DELETE_INVITED_USER_FAILURE,
  GET_ALL_STORAGE,
  GET_STORAGE_PROJECTWISE,
  GET_APP_INFO,
  DELETE_APPLICATION_BY_ID,
  GET_ALL_REJECTED_APPS,
  DELETE_REJECTED_APPS,
  GET_Live_PROJECTS,
} = require("./project.types");

export const setFormDetails = (payload) => (dispatch) => {
  return dispatch({
    type: SET_PROJECT_FORM_DETAILS,
    payload: {
      ...payload,
    },
  });
};

export const createProject =
  (payload, response, projectId) => async (dispatch) => {
    try {
      let response = await publisher.createProject(payload, projectId);
      return response;
    } catch (error) {
      return error;
    }
  };

export const setPrice = (payload) => (dispatch) => {
  return dispatch({ type: SET_PRICE, payload: payload });
};

export const createPricePlan =
  (payload, projectID, appID) => async (dispatch) => {
    try {
      const response = await publisher.createPricePlan(
        payload,
        projectID,
        appID
      );
      return response;
    } catch (error) {
      return error;
    }
  };

export const getSingleProjectDetails =
  (publisherId, projectId, projectName) => async (dispatch) => {
    try {
      const response = await publisher.getProjectById(
        publisherId,
        projectId,
        projectName
      );
      return dispatch({
        type: GET_PROJECT_BY_ID,
        payload: response.data || {},
      });
    } catch (error) {
      return error;
    }
  };
export const getSingleDraftProjectDetails = (appId) => async (dispatch) => {
  try {
    const response = await publisher.getSaveDraftById(appId);
    return dispatch({
      type: GET_PROJECT_BY_ID,
      payload: response.data || {},
    });
  } catch (error) {
    return error;
  }
};

export const getAllProjects =
  (publisherId, projectID, projectName) => async (dispatch) => {
    try {
      const response = await publisher.getAllProjects(
        publisherId,
        projectID,
        projectName
      );
      return dispatch({
        type: GET_ALL_PROJECTS,
        payload: response.data || [],
      });
    } catch (error) {
      return error;
    }
  };
export const getLiveProjects =
  (publisherId, projectID, projectName) => async (dispatch) => {
    try {
      const response = await publisher.getLiveProject(
        publisherId,
        projectID,
        projectName
      );
      return dispatch({
        type: GET_Live_PROJECTS,
        payload: response.data || [],
      });
    } catch (error) {
      return error;
    }
  };

export const getRCWithLiveApps =
  (publisherId, projectId, applicationType) => async (dispatch) => {
    try {
      const response = await publisher.getRCWithLiveApps(
        publisherId,
        projectId,
        applicationType
      );
      return dispatch({
        type: GETRC_WITH_LIVE_APPLICATION,
        payload: response.data || [],
      });
    } catch (error) {
      return error;
    }
  };

export const getApplicationDetails =
  (publisherId, projectId, applicationId, rcName, infoType) =>
  async (dispatch) => {
    try {
      const response = await publisher.getAppsDetails(
        publisherId,
        projectId,
        applicationId,
        rcName,
        infoType
      );
      dispatch({
        type: GET_APPLICATION_DATA,
        payload: response?.data || {},
      });
      return dispatch({
        type: SET_PROJECT_FORM_DETAILS,
        payload: response?.data || {},
      });
    } catch (error) {
      return {
        error: error.message,
        success: false,
      };
    }
  };

export const radioButton = (payload) => (dispatch) => {
  return dispatch({ type: SET_RADIO_BUTTON, payload: payload });
};

export const clearSetProjectFormDetails = () => (dispatch) => {
  return dispatch({ type: EMPTY_FORM_DETAILS, payload: {} });
};

export const getAppsInreview = (appID) => async (dispatch) => {
  try {
    const res = await publisher.getAppsInreview("");
    dispatch({ type: GET_APPS_IN_REVIEW, payload: res.data || {} });
    return res;
  } catch (error) {
    return error;
  }
};

export const getApplicationInfo = (appID) => async (dispatch) => {
  try {
    const res = await publisher.getAppInfo(appID);
    dispatch({ type: GET_APP_INFO, payload: res.data || {} });
  } catch (error) {
    return error;
  }
};

export const getAppReviewStatus = (appID) => async (dispatch) => {
  try {
    const res = await publisher.getAppReviewStatus(appID);
    return dispatch({ type: GET_APP_REVIEW_STATUS, payload: res.data || {} });
  } catch (error) {}
};
export const getAllSectors = () => async (dispatch) => {
  try {
    const res = await publisher.getAllSectors();
    dispatch({ type: GET_ALL_SECTORS, payload: res.data || [] });
    return res;
  } catch (error) {
    return error;
  }
};
export const getSubSectorsById = (sectorId) => async (dispatch) => {
  try {
    const res = await publisher.getSectorsById(sectorId);
    dispatch({ type: GET_SECTOR_BY_ID, payload: res.data || [] });
  } catch (error) {
    return error;
  }
};

export const createReleaseChannel = (payload) => async (dispatch) => {
  try {
    const res = await publisher.createReleaseChannel(payload);
    return dispatch({
      type: CREATE_RELEASE_CHANNEL,
      payload: res.releaseChannel || {},
    });
  } catch (error) {
    return error;
  }
};

// save And Draft

export const saveAndDraft =
  (payload, response, projectId) => async (dispatch) => {
    try {
      const response = await publisher.saveAndDraft(payload, projectId);
      return response;
    } catch (error) {
      return error;
    }
  };

// get all saveDraft

export const getAllSaveDraftProjects = () => async (dispatch) => {
  try {
    const response = await publisher.getSaveDraftById();
    return dispatch({
      type: GET_ALL_SAVE_DRAFT_PROJECT,
      payload: response.data || [],
    });
  } catch (error) {
    return error;
  }
};

// get all rejected apps
export const getAllRejectedApps = () => async (dispatch) => {
  try {
    const response = await publisher.getAllRejectedApps();
    return dispatch({
      type: GET_ALL_REJECTED_APPS,
      payload: response.data || [],
    });
  } catch (error) {
    return error;
  }
};

export const deleteDraftProjects =
  (data, isDelete = "true") =>
  async (dispatch) => {
    try {
      const response = await publisher.deleteDraftProjectBYId(data, isDelete);
      return dispatch({
        type: DELETE_DRAFT_PROJECT_BY_ID,
        payload: response.data || [],
        success: true,
      });
    } catch (error) {
      return error;
    }
  };

export const deleteApplicationVersion = (appId) => async (dispatch) => {
  try {
    const response = await publisher.deleteApplicationVersion(appId);
    return dispatch({
      type: DELETE_APPLICATION_BY_ID,
      payload: response || {},
    });
  } catch (error) {
    return error;
  }
};

export const makeAPPLive =
  (projectID, appID, schedule, data) => async (dispatch) => {
    try {
      const yes = window.confirm("Are you sure you want to make app live?");
      if (!yes) return;
      const response = await publisher
        .makeAppLive(projectID, appID, schedule, data)
        .then((res) => {
          toast.success("Request to make App live sent successfully.");
          if (!schedule) {
            return (window.location.href = "/projectDetails/" + projectID);
          }else{
            return (window.location.href = "/projects_reviews");
          }
        });

      return response;
    } catch (error) {
      return error;
    }
  };

export const getActiveUsersData = (appID, dataFor) => async (dispatch) => {
  try {
    const response = await publisher.getActiveUsers(appID, dataFor);
    return dispatch({
      type: GET_ACTIVE_USERS,
      payload: response.data || [],
      totalCounts: response.totalCounts,
    });
  } catch (error) {
    return error;
  }
};

export const getAppDownloadsData = (appID, dataFor) => async (dispatch) => {
  try {
    const response = await publisher.getAppDownloads(appID, dataFor);
    return dispatch({
      type: GET_APP_DOWNLOADS,
      payload: response.data || [],
      totalCounts: response.totalCounts,
    });
  } catch (error) {
    return error;
  }
};

export const getAppEarningData =
  (appID, dataFor, priceModel) => async (dispatch) => {
    try {
      const response = await publisher.getAppEarnings(
        appID,
        dataFor,
        priceModel
      );
      return dispatch({
        type: GET_APP_EARNINGS,
        payload: response.data || [],
        totalCounts: response.totalCounts,
      });
    } catch (error) {
      return error;
    }
  };

export const getUsersByRegion = (appID) => async (dispatch) => {
  try {
    const response = await publisher.getAppUsersByRegion(appID);
    return dispatch({
      type: GET_USERS_BY_REGION,
      payload: response.data || [],
    });
  } catch (error) {
    return error;
  }
};

export const getMiscellaneousData = (appID) => async (dispatch) => {
  try {
    const response = await publisher.getMiscData(appID);
    return dispatch({
      type: GET_MISCELLANEOUS_DATA,
      payload: response.data || {},
    });
  } catch (error) {
    return dispatch({
      type: GET_MISCELLANEOUS_DATA,
      payload: error.response.data.data,
    });
  }
};

export const getOverallDownloads = (dataFor) => async (dispatch) => {
  try {
    const response = await publisher.getOverallDownloadsData(dataFor);
    return dispatch({
      type: GET_OVERALL_DOWNLOADS,
      payload: response.data || [],
      totalCounts: response.totalCounts,
    });
  } catch (error) {
    return error;
  }
};

export const getOverallEarningData =
  (dataFor, priceModel) => async (dispatch) => {
    try {
      const response = await publisher.getOverallEarnings(dataFor, priceModel);
      return dispatch({
        type: GET_OVERALL_EARNINGS,
        payload: response.data || [],
        totalCounts: response.totalCounts,
      });
    } catch (error) {
      return error;
    }
  };

export const getOverallActiveUsersData = (dataFor) => async (dispatch) => {
  try {
    const response = await publisher.getOverallActiveUsers(dataFor);
    return dispatch({
      type: GET_OVERALL_USERS,
      payload: response.data || [],
      totalCounts: response.totalCounts,
    });
  } catch (error) {
    return error;
  }
};

export const getOverallUsersByRegionData = () => async (dispatch) => {
  try {
    const response = await publisher.getOverallUsersByRegion();
    return dispatch({
      type: GET_OVERALL_USERS_BY_REGION,
      payload: response.data || [],
    });
  } catch (error) {
    return error;
  }
};

export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};

export const getAppratingsData = (appID) => async (dispatch) => {
  try {
    const response = await publisher.getAppRatings(appID);
    return dispatch({
      type: GET_APP_RATINGS,
      payload: response.data || {},
    });
  } catch (error) {
    return error;
  }
};

export const getAppReviewsData = (appID) => async (dispatch) => {
  try {
    const response = await publisher.getAppReviews(appID);
    return dispatch({
      type: GET_APP_REVIEWS,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};

//Get Account List.
export const getAccountsList = () => async (dispatch) => {
  try {
    const data = await publisher.getAccountList();
    return dispatch({ type: GET_BANK_ACCOUNT_LIST, payload: data || [] });
  } catch (error) {
    return error;
  }
};

//Create Account from Details
export const addAccountDetails = (payload) => async (dispatch) => {
  try {
    const response = await publisher.createPaymentAccount(payload);
    return response;
  } catch (error) {
    return error;
  }
};

// Set Account to the Primary,
export const setPrimaryAccount = (accountId, isPrimary) => async (dispatch) => {
  try {
    await publisher.setPrimaryAccount(accountId, isPrimary);
    return dispatch({
      type: SET_PRIMARY_ACCOUNT,
      payload: accountId,
    });
  } catch (error) {
    return error;
  }
};

// Delete Account Details
export const delAccount = (accountId) => async (dispatch) => {
  try {
    await publisher.deleteAccount(accountId);
    return dispatch({
      type: DELETE_ACCOUNT,
      payload: accountId,
    });
  } catch (error) {
    return error;
  }
};

export const getBankDetaisFromIFSC = (ifscCode) => async (dispatch) => {
  try {
    const response = await publisher.getBankDetails(ifscCode);
    return dispatch({
      type: GET_BANK_DETAIL,
      payload: response.data,
    });
  } catch (err) {
    return err;
  }
};

// Get All the States based on the country.
export const getAllStates = (country) => async (dispatch) => {
  try {
    const response = await publisher.fetchStates();
    return dispatch({
      type: GET_ALL_STATES,
      payload: response.data || [],
    });
  } catch (error) {
    return error;
  }
};

// Get All the Cities based on the state is being selected.
export const getAllCities = (state) => async (dispatch) => {
  try {
    const response = await publisher.fetchCities(state);
    return dispatch({
      type: GET_ALL_CITIES,
      payload: response.data || [],
    });
  } catch (error) {
    return error;
  }
};

// UPDATE couponsById
export const upDateCouponsById = (couponId, data) => async (dispatch) => {
  try {
    const response = await publisher.updateCouponById(couponId, data);
    return dispatch({
      type: UPDATE_COUPON_BYID,
      payload: response.data,
      message: response.message,
    });
  } catch (error) {
    return error;
  }
};

// create coupons
export const generateCoupons = (data) => async (dispatch) => {
  try {
    const response = await publisher.createCoupon(data);
    return dispatch({
      type: CREATE_COUPON,
      payload: response || [],
      createCoupons: response.newCoupon,
    });
  } catch (error) {
    return error;
  }
};

// get couponsBy AppId
export const getCouponsByAppId = (appId) => async (dispatch) => {
  try {
    const response = await publisher.getCouponsByAppId(appId);
    return dispatch({
      type: GET_COUPONS_BYAPPID,
      payload: response.data || [],
    });
  } catch (error) {
    return error;
  }
};

// DELETE couponsById
export const deleteCouponById = (couponId) => async (dispatch) => {
  try {
    await publisher.deleteCouponByCouponId(couponId);
    return dispatch({
      type: DELETE_COUPONBYID,
      payload: couponId,
    });
  } catch (error) {
    return error;
  }
};

// get couponsById
export const getCouponsById = (couponId) => async (dispatch) => {
  try {
    const response = await publisher.findCouponById(couponId);
    return dispatch({
      type: GET_COUPONS_BYID,
      payload: response?.data,
    });
  } catch (error) {
    return error;
  }
};
// get storage
export const getAllowedStorage = (userID, projectID) => async (dispatch) => {
  try {
    const response = await publisher.getAllowedStorage(userID, projectID);
    return dispatch({
      type: GET_STORAGE,
      payload: response?.data?.data,
    });
  } catch (error) {
    return error;
  }
};

// getstorage projectwise
export const getAllowedStorageProjectwise = () => async (dispatch) => {
  try {
    const response = await publisher.getStorageProjectwise();
    return dispatch({
      type: GET_STORAGE_PROJECTWISE,
      payload: response,
    });
  } catch (error) {
    return error;
  }
};

// delete project

export const deleteProjectCore = (projectId) => async (dispatch) => {
  try {
    publisher.deleteProject(projectId);
    return dispatch({
      type: DELETE_PROJECT,
      payload: projectId,
    });
  } catch (error) {
    return error;
  }
};

// create comment, like ,flag all in one
export const createComment = (commentID, data) => async (dispatch) => {
  try {
    const response = await publisher.createComment(commentID, data);
    return response;
  } catch (error) {
    return error;
  }
};

// action function for search
export const getGlobalSearch = (query) => async (dispatch) => {
  try {
    const data = await publisher.globalSearch(query);
    dispatch({ type: GET_ALL_SEARCH, payload: data });
  } catch (error) {
    return error;
  }
};

// GET_ALL_VERSIONS_HISTORY
export const getAllVersionHistoryData =
  (projectID, rcName, applicationType, appID) => async (dispatch) => {
    //;
    try {
      const response = await publisher.getAllVersionsHistory(
        projectID,
        rcName,
        applicationType,
        appID
      );
      return dispatch({
        type: GET_ALL_VERSIONS_HISTORY,
        payload: response?.data,
      });
    } catch (error) {
      return error;
    }
  };

// set price details
export const setPriceDetails = (payload) => (dispatch) => {
  return dispatch({ type: SET_PRICE_DETAILS, payload: payload });
};

export const addUser = (user) => {
  return {
    type: ADD_USER,
    payload: user,
  };
};

export const deleteUser = (email) => {
  return {
    type: DELETE_USER,
    payload: email,
  };
};

// Invite users API
export const addUserApi =
  (rcName, projectID, appId, rcApplicationType, data, file) =>
  async (dispatch) => {
    try {
      const response = await publisher.inviteUsers(
        rcName,
        projectID,
        appId,
        rcApplicationType,
        file ? file : { users: data }
      );
      return response;
    } catch (error) {
      return error;
    }
  };

// get Invited Users
export const getInvitedUsers =
  (rcName, projectID, rcApplicationType) => async (dispatch) => {
    try {
      const response = await publisher.getInvitedUsersApi(
        rcName,
        projectID,
        rcApplicationType
      );
      return dispatch({
        type: GET_INVITED_USERS,
        payload: response.users || [],
      });
    } catch (error) {
      return error;
    }
  };
export const getAllStoragedata = () => async (dispatch) => {
  try {
    const response = await publisher.getAllStorage();
    return dispatch({
      type: GET_ALL_STORAGE,
      payload: response?.storagePlans,
    });
  } catch (error) {
    return error;
  }
};

// delete Invited Users
export const deleteInvitedUser =
  (rcName, projectID, rcApplicationType, selectedUsers) => async (dispatch) => {
    try {
      const response = await publisher.deleteInvitedUserApi(
        rcName,
        projectID,
        rcApplicationType,
        { userIds: selectedUsers }
      );
      dispatch({
        type: DELETE_INVITED_USER_SUCCESS,
        payload: response, // Assuming your API returns some data upon success
      });
      return response; // Return the response to indicate success
    } catch (error) {
      dispatch({
        type: DELETE_INVITED_USER_FAILURE,
        payload: error.message,
      });
      throw error; // Throw the error to indicate failure
    }
  };
