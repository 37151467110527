import React, { useEffect } from "react";
import "./purchaseComp.css";
import sampleImg from "../../assets/sampleTrainingImg.png";

export const PurchaseComp = () => {
  useEffect(() => {
    const cardBody = document.querySelector(".card-body");
    const cardTitle = document.querySelector(".card-title");
    const sampleImg = "your_image_url"; // replace with your actual image URL

    const img = new Image();
    img.src = sampleImg;

    img.onload = () => {
      const vibrantColor = getVibrantColor(img);
      cardBody.style.background = `url(${sampleImg}) no-repeat center center`;
      cardBody.style.backgroundSize = "cover";
      cardTitle.style.color = vibrantColor;
    };
  }, []);

  // Function to determine vibrant color based on image brightness
  const getVibrantColor = (img) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 1;
    canvas.height = 1;
    ctx.drawImage(img, 0, 0, 1, 1);

    const imageData = ctx.getImageData(0, 0, 1, 1).data;
    const brightness =
      (0.299 * imageData[0] + 0.587 * imageData[1] + 0.114 * imageData[2]) /
      255;

    return brightness < 0.5 ? "white" : "black";
  };

  const renderText = (label, value) => {
    return (
      <div className="d-flex justify-content-between mx-2">
        <div className="lowerDivLabel">{label}</div>
        <div className="lowerDivValue">{value}</div>
      </div>
    );
  };

  return (
    <div className="purchaseCompCard">
      <div
        className="card-body"
        style={{
          background: `url(${sampleImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}>
        <div className="card-title">
          <div className="sector">Industrial</div> <div className="projectName">Fire & Safety Training</div>
        </div>
      </div>
      <div className="card py-2 px-1">
        {renderText("Your Revenue", "₹1.5 Crore")}
        {renderText("Your Investment", "₹50 Lakhs")}
        {renderText("3 Year ROI", "200%")}
        <hr className="mx-2 my-2" />
        {renderText("Time To Develop", "4 Months")}
        {renderText("No Of Team Members", "10-12")}
      </div>
    </div>
  );
};
