import { styled } from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  gap: 24px;
  alignitems: flex-start;
  height: "auto";
`;
export const CardContainer2 = styled.div`
  display: flex;
  gap: 24px;
`;

export const KeyTxt = styled.div`
  color: var(--text-color-text-1, #161719);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;
export const ValuesContainer = styled.div`
  fontweight: 300;
`;

export const TextBtnCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextBtn = styled.div`
    display: flex;
    alignItems: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9,
  }}
`;

export const EditBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  outline: none;
  border-radius: 500px;
  background: var(--dark-mode-text-11, #fff);
  color: var(--text-color-text-1, #0c0c0d);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 7px 14px;
`;

export const LiveTxt = styled.div`
  border-radius: 7px;
  background: #eeffed;
  color: #000;
  padding: 0 5px;
`;

// skleton css
export const ImageContainer = styled.div`
  height: 154px;
  width: 200px;
  border-radius: 15px;
`;
export const MainCont = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const DeleteBtn = styled.div`
  width: 100px;
  height: 50px;
  border-radius: 50%;
`;

export const BackgroundWhite = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  padding: 20px;
  margin-left: 30px;
`;
