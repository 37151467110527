import imgIcon from "assets/imageIcon.svg";
import video from "assets/play.svg";

export const mediaData = [
  {
    id: 1,
    thumbnail: imgIcon,
    thumbName: "Upload Preview Image",
    type: "Image",
    accept: "image/*",
    name: "coverImage",
    isTop: true,
    isThumbnail: true,
    projectInfo: "project information related shown here..",
    price: "Price",
    isMandatory: true,
    info: " Max file size - 5MB Resolution - 1920x1080pAspect Ratio - 16:9",
  },
  {
    id: 3,
    thumbnail: video,
    thumbName: "Upload Trailer Video",
    type: "Video",
    accept: "video/*",
    name: "video",
    isTop: false,
    projectInf: "",
    price: "",
    isMandatory: true,
    info: " Max file size - 5MB Resolution - 1920x1080pAspect Ratio - 16:9",
  },
  {
    id: 4,
    thumbnail: imgIcon,
    thumbName: "Upload Video Thumbnail",
    type: "Image",
    accept: "image/*",
    name: "videothumbnail",
    isTop: false,
    projectInf: "",
    price: "",
    isMandatory: true,
    info: " Max file size - 5MB Resolution - 1920x1080pAspect Ratio - 16:9",
  },
  // {
  //   id: 5,
  //   thumbnail: imgIcon,
  //   thumbName: "Upload Preview Image",
  //   type: "Image",
  //   accept: "image/*",
  //   name: "imageOne",
  //   isTop: false,
  //   projectInf: "",
  //   price: "",
  //   info: " Max file size - 50MB Resolution - 1920x1080pAspect Ratio - 16:9",
  // },
  {
    id: 6,
    thumbnail: imgIcon,
    thumbName: "Upload Preview Image",
    type: "Image",
    accept: "image/*",
    name: "imageTwo",
    isTop: false,
    projectInf: "",
    price: "",
    info: " Max file size - 5MB Resolution - 1920x1080pAspect Ratio - 16:9",
  },
  {
    id: 7,
    thumbnail: imgIcon,
    thumbName: "Upload Preview Image",
    type: "Image",
    accept: "image/*",
    name: "imageThree",
    isTop: false,
    projectInf: "",
    price: "",
    info: " Max file size - 5MB Resolution - 1920x1080pAspect Ratio - 16:9",
  },
];

const formFields = [
  {
    label: "QP CODE",
    placeholder: "Ex. ASC/Q9707",
    name: "qpCode",
    value: "ASC/Q9707",
  },
  {
    label: "NQR Code",
    placeholder: "Ex. 2021/AUT/ASDC/04535",
    name: "nqrCode",
    value: "2021/AUT/ASDC/04535",
  },
  {
    label: "Occupation",
    placeholder: "Ex. Driving",
    name: "occupation",
    value: "Driving",
  },
  {
    label: "NSQF LEVEL",
    placeholder: "Ex. 4",
    name: "nsqfLevel",
    value: "4",
  },
];
