import React from "react";
import LineChart from "components/Graphs/LineChart";
import {
  DownloadCard,
  DownloadWrapper,
  TextBlack,
} from "components/styled/common.styled";
import { LoadingSpinner } from "./LoadingSpinner";

import PropTypes from "prop-types";

export const InfoCard = ({
  text,
  imgSrc,
  totalCount,
  onClick,
  isDataLoaded,
  labelData,
  numData,
  chartBorderColor,
  lgData,
}) => (
  <DownloadCard
    data-testid="downloadCardButton"
    className="col-12 cursor-pointer"
    onClick={onClick}
  >
    <div className="d-flex flex-column col-8">
      <TextBlack className="fw-600">{text}</TextBlack>
      <DownloadWrapper className="mt-4">
        <div
          style={{
            background: "#242528",
            width: "30px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img height={"20"} src={imgSrc} alt="" />
        </div>
        <div className="fw-600 ms-2">{totalCount}</div>
      </DownloadWrapper>
    </div>
    <div className="smallGraphbox">
      {isDataLoaded && labelData && numData ? (
        <LineChart
          isAxisData={false}
          borderColor={chartBorderColor}
          labelData={labelData}
          lgData={lgData}
          numData={numData}
          noData={"noData"}
        />
      ) : (
        <LoadingSpinner />
      )}
    </div>
  </DownloadCard>
);

InfoCard.propTypes = {
  text: PropTypes.string,
  imgSrc: PropTypes.string,
  totalCount: PropTypes.number,
  onClick: PropTypes.func,
  isDataLoaded: PropTypes.bool,
  labelData: PropTypes.array,
  numData: PropTypes.array,
  chartBorderColor: PropTypes.string,
  lgData: PropTypes.string,
};
