import { CardSearch } from "components/card/Card";
import { BoxWrapper } from "components/styled/common.styled";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import search from "assets/search.svg";
import { getGlobalSearch } from "redux/Project/project.action";

import { useLocation } from "react-router-dom";
import { useOutsideAlerter } from "components/OutsideAlerter/OutsideAlerter";

export const Search = ({ height, width }) => {
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const [showResults, setShowResults] = useState(false);

  const handleSearch = (e) => {
    const value = e.target.value;
    setShowResults(true);
    setSearchValue(value);
  };

  useEffect(() => {
    if (searchValue === "") return;
    const delayDebounceFn = setTimeout(() => {
      dispatch(getGlobalSearch(searchValue));
    }, 200);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const resultsRef = useRef(null);

  // Custom hook to handle clicks outside the search results
  useOutsideAlerter(resultsRef, setShowResults);

  const searchData = useSelector((state) => state.appReducer.searchData);
  
  return (
    <div className="position-relative">
      <BoxWrapper
        height={height}
        width={width}
        className=" d-flex justify-content-start background  border-radius-50 mx-3"
      >
        <img
          src={search}
          height={"20px"}
          alt="search"
          style={{ height: "14px", width: "14px" }}
        />
        <input
          // autoFocus
          placeholder="Search"
          className="border-0"
          type="search"
          value={searchValue}
          onChange={(e) => {
            handleSearch(e);
          }}
          style={{
            width: "100%",
            fontSize: "14px",
            fontWeight: "400",
            autoFocus: false,
          }}
        />
      </BoxWrapper>
      <div></div>

      {searchData?.length !== 0 && searchValue.length !== 0 ? (
        <CardSearch
          searchData={searchData}
          setSearchValue={setSearchValue}
          setShowResults={setShowResults}
          showResults={showResults}
          ref={resultsRef}
        />
      ) : null}
    </div>
  );
};
