import { PurchaseComp } from "components/Purchase/PurchaseComp";
import { Search } from "components/search/Search";
import React from "react";
import "./myPurchase.css";

export const MyPurchase = () => {
  return (
    <div className="mx-4 my">
      <h6>My Purchases</h6>
      <hr />
      <div className="mt-3">
        <Search />
      </div>

      {/* create responsive grid layout for 4 items in a row */}
      <div className="purchaseBox row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 my-2">
        <div className="col">
          <PurchaseComp />
        </div>
        <div className="col">
          <PurchaseComp />
        </div>
        <div className="col">
          <PurchaseComp />
        </div>
        <div className="col">
          <PurchaseComp />
        </div>
        <div className="col">
          <PurchaseComp />
        </div>
        <div className="col">
          <PurchaseComp />
        </div>
        <div className="col">
          <PurchaseComp />
        </div>
        <div className="col">
          <PurchaseComp />
        </div>
      </div>
    </div>
  );
};
