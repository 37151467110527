import { mapPlatform } from "helper";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormDetails } from "redux/Project/project.action";

export const SelectFileType = ({ files, setFiles }) => {
  const { singleProjectDetails, projectFormDetails } = useSelector(
    (state) => state.projectReducer
  );
  const query = new URLSearchParams(window.location.search);

  // let applicationType = "Apk";
  // if (singleProjectDetails?.applicationType === ".exe") {
  //   applicationType = "Exe";
  // } else if (singleProjectDetails?.applicationType === ".sh") {
  //   applicationType = "Sh";

  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     debugger;
  //     if (query.get("applicationType"))
  //       dispatch(
  //         setFormDetails({
  //           applicationType: query.get("applicationType"),
  //         })
  //       );
  //   }, 1000);
  // }, [query.get("applicationType")]);

  const dispatch = useDispatch();
  const handleChange = async (e) => {
    const buildURL = projectFormDetails?.buildURL;
    const appType =
      projectFormDetails?.applicationType || query.get("applicationType");
 
    if (appType !== e?.target?.value && buildURL) {
      const ifConfirm = window.confirm(
        "Are you sure you want to change the application type?"
      );
      if (ifConfirm) {
        dispatch(
          setFormDetails({
            applicationType: e?.target?.value,
            buildURL: "",
          })
        );
        setFiles({
          ...files,
          apk: "",
          exe: "",
          sh: "",
          name: "",
        });
      }
    } else {
      dispatch(
        setFormDetails({
          applicationType: e?.target?.value,
        })
      );
    }

  };

  return (
    <div>
      <select
        name="filetype"
        id="filetype"
        className="border-0 "
        onChange={handleChange}
        value={
          projectFormDetails?.applicationType ||
          singleProjectDetails?.applicationType
        }
      >
        {/* <option id="default_application_type" value="" selected hidden>
          {mapPlatform(
            projectFormDetails?.applicationType ||
              singleProjectDetails?.applicationType
          )}
        </option> */}
        <option value=".apk">Android</option>
        <option value=".exe">Window</option>
        {/* <option value=".sh">Linux</option> */}
      </select>
    </div>
  );
};
