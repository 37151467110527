import styled from "styled-components";

export const ShowVersionTxt = styled.div`
  text-align: center;
  width: 100%;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
  cursor: pointer;
`;
