import React, { useState, useEffect } from "react";
import { CustomModal } from "../Modal/CustomModal";
import {
  CustomInput,
  CustomLabel,
  CutomCancelBtn,
} from "../styled/payment.styled";
import { ButtonSecondary } from "components/styled/common.styled";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateCoupons, upDateCouponsById } from "redux/Project/project.action";
import { toast } from "react-toastify";

const CreateCopoun = ({ setIsOpen, isOpen }) => {
  const { getCouponsById } = useSelector((state) => state.projectReducer);

  const [searchQuery] = useSearchParams();
  const appId = searchQuery.get("appId");

  const { appVersionDetails } = useSelector((state) => state?.projectReducer);

  let price = appVersionDetails?.priceInfo?.pricing[0]?.applicationPrice;

  useEffect(() => {
    if (appVersionDetails && price) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      price = appVersionDetails?.priceInfo?.pricing[0]?.applicationPrice;
    }
  }, [appVersionDetails, price]);

  const dispatch = useDispatch();

  const [inputValue, setInputinputValue] = useState({
    createdBy: "",
    applicationID: appId,
    offertype: "",
    amount: "",
    startDate: "",
    endDate: "",
    couponDescription: "",
    noOfCoupons: "",
    couponName: "",
    couponCode: "",
  });

  const InputValueHandle = (e) => {
    const { name, value, type } = e.target;

    if (type === "radio") {
      setInputinputValue({ ...inputValue, [name]: value });
    } else {
      if (name === "couponCode") {
        setInputinputValue({ ...inputValue, [name]: value.toUpperCase() });
      } else {
        setInputinputValue({ ...inputValue, [name]: value });
      }
    }
  };

  const validateInput = (inputValue, price) => {
    const requiredFields = [
      "createdBy",
      "applicationID",
      "offertype",
      "amount",
      "startDate",
      "endDate",
      "couponDescription",
      "noOfCoupons",
      "couponName",
      "couponCode",
    ];

    for (const field of requiredFields) {
      if (!inputValue[field]) {
        return "please fill data";
      }
    }

    if (inputValue.offertype === "flat" && inputValue.amount > price) {
      return "Amount must be less than the Application price for Flat type.";
    }

    if (
      inputValue.offertype === "percentage" &&
      parseFloat(inputValue.amount) > 100
    ) {
      return "offertype percentage must not be greater than 100%";
    }

    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationError = validateInput(inputValue, price);
    if (validationError) {
      toast.error(validationError);
      return;
    }

    if (inputValue.offertype === "flat" && inputValue.amount > price) {
      toast.info("Amount must be less than the Application price for Flat type.");
      return;
    }

    if (
      inputValue.offertype === "percentage" &&
      parseFloat(inputValue.amount) > 100
    ) {
      toast.info("Offer type percentage must not be greater than 100%");
      return;
    }

    if (isOpen.id) {
      updateCoupon();
    } else {
      generateCoupon();
    }
  };

  const updateCoupon = () => {
    dispatch(upDateCouponsById(isOpen.id, inputValue))
      .then((res) => {
        if (res) {
          toast.info(res.message);
        }
        resetForm();
      })
      .catch(handleError);
  };

  const generateCoupon = () => {
    dispatch(generateCoupons(inputValue))
      .then((res) => {
        if (res?.response?.data?.message) {
          toast.info(res?.response?.data?.message);
        } else if (res?.payload?.message === "Coupon created successfully") {
          toast.success(res?.payload?.message);
          resetForm();
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .catch(handleError);
  };

  const resetForm = () => {
    window.location.reload();
    setIsOpen(false);
    setInputinputValue({
      createdBy: "",
      applicationID: appId,
      offertype: "",
      amount: "",
      startDate: "",
      endDate: "",
      couponDescription: "",
      noOfCoupons: "",
      couponName: "",
      couponCode: "",
    });
  };

  const handleError = (error) => {
    console.error("An error occurred:", error);
  };

  useEffect(() => {
    if (isOpen?.id && getCouponsById) {
      setInputinputValue({
        createdBy: getCouponsById.createdBy,
        applicationID: appId,
        offertype: getCouponsById.offertype,
        amount: getCouponsById.amount,
        startDate: getCouponsById.startDate,
        endDate: getCouponsById.endDate,
        couponDescription: getCouponsById.couponDescription,
        noOfCoupons: getCouponsById.noOfCoupons,
        couponName: getCouponsById.couponName,
        couponCode: getCouponsById.couponCode,
      });
    }
  }, [appId, getCouponsById, isOpen?.id]);

  return (
    <div className="row m-2 ">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h6 style={{ fontSize: "16px" }}>Discounts</h6>
        <div>
          <ButtonSecondary
            width="200px"
            color="#6C56FB"
            border="#6C56FB"
            onClick={() => setIsOpen({ ...isOpen, modal: true })}
          >
            + Generate Coupon
          </ButtonSecondary>
        </div>
      </div>

      <CustomModal
        isOpen={isOpen?.modal}
        setIsOpen={setIsOpen}
        key={"coupon"}
        size="lg"
        title={"Genarate Coupon"}
      >
        <div className="row">
          <div className="col-6 mt-4">
            <CustomLabel>CreatedBy</CustomLabel>
            <CustomInput
              type="text"
              required
              placeholder="0000"
              name="createdBy"
              value={inputValue.createdBy}
              onChange={InputValueHandle}
            />
          </div>
          <div className="col-6 mt-4">
            <CustomLabel>ApplicationID</CustomLabel>
            <CustomInput
              type="text"
              required
              placeholder="0000"
              name="applicationID"
              value={inputValue.applicationID}
              onChange={InputValueHandle}
              readOnly
            />
          </div>
          <div className="col-6 mt-4">
            <CustomLabel>Offertype</CustomLabel>
            <div style={{ display: "flex", gap: "15px" }}>
              <label style={{ display: "flex", gap: "5px" }}>
                <input
                  type="radio"
                  name="offertype"
                  value="flat"
                  checked={inputValue.offertype === "flat"}
                  onChange={InputValueHandle}
                />
                Flat
              </label>
              <label style={{ display: "flex", gap: "5px" }}>
                <input
                  type="radio"
                  name="offertype"
                  value="percentage"
                  checked={inputValue.offertype === "percentage"}
                  onChange={InputValueHandle}
                />
                Percentage
              </label>
            </div>
          </div>
          <div className="col-6 mt-4">
            <CustomLabel>Amount</CustomLabel>
            <CustomInput
              type="number"
              required
              placeholder="0000"
              name="amount"
              value={inputValue.amount}
              onChange={InputValueHandle}
            />
          </div>
          <div className="col-6 mt-4">
            <CustomLabel>StartDate</CustomLabel>
            <CustomInput
              type="date"
              required
              placeholder="0000"
              name="startDate"
              value={inputValue.startDate?.split("T")[0]}
              onChange={InputValueHandle}
            />
          </div>{" "}
          <div className="col-6 mt-4">
            <CustomLabel>EndDate</CustomLabel>
            <CustomInput
              type="date"
              required
              placeholder="0000"
              name="endDate"
              value={inputValue.endDate?.split("T")[0]}
              onChange={InputValueHandle}
            />
          </div>{" "}
          <div className="col-6 mt-4">
            <CustomLabel>Coupon Description</CustomLabel>
            <CustomInput
              type="text"
              required
              placeholder="0000"
              name="couponDescription"
              value={inputValue.couponDescription}
              onChange={InputValueHandle}
            />
          </div>{" "}
          <div className="col-6 mt-4">
            <CustomLabel>Number of Coupons</CustomLabel>
            <CustomInput
              type="text"
              required
              placeholder="0000"
              name="noOfCoupons"
              value={inputValue.noOfCoupons}
              onChange={InputValueHandle}
            />
          </div>{" "}
          <div className="col-6 mt-4">
            <CustomLabel>Coupon Name</CustomLabel>
            <CustomInput
              type="text"
              required
              placeholder="0000"
              name="couponName"
              value={inputValue.couponName}
              onChange={InputValueHandle}
            />
          </div>{" "}
          <div className="col-6 mt-4">
            <CustomLabel>Coupon Code</CustomLabel>
            <CustomInput
              type="text"
              required
              placeholder="0000"
              name="couponCode"
              value={inputValue.couponCode}
              onChange={InputValueHandle}
            />
          </div>
          <div style={{ width: "100%", textAlign: "right", marginTop: "1rem" }}>
            <CutomCancelBtn className="mx-4 bg1" onClick={handleSubmit}>
              Generate
            </CutomCancelBtn>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default CreateCopoun;
