const {
  projectFormInitialState,
  accountDetail,
} = require("ui/NewProject/constant");
const {
  SET_PROJECT_FORM_DETAILS,
  SET_PRICE,
  GET_ALL_PROJECTS,
  SET_RADIO_BUTTON,
  EMPTY_FORM_DETAILS,
  GET_PROJECT_BY_ID,
  GETRC_WITH_LIVE_APPLICATION,
  GET_APPLICATION_DATA,
  GET_APP_REVIEW_STATUS,
  GET_APPS_IN_REVIEW,
  GET_ALL_SECTORS,
  GET_SECTOR_BY_ID,
  CREATE_RELEASE_CHANNEL,
  CREATE_DRAFT_AND_SAVE,
  GET_ALL_SAVE_DRAFT_PROJECT,
  GET_SINGLE_DRAFT_PROJECT,
  DELETE_DRAFT_PROJECT_BY_ID,
  GET_ACTIVE_USERS,
  GET_APP_DOWNLOADS,
  GET_APP_EARNINGS,
  GET_USERS_BY_REGION,
  GET_MISCELLANEOUS_DATA,
  GET_OVERALL_DOWNLOADS,
  GET_OVERALL_EARNINGS,
  GET_OVERALL_USERS,
  GET_OVERALL_USERS_BY_REGION,
  RESET_STATE,
  GET_APP_RATINGS,
  GET_APP_REVIEWS,
  GET_BANK_ACCOUNT_LIST,
  SET_ACCOUNT_FROM_DETAILS,
  SET_PRIMARY_ACCOUNT,
  DELETE_ACCOUNT,
  GET_ALL_STATES,
  GET_ALL_CITIES,
  GET_BANK_DETAIL,
  CREATE_COUPON,
  GET_COUPONS_BYAPPID,
  DELETE_COUPONBYID,
  UPDATE_COUPON_BYID,
  GET_COUPONS_BYID,
  GET_STORAGE,
  DELETE_PROJECT,
  CREATE_COMMENT,
  GET_ALL_SEARCH,
  GET_ALL_VERSIONS_HISTORY,
  SET_PRICE_DETAILS,
  ADD_USER,
  DELETE_USER,
  GET_INVITED_USERS,
  GET_ALL_STORAGE,
  GET_STORAGE_PROJECTWISE,
  GET_APP_INFO,
  GET_ALL_REJECTED_APPS,
  GET_Live_PROJECTS,
} = require("./project.types");

const initialState = {
  projectFormDetails: projectFormInitialState,
  projectList: [],
  projectDetails: {},
  allProjects: [],
  singleProjectDetails: {},
  price: null,
  releaseChannelsApps: [],
  createSaveDraft: [],
  inputValue: {
    channelRadio: "",
  },
  appVersionDetails: {},
  appReviewStatus: {},
  appsInreview: [],
  sectors: [],
  subSectors: [],
  allDraftProjects: [],
  allRejectedApps: [],
  singleDraftProjectDetails: {},
  activeUsers: [],
  appDownloads: [],
  appEarnings: [],
  usersByRegion: [],
  miscData: {},
  overallDownloads: [],
  overallEarnings: [],
  overallUsers: [],
  overallUsersByRegion: [],
  ratingsData: {},
  appReviewsData: [],
  bankAccounts: [],
  allStates: [],
  allCities: [],
  bankDetail: {},
  createCoupons: [],
  getAllCouponsByAppId: [],
  deleteCouponById: {},
  updateCouponsById: {},
  getCouponsById: {},
  storageData: {},
  totalCountsDownload: 0,
  totalCountsUsers: 0,
  totalCountsEarnings: 0,
  totalCountsDownloadApp: 0,
  totalCountsUsersApp: 0,
  totalCountsEarningsApp: 0,
  searchData: {},
  fixedSetPrice: null,
  users: [],
  getUsers: [],
  getAllstorageData: {},
  getstorageProjectwise: [],
  appInfo: {},
  allLiveProjects: [],
};

const handleSetProjectFormDetails = (state, action) => {
  if (!action.payload || !Object.values(action.payload).length) {
    return state;
  }
  return {
    ...state,
    projectFormDetails: {
      ...state.projectFormDetails,
      ...action.payload,
      mediafileURLs: {
        ...state.projectFormDetails.mediafileURLs,
        ...action.payload.mediafileURLs,
        mediaSize:
          Number(action?.payload?.fileSize || 0) +
          Number(state.projectFormDetails?.mediafileURLs?.mediaSize),
      },
    },
  };
};

const handleSetAccountFormDetails = (state, action) => {
  if (!action.payload || !Object.values(action.payload).length) {
    return state;
  }
  return {
    ...state,
    accountDetail: {
      ...state.accountHolderName,
      ...state.accountNumber,
      ...state.accountType,
      ...state.bankName,
      ...state.IFSCode,
      ...state.bankAddress,
      ...state.bankCity,
      ...state.bankState,
      ...state.bankPinCode,
    },
  };
};

const handleCreateCoupon = (state, action) => {
  return {
    ...state,
    getAllCouponsByAppId: [
      ...state.getAllCouponsByAppId,
      action.payload.newCoupon,
    ],
  };
};

const handleDeleteDraftProjectById = (state, action) => {
  return {
    ...state,
    allDraftProjects: state.allDraftProjects.filter((item) =>
      action.payload.some((r) => r !== item?._id)
    ),
  };
};
const handleDeleteProjectById = (state, action) => {
  return {
    ...state,
    allProjects: state.allProjects.filter((item) =>
      action.payload?.some((r) => r !== item?._id)
    ),
  };
};

const handleSetPrimaryAccount = (state, action) => {
  return {
    ...state,
    bankAccounts: state.bankAccounts.map((item) =>
      item._id === action.payload
        ? { ...item, isPrimary: true }
        : { ...item, isPrimary: false }
    ),
  };
};

const handleDeleteAccount = (state, action) => {
  return {
    ...state,
    bankAccounts: state.bankAccounts.filter(
      (item) => action.payload !== item?._id
    ),
  };
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_FORM_DETAILS:
      return handleSetProjectFormDetails(state, action);
    case SET_ACCOUNT_FROM_DETAILS:
      return handleSetAccountFormDetails(state, action);
    case CREATE_COUPON:
      return handleCreateCoupon(state, action);
    case DELETE_DRAFT_PROJECT_BY_ID:
      return handleDeleteDraftProjectById(state, action);
    case SET_PRIMARY_ACCOUNT:
      return handleSetPrimaryAccount(state, action);
    case DELETE_ACCOUNT:
      return handleDeleteAccount(state, action);
    // Add other cases here, following the pattern above
    case SET_PRICE:
      return { ...state, price: action.payload };
    case GET_ALL_PROJECTS:
      return {
        ...state,
        allProjects: action.payload,
        projectDetails: action.payload,
      };
    case GET_Live_PROJECTS:
      return {
        ...state,
        allLiveProjects: action.payload,
      };
    case GET_PROJECT_BY_ID:
      return {
        ...state,
        singleProjectDetails: action.payload,
        projectDetails: action.payload,
        projectFormDetails: action.payload,
      };
    case SET_RADIO_BUTTON:
      return {
        ...state,
        inputValue: {
          ...state.inputValue,
          channelRadio: action.payload,
        },
      };
    case EMPTY_FORM_DETAILS:
      return {
        ...state,
        projectFormDetails: projectFormInitialState,
      };
    case GETRC_WITH_LIVE_APPLICATION:
      return {
        ...state,
        releaseChannelsApps: action.payload,
      };
    case GET_APPLICATION_DATA:
      return {
        ...state,
        appVersionDetails: action.payload,
      };
    case GET_APP_REVIEW_STATUS:
      return {
        ...state,
        appReviewStatus: action.payload,
      };
    case GET_APPS_IN_REVIEW:
      return {
        ...state,
        appsInreview: action.payload,
      };
    case GET_ALL_SECTORS:
      return {
        ...state,
        sectors: action.payload,
      };
    case GET_SECTOR_BY_ID:
      return {
        ...state,
        subSectors: action.payload,
      };
    case CREATE_RELEASE_CHANNEL:
      return {
        ...state,
        releaseChannelsApps: [...state.releaseChannelsApps, action.payload],
      };
    case CREATE_DRAFT_AND_SAVE:
      return {
        ...state,
        createSaveDraft: [...state.createSaveDraft, action.payload],
      };
    case GET_ALL_SAVE_DRAFT_PROJECT:
      return {
        ...state,
        allDraftProjects: action.payload,
      };
    case GET_ALL_REJECTED_APPS:
      return {
        ...state,
        allRejectedApps: action.payload,
      };
    case GET_SINGLE_DRAFT_PROJECT:
      return {
        ...state,
        singleDraftProjectDetails: action.payload,
      };
    case GET_ACTIVE_USERS:
      return {
        ...state,
        activeUsers: action.payload,
        totalCountsUsersApp: action.totalCounts,
      };
    case GET_APP_DOWNLOADS:
      return {
        ...state,
        appDownloads: action.payload,
        totalCountsDownloadApp: action.totalCounts,
      };

    case GET_APP_EARNINGS:
      return {
        ...state,
        appEarnings: action.payload,
        totalCountsEarningsApp: action.totalCounts,
      };

    case GET_USERS_BY_REGION:
      return {
        ...state,
        usersByRegion: action.payload,
      };

    case GET_MISCELLANEOUS_DATA:
      return {
        ...state,
        miscData: action.payload || {},
      };

    case GET_OVERALL_DOWNLOADS:
      return {
        ...state,
        overallDownloads: action.payload,
        totalCountsDownload: action.totalCounts,
      };

    case GET_OVERALL_EARNINGS:
      return {
        ...state,
        overallEarnings: action.payload,
        totalCountsEarnings: action.totalCounts,
      };

    case GET_OVERALL_USERS:
      return {
        ...state,
        overallUsers: action.payload,
        totalCountsUsers: action.totalCounts,
      };
    case GET_OVERALL_USERS_BY_REGION:
      return {
        ...state,
        overallUsersByRegion: action.payload,
      };
    case GET_APP_RATINGS:
      return {
        ...state,
        ratingsData: action.payload,
      };

    case GET_APP_REVIEWS:
      return {
        ...state,
        appReviewsData: action.payload,
      };
    case RESET_STATE:
      return initialState;

    case GET_BANK_ACCOUNT_LIST:
      return {
        ...state,
        bankAccounts: action.payload.data,
        accountDetail: {
          ...state.accountHolderName,
          ...state.accountNumber,
          ...state.accountType,
          ...state.bankName,
          ...state.IFSCode,
          ...state.bankAddress,
          ...state.bankCity,
          ...state.bankState,
          ...state.bankPinCode,
        },
      };

    case GET_ALL_STATES:
      return {
        ...state,
        allStates: action.payload.data,
      };
    case GET_ALL_CITIES:
      return {
        ...state,
        allCities: action.payload.data,
      };
    case GET_BANK_DETAIL:
      return {
        ...state,
        bankDetail: action.payload || {},
      };

    case GET_COUPONS_BYAPPID:
      return {
        ...state,
        getAllCouponsByAppId: action.payload || [],
      };
    case DELETE_COUPONBYID:
      return {
        ...state,
        getAllCouponsByAppId: state.getAllCouponsByAppId.filter(
          (c) => c._id !== action.payload
        ),
      };
    case UPDATE_COUPON_BYID:
      return {
        ...state,
        updateCouponsById: action.payload || {},
      };
    case GET_COUPONS_BYID:
      return {
        ...state,
        getCouponsById: action.payload || {},
      };
    // case GET_STORAGE:
    //   return {
    //     ...state,
    //     storageData: action.payload || {},
    //   };
    case DELETE_PROJECT:
      return handleDeleteProjectById(state, action);
      // return {
      //   ...state,
      //   ...initialState,
      // };

    case GET_ALL_SEARCH:
      return { ...state, searchData: action.payload };
    case GET_ALL_VERSIONS_HISTORY:
      return {
        ...state,
        getAllversionHistory: action.payload || [],
      };
    case SET_PRICE_DETAILS:
      return {
        ...state,
        fixedSetPrice: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user.emailId !== action.payload),
      };
    case GET_INVITED_USERS:
      return {
        ...state,
        getUsers: action.payload,
      };

    case GET_APP_INFO:
      return {
        ...state,
        appInfo: action.payload,
      };
    case GET_ALL_STORAGE:
      return {
        ...state,
        getAllstorageData: action.payload,
      };
    case GET_STORAGE_PROJECTWISE:
      return {
        ...state,
        getstorageProjectwise: action.payload?.projects || [],
        storageData: {
          totalAllocated: action.payload?.totalStorageAllocated,
          totalUsed: action.payload?.totalStorageUsed,
        },
      };

    default:
      return state;
  }
};

export default projectReducer;
