import { SelectFileType } from "components/Select/SelectFileType";
import { ProjectRows } from "components/Table/ProjectRows";
import { CardBlackWhite } from "components/card/Card";
import {
  ButtonPrimary,
  TextBlack,
  TextWhite,
} from "components/styled/common.styled";
import Info from "assets/info.svg";

import React, { useEffect, useState } from "react";

import { ProjectInfo } from "components/ProjectInfo/ProjectInfo";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createReleaseChannel,
  getSingleProjectDetails,
} from "redux/Project/project.action";
import { Modal } from "react-bootstrap";
import CustomBreadcrumb from "components/CustomBreadScrum";
import { toast } from "react-toastify";
import TableSkelton from "components/TableSkelton/TableSkelton";

const ProjectDetails = () => {
  const { projectId } = useParams();
  const { projectFormDetails, singleProjectDetails } = useSelector(
    (state) => state.projectReducer
  );

  const [isProjectLoading, setIsProjectLoading] = useState(true);

  const [rcPayload, setRcPayload] = useState({});
  const dispatch = useDispatch();
  const segments = [
    {
      text: "Projects",
      link: "/home",
    },
    {
      text: singleProjectDetails?.projectName,
      link: `/projectDetails/${projectId}`,
    },
  ];
  useEffect(() => {
    setRcPayload({
      publisherID: "",
      projectId: projectId,
      rcType: "ProjectBased",
      rcApplicationType: projectFormDetails?.applicationType ,
      rcDescription:
        "This will be a private release channel. You can invite users to this channel to test your Builds",
    });
    setTimeout(() => {
      setIsProjectLoading(false);
    }, 200);
  }, [projectFormDetails, projectId]);

  const [isOpen, setIsOpen] = useState({
    modal: false,
  });

  useEffect(() => {
    setTimeout(() => {
      dispatch(getSingleProjectDetails("", projectId));
    }, 200);
  }, [dispatch, projectId]);

  const upperContent = [
    <div className="d-flex gap-3">
      Release Channels
      <SelectFileType />
      <img src={Info} alt="img" />
    </div>,

    <ButtonPrimary
      onClick={() => setIsOpen({ ...isOpen, modal: true })}
      width="200px"
      height="40px"
      bg="#f2f2f2"
      color="#3c3e43"
      hover="light"
      
    >
      + New Channel
    </ButtonPrimary>,
  ];

  const apiParameters = {
    projectId: projectId,
    applicationType: projectFormDetails?.applicationType
  };

  const createRcChannel = () => {
    if (rcPayload.rcName === "") {
      toast.warning("Please enter channel name");
      return;
    }

    dispatch(createReleaseChannel(rcPayload)).then(() => {
      toast.success("Channel created successfully");
    });
    setIsOpen({ ...isOpen, modal: false });
    setRcPayload({ ...rcPayload, rcName: "" });
  };

  const handleChangeRcName = (e) => {
    const { name, value } = e.target;
    setRcPayload({ ...rcPayload, [name]: value });
  };

  return (
    <div className="mx-4 col-11">
      <CustomBreadcrumb items={segments} />

      <ProjectInfo editIcon={true} delICon={true} />
      {isProjectLoading ? (
        <TableSkelton />
      ) : (
        <div className="py-3 my-2">
          <CardBlackWhite
            upperContent={upperContent}
            lowerContent={
              <ProjectRows
                apiParameters={apiParameters}
                setIsProjectLoading={setIsProjectLoading}
              />
            }
          />
        </div>
      )}

      <Modal
        show={isOpen.modal}
        id="custom-modal"
        size="lg"
        onHide={() => setIsOpen(false)}
      >
        <CardBlackWhite
          upperContent={[
            <Modal.Header key="channel" className="w-100" closeButton>
              <TextWhite className="fs-20">New Channel</TextWhite>
            </Modal.Header>,
          ]}
          lowerContent={
            <Modal.Body>
              <div className="create-rcName">
                <p className="">
                  This will be a private release channel. You can invite users
                  to this channel to test your Builds.{" "}
                </p>
                <div className="d-flex rcName-input">
                  <label htmlFor="">
                    Set Release Channel Name{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Release Channel Name"
                    name="rcName"
                    style={{
                      background: "transparent",
                      padding: "10px",
                    }}
                    value={rcPayload.rcName}
                    onChange={handleChangeRcName}
                    required
                  />
                </div>
                <div className="d-flex gap-3 cc-btn my-2">
                  <button
                    className="cc-btn1"
                    onClick={() => {
                      setRcPayload({ ...rcPayload, rcName: "" });
                      setIsOpen({ ...isOpen, modal: false });
                    }}
                  >
                    Cancel
                  </button>
                  <button className="cc-btn2" onClick={createRcChannel}>
                    Create
                  </button>
                </div>
              </div>
            </Modal.Body>
          }
        />
      </Modal>
    </div>
  );
};

export default ProjectDetails;
