import { styled } from "styled-components";

export const DiscountCont = styled.div`
  display: flex;
  width: 48%;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  border: 1px dashed #000;
  background: var(--light-mode-neutral-color-neutral-2, #f5f5f5);
`;
export const LeftSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &.bottom {
    margin-top: 20px;
  }
  &.top {
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 10px;
  }
`;
export const DisName = styled.div`
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.124px;
`;
export const DisCode = styled.div`
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  &.fs-12-400 {
    font-size: 12px;
    font-weight: bold;
  }
`;
export const NameAnduse = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
`;
export const CodeFile = styled.div`
  display: flex;
  alignitems: center;
  gap: 6px;
`;
export const CardInnerCont = styled.div`
  display: flex;
  flexdirection: column;
  justifycontent: space-between;
  width: 100%;
`;
