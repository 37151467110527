/* eslint-disable react-hooks/exhaustive-deps */
import { CardBlackWhite, CardBox } from "components/card/Card";
import {
  ButtonPrimary,
  ButtonSecondary,
  DetailsWrapper,
  OverFlow,
  Price,
  ProjectName,
  TagWrapper,
  TextBlack,
  TextMiddle,
} from "components/styled/common.styled";
import React, { useEffect, useMemo, useState } from "react";
import imgIconWhite from "assets/imgIconWhite.svg";
import imgIconColor from "assets/imageIconColor.svg";
import add from "assets/add.svg";
import uploadApk from "assets/uploadApk.svg";
import uploadApkColor from "assets/uploadApkColor.svg";
import exe from "assets/exeWhite.svg";

import exeColor from "assets/exeColor.svg";
import imgIconWhite2 from "assets/imgIconWhite2.svg";
import cross from "assets/crossNew.svg";
import analyticsIcon from "assets/analyticsIcon.png";
import {
  CardBoxImage,
  CardDescriptionWrapper,
  CardModalBody,
} from "components/card/card.styled";

import Info from "assets/info.svg";

import { EmptyRow } from "components/Table/EmptyRow";
import { CustomModal } from "components/Modal/CustomModal";
import { SelectFileType } from "components/Select/SelectFileType";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SelectInput } from "components/Select/SelectInput";
import { EditBtn } from "components/EditButton/EditBtn";
import { CustomTabs } from "components/Tabs/CustomTabs";
import { CustomPane } from "components/Tabs/customTabs.styled";
import { Media } from "components/Media/Media";
import { Reviews } from "components/Reviews/Reviews";
import { PriceTabs } from "components/PriceField/PriceTabs";
import { projectFormInitialState, publisherForData } from "./constant";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDraftProjects,
  getAllProjects,
  getAllSectors,
  getApplicationDetails,
  getSingleDraftProjectDetails,
  getSingleProjectDetails,
  getSubSectorsById,
  resetState,
  saveAndDraft,
  setFormDetails,
} from "redux/Project/project.action";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { getDateByUpdate, getUserToken, mapPlatform } from "helper";
import {
  CardContainer,
  KeyTxt,
  LiveTxt,
  TextBtn,
  TextBtnCont,
} from "components/styled/ProjectDetails.style";
import { KeyValue } from "components/styled/projectreview.styled";
import CustomBreadcrumb from "components/CustomBreadScrum";
import { ProjectInfo } from "components/ProjectInfo/ProjectInfo";
import Uploader from "ajnavidya-file-upload-client";

import { checkErrors } from "utils/errorHandling";
import { toast } from "react-toastify";
import VersionHistory from "ui/Versions/VersionHistory";
import { ChannelUsers } from "components/Table/ChannelUsers";
import { ThumnailUpload } from "components/ThumbnailUploadModal/ThumnailUpload";
import CaverInfoCard from "components/CaverInfoCard/CaverInfoCard";
const { publisher } = require("redux/api");

export const NewProject = () => {
  const [isHovered, setIsHovered] = useState(false);
  const { name, s3Key } = useParams();
  const [isHovered2, setIsHovered2] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileURL, setFileURL] = useState("");
  const [isFormDateLoaded, setIsFormDateLoaded] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  let id = searchParams.get("appId") ? searchParams.get("appId") : null;
  id = id?.length > 10 ? id : null;

  let draft = searchParams.get("query") ? searchParams.get("query") : null;

  const projectId = searchParams.get("projectId");
  let appId = searchParams.get("appId") ? searchParams.get("appId") : null;
  appId = appId?.length > 10 ? appId : null;
  const infoType = searchParams.get("infoType");
  const rcName = searchParams.get("rcName");
  const draftId = searchParams.get("draftId");
  const appType = searchParams.get("applicationType");
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();
  const [files, setFiles] = useState({
    apk: "",
    exe: "",
    logoURL: "",
    sh: "",
    name: "",
  });
  const [isLoading, setIsLoading] = useState({
    apk: false,
    exe: false,
    sh: false,
    logoURL: false,
    submit: false,
    draft: false,
  });
  const [submitLoader, setSubmitLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState({
    apk: "",
    exe: "",
    logoURL: "",
  });
  const [isProjectExits, setIsProjectExits] = useState(null);
  const [dataLoaded, setDataLoeaded] = useState(false);
  const [percentCompleted, setPercentCompleted] = useState(0);

  const {
    projectFormDetails,
    projectDetails,
    sectors,
    subSectors,
    price,
    singleProjectDetails,
  } = useSelector((state) => state.projectReducer);

  const [projectForm, setProjectForm] = useState(projectFormInitialState);
  const [isOpen, setIsOpen] = useState({
    modal: false,
    project: false,
    id: "",
  });
  const [error, setError] = useState(null);

  const [modal, setModal] = useState({ modal: false, text: "" });

  const navigate = useNavigate();

  const slicedContent = projectFormDetails?.projectBrief?.slice(0, 50);

  // Create a temporary div element
  const tempDiv = document.createElement("div");

  // Set the innerHTML of the div to your HTML snippet
  tempDiv.innerHTML = slicedContent;

  // Retrieve the text content from the div
  const textContent = tempDiv.textContent || tempDiv.innerText;

  const handleOpenModal = () => {
    setIsOpen({ ...isOpen, modal: true });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  const lastWord = window.location.href.split("=").pop();

  const handleThumbnailChange = (e) => {
    setError(null);
    const selectedImage = e.target.files[0];
    if (e) {
      setFileName(e.target.files[0].name);
    }
    // Image size validation (max 5MB in this example)
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (selectedImage.size > maxSize) {
      setError("Image size is too large. Max size allowed: 5MB");
      return;
    }

    // Image aspect ratio validation (16:10 with a tolerance of 0.05)
    const targetAspectRatio = 1;
    const image = new Image();
    image.src = URL.createObjectURL(selectedImage);
    image.onload = () => {
      const aspectRatio = image.width / image.height;
      const tolerance = 0.8;
      if (Math.abs(aspectRatio - targetAspectRatio) > tolerance) {
        setError("Image aspect ratio must be 1:1");
        return;
      }

      setFiles({
        ...files,
        logoURL: e,
      });
    };
  };

  const upperContent = [
    <div key={"release channel"} className="d-flex gap-3 fs-18 text-capitalize">
      {name || rcName} Release Channel
      {!id && !appType && <SelectFileType files={files} setFiles={setFiles} />}
      {/* {!id && projectFormDetails?.buildURL ? (
        <div
          className="py-1 px-2 fs-14"
          style={{ background: "#303136", borderRadius: "8px" }}
        >
          {mapPlatform(projectFormDetails?.applicationType || appType)}
        </div>
      ) : !id ? (
        <SelectFileType />
      ) : (
        <></>
      )} */}
      {!id && appType && (
        <div
          className="py-1 px-2 fs-14"
          style={{ background: "#303136", borderRadius: "8px" }}
        >
          {mapPlatform(appType || projectFormDetails?.applicationType)}
        </div>
      )}
      {id && (
        <div
          className="py-1 px-2 fs-14"
          style={{ background: "#303136", borderRadius: "8px" }}
        >
          {mapPlatform(appType || projectFormDetails?.applicationType)}
        </div>
      )}
      {/* <img src={Info} alt="img" /> */}
      {/* <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="tooltip" className="custom-tooltip">
        
            <CaverInfoCard
              text={
                "A cover image is the first visual impression users have of your experience. It's essential to follow the suggested file size, resolution, and ratios to ensure the best display quality."
              }
            />
          </Tooltip>
        }
      >
        <img src={Info} alt="img" />
      </OverlayTrigger> */}
    </div>,
    (!id ||
      (id &&
        searchParams.get("infoType") !== "Reviews" &&
        searchParams.get("infoType") !== "Manage Pricing")) && (
      <ButtonPrimary
        id="uploadThumbnailModal"
        onClick={() => {
          id &&
            searchParams.get("infoType") === "Overview" &&
            navigate(
              `/appDetails/${s3Key}?rcName=${rcName}&appId=${appId}&projectId=${projectId}&applicationType=${appType}&infoType=new&query=null`
            );

          setIsOpen({ ...isOpen, project: true });
        }}
        width="200px"
        height="40px"
        bg="#f2f2f2"
        key={"upload thumbnail"}
        color="#3c3e43"
        hover="light"
      >
        {!id && "+ Upload Build"}
        {id && searchParams.get("infoType") === "new" && "+ Upload Build"}
        {id &&
          searchParams.get("infoType") === "Overview" &&
          "+ Upload New Version"}
      </ButtonPrimary>
    ),
  ];

  useEffect(() => {
    dispatch(getAllSectors()).then((res) => {
      if (res) {
        setIsFormDateLoaded(false);
      }
    });
    if (id) return;

    dispatch(
      setFormDetails({
        rcName: name ?? rcName,
        version: "v0.0.1",
        s3Key: s3Key,
      })
    );
  }, [name, id, s3Key]);

  useEffect(() => {
    if (!projectFormDetails?.projectName) return;

    if (appId || projectId) return;
    const fetchTimer = setTimeout(() => {
      dispatch(getAllProjects("", "", projectFormDetails?.projectName));
    }, [200]);
    return () => clearTimeout(fetchTimer);
  }, [appId, projectFormDetails?.projectName, projectId]);

  useEffect(() => {
    if (!projectDetails?.projectName && projectFormDetails?.projectName) {
      setIsProjectExits(false);
      return;
    }
    if (
      projectDetails?.projectName?.toLowerCase()?.trim() ===
        projectFormDetails?.projectName?.toLowerCase()?.trim() &&
      searchParams.get("infoType") !== "new"
    ) {
      setIsProjectExits(true);
      return;
    } else {
      setIsProjectExits(false);
      return;
    }

    // if (!projectFormDetails?.projectName) return;

    // dispatch(
    //   setFormDetails({
    //     projectName: projectFormDetails?.projectName,
    //     sector: projectDetails?.sector,
    //     subSector: projectDetails?.subSector,
    //   })
    // );
  }, [projectDetails, projectFormDetails?.projectName]);

  // api call for app version details while switching the info types
  useEffect(() => {
    if (appId) {
      setDataLoeaded(false);

      dispatch(
        getApplicationDetails(
          "",
          projectId,
          id,
          rcName,
          infoType === "new" ? "Overview" : infoType
        )
      ).then((res) => {
        setDataLoeaded(true);
      });
    }
  }, [appId, id, infoType, projectId, rcName]);

  const handleDivClick = (value, appType) => {
    if (appType === "Android") {
      const simulatedEvent = {
        target: {
          name: "apkSupportedPlatforms",
          value: value,
        },
      };
      handleChange(simulatedEvent);
    }
    if (appType === "Windows") {
      const simulatedEvent = {
        target: {
          name: "appStreamingType",
          value: value,
        },
      };
      handleChange(simulatedEvent);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "hashtags") {
      dispatch(
        setFormDetails({
          [name]: value.split(" "),
        })
      );
    } else if (name === "languageSupported") {
      dispatch(
        setFormDetails({
          languageSupported: [value],
        })
      );
    } else if (name === "sector") {
      const selectedSector = sectors.find((sector) => sector.sector === value);
      if (selectedSector) {
        dispatch(
          setFormDetails({
            sector: value,
          })
        );
        dispatch(
          setFormDetails({
            subSector: "",
          })
        );
        const sectorId = selectedSector.sectorId;
        // You can now use the selected sectorId as needed.
        dispatch(getSubSectorsById(sectorId));
      }
    } else
      dispatch(
        setFormDetails({
          [name]: value,
        })
      );
  };

  const saveDraft = () => {
    SaveDraftHandler(false);
  };

  useEffect(() => {
    if (!appId) {
      // Define a function to be executed after the delay
      const delayedSaveDraft = () => {
        //  check projectFormDetails?.mediafileURLs is not empty
        if (
          Object.keys(projectFormDetails?.mediafileURLs).length === 0 &&
          projectFormDetails?.buildSize === 0 &&
          projectFormDetails?.logoURL === ""
        )
          return;
        const values = Object.values(projectFormDetails?.mediafileURLs)
          .toString()
          .replace(/,/g, "");
        if (
          values !== "0" ||
          projectFormDetails?.buildSize ||
          projectFormDetails?.logoURL
        ) {
          SaveDraftHandler(false);
        }
      };

      // Set a timeout to execute the function after 100ms
      const timeoutId = setTimeout(delayedSaveDraft, 100);

      // Clean up the timeout on component unmount or when dependencies change
      return () => clearTimeout(timeoutId);
    }
  }, [projectFormDetails]);

  const MediaComp = useMemo(() => {
    return (
      <Media
        setProjectForm={setProjectForm}
        handleChange={handleChange}
        saveDraft={saveDraft}
      />
    );
  }, []);

  const fileUploader = async (file, type = "buildURL", appType) => {
    const fileSize = file.target.files[0]?.size;
    const maxFileSize = 20 * 1024 * 1024;
    const fileExtension = file.target.files[0]?.name
      ?.split(".")
      .pop()
      .toLowerCase();

    // validate file type
    if (appType === "apk") {
      if (fileExtension !== "apk") {
        toast.dismiss();
        toast.error("Upload apk files only");
        setIsLoading({ ...isLoading, [appType]: false, [type]: false });
        return;
      }
    } else if (appType === "sh" || appType === "exe") {
      if (fileExtension !== "zip") {
        toast.dismiss();
        toast.error("Upload zip files only");
        setIsLoading({ ...isLoading, [appType]: false, [type]: false });
        return;
      }
    }

    if (type === "buildURL" && fileSize <= maxFileSize) {
      toast.dismiss();
      toast.error("File size should be minimum 20mb");
      setIsLoading({ ...isLoading, [appType]: false, [type]: false });
      setFileName("");
      return;
    }
    //check if executable file path given or not for .exe and .sh
    if (
      type === "buildURL" &&
      (projectFormDetails?.applicationType === ".exe" ||
        projectFormDetails?.applicationType === ".sh") &&
      !projectFormDetails?.executableFileName
    ) {
      toast.dismiss();
      toast.error("Executable file path is required");
      setIsLoading({ ...isLoading, [appType]: false, [type]: false });
      return;
    }

    //check if streamable type given or not for .exe
    if (
      type === "buildURL" &&
      (projectFormDetails?.applicationType === ".exe" ||
        projectFormDetails?.applicationType === ".sh") &&
      !projectFormDetails?.appStreamingType
    ) {
      toast.dismiss();
      toast.error("Application streaming type is required");
      setIsLoading({ ...isLoading, [appType]: false, [type]: false });
      return;
    }

    // check if supported platforms given or not for .apk
    if (
      type === "buildURL" &&
      projectFormDetails?.applicationType === ".apk" &&
      !projectFormDetails?.apkSupportedPlatforms
    ) {
      toast.error("Supported platforms is required");
      setIsLoading({ ...isLoading, [appType]: false, [type]: false });
      return;
    }

    setIsLoading({ ...isLoading, [appType]: true, [type]: true });
    try {
      Uploader({
        file: file,
        url: {
          apiOrigin: "https://file-uploadv2.ajnavidya.com/",
          status: "/upload/status",
          upload: "/upload/files",
          complete: "/upload/complete",
        },
        projectID: s3Key,
        setFileURL: setFileURL,
        setProgress: setProgress,
        userID: getUserToken()?.sub,
      });

      document.addEventListener("fileStateChanged", (event) => {
        const progress1 = event.progress;
        setPercentCompleted(progress1);
        if (progress1 === 100) {
          setPercentCompleted(100);
          setIsLoading({
            ...isLoading,
            [appType]: false,
            [type]: false,
          });
        }
      });

      document.addEventListener(file.target.name, (event) => {
        const fileURL = event.data;
        setIsLoading({ ...isLoading, [appType]: false });
        setIsFormDateLoaded(false);
        setSuccessMessage({
          ...successMessage,
          [type]: "File Uploaded Successfully - 100%",
        });
        setPercentCompleted(100);
        setIsOpen({
          ...isOpen,
          [(type === "logoURL" && "modal") ||
          (type === "buildURL" && "project")]: false,
        });
        setPercentCompleted(0);
        dispatch(
          setFormDetails({
            ...projectFormDetails,
            [type]: fileURL?.url,
            buildSize: type === "buildURL" ? fileSize : 0,
            fileSize: type === "logoURL" ? fileSize : 0,
          })
        );
      });

      document.addEventListener("fileError", (event) => {
        setIsLoading({ ...isLoading, [appType]: false, [type]: false });
        setPercentCompleted(0);
        setFileName("");
        toast.error(event.error);
      });
    } catch (err) {
      setIsLoading({ ...isLoading, [appType]: false, [type]: false });
      setIsFormDateLoaded(false);
      //;
      toast.error(err && "somthing went wrong");
      return;
    }
  };

  useEffect(() => {
    if (!isOpen?.modal) {
      setSuccessMessage({
        ...successMessage,
        logoURL: "",
      });
    }
    if (!isOpen?.project) {
      setSuccessMessage({
        ...successMessage,
        apk: "",
        exe: "",
      });
    }
  }, [isOpen]);

  useEffect(() => {
    setFormDetails({ ...projectFormDetails, rcName: rcName });
  }, [projectFormDetails, rcName]);

  console.log('projectFormDetails111111', projectFormDetails);

  const formSubmitHandler = async (edited) => {
    setSubmitLoader(true);
    try {
      const message = checkErrors({
        projectFormDetails,
        projectId,
        edited,
        files,
        isProjectExits,
      });
      if (message) {
        setSubmitLoader(false);
        toast.dismiss();
        toast.error(message);
        return;
      }

      if (edited) {
        const { data } = await publisher.createProject(
          {
            ...projectFormDetails,
            s3Key: s3Key,
          },
          projectId ? projectId : ""
        );
        setSubmitLoader(false);
        if (data) {
          window.history.pushState("", "", `/home`);
          navigate(
            `/createProject/newProject/setPrice?tab=${
              (data?.rcName || projectFormDetails?.rcName) !== "production"
                ? "Free"
                : "Fixed"
            }&projectId=${data?.projectID}&appId=${data?.appID}&rcName=${
              data?.rcName || projectFormDetails.rcName
            }`
          );
        }
      } else {
        const { data } = await publisher.createProject(
          {
            ...projectFormDetails,
            hashtags: projectFormDetails?.hashtags?.filter((item) => item),
            rcName: rcName ? rcName : name,
            version:
              infoType === "newVersion"
                ? projectFormDetails?.version
                : projectFormDetails?.version || "v0.0.1",
            s3Key: s3Key,
          },
          projectId ? projectId : "",
          infoType === "newVersion" ? appId : ""
        );
        setSubmitLoader(false);
        if (data) {
          window.history.pushState("", "", `/home`);
          navigate(
            `/createProject/newProject/setPrice?tab=${
              (projectFormDetails?.rcName || name) !== "production"
                ? "Free"
                : "Fixed"
            }&projectId=${data?.projectID}&appId=${data?.appID}&rcName=${
              projectFormDetails?.rcName || name
            }`
          );
        }
        // delete draft if project is created
        if (draftId) {
          dispatch(deleteDraftProjects({ docIds: [draftId] }, "false"));
        }

        setSubmitLoader(false);
      }
    } catch (e) {
      setSubmitLoader(false);
      toast.dismiss();
      toast.error(e?.response?.data.message);
      return;
    }
  };

  // save draft function
  const SaveDraftHandler = async (isNavigate = true) => {
    setIsLoading({ ...isLoading, draft: true });
    try {
      setIsLoading({ ...isLoading, draft: false });
      setIsFormDateLoaded(false);
      const { data } = await publisher.saveAndDraft(
        {
          ...projectFormDetails,
          hashtags: projectFormDetails?.hashtags,
          rcName: rcName ? rcName : name,
          [projectId ? "projectID" : ""]: projectId || "",
        },
        draftId
      );

      !draftId &&
        !rcName &&
        setSearchParams({ query: "initialdraft", draftId: data?._id });

      rcName &&
        projectId &&
        setSearchParams({
          query: "draft",
          draftId: data?._id,
          rcName: name ?? rcName,
          appId: appId,
          projectId: projectId,
          applicationType: appType,
        });
      if (data && isNavigate) {
        setIsLoading({ ...isLoading, draft: false });
        setTimeout(() => {
          navigate("/home?infoType=Draft");
        }, 1000);
        toast.dismiss();
        toast.success("Your data is saved & drafted.");
        return;
      }
    } catch (e) {
      setIsLoading({ ...isLoading, draft: false });
      toast.dismiss();
      toast.error(e?.response?.data.message);
      return;
    }
  };

  useEffect(() => {
    // Fetch the data only if it's not already available
    dispatch(getAllSectors());
    if (searchParams.get("query") === "draft") {
      dispatch(getSingleDraftProjectDetails(draftId));
    }
  }, []);

  const segments = !rcName
    ? [
        { text: "Create Project", link: "/" },
        { text: "Select Release Channel", link: "/createProject" },
        {
          text: "Fill Project Information",
          link: `/createProject/${name}/${s3Key}`,
        },
      ]
    : [
        { text: "Projects", link: "/home" },
        {
          text: projectFormDetails?.projectName || "Untitled",
          link: `/projectDetails/${projectId}`,
        },
        {
          text: rcName,
          link: window.location.pathname + window.location.search,
        },
      ];

  console.log("segments", segments);
  useEffect(() => {
    if (!projectId) return;
    if (draft) {
      dispatch(getSingleDraftProjectDetails(draftId));
    } else {
      dispatch(
        setFormDetails({
          rcName: searchParams.get("rcName"),
        })
      );
    }
  }, [appId, draft, projectId, searchParams]);

  useEffect(() => {
    if (modal.modal) {
      const timeoutId = setTimeout(() => {
        setModal({ modal: false, text: "" });
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [modal]);

  //fetch project details when id is not available
  useEffect(() => {
    if (projectId && !appId) {
      dispatch(getSingleProjectDetails("", projectId, ""));
    }
  }, [appId, projectId]);
  console.log("thuimbnailfile", files);
  return (
    <div className="mx-4">
      <OverFlow>
        <CustomBreadcrumb items={segments} />
        {isOpen?.modal && (
          <ThumnailUpload
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleThumbnailChange={handleThumbnailChange}
            fileName={fileName}
            error={error}
            percentCompleted={percentCompleted}
            successMessage={successMessage}
            fileUploader={fileUploader}
            files={files}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setFiles={setFiles}
            handleMouseEnter2={handleMouseEnter2}
            handleMouseLeave2={handleMouseLeave2}
            isHovered2={isHovered2}
            imgIconColor={imgIconColor}
            imgIconWhite={imgIconWhite}
          />
        )}
        <input type="file" id="uploadThumbnail" hidden />
        {isOpen?.project && (
          <CustomModal
            isOpen={isOpen?.project}
            setIsOpen={setIsOpen}
            key={"xyz"}
            size="md"
            title={"Upload File"}
            openFor={"fileUpload"}
            isLoading={isLoading}
            files={files}
            setIsLoading={setIsLoading}
            setFiles={setFiles}
          >
            <div className="d-flex flex-column align-items-center gap-4 px-4  mt-5">
              <CardModalBody>
                {/* {console.log(appType, "<--- applicationType")} */}
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex justify-content-center gap-4 py-2">
                    <input
                      type="file"
                      hidden
                      accept=".apk"
                      onBlur={saveAndDraft(false)}
                      id="apk"
                      onChange={(e) => {
                        setFiles({
                          ...files,
                          apk: e,
                          name: e.target.files[0].name,
                        });
                      }}
                    />
                    <div
                      onClick={() => document.getElementById("apk")?.click()}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{
                        display:
                          projectFormDetails?.applicationType === ".apk"
                            ? "flex"
                            : "none",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "1rem",
                        marginLeft: "3rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          height: "60px",
                          width: "60px",
                          // border: isHovered
                          //   ? "2px solid #6C56FB"
                          //   : "2px solid #161719",
                          background: isHovered ? "#6C56FB" : "transparent",
                          border: "2px solid black !important",
                        }}
                      >
                        <img
                          height={"100%"}
                          src={isHovered ? uploadApkColor : uploadApk}
                          alt=""
                        />
                      </div>
                      <TextBlack
                        className="fs-18 fw-600"
                        style={{
                          color: isHovered ? "#6C56FB" : "#161719",
                        }}
                      >
                        Upload Android Build
                      </TextBlack>
                      {files?.name?.length !== 0 && (
                        <TextBlack>{files.name}</TextBlack>
                      )}
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                      <input
                        type="file"
                        hidden
                        id="exe"
                        accept="application/zip"
                        onBlur={saveAndDraft(false)}
                        onChange={(e) => {
                          setFiles({
                            ...files,
                            exe: e,
                            name: e.target.files[0].name,
                          });
                        }}
                      />
                      <div
                        onMouseEnter={handleMouseEnter2}
                        onClick={() => document.getElementById("exe")?.click()}
                        onMouseLeave={handleMouseLeave2}
                        style={{
                          display:
                            projectFormDetails?.applicationType === ".exe"
                              ? "flex"
                              : "none",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            height: "60px",
                            width: "60px",
                            background: isHovered2 ? "#6C56FB" : "transparent",
                          }}
                        >
                          <img
                            height={"100%"}
                            src={isHovered2 ? exeColor : exe}
                            alt=""
                          />
                        </div>
                        <TextBlack
                          className="fs-18 fw-600"
                          style={{
                            color: isHovered2 ? "#6C56FB" : "#161719",
                          }}
                        >
                          Upload Window Build
                        </TextBlack>
                        {files?.name?.length !== 0 && (
                          <TextBlack>{files.name}</TextBlack>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                      <input
                        type="file"
                        hidden
                        id="sh"
                        accept="application/zip"
                        onBlur={saveAndDraft(false)}
                        onChange={(e) => {
                          setFiles({
                            ...files,
                            sh: e,
                            name: e.target.files[0].name,
                          });
                        }}
                      />
                      <div
                        onMouseEnter={handleMouseEnter2}
                        onClick={() => document.getElementById("sh")?.click()}
                        onMouseLeave={handleMouseLeave2}
                        style={{
                          display:
                            projectFormDetails?.applicationType === ".sh"
                              ? "flex"
                              : "none",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "1rem",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            height: "60px",
                            width: "60px",
                            background: isHovered2 ? "#6C56FB" : "transparent",
                          }}
                        >
                          <img
                            height={"100%"}
                            src={isHovered2 ? exeColor : exe}
                            alt=""
                          />
                        </div>
                        <TextBlack
                          className="fs-18 fw-600"
                          style={{
                            color: isHovered2 ? "#6C56FB" : "#161719",
                          }}
                        >
                          Upload Linux Build
                        </TextBlack>
                        {files?.name?.length !== 0 && (
                          <TextBlack>{files.name}</TextBlack>
                        )}
                      </div>
                    </div>
                  </div>
                  <TextMiddle>
                    Select at least one file type from the options mentioned
                    above
                  </TextMiddle>
                </div>
              </CardModalBody>
              {(isLoading?.exe || isLoading?.apk || isLoading?.sh) && (
                <progress
                  id="file"
                  max="100"
                  value={percentCompleted}
                  style={{ width: "100%" }}
                ></progress>
              )}
              {percentCompleted === 100 &&
                (successMessage.exe ||
                  successMessage?.apk ||
                  isLoading?.sh) && (
                  <TextBlack className="fs-18 fw-600">
                    File Uploaded Successfully - 100%
                  </TextBlack>
                )}

              <TextBlack className="fs-18 fw-600 text-center">
                {files?.apk?.name || files?.exe?.name || files?.sh?.name || ""}
              </TextBlack>
              <div className="w-100">
                {projectFormDetails?.applicationType !== ".apk" ? (
                  <>
                    <TextBlack>
                      Project Executable Path{" "}
                      <span style={{ color: "red" }}>*</span>
                    </TextBlack>
                    <input
                      onChange={handleChange}
                      value={projectFormDetails?.executableFileName || ""}
                      name={"executableFileName"}
                      placeholder="Project Executable Path e.g run.sh | run.exe"
                      className="form-input w-100"
                    />
                    <div className="mt-3">
                      <TextBlack>
                        Streamable Platform{" "}
                        <span style={{ color: "red" }}>*</span>
                      </TextBlack>
                      <div className="d-flex gap-3 w-100  platformCapsule">
                        <div
                          onClick={() =>
                            handleDivClick("cbStreaming", "Windows")
                          }
                          className={`${
                            projectFormDetails.appStreamingType ===
                            "cbStreaming"
                              ? "platformCapsuleDivActive"
                              : ""
                          } platformCapsuleDiv`}
                        >
                          Cloud based Streaming
                        </div>
                        <div
                          onClick={() =>
                            handleDivClick("pcvrStreaming", "Windows")
                          }
                          className={`${
                            projectFormDetails.appStreamingType ===
                            "pcvrStreaming"
                              ? "platformCapsuleDivActive"
                              : ""
                          } platformCapsuleDiv`}
                        >
                          PC VR Streaming
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <TextBlack>
                      Supported Platform <span style={{ color: "red" }}>*</span>
                    </TextBlack>
                    <div className="d-flex gap-3 w-100  platformCapsule">
                      <div
                        onClick={() => handleDivClick("AjnaXR_SE", "Android")}
                        className={`${
                          projectFormDetails.apkSupportedPlatforms ===
                          "AjnaXR_SE"
                            ? "platformCapsuleDivActive"
                            : ""
                        } platformCapsuleDiv`}
                      >
                        AjnaXR SE
                      </div>
                      <div
                        onClick={() => handleDivClick("AjnaXR_Pro", "Android")}
                        className={`${
                          projectFormDetails.apkSupportedPlatforms ===
                          "AjnaXR_Pro"
                            ? "platformCapsuleDivActive"
                            : ""
                        } platformCapsuleDiv`}
                      >
                        AjnaXR Pro
                      </div>
                      <div
                        onClick={() => handleDivClick("Both", "Android")}
                        className={`${
                          projectFormDetails.apkSupportedPlatforms === "Both"
                            ? "platformCapsuleDivActive"
                            : ""
                        } platformCapsuleDiv`}
                      >
                        Both
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <ButtonPrimary
                onClick={() => {
                  if (projectFormDetails?.applicationType === ".apk")
                    fileUploader(files?.apk, "buildURL", "apk");
                  else if (projectFormDetails?.applicationType === ".exe")
                    fileUploader(files?.exe, "buildURL", "exe");
                  else if (projectFormDetails?.applicationType === ".sh")
                    fileUploader(files?.sh, "buildURL", "sh");
                }}
                disabled={isLoading?.apk || isLoading?.exe || isLoading?.sh}
                width="150px"
              >
                {((isLoading?.apk || isLoading?.exe || isLoading?.sh) && (
                  <Spinner animation="border" variant="light" size="sm" />
                )) ||
                  "Upload"}
              </ButtonPrimary>
            </div>
          </CustomModal>
        )}
        {!projectId && !appId && (
          <div className="d-flex gap-4 py-2 col-10">
            <div className="col-2">
              <div className="cursor-pointer h-100">
                {projectFormDetails?.logoURL ? (
                  <div>
                    <div className="imgCont2">
                      <CardBoxImage>
                        <img
                          src={projectFormDetails?.logoURL}
                          alt=""
                          className="border-radius-10"
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "8px",
                          }}
                        />
                      </CardBoxImage>
                      <div className="overlayDiv">
                        <img height={"30px"} src={imgIconWhite2} alt="img" />
                        <TextBlack
                          className="fs-16 text-white cursor-pointer"
                          onClick={() => {
                            handleOpenModal();
                            setPercentCompleted(0);
                          }}
                        >
                          Replace Thumbnail
                        </TextBlack>
                        <img
                          className="cross-sign cursor-pointer"
                          src={cross}
                          onClick={() => {
                            dispatch(setFormDetails({ logoURL: "" }));
                            setFiles({});
                            setPercentCompleted(0);
                          }}
                          alt="x"
                        />
                      </div>
                      {/* icon */}
                    </div>
                  </div>
                ) : (
                  <div onClick={handleOpenModal}>
                    <CardBox
                      border={true}
                      item={{
                        id: 1,
                        thumbnail: add,
                        thumbName: "Thumbnail",
                      }}
                    />
                  </div>
                )}
              </div>

              {/* upload Thumbnail modal */}
            </div>

            {!projectId && (
              <div className="col-10">
                <div className="d-flex w-100 h-100 flex-column justify-content-between">
                  <div className="col-7">
                    <TextBlack>
                      Project Name <span style={{ color: "red" }}>*</span>
                    </TextBlack>
                    <input
                      name={"projectName"}
                      placeholder="Project name"
                      value={projectFormDetails?.projectName || ""}
                      autoComplete="off"
                      onBlur={saveDraft}
                      onChange={(e) => {
                        const regex = /^[a-zA-Z\d\s:-]*$/;
                        if (e.target.value?.match(regex)) {
                          dispatch(
                            setFormDetails({
                              ...projectFormDetails,
                              projectName: e.target.value,
                            })
                          );
                        } else if (e.target.value === "") {
                          dispatch(
                            setFormDetails({
                              ...projectFormDetails,
                              projectName: " ",
                            })
                          );
                        }
                      }}
                      className="form-input w-100"
                    />
                    <div
                      style={{
                        position: "relative",
                        width: "700px",
                        zIndex: "-1",
                      }}
                    >
                      {isProjectExits === false &&
                        projectFormDetails?.projectName?.length < 25 &&
                        projectFormDetails?.projectName?.trim() && (
                          <small className="text-success">
                            Project name available. Please proceed to next step.
                          </small>
                        )}
                      {isProjectExits === true &&
                        projectFormDetails?.projectName?.length < 25 &&
                        projectFormDetails?.projectName && (
                          <small className="text-danger">
                            Project name already exists. Please choose another
                            name.
                          </small>
                        )}
                      {projectFormDetails?.projectName?.length > 50 && (
                        <small className="text-danger">
                          Project name shouldn't be more than 50 characters.
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="mt-4 d-flex gap-4 col-8">
                    <div className="col-5">
                      <TextBlack>
                        Select Sector <span style={{ color: "red" }}>*</span>
                      </TextBlack>
                      {/* <Select title={"Sector"} /> */}
                      <div>
                        <SelectInput
                          name={"sector"}
                          onChange={handleChange}
                          onBlur={saveDraft}
                          options={sectors}
                          title={"Sector"}
                          defaultValue={
                            projectFormDetails.sector || "Select Sector"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-5">
                      <TextBlack>
                        Select Sub Sector{" "}
                        <span style={{ color: "red" }}>*</span>
                      </TextBlack>
                      {/* <Select title={"Sub-Sector"} /> */}
                      <div>
                        <SelectInput
                          name={"subSector"}
                          options={subSectors}
                          onChange={handleChange}
                          onBlur={saveDraft}
                          title={"Sub-Sector"}
                          defaultValue={
                            projectFormDetails.subSector || "Select Sub-Sector"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-5">
                      <TextBlack>
                        Publishing For <span style={{ color: "red" }}>*</span>
                      </TextBlack>
                      {/* <Select title={"Sub-Sector"} /> */}
                      <div>
                        <SelectInput
                          name={"publishingFor"}
                          options={publisherForData}
                          onChange={handleChange}
                          onBlur={saveDraft}
                          title={"Publishing For"}
                          defaultValue={
                            projectFormDetails.publishingFor || "Publishing For"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {projectId && !appId && (
          <div style={{ marginBottom: "1.5rem" }}>
            <ProjectInfo />
          </div>
        )}
        {/* App Info */}
        {id && (
          <div className="col-10">
            <CardContainer style={{ marginBottom: "1.5rem" }}>
              <div className="imgCont">
                {projectFormDetails?.logoURL ? (
                  <img src={projectFormDetails?.logoURL} alt="img" />
                ) : (
                  <div className=""></div>
                )}
              </div>
              {/* text cont */}

              <TextBtnCont>
                <TextBtn>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      rowGap: "1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "24px",
                        color: "#161719",
                        fontWeight: "600",
                      }}
                    >
                      {projectFormDetails?.projectName}
                    </p>
                    <KeyValue style={{ marginTop: "-10px" }}>
                      <KeyTxt> Release Channel - &nbsp;</KeyTxt>
                      <div style={{ fontWeight: 300 }}>
                        {projectFormDetails?.rcName}
                      </div>
                    </KeyValue>
                    <KeyValue>
                      <KeyTxt> Version - &nbsp;</KeyTxt>
                      <div style={{ fontWeight: "300" }}>
                        {projectFormDetails.version}
                      </div>
                    </KeyValue>
                    <KeyValue
                      style={{
                        paddingBottom: "1rem",
                      }}
                    >
                      <KeyTxt> APP ID - &nbsp;</KeyTxt>
                      <div style={{ fontWeight: "300" }}>
                        {projectFormDetails?.appID
                          ? projectFormDetails?.appID?.slice(-10)
                          : appId}
                      </div>
                    </KeyValue>
                  </div>
                  {!draft && (
                    <div>
                      <div
                        className="d-flex gap-2 px-3 py-2"
                        onClick={() => {
                          navigate(`/analytics/${projectId}/${appId}`);
                        }}
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#fff",
                          borderRadius: "3rem",
                          cursor: "pointer",
                        }}
                      >
                        <img src={analyticsIcon} alt="" />
                        <div>Analytics</div>
                      </div>
                    </div>
                  )}
                </TextBtn>
                <div
                  style={{
                    fontWeight: "300",
                    borderTop: "1px solid #D9D9D9",
                  }}
                >
                  <KeyValue
                    style={{
                      paddingTop: "0.6rem",
                    }}
                  >
                    <div> Last Edited On&nbsp;</div>
                    <div>{getDateByUpdate(projectFormDetails.lastEdited)}</div>
                  </KeyValue>
                </div>
              </TextBtnCont>
            </CardContainer>
          </div>
        )}

        <div className="my-2 col-10">
          <CardBlackWhite
            upperContent={upperContent}
            lowerContent={
              <>
                <EmptyRow
                  type={true}
                  newVersion={infoType === "new" || "newVersion" ? true : false}
                  data={[
                    {
                      name:
                        projectFormDetails?.buildURL
                          ?.split("/")
                          .pop()
                          ?.split(".")[0] || "No Build Available",

                      createAt: projectFormDetails?.buildURL
                        ? new Date().toDateString()
                        : "-",
                      version: (
                        <div style={{ display: "flex", gap: "10px" }}>
                          {projectFormDetails?.buildURL !== "" &&
                            projectFormDetails?.version}
                          {infoType === "Overview" && (
                            <LiveTxt>Live version</LiveTxt>
                          )}
                        </div>
                      ),
                    },
                  ]}
                />

                <>
                  {id &&
                    projectId &&
                    searchParams.get("infoType") !== "new" && (
                      <VersionHistory />
                    )}
                </>
              </>
            }
          />
        </div>
        {(!id ||
          !appId ||
          searchParams.get("infoType") === "new" ||
          searchParams.get("infoType") === "newVersion") &&
          MediaComp}
        {id && projectId && searchParams.get("infoType") !== "new" && (
          <div className="mx-2 ">
            <div className="d-flex justify-content-between col-10 mt-4 mb-2">
              <TextBlack className="fs-20">
                Version - {projectFormDetails?.version || "v0.0.1"}
              </TextBlack>

              <EditBtn />
            </div>
            <CustomTabs
              tabTitles={["Overview", "Reviews", "Manage Pricing"]}
              tabs={infoType}
              page={["Overview"]}
              type={"plane"}
            >
              {infoType === "Overview" && (
                <CustomPane eventKey="Overview">
                  {/* <Media /> */}
                  {dataLoaded && (
                    <div>
                      <div className="px-2 d-flex justify-content-between col-10">
                        <div
                          className="leftboxDetails"
                          style={{
                            border:
                              projectFormDetails?.mediafileURLs
                                ?.projectCardImage && "none",
                          }}
                        >
                          <div
                            className="imgCont2"
                            style={{ width: "100%", height: "100%" }}
                          >
                            <div style={{ width: "100%", height: "100%" }}>
                              <img
                                width={"100%"}
                                height={"100%"}
                                src={
                                  projectFormDetails?.mediafileURLs
                                    ?.projectCardImage
                                }
                                alt="imgIcon"
                              />
                            </div>
                          </div>
                          <div
                            className="leftchildTwo"
                            style={{
                              border:
                                projectFormDetails?.mediafileURLs
                                  ?.projectCardImage && "none",
                              backdropFilter:
                                projectFormDetails?.mediafileURLs
                                  ?.projectCardImage &&
                                " blur(30.52007293701172px)",
                              background:
                                projectFormDetails?.mediafileURLs
                                  ?.projectCardImage && "rgba(0, 0, 0, 0.2)",
                              width:
                                projectFormDetails?.mediafileURLs
                                  ?.projectCardImage && "100%",
                              borderRadius:
                                projectFormDetails?.mediafileURLs
                                  ?.projectCardImage &&
                                "0px 0px 9.766px 9.766px",
                              color:
                                projectFormDetails?.mediafileURLs
                                  ?.projectCardImage && "white",
                            }}
                          >
                            {isLoading?.projectCardImage}
                            <ProjectName
                              style={
                                {
                                  // color: file.projectCardImage && "white",
                                }
                              }
                            >
                              {projectFormDetails?.projectName}
                            </ProjectName>
                            <div
                              style={{
                                fontSize: "13px",
                                color: "white",
                              }}
                            >
                              {textContent}...
                            </div>
                            <Price
                              style={
                                {
                                  // color: file.projectCardImage && "white",
                                }
                              }
                            >
                              Price - {price || "Free"}
                            </Price>
                          </div>
                        </div>

                        <div
                          className="imgCont2 col-8 rightboxDetails no-border"
                          style={{
                            border: !isLoading.featuredCardImage ? "none" : "",
                          }}
                        >
                          <div
                            className="w-100 h-100"
                            style={{
                              border: "none",
                            }}
                          >
                            <video
                              width={"100%"}
                              height={"100%"}
                              style={{ objectFit: "cover" }}
                              src={
                                projectFormDetails?.mediafileURLs
                                  ?.featuredCardImage
                              }
                              alt="imgIcon"
                              controls
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-10 hscrollbar pb-4 mt-4 border">
                        {projectFormDetails?.mediafileURLs?.coverImage && (
                          <img
                            width={"275px"}
                            height={"200px"}
                            style={{ borderRadius: "8px" }}
                            src={projectFormDetails?.mediafileURLs?.coverImage}
                            alt="img"
                          />
                        )}
                        {projectFormDetails?.mediafileURLs?.video.includes(
                          ".mp4"
                        ) && (
                          <div>
                            <video
                              width={"275px"}
                              height={"200px"}
                              style={{
                                borderRadius: "8px",
                                objectFit: "fill",
                              }}
                              controls
                            >
                              <source
                                src={projectFormDetails?.mediafileURLs?.video}
                              />
                            </video>
                          </div>
                        )}
                        {projectFormDetails?.mediafileURLs?.videothumbnail && (
                          <img
                            width={"275px"}
                            height={"200px"}
                            style={{ borderRadius: "8px" }}
                            src={
                              projectFormDetails?.mediafileURLs?.videothumbnail
                            }
                            alt="img"
                          />
                        )}
                        {projectFormDetails?.mediafileURLs?.imageOne && (
                          <img
                            width={"275px"}
                            height={"200px"}
                            style={{ borderRadius: "8px" }}
                            src={projectFormDetails?.mediafileURLs?.imageOne}
                            alt="img"
                          />
                        )}
                        {projectFormDetails?.mediafileURLs?.imageTwo && (
                          <img
                            width={"275px"}
                            height={"200px"}
                            style={{ borderRadius: "8px" }}
                            src={projectFormDetails?.mediafileURLs?.imageTwo}
                            alt="img"
                          />
                        )}

                        {projectFormDetails?.mediafileURLs?.imageThree && (
                          <img
                            width={"275px"}
                            height={"200px"}
                            style={{
                              borderRadius: "8px",
                            }}
                            src={projectFormDetails?.mediafileURLs?.imageThree}
                            alt="img"
                          />
                        )}
                      </div>
                      {/* Project Brief */}
                      <div className="row my-2 col-10">
                        <div className="mx-1 my-2">
                          <CardDescriptionWrapper>
                            <TextBlack className="fs-18 fw-600 py-2 px-1">
                              Project Brief
                            </TextBlack>
                            <div className="my-2 px-1">
                              <DetailsWrapper style={{ height: "80px" }}>
                                <article
                                  dangerouslySetInnerHTML={{
                                    __html: projectFormDetails?.projectBrief,
                                  }}
                                ></article>
                              </DetailsWrapper>
                            </div>
                          </CardDescriptionWrapper>
                        </div>
                      </div>
                      {/* description */}
                      <div className="row my-2 col-10">
                        <div className="mx-1 my-2">
                          <CardDescriptionWrapper>
                            <TextBlack className="fs-18 fw-600 py-2 px-1">
                              Project Description
                            </TextBlack>
                            <div className="my-2 px-1">
                              <DetailsWrapper>
                                <article
                                  dangerouslySetInnerHTML={{
                                    __html: projectFormDetails?.description,
                                  }}
                                ></article>
                              </DetailsWrapper>
                            </div>
                          </CardDescriptionWrapper>
                        </div>
                      </div>

                      {/* Tags */}
                      <div className="row my-4 col-10">
                        <div className="my-2">
                          <TextBlack className="fs-18">Tags</TextBlack>
                        </div>
                        <div className="d-flex flex-wrap gap-4">
                          {projectFormDetails?.hashtags?.length > 0 &&
                            Array.isArray(projectFormDetails?.hashtags) &&
                            projectFormDetails?.hashtags?.map((item, index) => (
                              <TagWrapper key={item}>{`${item}`}</TagWrapper>
                            ))}
                        </div>
                      </div>
                      {/* internet, age, language, */}
                      <div className="ialtMain col-10">
                        {/* internet */}
                        <div className="d-flex flex-column justify-content-center align-items-center bg-white rounded-2 ialt">
                          <TextBlack>
                            Internet Requirement :{" "}
                            {projectFormDetails?.internetRequired
                              ? "Yes"
                              : "No"}
                          </TextBlack>
                        </div>
                        {/* suitable age */}
                        <div className="bg-white rounded-2 p-4 ialt">
                          <TextBlack>Suitable for ages</TextBlack>
                          <TextBlack className="fw-600">
                            {projectFormDetails?.suitableForAge === "all"
                              ? "All ages"
                              : projectFormDetails?.suitableForAge + "+"}
                          </TextBlack>
                        </div>
                        {/* language support */}

                        <div className="bg-white rounded-2 p-4 ialt">
                          <TextBlack>Language Support</TextBlack>
                          <TextBlack className="fw-600">
                            {projectFormDetails?.languageSupported?.toString()}
                          </TextBlack>
                        </div>
                        {/* type of experience offered */}
                        <div className="bg-white rounded-2 p-4 ialt">
                          <TextBlack>Type of Experience</TextBlack>
                          <TextBlack className="fw-600 text-uppercase">
                            {projectFormDetails?.tag}
                          </TextBlack>
                        </div>
                      </div>

                      {/* Disclaimer */}
                      <div className="row my-2 col-10">
                        <div className="m-1">
                          <CardDescriptionWrapper>
                            <TextBlack className="fs-18 fw-600 py-2 px-1">
                              Content Disclaimer
                            </TextBlack>
                            <div className="my-2 px-1">
                              <DetailsWrapper>
                                <article
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      projectFormDetails?.contentDisclaimer,
                                  }}
                                ></article>
                              </DetailsWrapper>
                            </div>
                          </CardDescriptionWrapper>
                        </div>
                      </div>
                      {/* {console.log(rcName, "<--- rcName")} */}
                      {rcName && (
                        <div className="row col-10">
                          <ChannelUsers
                            projectID={projectId}
                            rcApplicationType={appType}
                            rcName={rcName}
                            appId={appId}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {!dataLoaded && (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100 mt-2">
                      <Spinner animation="border" size="sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                </CustomPane>
              )}
              {infoType === "Reviews" && (
                <CustomPane eventKey="Reviews">
                  <Reviews appIDInitial={appId} />
                </CustomPane>
              )}
              {infoType === "Manage Pricing" &&
                projectFormDetails?.priceInfo && (
                  <CustomPane eventKey="Manage Pricing">
                    <PriceTabs
                      isProjectPage={true}
                      activeTab={projectFormDetails?.priceInfo?.PriceModelType}
                      priceInfo={projectFormDetails?.priceInfo}
                    />
                  </CustomPane>
                )}
            </CustomTabs>
          </div>
        )}

        {/* Proceed Button */}
        <div className="d-flex align-items-center col-11">
          {(searchParams.get("infoType") !== "Overview" ||
            (searchParams.get("infoType") === "Overview" &&
              (searchParams.get("appId") === "" ||
                decodeURIComponent(searchParams.get("appId")) === '""'))) &&
            searchParams.get("infoType") !== "Reviews" &&
            searchParams.get("infoType") !== "Manage Pricing" &&
            !(
              (searchParams.get("infoType") === "new" &&
                (searchParams.get("query") === null ||
                  searchParams.get("query") === "null")) ||
              (searchParams.get("appid") === "" &&
                searchParams.get("infoType") === "Overview")
            ) && (
              <>
                <span
                  style={{
                    pointerEvents: isLoading.draft ? "none" : "",
                  }}
                  onClick={() => SaveDraftHandler(true)}
                  className="save__draft"
                >
                  {isLoading.draft ? "Saving Draft..." : "Save as Draft"}
                </span>
                <div
                  className={`d-flex justify-content-end my-4 gap-2 ${
                    lastWord === "Reviews" ? "col-12" : "col-10"
                  }`}
                >
                  <ButtonSecondary
                    width="150px"
                    onClick={() => {
                      dispatch(resetState());
                      dispatch(deleteDraftProjects({ docIds: [draftId] }));
                      navigate("/");
                    }}
                  >
                    Discard
                  </ButtonSecondary>
                  <ButtonPrimary
                    width="150px"
                    onClick={() => formSubmitHandler()}
                  >
                    {submitLoader ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      "Proceed"
                    )}
                  </ButtonPrimary>
                </div>
              </>
            )}
        </div>

        {searchParams.get("infoType") === "new" &&
          searchParams.get("query") !== "draft" && (
            <div className="d-flex col-10 justify-content-end gap-4">
              <ButtonSecondary
                className="mt-4"
                width="150px"
                onClick={() => {
                  dispatch(resetState());
                  navigate("/");
                }}
              >
                Discard
              </ButtonSecondary>
              <ButtonPrimary
                className="mt-4"
                width={"200px"}
                onClick={() =>
                  formSubmitHandler(
                    searchParams.get("query") === "draft" ? "" : "edited"
                  )
                }
              >
                Save Changes
              </ButtonPrimary>
            </div>
          )}
      </OverFlow>
    </div>
  );
};
