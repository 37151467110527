import {
  BoxCont,
  MarkAsreadAll,
  MarkLine,
  MarkTitle,
  NotificationCard,
  NotificationCont,
  SingleBox,
  Time,
  Top,
} from "components/styled/Notification.style";
import { TextBlack } from "components/styled/common.styled";
import React, { useEffect, useState } from "react";
import { SeenText } from "components/styled/Buystorage.style";
// import { notification } from "utils/socket";
import { getDateByUpdate } from "helper";
import CustomBreadcrumb from "components/CustomBreadScrum";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const [messages, setMessages] = useState([]);
  const [singleMessage, setSingleMessage] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState(false);
  const [newNotificationContent, setNewNotificationContent] = useState(null);
  const [newUpdate, setNewUpdate] = useState([]); // [
  const token = localStorage.getItem("token");
  const decodedToken = JSON.parse(atob(token?.split(".")[1]));
  const userId = decodedToken?.sub;
  const navigate = useNavigate();

  // useEffect(() => {
  //   notification.emit("login", { name: userId, room: userId }, (err) => {
  //     if (err) {
  //       console.log("error", err);
  //     }
  //   });
  //   notification.on("message", function (message) {
  //     console.log("message", message);
  //     // new update
  //     if (JSON.parse(message?.text)?.content) {
  //       setNewUpdate(JSON.parse(message?.text)?.content);
  //       setNewAnnouncement(message?.text);
  //       setNewNotificationContent(true);
  //       return;
  //     }
  //     setSingleMessage([
  //       ...messages,
  //       {
  //         username: message?.user,
  //         message: message?.text,
  //         timestamp: new Date().toISOString(),
  //         room: userId,
  //       },
  //     ]);
  //   });
  //   notification.on("historical_messages", function (messages) {
  //     console.log("message", messages);
  //     const filteredMessages = messages.filter((item) => {
  //       const isNewUpdateMessage =
  //         item?.message && !!JSON.parse(item?.message || "")?.content;
  //       if (isNewUpdateMessage) {
  //         setNewUpdate(JSON.parse(item.message)?.content);
  //         setNewNotificationContent(true);
  //         setNewAnnouncement(item.message);
  //       } else {
  //         return item;
  //       }
  //     });
  //     setMessages([...filteredMessages]);
  //   });
  // }, [notification]);

  // const clearSingle = (message) => {
  //   if (typeof message === "undefined") return;
  //   notification.emit("delete_single_message", {
  //     username: userId,
  //     room: userId,
  //     message: JSON.stringify(message),
  //     timestamp: new Date().toISOString(),
  //   });
  //   setMessages(messages.filter((item) => item.message !== message));
  // };

  // useEffect(() => {
  //   if (newNotificationContent === false) {
  //     clearSingle(newAnnouncement);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [newNotificationContent, newAnnouncement]);

  const uniqueMap = new Map();

  [...messages, ...singleMessage].forEach((item) => {
    let parseItem = item?.message ? JSON.parse(item?.message) : "";
    // get day and time from timestamp
    let date =
      item?.timestamp && new Date(item?.timestamp).toISOString().split("T")[0];

    let timestamp = date;
    // unique message based on message content and timestamp minute and date
    const existingItem = uniqueMap.get(parseItem.message);
    const existingItemTimestamp = uniqueMap.get(timestamp);

    if (!existingItemTimestamp) {
      uniqueMap.set(timestamp, item);
    }
    if (!existingItem) {
      uniqueMap.set(parseItem.message + parseItem?.messageTitle, item);
    }
  });

  const formattedMessages = Array.from(uniqueMap.values());
  // console.log("userid: ", userId);
  // console.log("formattedMessages", formattedMessages);
  return (
    <NotificationCont>
      <CustomBreadcrumb
        items={[{ text: "Notification", link: "/notification" }]}
      />

      <NotificationCard>
        <Top>
          <div className="text">
            Notifications (
            {formattedMessages.filter((item) => item.message).length} new){" "}
          </div>
          {formattedMessages.length !== 0 && (
            <MarkAsreadAll>Mark as read all</MarkAsreadAll>
          )}
        </Top>
        <BoxCont>
          {formattedMessages.length !== 0 &&
            formattedMessages.reverse().map((item, ind) => {
              const parsedMessage =
                (item?.message && JSON.parse(item.message)) || {};
              const { message, messageTitle, slug } = parsedMessage;
              return (
                item?.message && (
                  <div key={item?.timestamp}>
                    {item.seen && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "2rem",
                          gap: "10px",
                        }}>
                        <SeenText>Seen</SeenText>
                        <div className="line"></div>
                      </div>
                    )}
                    <SingleBox>
                      <div className="main w-75">
                        <div>
                          <MarkTitle
                            dangerouslySetInnerHTML={{
                              __html: messageTitle,
                            }}
                          />
                          <MarkTitle
                            dangerouslySetInnerHTML={{
                              __html: message,
                            }}
                          />
                          <Time className="mt-1">
                            {getDateByUpdate(item?.timestamp)}
                          </Time>
                        </div>
                      </div>

                      <div
                        className="btnCont"
                        style={{ display: item.id === 3 ? "none" : "flex" }}>
                        <button
                          onClick={() => navigate(`/review_details/${slug}`)}>
                          Action
                        </button>
                        <MarkLine>Mark as Read</MarkLine>
                      </div>
                    </SingleBox>
                  </div>
                )
              );
            })}
          {formattedMessages.length === 0 && (
            <div className="d-flex justify-center m-auto">
              No new notifications
            </div>
          )}
        </BoxCont>
      </NotificationCard>
    </NotificationCont>
  );
};

export default Notification;
