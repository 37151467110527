import React, { useEffect, useState } from "react";
import {
  DisCode,
  DisName,
  DiscountCont,
  LeftSection,
  NameAnduse,
} from "components/styled/DiscountCard.style";
import { ButtonPrimary } from "components/styled/common.styled";
import { CustomModal } from "components/Modal/CustomModal";
import CreateCopoun from "components/CreateCoupon/CreateCopoun";
import { useDispatch, useSelector } from "react-redux";

import { useSearchParams } from "react-router-dom";
import deleteIcon from "assets/deleteIcon.svg";
import file from "assets/file.svg";
import edit from "assets/edit-2.svg";
import {
  deleteCouponById,
  getCouponsByAppId,
  getCouponsById,
} from "redux/Project/project.action";
import { toast } from "react-toastify";

const DiscountCard = () => {
  const { getAllCouponsByAppId } = useSelector((state) => state.projectReducer);
  const [searchQuery] = useSearchParams();
  const appId = searchQuery.get("appId");

  const dispatch = useDispatch();


  const [isOpen, setIsOpen] = useState({
    modal: false,
    project: false,
    id: "",
    couponId: "",
  });

  const formateDate = (data) => {
    const formatedDate = new Date(data).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return formatedDate;
  };

  const handleDelete = (couponId) => {
    if (couponId) {
      dispatch(deleteCouponById(couponId)).then((res) => {
        if (res.payload) {
          toast.success("Coupon deleted successfully");
          dispatch(getAllCouponsByAppId(appId));
        }
      });
    }
  };

  const handleEdit = (couponId) => {
    if (couponId) {
      setIsOpen({ ...isOpen, modal: true, id: couponId });
      dispatch(getCouponsById(couponId));
    }
  };

  useEffect(() => {
    if (appId) {
      dispatch(getCouponsByAppId(appId));
    }
  }, [appId, dispatch]);

  function copyDiscountCode(code) {
    // Create a temporary textarea element to hold the discount code
    let tempTextarea = document.createElement("textarea");
    tempTextarea.value = code;
    // Append the textarea to the document
    document.body.appendChild(tempTextarea);
    // Select the text within the textarea
    tempTextarea.select();
    // Copy the selected text to the clipboard
    navigator.clipboard.writeText(window.getSelection().toString());
    // Remove the temporary textarea from the document
    document.body.removeChild(tempTextarea);
    toast.info("Discount code copied to clipboard: " + code);
  }

  return (
    <div className="row gap-4 my-4">
      {isOpen?.modal && (
        <CustomModal
          isOpen={isOpen?.modal}
          setIsOpen={setIsOpen}
          key={"plans"}
          size="md"
          title={"Create Coupon "}
        >
          <div className="w-100">
            <div className="d-flex my-4 justify-content-end">
              <ButtonPrimary
                width="100px"
                height="45px"
                // onClick={createSubscriptionPlan}
              >
                Create
              </ButtonPrimary>
            </div>
          </div>
        </CustomModal>
      )}
      {/* create couponcard */}
      <CreateCopoun setIsOpen={setIsOpen} isOpen={isOpen} />
      {getAllCouponsByAppId?.map((discount) => (
        <DiscountCont key={discount._id}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <LeftSection className="top">
              <NameAnduse>
                {discount.offertype === "flat" ? (
                  <DisName>₹ {discount.amount} OFF</DisName>
                ) : (
                  <DisName>{discount.amount}% Discount</DisName>
                )}
                <DisCode className="fs-12-400">
                  use By {formateDate(discount.endDate)}
                </DisCode>
              </NameAnduse>

              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                <DisCode id="discountCode">{discount.couponCode}</DisCode>
                <div>
                  <img
                    src={file}
                    alt="file"
                    onClick={() => copyDiscountCode(discount.couponCode)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </LeftSection>
            <LeftSection className="bottom">
              <DisCode className="fs-12-400">
                {discount?.noOfCoupons} / {discount?.noOfCouponsUsed}
              </DisCode>
              <div
                style={{
                  display: "flex",
                  gap: "25px",
                }}
              >
                <img
                  src={edit}
                  alt="Icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEdit(discount._id)}
                />
                <img
                  src={deleteIcon}
                  alt="Icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDelete(discount._id)}
                />
              </div>
            </LeftSection>
          </div>
        </DiscountCont>
      ))}
    </div>
  );
};

export default DiscountCard;
