export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SIGNUP = "SIGNUP";

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const CLEAR_SIGNUP = "CLEAR_SIGNUP";

export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const ERROR = "ERROR";

export const SUCCESS = "SUCCESS";

export const PROFILE_INFO = "PROFILE_INFO";