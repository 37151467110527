import { TextBlack } from "components/styled/common.styled";
import React, { useEffect, useState } from "react";
import { PriceInput } from "./PriceInput";
import { useDispatch, useSelector } from "react-redux";
import { setPrice } from "redux/Project/project.action";
import DiscountCard from "components/DiscountCard/DiscountCard";
import { useLocation } from "react-router-dom";
export const FixedPrice = ({ priceDetails = {} }) => {

    const { profilePayload } = useSelector((state) => state.authReducer);
  const location = useLocation();
  const [readOnly, setReadOnly] = useState(
    priceDetails?.PriceModelType === "Fixed" ? true : false
  );
  const [price, setStatePrice] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (priceDetails?.PriceModelType === "Fixed") {
      setStatePrice(
        priceDetails?.pricing ? priceDetails?.pricing[0]?.applicationPrice : 0
      );
      setReadOnly(true);
    } else if (priceDetails?.PriceModelType === "Free") {
      setReadOnly(false);
    }
  }, [priceDetails?.PriceModelType, priceDetails?.pricing?.length]);

  const handlePriceChange = (price) => {
    let earningPerDownload = 0;
    const pricePattern = /^(?!-)\d+(\.\d{1,2})?$/;
    if (pricePattern.test(price)) {
      const tax = (price * 18) / 100;
      // const platformFee = (price * 50) / 100;
      if (profilePayload.region==="IN") {
        const yourShare =  (price * 82) / 100; 
        earningPerDownload = yourShare;
      }else{
        earningPerDownload = price;
      }

      // earningPerDownload = yourShare - (yourShare * 18) / 100;
    }
    return parseFloat(earningPerDownload).toFixed(2);
  };

  useEffect(() => {
    dispatch(setPrice(price ? price : null));
  }, [dispatch, price]);

  return (
    <div>
      <div className="d-flex justify-content-between col-3 my-3">
        <TextBlack className="fs-22 my-1">Fixed Price Model</TextBlack>
      </div>

      <TextBlack className="fs-16 my-2">
        Set a defined price for your project
      </TextBlack>
      <div className="my-2">Set Price</div>
      <div className="col-4 my-2">
        <PriceInput
          setPrice={setStatePrice}
          defaultValue={price || ""}
          readonly={readOnly}
          type={"number"}
        />
      </div>
      <hr />
      <div>
        <tr className="d-flex justify-content-between">
          <td>Tax</td>
          <td>{profilePayload.region === "IN" ? "18%" : "0%"} </td>
        </tr>
        <tr className="d-flex justify-content-between">
          <td>Platform fee</td>
          <td>0%</td>
        </tr>
        <tr className="d-flex justify-content-between">
          <td>Your Share</td>
          <td>{profilePayload.region === "IN" ? "82%" : "100%"}</td>
        </tr>
      </div>
      <hr />
      <div>
        <tr className="d-flex justify-content-between">
          <td>
            {" "}
            <TextBlack className="fs-16">
              Your Earning Per Download
            </TextBlack>{" "}
          </td>
          <td>
            <TextBlack className="fs-16">
              {handlePriceChange(price)}{" "}
              {profilePayload.region === "IN" ? " ₹ -/INR" : " $USD"}
            </TextBlack>
          </td>
        </tr>
      </div>
      {!location.pathname.includes("newProject") && <DiscountCard />}
    </div>
  );
};
