import React, { useEffect } from "react";
import { TableContainer } from "./table.styled";
import { TextBlack } from "components/styled/common.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicationDetails,
  getRCWithLiveApps,
  setFormDetails,
} from "redux/Project/project.action";
import { UUIDGenerator, getDateByUpdate } from "helper";
import { useNavigate } from "react-router-dom";
import projectAction from "redux/Project/project.action";
import { Spinner } from "react-bootstrap";

export const ProjectRows = ({ apiParameters, setIsProjectLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { releaseChannelsApps, projectFormDetails } = useSelector(
    (state) => state.projectReducer
  );
  const fetchAppDetails = (item) => {
  
    //&& !item?.appInReviewID
    if (!item?.applicationID && !item?.appInReviewID) {
      navigate(
        `/appDetails/${UUIDGenerator()}?rcName=${
          item?.rcName
        }&appId=""&projectId=${apiParameters?.projectId}&applicationType=${
          apiParameters?.applicationType
        }&infoType=new&query=draft`
      );
    }
    if (!item?.applicationID && item?.appInReviewID){
      navigate(`/review_details/${item?.appInReviewID}`);
    }

    if (!(apiParameters?.projectId && item?.applicationID && item?.rcName)) {
      return;
    }
      dispatch(
        getApplicationDetails(
          "",
          apiParameters?.projectId,
          item?.applicationID,
          item?.rcName,
          "Overview"
        )
      ).then((res) => {
        // dispatch(setFormDetails(res?.payload)).then((res) => {
        //   if (res) {
        //     setIsProjectLoading(false);
        //   }
        // });
        navigate(
          `/appDetails/${UUIDGenerator()}?rcName=${item?.rcName}&appId=${
            item?.applicationID
          }&projectId=${apiParameters?.projectId}&applicationType=${
            apiParameters?.applicationType
          }&infoType=Overview`
        );
      });
  };

  useEffect(() => {
    if (!(apiParameters.applicationType === "")) {
      dispatch(
        getRCWithLiveApps(
          "",
          apiParameters.projectId,
          apiParameters.applicationType
        )
      );
      setIsProjectLoading(false);
    }
  }, [apiParameters?.applicationType]);

  return (
    <div className="w-100">
      <TableContainer>
        <table>
          <thead className="border-bottom">
            <tr>
              <td>
                <th>Name</th>
              </td>
              <td>
                <th>Build</th>
              </td>
              <td>
                <th>Date</th>
              </td>
              <td>
                <th>Downloads</th>
              </td>
              <td>
                <th>Earnings</th>
              </td>
              {/* <td>
                <th></th>
              </td> */}
            </tr>
          </thead>
          <tbody>
            {releaseChannelsApps?.map(
              (item, index) =>
                item?.rcName && (
                  <tr
                    key={item?.id}
                    style={{
                      // pointerEvents: item?.applicationID === null ? "none" : "auto",
                      cursor: "pointer",
                    }}
                    onClick={() => fetchAppDetails(item)}
                  >
                    <td>
                      <TextBlack>{item.rcName}</TextBlack>{" "}
                    </td>
                    <td>
                      <TextBlack>{item.version || "-"}</TextBlack>
                    </td>
                    <td>
                      <TextBlack>
                        {getDateByUpdate(item.appMadeLiveOn) || "-"}
                      </TextBlack>
                    </td>
                    <td>
                      <TextBlack>{item.downloads || "-"}</TextBlack>
                    </td>
                    <td>
                      <TextBlack style={{ color: "#20A019" }}>
                        {item.earnings || "-"}
                      </TextBlack>
                    </td>
                    {/* <td>
                  <TextBlack style={{ color: "#808080", cursor: "pointer" }}>
                    Delete
                  </TextBlack>
                </td> */}
                  </tr>
                )
            )}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};
