import styled from "styled-components";

export const PrpojectCardConatiner = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
  background: #fff;
`;

export const LogoConatiner = styled.div`
  width: 50%;
  height: 100px;
`;
export const TextConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 28px;
`;

export const ProjectName = styled.div`
  width: 150px;
  height: 20px;
  &.dateText {
    width: 200px;
    height: 20px;
  }
  &.upperContent{
    width: 100%;
    height: 50px;
    border-radius: 20px;
  }
  &.analytics{
    height: 40px;
  }
`;

export const DeleteBtn = styled.div`
 width: 50px;
 height: 50px;
 border-radius: 50%;

`

export const TableTr = styled.tr`
width: 100%;
height: 50px;
background: #fff;

`
