import { AccountList } from "components/styled/payment.styled";
import deleteIcon from "assets/deleteIcon.svg";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "redux/Project/project.action";

const UsersList = ({data}) => {
  const dispatch = useDispatch();
  const handleDelete = (emailToDelete) => {
    // Filter out the object with the specified email
    dispatch(deleteUser(emailToDelete));
    // Set the updated data in the parent component state
  };

  return (
    <div className="px-1" style={{ maxHeight: "110px", overflowY: "auto" }}>
      {data?.map((userData) => (
        <div
          key={userData.emailId} // Add a unique key for each mapped element
          className="d-flex gap w-100 my-1 align-items-center justify-content-between">
          <AccountList>
            <h6 style={{ margin: "0", padding: "0" }}>{userData.emailId}</h6>
          </AccountList>
          <img
            src={deleteIcon}
            height={"20px"}
            alt="deleteIcon"
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(userData.emailId)}
          />
        </div>
      ))}
    </div>
  );
};

export default UsersList;
