import React from "react";
import {
  MarkTitle,
  NotificationCont,
  SingleBox,
  Time,
} from "components/styled/Notification.style";
import { Secdata } from "./constant";

const Security = () => {
  return (
    <NotificationCont className="settingCont">
      {Secdata.map((item) => (
        <a
          href={item.link}
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: "none" }}
        >
          <SingleBox className="settings" key={item.id}>
            <div className="main">
              <img
                src={item.img}
                alt="item"
                style={{ width: "24px", height: "24px" }}
              />
              <div>
                <MarkTitle>{item.title}</MarkTitle>

                <Time>{item.time}</Time>
              </div>
            </div>
          </SingleBox>
        </a>
      ))}
    </NotificationCont>
  );
};

export default Security;
