import { CustomAlertPopup } from "components/CustomAlert";
import { buttonConfigs } from "components/CustomAlert/constant";
import { ButtonSecondary, TextBlack } from "components/styled/common.styled";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { publisher } from "redux/api";

export const FreeCost = () => {
  const [searchQuery] = useSearchParams();
  const projectID = searchQuery.get("projectId");
  const appID = searchQuery.get("appId");
  const [modal, setModal] = useState({
    modal: false,
    text: "",
  });

  const createSubscriptionPlan = async () => {
    const payload = {
      priceModelType: "Free",
      timePeriod: "Lifetime",
      priceModelDescription: "This is a dummy price for the application.",
      applicationPrice: "",
    };
    try {
      const response = await publisher.createPricePlan(
        payload,
        projectID,
        appID
      );
      if (response) {
        toast.success("Free Plan created successfully");
        // setModal({
        //   modal: true,
        //   text: "Free plan created successfully",
        // })
      }
    } catch (err) {
      toast.error("Free Plan Already Exits");
      // setModal({
      //   modal: true,
      //   text: "Free Plan Already Exits",
      // })
      console.log(err);
    }
  };
  return (
    <div>
      {modal && (
        <div style={{ marginTop: "-0.5rem", marginLeft: "0rem", top: "20px" }}>
          <CustomAlertPopup
            modal={modal}
            setModal={setModal}
            text={modal.text}
            buttonType={buttonConfigs.OKCancel}
            titleText="Free Plan"
          />
        </div>
      )}
      <TextBlack className="fs-22 mt-4">Free Of Cost Model</TextBlack>
      <div>Make your project free to use</div>
      {/* <div className="col-4 my-4">
        <ButtonSecondary
          onClick={() => createSubscriptionPlan()}
          width="200px"
          color="#6C56FB"
          border="#6C56FB">
          Make it free
        </ButtonSecondary>
      </div> */}
    </div>
  );
};
