// variants of alerts
import success from "assets/success.svg";
import danger from "assets/danger.svg";
import info from "assets/information.svg";
import error from "assets/errorIcon.svg";
import crossred from "assets/cross-red.svg";
import crossGreen from "assets/cross-green.svg";
import crossBlue from "assets/cross-blue.svg";
import crossYellow from "assets/cross-yellow.svg";

export const variants = [
  //red 0
  {
    mainColor: "#FFE4E0",
    secondaryColor: "#FF7666",
    symbol: error,
    title: "Error",
    text: "",
    iconcolor: "#FF7666",
    sign: crossred,
  },
  //blue 1
  {
    mainColor: "#E0E7FF",
    secondaryColor: "#6688FF",
    symbol: info,
    title: "Information",
    text: "",
    iconcolor: "#6688FF",
    sign: crossBlue,
  },
  //green 2
  {
    mainColor: "#D2ECD1",
    secondaryColor: "#20A019",
    symbol: success,
    title: "Success",
    text: "",
    iconcolor: "#20A019",
    sign: crossGreen,
  },
  //yellow
  {
    mainColor: "#ffff",
    secondaryColor: "#D48B05",
    symbol: danger,
    title: "Warning",
    text: "",
    iconcolor: "#D48B05",
    sign: crossYellow,
  },
];

export const handleButtonClick = (label, eventName) => {
  let returnValue = false;
  switch (label) {
    case "Yes":
      eventName();
      returnValue = true;
      break;
    case "No":
      eventName();
      break;
    default:
      break;
  }
  return returnValue;
};

// Define button configurations for each button type
export const buttonConfigs = {
  OKOnly: [
    {
      label: "OK",
      class: "ok",
      backgroundColor: variants[1].iconcolor,
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
  ],
  OKCancel: [
    {
      label: "OK",
      class: "ok",
      backgroundColor: variants[1].iconcolor,
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
    {
      label: "Cancel",
      class: "cancel",
      backgroundColor: variants[0].iconcolor,
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
  ],
  AbortRetryIgnore: [
    {
      label: "Abort",
      class: "abort",
      backgroundColor: variants[0].iconcolor,
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
    {
      label: "Retry",
      class: "retry",
      backgroundColor: variants[3].iconcolor,
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
    {
      label: "Ignore",
      class: "ignore",
      backgroundColor: variants[3].iconcolor,
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
  ],
  YesNoCancel: [
    {
      label: "Yes",
      class: "yes",
      backgroundColor: variants[2].iconcolor,
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
    {
      label: "No",
      class: "no",
      backgroundColor: variants[0].iconcolor,
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
    {
      label: "Cancel",
      class: "cancle",
      backgroundColor: variants[0].iconcolor,
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
  ],
  YesNo: [
    {
      label: "Cancel",
      class: "cancel",
      backgroundColor: "#000",
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
    {
      label: "Confirm",
      class: "confirm",
      backgroundColor: "#fff",
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
  ],
  RetryCancel: [
    {
      label: "Retry",
      class: "retry",
      backgroundColor: variants[3].iconcolor,
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
    {
      label: "Cancel",
      class: "cancle",
      backgroundColor: variants[0].iconcolor,
      foreColor: variants[1].mainColor,
      clickable: handleButtonClick,
    },
  ],
};
