import styled from "styled-components";

export const UploadFeatureVideocont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 456px;
  height: 215px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px dashed #d9d9d9;
  background: #fff;
`;

export const ProgressCont = styled.div`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  align-items: start;
  //   justify-content: center;
  flex-direction: column;
  width: 456px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
`;

export const Uploading = styled.span`
  color: #161719;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
`;
