import React from "react";
import "./cart.css";
import percentageIcon from "assets/percentage-square.svg";
import { ButtonPrimary } from "components/styled/common.styled";

export const PurchaseDetails = () => {
  return (
    <div className="PurchaseCard">
      <p className="CardHeading">Purchase Summary </p>

      <div className="elemDiv mt-5">
        <p className="elemName">Subtotal</p>
        <p className="elemValue">₹1,999.00</p>
      </div>
      <div className="elemDiv">
        <p className="elemName">Tax</p>
        <p className="elemValue">₹359.00</p>
      </div>
      <div className="elemDiv">
        <p className="elemTotal">Total</p>
        <p className="elemValueTotal">₹2,358.00</p>
      </div>

      <div className="coupon">
        <img src={percentageIcon} alt="" />
        <input
          type="text"
          placeholder="Add Coupon Code"
          className="couponInput"
        />
      </div>
      <div className="mt-4">
        <p className="promo mt-4">Promo Codes</p>
        <ButtonPrimary>Checkout Now</ButtonPrimary>
      </div>
    </div>
  );
};
