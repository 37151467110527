import { Search } from "components/search/Search";
import React from "react";
import imgIcon from "assets/dummy-welding.png";
import deleteIcon from "assets/deleteIcon.svg";
import "./cart.css";
import { PurchaseDetails } from "./PurchaseDetails";
export const Cart = () => {
  return (
    <div className="mx-4">
      <p className="mainHeading">Cart</p>
      <hr />
      <div className="row">
        <div className="col-7">
          <div className=" mb-2">
            <h4>{`My Cart (1)`}</h4>
          </div>
          <div className="my-3" style={{marginLeft:"-1rem"}}>
            <Search />
          </div>

          <div className="d-flex mainDiv my-2">
            <img className="col-3" width={"118px"} src={imgIcon} alt="img" />
            <div className="col-9  p-2">
              <div className="d-flex align-items-center justify-content-between">
                <h5>Fitter Trade</h5>
                <img src={deleteIcon} alt="del" />
              </div>
              <p className="cartSmallText">Revenue Breakdown</p>
              <div className="rounded-pill priceDiv">₹1,999.00</div>
            </div>
          </div>
        </div>
        <div className="col-5">
          <PurchaseDetails />
        </div>
      </div>
    </div>
  );
};
