import {
  CLEAR_SIGNUP,
  ERROR,
  LOADED,
  LOADING,
  LOGIN,
  LOGOUT,
  PROFILE_INFO,
  SIGNUP,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  SUCCESS,
} from "./action.type";

const initialState = {
  user: null,
  error: null,
  success: null,
  isLoading: false,
  isLoggedIn: null,
  isSignedUp: null,
  profilePayload:{},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, isLoading: true };
    case LOADED:
      return { ...state, isLoading: false };
    case LOGIN:
      return { ...state, user: action.payload, isLoggedIn: true };
    case SIGNUP:
      return { ...state, user: action.payload };
    case LOGOUT:
      return { ...state, user: null, isLoggedIn: false };
    case ERROR:
      return { ...state, error: action.payload };
    case SUCCESS:
      return { ...state, success: action.payload };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isSignedUp: true,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        isSignedUp: false,
      };
    case CLEAR_SIGNUP:
      return {
        ...state,
        isSignedUp: null,
      };
    case PROFILE_INFO:
      
      return {
        ...state,
        profilePayload: { ...action.payload },
      };
    default:
      return state;
  }
};

export default authReducer;
