import React, { useEffect, useRef, useState } from "react";
import {
  CardSelect,
  CardUpper,
  CardVideoWrapper,
  CardBoxFlexible,
  CardWrapper,
  Info,
  LowerCard,
  OverlayContainer,
  OverlayImg,
  UpperBlack,
  LowerWhite,
  BlackWhiteDiv,
  CardBoxImage,
} from "./card.styled";
import {
  ButtonPrimary,
  TextBlack,
  TextMiddle,
  UploadText,
  UploadTextinfo,
} from "components/styled/common.styled";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import overlay from "assets/video-circle.svg";
import { resetState, setFormDetails } from "redux/Project/project.action";
import arrow from "assets/arrow-circle-right.svg";
import arrowLeft from "assets/arrow-circle-left.svg";
import { useDispatch, useSelector } from "react-redux";
import { UUIDGenerator, getDateByUpdate, getUserToken } from "helper";
import Iicon from "assets/Iicon.png";
import { OverlayTrigger, ProgressBar, Spinner, Tooltip } from "react-bootstrap";
import Uploader from "ajnavidya-file-upload-client";
import { CustomModal } from "components/Modal/CustomModal";
import ImageCroper from "components/ImageCroper/ImageCroper";
import deleteIcon from "../../assets/Del.svg";
import { toast } from "react-toastify";
import { ProgressCont, Uploading } from "components/styled/media.styled";
export const CardSquare = ({
  logoURL,
  isOpen,
  setIsOpen,
  text,
  projectId,
  data,
  name,
  isTutorial = false,

  updatedAt,
  eventKey,
  setIsDelete,
  isDelete,
  appId,
  setDraftCardId,
  draftCardId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const HandleNavigate = () => {
    dispatch(resetState());
    if (eventKey === "Draft") {
      if (data.slug === "Price need to be Set") {
        // createProject/newProject/setPrice
        navigate(
          `/createProject/newProject/setPrice?tab=${
            data?.rcName !== "production" ? "Free" : "Fixed"
          }&projectId=${data?.projectID}&appId=${data?._id}&rcName=${
            data?.rcName
          }`
        );
        return;
      } else {
        navigate(
          `/appDetails/${UUIDGenerator()}?rcName=${data?.rcName}&draftId=${
            data?._id
          }&applicationType=${
            data?.applicationType
          }&infoType=${"new"}&query=${"draft"}&projectId=${
            data?.projectID || ""
          }`
        );
        return;
      }
    } else {
      navigate(`/projectDetails/${projectId}`);
    }
  };

  const SelectedCard = (e) => {
    if (appId) {
      if (!draftCardId?.includes(appId)) {
        // Add the appId to the array only if it's not already there
        setDraftCardId((prevId) => [...prevId, appId]);
      } else {
        const filterDraftIds = draftCardId?.filter((item) => item !== appId);
        setDraftCardId(filterDraftIds);
      }
    }
    if (e.target.checked) {
      return setIsDelete(true);
    } else {
      setIsDelete(false);
    }
  };

  return (
    <CardWrapper
      style={{
        overflow: "hidden",
        display: "flex",
        position: "relative",
      }}
    >
      <CardUpper
        className="background"
        style={{
          height: "130px",
        }}
      >
        <img
          alt=""
          src={logoURL}
          height={"100%"}
          width={"100%"}
          onError={(e) => {
            e.target.src =
              "https://cdn.ajnavidya.com/ajnavidya/no%20thumbnail.png";
          }}
          style={{ objectFit: "cover", aspectRatio: "1/1", cursor: "pointer" }}
          onClick={() => {
            if (eventKey == "Rejected") {
              navigate(`/review_details/${appId}`);
            } else {
              HandleNavigate();
            }
          }}
        />
      </CardUpper>
      {/* <LowerCard
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          height: "100%",
        }}
      >
        <TextBlack className="fs-16 fw-600 px-3 d-flex justify-content-between">
          <Link>
            {text.length > 20 ? <>{text.slice(0, 20)}...</> : <>{text}</>}
          </Link>
        </TextBlack>
        <TextBlack className="fs-14 px-3 pt-1">
          Edited {getDateByUpdate(updatedAt)}
        </TextBlack>
      </LowerCard> */}

      <LowerCard
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          height: "128.5px",
        }}
      >
        {/* http://localhost:3000/appDetails?rcName=${mockData.rcName}&appId=${mockData._id}&projectId=${mockData.projectID._id}&applicationType=${mockData.applicationType}&version=${mockData.version} */}

        <TextBlack
          className="fs-16 fw-600 px-3 d-flex justify-content-between"
          onClick={() => {
            if (eventKey == "Rejected") {
              navigate(`/review_details/${appId}`);
            } else {
              HandleNavigate();
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <Link style={{ textDecoration: "none", color: "#000" }}>
            {text?.length > 40 ? <>{text?.slice(0, 40)}...</> : <>{text}</>}
          </Link>
        </TextBlack>
        <TextBlack className="fs-14 px-3 pt-1">
          {eventKey === "Rejected"
            ? `Rejected: ${getDateByUpdate(updatedAt)}`
            : `Edited: ${getDateByUpdate(updatedAt)}`}
          {/* Edited {getDateByUpdate(updatedAt)} */}
        </TextBlack>
        {eventKey === "Draft" ? (
          <input
            type="checkbox"
            className="customcheckbox"
            onChange={(e) => SelectedCard(e)}
          />
        ) : null}
      </LowerCard>
    </CardWrapper>
  );
};

export const CardRectange = ({ index, image, text, description }) => {
  return (
    <CardWrapper
      style={{
        border: "1px solid #2D2D2D",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <div className="d-flex">
        <div className="col-3">
          <CardUpper
            className="background"
            style={{
              height: "100%",
              borderRight: index === 0 ? "none" : "1px solid #2D2D2D",
            }}
          >
            <img
              src={image}
              height={"100%"}
              width={"100%"}
              style={{ objectFit: "cover" }}
              alt="coverImg"
            />
          </CardUpper>
        </div>
        <div className="col-9 px-2 py-1">
          <TextBlack className="fs-16">{text}</TextBlack>
          <TextBlack
            className="fs-14 pt-1"
            style={{
              height: "35px",
            }}
          >
            {description}
          </TextBlack>
          <TextBlack className="fs-14 pt-1 fw-600 text-underlined">
            Explore SOPs
          </TextBlack>
        </div>
      </div>
    </CardWrapper>
  );
};

export const CardVideo = ({ image, text }) => {
  return (
    <CardVideoWrapper>
      <CardUpper>
        <img
          src={image}
          height={"137"}
          alt="video"
          width={"100%"}
          style={{ objectFit: "cover" }}
        />
        <OverlayContainer>
          <OverlayImg src={overlay} alt="overlayimg" />
        </OverlayContainer>
      </CardUpper>
      <TextBlack className="fs-18 px-1 py-2 pt-3">{text}</TextBlack>
    </CardVideoWrapper>
  );
};

export const CardResources = ({ name, creation, active, setSdkID, id }) => {
  return (
    <div>
      <CardSelect active={active} onClick={() => setSdkID(id)}>
        <TextBlack className="fs-18 align-self-center mb-0">{name}</TextBlack>
        <div className="d-flex">
          <Info>{creation}</Info>
          <label style={{ marginLeft: "8px" }}>
            {active ? (
              <img
                src={arrowLeft}
                alt="arrow"
                style={{ marginBottom: "5px" }}
              />
            ) : (
              <img src={arrow} alt="arrow" style={{ marginBottom: "5px" }} />
            )}
          </label>
        </div>
      </CardSelect>
    </div>
  );
};

// card to show searched data
export const CardSearch = ({
  searchData,
  ref,
  showResults,
  setShowResults,
  setSearchValue,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchDataResults, setSearchDataResults] = useState();

  const navigateLink = (id) => {
    navigate(`projectDetails/${id}`);
    setSearchValue("");
  };
  useEffect(() => {
    setShowResults(false);
  }, [location]);

  useEffect(() => {
    if (searchData) {
      setSearchDataResults(
        searchData?.findproject || searchData?.findapp || []
      );
    }
  }, [searchData]);

  return (
    <>
      {showResults && (
        <div
          ref={ref}
          className="mx-4 me-3 mt-2 p-3"
          id="hide-scroll"
          style={{
            position: "absolute",
            backgroundColor: "#fff",
            width: "90%",
            borderRadius: "12px",
            overflowY: "scroll",
            maxHeight: "300px",
            zIndex: "10000",
          }}
        >
          {searchDataResults?.length === 0 ? (
            <div>No Data Found</div>
          ) : (
            <>
              {searchDataResults?.map((item, index) => {
                return (
                  <div
                    key={item._id}
                    onClick={() => {
                      navigateLink(item?._id);
                      setSearchValue("");
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        navigateLink(item?._id);
                        setSearchValue("");
                      }
                    }}
                    style={{ cursor: "pointer" }}
                    tabIndex={0}
                  >
                    <CardWrapper className="imgTxt px-2">
                      <div>
                        <img
                          src={item?.logoURL}
                          onError={(e) => {
                            e.target.src =
                              "https://cdn.ajnavidya.com/ajnavidya/no%20thumbnail.png";
                          }}
                          alt="imges"
                        />
                      </div>
                      <div className="searchBox">{item?.projectName}</div>
                    </CardWrapper>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
    </>
  );
};

// publisher hub components

export const CardBox = ({ item, border }) => {
  return (
    <div>
      <CardBoxImage border={border}>
        <img height={"40px"} width={"40px"} src={item.thumbnail} alt="img" />
        {item.thumbName}
      </CardBoxImage>
    </div>
  );
};

export const CardBoxUpload = ({ item, type, accept, name, setMedia }) => {
  const [isOpen, setIsOpen] = useState({
    modal: false,
    project: false,
    id: "",
  });
  const { s3Key } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const { projectFormDetails } = useSelector((state) => state.projectReducer);
  const [videoFile, setVideoFile] = useState(null);
  const expectedAspectRatio = 16 / 10;

  const validateAspectRatio = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        if (Math.abs(aspectRatio - expectedAspectRatio) > 0.1) {
          const error = new Error("Aspect ratio should be 16:9.");
          if (error) {
            setIsOpen({ ...isOpen, modal: true, file: file, id: file.name });
          }
          reject(error);
        } else {
          resolve();
        }
      };
      img.onerror = () => {
        const error = new Error("Error loading image.");
        reject(error);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const [progress, setProgress] = useState(0);
  const [fileURL, setFileURL] = useState("");

  document.addEventListener("fileStateChanged", (event) => {
    const progress1 = event.progress;
    setPercentCompleted(Math.floor(progress1));
    if (progress1 === 100) {
      setIsLoading(false);
      setPercentCompleted(0);
    }
  });

  const handleFileChange = async (e, type = "Image") => {
    const file = e.target.files[0];
    setVideoFile(file);
    const fileExtension = file?.name.split(".").pop().toLowerCase();

    const allowedVideoExtensions = ["mp4", "webm", "ogg", "avi", "mov"];
    const allowedImageExtensions = ["jpg", "jpeg", "png"];

    if (type === "Video") {
      if (!allowedVideoExtensions.includes(fileExtension)) {
        toast.error("Please upload a valid video file.");
        return;
      }
    }
    if (type === "image" || type === "Image") {
      if (!allowedImageExtensions.includes(fileExtension)) {
        toast.error("Please upload a valid image file.");
        return;
      }
    }
    setError(null);
    // Set size limits based on file type
    const maxSizeInBytes =
      type === "Video" ? 100 * 1024 * 1024 : 5 * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      setError(
        `File size exceeds the ${type === "Video" ? "100MB" : "5MB"} limit.`
      );
      return;
    }
    try {
      if (type !== "Video") {
        await validateAspectRatio(file);
      }
      setIsLoading(true);

      type === "Video" && setIsVideoModal(true);
      Uploader({
        file: e,
        cancel: false,
        url: {
          apiOrigin: "https://file-uploadv2.ajnavidya.com/",
          status: "/upload/status",
          upload: "/upload/files",
          complete: "/upload/complete",
        },
        projectID: s3Key,
        setFileURL: setFileURL,
        setProgress: setProgress,
        userID: getUserToken()?.sub,
      });
      //;
      document.addEventListener(e.target.name, (event) => {
        //;
        const fileURL = event.data;
        dispatch(
          setFormDetails({
            mediafileURLs: {
              [e.target.name]: fileURL?.url,
            },
            fileSize: file?.size,
          })
        );
      });
      document.addEventListener("fileError", (event) => {
        const error = event.error;
        toast.error(error);
        setIsLoading(false);
        //  clear event listener
        document.removeEventListener("fileError", () => {});
      });
    } catch (error) {
      setError(error);
    }
  };

  const [isVideoModal, setIsVideoModal] = useState(false);

  return (
    <>
      {isOpen?.modal && (
        <CustomModal
          isOpen={isOpen?.modal}
          setIsOpen={setIsOpen}
          key={"abc"}
          size="md"
          title={"Crop Your Image"}
          back={true}
          setIsLoading={setIsLoading}
          files={videoFile}
          openFor={"FeaturevideoUpload"}
        >
          <ImageCroper
            name={new Date().getTime() + isOpen?.id}
            file={isOpen?.file}
            id={name}
            handleFileChange={handleFileChange}
            expectedAspectRatio={expectedAspectRatio}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </CustomModal>
      )}
      {isVideoModal && (
        <CustomModal
          isOpen={isVideoModal}
          setIsOpen={setIsVideoModal}
          // openFor={"videoUpload"}
          key={"VideoModal"}
          size="md"
          title={"Video Upload"}
          back={true}
          setIsLoading={setIsLoading}
          files={videoFile}
          openFor={"FeaturevideoUpload"}
        >
          <div className="my-2 d-flex justify-content-center">
            <CardBoxFlexible
              border={true}
              style={{ position: "relative" }}
              onClick={() => {
                document.getElementById(`file-upload_${name}`).click();
              }}
            >
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center w-100 h-100 mt-2">
                  {/* {percentCompleted > 0 && percentCompleted + "%"} */}
                  <video width="100%" height="100%" controls>
                    <source src={fileURL} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <>
                  <img
                    height={"30px"}
                    width={"30px"}
                    src={item.thumbnail}
                    alt="img"
                  />
                  <TextMiddle>
                    {item.thumbName}
                    <span className="text-danger fw-bold">*</span>
                  </TextMiddle>
                </>
              )}
            </CardBoxFlexible>
            {error !== null && (
              <div className="text-danger">{error.toString()}</div>
            )}
          </div>
          {isLoading ? (
            <ProgressCont>
              <Uploading> Uploading... </Uploading>
              <ProgressBar now={percentCompleted} style={{ width: "100%" }} />
            </ProgressCont>
          ) : (
            <ButtonPrimary>
              {/* {isLoading ? "Uploading..." + percentCompleted + "%" : "Upload"} */}
              Upload
            </ButtonPrimary>
          )}
        </CustomModal>
      )}

      <div>
        <CardBoxFlexible
          border={true}
          // style={{ position: "relative" }}
          onClick={() => {
            document.getElementById(`file-upload_${name}`).click();
            document.getElementById(`file-upload_${name}`).value = "";
          }}
        >
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100 mt-2">
              <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <img
                height={"30px"}
                width={"30px"}
                src={item.thumbnail}
                alt="img"
              />
              <TextMiddle>
                {/* {item.thumbName} */}
                {/* <span className="text-danger fw-bold border border-primary">*</span> */}
              </TextMiddle>
              <div className="d-flex flex-column py-2">
                <UploadText>{item.thumbName}</UploadText>
                <UploadTextinfo>
                  Max file size - 5MB Resolution - 1920x1080p Aspect Ratio -
                  16:9
                </UploadTextinfo>
              </div>
            </>
          )}

          <input
            type="file"
            hidden
            name={name}
            id={`file-upload_${name}`}
            accept={accept}
            onChange={(e) => handleFileChange(e, type)}
          />
          {/* <div style={{ position: "absolute", top: "0", right: "10px" }}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip">
                  <strong>Aspect Ratio</strong> 16:10
                </Tooltip>
              }
            >
              <img
                src={Iicon}
                alt="Iicon"
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              />
            </OverlayTrigger>
          </div> */}
        </CardBoxFlexible>
        {error !== null && (
          <div className="text-danger">{error.toString()}</div>
        )}
      </div>
    </>
  );
};

export const CardBlackWhite = ({ upperContent = [], lowerContent, back }) => {
  return (
    <BlackWhiteDiv className="">
      <UpperBlack back={back}>{upperContent.map((item) => item)}</UpperBlack>
      <LowerWhite>{lowerContent}</LowerWhite>
    </BlackWhiteDiv>
  );
};
