import { PriceInputWrapper } from "components/styled/common.styled";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

export const PriceInput = ({ setPrice, defaultValue, readonly, type }) => {

  const { profilePayload } = useSelector((state) => state.authReducer);
  console.log(profilePayload, "profilePayload in price input L9");
  return (
    <PriceInputWrapper>
      <div className=" text-white p-2 bg-black inr-dropdown btn-dark ">
        {profilePayload.region === "IN" ? "INR" : "USD"}
      </div>
      <input
        style={{
          height: "35px",
          border: "none",
          outline: "none",
          marginLeft: "10px",
          width: "100%",
        }}
        readOnly={readonly}
        value={defaultValue}
        onChange={(e) => setPrice(Number(e.target.value))}
        placeholder="Enter Price..."
        autofocus={!readonly}
        type={type || ""}
      />
    </PriceInputWrapper>
  );
};
