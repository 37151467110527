export const SET_PROJECT_FORM_DETAILS = "SET_PROJECT_FORM_DETAILS";
export const GET_PROJECT_FORM_DETAILS = "GET_PROJECT_FORM_DETAILS";

export const CREATE_PROJECT = "CREATE_PROJECT";
export const GET_PROJECT_BY_ID = "GET_PROJECT_BY_ID";

export const DELETE_PROJECT_BY_ID = "DELETE_PROJECT_BY_ID";
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";

export const SET_PRICE = "SET_PRICE";

export const GETRC_WITH_LIVE_APPLICATION = "GETRC_WITH_LIVE_APPLICATION";

export const GET_APPLICATION_DATA = "GET_APPLICATION_DATA";
export const GET_APP_INFO = "GET_APP_INFO";

export const SET_RADIO_BUTTON = "SET_RADIO_BUTTON";

export const EMPTY_FORM_DETAILS = "EMPTY_FORM_DETAILS";

export const GET_APP_REVIEW_STATUS = "GET_APP_REVIEW_STATUS";

export const GET_APPS_IN_REVIEW = "GET_APPS_IN_REVIEW";
export const GET_ALL_SECTORS = "GET_ALL_SECTORS";

export const GET_SECTOR_BY_ID = "GET_SECTOR_BY_ID";

export const CREATE_RELEASE_CHANNEL = "CREATE_RELEASE_CHANNEL";

export const CREATE_DRAFT_AND_SAVE = "CREATE_DRAFT_AND_SAVE";
export const GET_ALL_SAVE_DRAFT_PROJECT = "GET_ALL_SAVE_DRAFT_PROJECT";
export const GET_SINGLE_DRAFT_PROJECT = "GET_SINGLE_DRAFT_PROJECT";
export const DELETE_DRAFT_PROJECT_BY_ID = "DELETE_DRAFT_PROJECT_BY_ID";
export const GET_ACTIVE_USERS = "GET_ACTIVE_USERS";
export const GET_APP_DOWNLOADS = "GET_APP_DOWNLOADS";
export const GET_APP_EARNINGS = "GET_APP_EARNINGS";
export const GET_USERS_BY_REGION = "GET_USERS_BY_REGION";
export const GET_MISCELLANEOUS_DATA = "GET_MISCELLANEOUS_DATA";
export const GET_OVERALL_DOWNLOADS = "GET_OVERALL_DOWNLOADS";
export const GET_OVERALL_EARNINGS = "GET_OVERALL_EARNINGS";
export const GET_OVERALL_USERS = "GET_OVERALL_USERS";
export const GET_OVERALL_USERS_BY_REGION = "GET_OVERALL_USERS_BY_REGION";
export const RESET_STATE = "RESET_STATE";
export const GET_APP_RATINGS = "GET_APP_RATINGS";
export const GET_APP_REVIEWS = "GET_APP_REVIEWS";
export const DELETE_APPLICATION_BY_ID = "DELETE_APPLICATION_BY_ID";
export const GET_ALL_REJECTED_APPS = "GET_ALL_REJECTED_APPS";
export const DELETE_REJECTED_APPS = "DELETE_REJECTED_APPS";

// API for Account Mapping of the Publisher.
export const GET_BANK_ACCOUNT_LIST = "GET_BANK_ACCOUNT_LIST";
export const SET_ACCOUNT_FROM_DETAILS = "SET_ACCOUNT_FROM_DETAILS";
export const SET_PRIMARY_ACCOUNT = "SET_PRIMARY_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const GET_ALL_STATES = "GET_ALL_STATES";
export const GET_ALL_CITIES = "GET_ALL_CITIES";
export const GET_BANK_DETAIL = "GET_BANK_DETAIL";

// coupons for the Publisher
export const CREATE_COUPON = "CREATE_COUPON";
export const GET_COUPONS_BYAPPID = "GET_COUPONS_BYAPPID";
export const DELETE_COUPONBYID = "DELETE_COUPONBYID";
export const UPDATE_COUPON_BYID = "UPDATE_COUPON_BYID";
export const GET_COUPONS_BYID = "GET_COUPONS_BYID";

// get storage
export const GET_STORAGE = "GET_STORAGE";

// Delete project
export const DELETE_PROJECT = "DELETE_PROJECT";

// comments like flag
export const CREATE_COMMENT = "CREATE_COMMENT";
export const GET_ALL_SEARCH = "GET_ALL_SEARCH";
export const GET_ALL_VERSIONS_HISTORY = "GET_ALL_VERSIONS_HISTORY";
// setting Price Details
export const SET_PRICE_DETAILS = "SET_PRICE_DETAILS";

// add user to Invite
export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";

// Invite user API call
export const ADD_USER_API = "ADD_USER_API";
export const GET_INVITED_USERS = "GET_INVITED_USERS";
export const DELETE_INVITED_USER = "DELETE_INVITED_USER";
export const DELETE_INVITED_USER_SUCCESS = "DELETE_INVITED_USER_SUCCESS";
export const DELETE_INVITED_USER_FAILURE = "DELETE_INVITED_USER_FAILURE";
// storgae
export const GET_ALL_STORAGE = "GET_ALL_STORAGE";
// get storage projectwise
export const GET_STORAGE_PROJECTWISE = "GET_STORAGE_PROJECTWISE";
export const GET_Live_PROJECTS = "GET_Live_PROJECTS";
