import styled from "styled-components";

export const RatingBoxWrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  width: fit-content;
  div {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
    }
    .rating-bar {
      width: 200px;
      height: 8px;
      border-radius: 5px;
      background-color: lightgrey;
      overflow: hidden;

      .filled-bar {
        height: 100%;
      }

      .five-star {
        background-color: #20a019;
      }

      .four-star {
        background-color: #20a019;
      }

      .three-star {
        background-color: #fbb739;
      }

      .two-star {
        background-color: #ff7666;
      }

      .one-star {
        background-color: #ff7666;
      }
    }
  }
`;
