import { CustomModal } from "components/Modal/CustomModal";
import { authConst } from "ui/auth/authConstant";
export const TermsOfUse = ({ isOpen, setIsOpen }) => {
  return (
    <>
      {isOpen && (
        <CustomModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          openFor={"termsOfUse"}
          size="lg"
          title="Terms Of Use"
        >
          <div style={{ overflowY: "scroll", maxHeight: "400px" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: authConst?.termsOfUse,
              }}
            />
          </div>
        </CustomModal>
      )}
    </>
  );
};
