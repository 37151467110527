import { ProjectName, TableTr } from "components/styled/home.styled";
import React from "react";
import Skeleton from "react-loading-skeleton";

const TableSkelton = () => {
  const tableArr = [1, 2, 3, 4, 5];
  const tableHead = tableArr.map((_, index) => (
    <th scope="col-2" key={index}>
      <Skeleton height={"100%"} width={"50%"} />
    </th>
  ));
  return (
    <div>
      <ProjectName className="upperContent">
        <Skeleton height={"100%"} width={"100%"} />
        <table class="table no-border">
          <thead>
            <TableTr>{tableHead}</TableTr>
          </thead>
          <tbody>
            <TableTr>{tableHead}</TableTr>
            <TableTr>{tableHead}</TableTr>
            <TableTr>{tableHead}</TableTr>
          </tbody>
        </table>
      </ProjectName>
    </div>
  );
};

export default TableSkelton;
