import { CardSquare } from "components/card/Card";

import React, { useEffect, useState } from "react";
import draftEmpty from "assets/draft.png";
import projectEmpty from "assets/project-empty.png";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDraftProjects,
  getAllProjects,
  getAllRejectedApps,
  getAllSaveDraftProjects,
} from "redux/Project/project.action";
import { VdiLanding } from "components/VdiLanding/VdiLanding";
import { CustomTabs } from "components/Tabs/CustomTabs";
import { CustomPane } from "components/Tabs/customTabs.styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import { variants, buttonConfigs } from "components/CustomAlert/constant";
import { CustomAlertPopup } from "components/CustomAlert";
import { OverFlow } from "components/styled/common.styled";
import { toast } from "react-toastify";
import { CustomSkeleton } from "./CustomSkeleton";
// import userEvent from "@testing-library/user-event";
import { getProfileInfo } from "redux/Auth/action";
// import { CustomProgress } from "ui/sidebar/CustomProgress";

export const Home = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("infoType");
  const [isProjectsLoading, setIsProjectsLoading] = useState(true);
  const [isDraftLoading, setIsDraftLoading] = useState(true);
  const [isRejectedLoading, setIsRejectedLoading] = useState(true);

  const navigate = useNavigate();
  const projectsData = useSelector((state) => state.projectReducer.allProjects);

  const draftProjects = useSelector(
    (state) => state?.projectReducer?.allDraftProjects
  );

  const rejectedAppsData = useSelector(
    (state) => state?.projectReducer?.allRejectedApps
  );

  const [isDelete, setIsDelete] = useState(false);

  const [draftCardId, setDraftCardId] = useState([]);

  const dispatch = useDispatch();
  const [variant, setVariant] = useState(0);
  const selectedVariant = variants[variant];
  const [modal, setModal] = useState({
    modal: false,
    text: "",
    draftIds: [],
  });
  const allIds = draftProjects?.map((item) => item?._id);

  const handleDelete = async (draftCardId) => {
    if (draftCardId?.length) {
      const data = {
        docIds: draftCardId,
      };

      dispatch(deleteDraftProjects(data)).then((res) => {
        if (res.success) {
          toast.success("Your Apps has been deleted successfully");

          // await new Promise((resolve) => setTimeout(resolve, 100));
          setDraftCardId([]);
          dispatch(getAllSaveDraftProjects());
        }
      });
    }
  };

  useEffect(() => {
    if (type === "Draft") {
      setTimeout(() => {
        dispatch(getAllSaveDraftProjects()).then((res) => {
          if (res) {
            setIsDraftLoading(false);
          }
        });
      }, 200);
    } else if (type === "Rejected") {
      dispatch(getAllRejectedApps()).then((res) => {
        setIsRejectedLoading(false);
      });
    } else {
      dispatch(getAllProjects()).then((res) => {
        if (res) {
          setIsProjectsLoading(false);
        }
      });
    }
  }, [type]);

  useEffect(() => {
    dispatch(getProfileInfo());
  }, []);
  const numberOfProjectsCard = projectsData?.length;

  return (
    <>
      {modal && (
        <div style={{ marginTop: "-0.5rem", marginLeft: "0rem", top: "20px" }}>
          <CustomAlertPopup
            modal={modal}
            setModal={setModal}
            selectedVariant={selectedVariant}
            text={modal.text}
            buttonType={
              selectedVariant.title === "Warning" ? buttonConfigs.YesNo : null
            }
            triggerEvent={() => handleDelete(modal.draftIds)}
            titleText="Delete Draft project"
          />
        </div>
      )}
      <OverFlow>
        <div className="container-fluid px-4 py-2">
          <VdiLanding />
          <div>
            <CustomTabs
              tabTitles={["My Projects", "Draft", "Rejected"]}
              tabs={""}
              page={[""]}
              type={"plane"}
            >
              <CustomPane eventKey="My Projects">
                <div className="row gy-1">
                  {isProjectsLoading ? (
                    [...Array(3)].map((_, index) => (
                      <div className="col-4 gy-3" key={index}>
                        <CustomSkeleton />
                      </div>
                    ))
                  ) : Array.isArray(projectsData) &&
                    projectsData?.length === 0 ? (
                    <div className="text-center">
                      <img
                        src={projectEmpty}
                        alt="projectEmpty"
                        height={100}
                        width={200}
                      />
                    </div>
                  ) : (
                    projectsData.map((item, index) => (
                      <div className="col-4 gy-3" key={item._id}>
                        <CardSquare
                          projectId={item?._id}
                          text={item?.projectName}
                          index={index}
                          updatedAt={item?.updatedAt}
                          logoURL={item?.logoURL}
                          eventKey={"My project"}
                        />
                      </div>
                    ))
                  )}
                </div>
              </CustomPane>

              <CustomPane eventKey="Draft">
                <div className="Delete">
                  {draftCardId?.length !== 0 ? (
                    <p
                      style={{ margin: 0, color: "red" }}
                      onClick={() => {
                        setModal({
                          modal: true,
                          text: "Are you sure you want to delete selected draft?Please note that all your saved progress will be lost.",
                          draftIds: draftCardId,
                        });
                      }}
                    >
                      {draftCardId.length === 0 ? "" : "Delete Selected Drafts"}
                    </p>
                  ) : (
                    <></>
                  )}
                  {draftProjects.length !== 0 && (
                    <div>
                      <div
                        onClick={() => {
                          setVariant(3);
                          setModal({
                            modal: true,
                            text: "Are you sure you want to delete all Apps?",
                            draftIds: allIds,
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          display:
                            draftProjects?.length === 0 ? "none" : "block",
                          float: "right",
                        }}
                      >
                        Delete All
                      </div>
                    </div>
                  )}
                </div>
                <div className="row  gy-2">
                  {isDraftLoading ? (
                    [...Array(3)].map((_, index) => (
                      <div className="col-4 gy-3" key={index}>
                        <CustomSkeleton />
                      </div>
                    ))
                  ) : (
                    <>
                      {Array.isArray(draftProjects) &&
                      draftProjects?.length === 0 ? (
                        <div className="text-center">
                          <img
                            src={draftEmpty}
                            alt="draftEmpty"
                            height={100}
                            width={200}
                          />
                        </div>
                      ) : (
                        draftProjects?.map((item, index) => (
                          <div className="col-4 gy-3" key={item._id}>
                            <CardSquare
                              appId={item?._id}
                              text={
                                item?.projectName
                                  ? item?.projectName
                                  : "Untitled"
                              }
                              index={index}
                              data={item}
                              updatedAt={item?.updatedAt}
                              logoURL={item?.logoURL}
                              eventKey={"Draft"}
                              setIsDelete={setIsDelete}
                              isDelete={isDelete}
                              draftCardId={draftCardId}
                              setDraftCardId={setDraftCardId}
                            />
                          </div>
                        ))
                      )}
                    </>
                  )}
                </div>
              </CustomPane>
              <CustomPane eventKey="Rejected">
                <div className="Delete">
                  {/* {draftCardId?.length !== 0 ? (
                    <p
                      style={{ width: "20%", margin: 0 }}
                      onClick={() => {
                        setModal({
                          modal: true,
                          text: "Are you sure you want to delete selected draft?Please note that all your saved progress will be lost.",
                          draftIds: draftCardId,
                        });
                      }}
                    >
                      {rejectedAppsData.length === 0
                        ? ""
                        : "Delete Selected Drafts"}
                    </p>
                  ) : (
                    <></>
                  )} */}
                  {/* {rejectedAppsData.length !== 0 && (
                    <div style={{ width: "100%" }}>
                      <div
                        onClick={() => {
                          setVariant(3);
                          setModal({
                            modal: true,
                            text: "Are you sure you want to delete all Apps?",
                            draftIds: allIds,
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          display:
                            rejectedAppsData?.length === 0 ? "none" : "block",
                          float: "right",
                        }}
                      >
                        Delete All
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="row  gy-2">
                  {isRejectedLoading ? (
                    [...Array(3)].map((_, index) => (
                      <div className="col-4 gy-3" key={index}>
                        <CustomSkeleton />
                      </div>
                    ))
                  ) : (
                    <>
                      {Array.isArray(rejectedAppsData) &&
                      rejectedAppsData?.length === 0 ? (
                        <div className="text-center">
                          <img
                            src={projectEmpty}
                            alt="NoProjects"
                            height={100}
                            width={200}
                          />
                        </div>
                      ) : (
                        rejectedAppsData?.map((item, index) => (
                          <div className="col-4 gy-3" key={item._id}>
                            <CardSquare
                              appId={item?.appID}
                              text={
                                item?.projectName
                                  ? item?.projectName
                                  : "Untitled"
                              }
                              index={index}
                              data={item}
                              updatedAt={item?.rejectedOn}
                              logoURL={item?.logoURL}
                              eventKey={"Rejected"}
                              onClick={() => {
                                navigate(`review_details/${item?.appID}`);
                              }}
                            />
                          </div>
                        ))
                      )}
                    </>
                  )}
                </div>
              </CustomPane>
            </CustomTabs>
          </div>
        </div>
      </OverFlow>
    </>
  );
};
