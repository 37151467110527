import React from "react";
import "./toggleSwitch.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFormDetails } from "redux/Project/project.action";
import { toast } from "react-toastify";

export const ToggleSwitch = ({
  name,
  checked,
  isPrimary,
  setPrimaryAccount,
  setBankId,
  onChange,
  isToggleChecked,
}) => {
  const switchBtn = document.getElementById("switchInput");
  const dispatch = useDispatch();

  useEffect(() => {
    if (checked && switchBtn) {
      switchBtn.checked = true;
    } else if (switchBtn) {
      switchBtn.checked = false;
    }
  }, [checked, dispatch, switchBtn]);

  const onSwitchChange = (bankId, isPrimary) => {
    onChange(!isToggleChecked);
    dispatch(setPrimaryAccount(bankId, isPrimary))
      .then((response) => {
        setBankId(bankId);
        if (response.error) {
          // Handle the error here
          toast.error("Error: " + response.error.message);
        } else {
          // The action was successful
          toast.success("Primary Accounts successfully changed.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      onClick={() =>
        dispatch(
          setFormDetails({
            [name]: switchBtn?.checked,
          })
        )
      }
    >
      <label className="switch">
        <input
          type="checkbox"
          onChange={() => onSwitchChange(name, true)}
          name={name}
          id={"switchInput"}
          value={checked}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
};
