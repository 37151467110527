import { PageRoutes } from "Routes";
import "./App.css";
import { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { loginconfig } from "ui/auth/Config";
import { getUserToken } from "helper";

import axios from "axios";

export const LoadChatbotScript = () => {
  const script = document.createElement("script");
  script.src = "https://cdn.ajnavidya.com/ajnavidya/main.1352c0ea.js";
  script.async = true;
  script.id = "chatbot";
  script.crossOrigin = "anonymous";
  script.integrity =
    "sha384-/T+NZWAjCoXwTwnH0M6SnXgIDinOnGQDsV1kfJw1vsnfUdRQSEn5gzDNqJT2FV26";
  // Ensure CORS requests are sent with the integrity attribute
  const isExist = document.getElementById("chatbot");
  if (!isExist) {
    const div = document.createElement("div");
    div.id = "root-2-2";
    document.body.appendChild(div);
    document.body.appendChild(script);
  }
};
function App() {
  let path = window.location.pathname;

  useEffect(() => {
    // Function to check if the chatbot should be loaded
    const shouldLoadChatbot = () => {
      const excludedPaths = ["/", "/onboarding"]; // Add paths where you don't want the chatbot
      if (!excludedPaths.includes(path)) {
        // Initialize the chatbot script here
        LoadChatbotScript();
      }
    };

    shouldLoadChatbot();
  }, []);

  useEffect(() => {
    // Function to check if the chatbot should be loaded
    const shouldLoadChatbot = () => {
      const excludedPaths = ["/", "/onboarding"];
      const path = window.location.pathname;
      if (!excludedPaths.includes(path)) {
        LoadChatbotScript();
      }
    };
    shouldLoadChatbot();

    // Check if the user is already logged in
    const token =
      localStorage.getItem("token") ||
      window.location.search.split("token=")[1];

    const decodeToken = getUserToken(token);

    if (decodeToken) {
      //  check profile is complete or not
      const profileComplete = decodeToken?.isProfileComplete;
      if (!profileComplete) {
        const checkProfileCompleted = async () => {
          try {
            const response = await axios.post(
              `${loginconfig.apiUrl}/api/v2/user/isProfileComplete`,
              {
                client_id: loginconfig.clientId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (response?.data?.data?.isProfileComplete === false) {
              localStorage.clear();
              window.location.replace(
                `${loginconfig.url}?clientId=${loginconfig.clientId}&platform=${loginconfig.platform}&redirect=${loginconfig.redirect}`
              );
              return;
            }
          } catch (error) {
            console.log(error);
          }
        };
        checkProfileCompleted();
      }
    }

    if (!token) {
      // User is not logged in, redirect to the login page
      window.location.replace(
        `${loginconfig.url}?clientId=${loginconfig.clientId}&platform=${loginconfig.platform}&redirect=${loginconfig.redirect}`
      );
    }
  }, []);

  return <PageRoutes />;
}

export default App;
