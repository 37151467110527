import unsplash from "assets/unsplash.jpg";
import boxWhite from "assets/onlyBoxWhite.svg";
import playWhite from "assets/playWhite.svg";
import imgWhite from "assets/imgIconWhite2.svg";
import { getUserToken } from "helper";

export const data = [
  {
    img: unsplash,
    title: "Welding Simulation ",
    key: "Release Channel",
    value: "Production",
    version: "version",
    number: 1,
    AppId: "AppId",
    id: "101010",
    submiitedOn: "Last edited on ",
    date: "30/05/23",
  },
];

export const projectFormInitialState = {
  projectName: "",
  sector: "",
  subSector: "",
  reviews: [],
  priceInfo: {},
  buildURL: "",
  buildSize: 0,
  logoURL: "",
  s3Key: "",
  rcName: "production",
  applicationType: ".apk",
  version: "v0.0.1",
  mediafileURLs: {
    logo: "",
    threeDFile: "",
    video: "",
    videothumbnail: "",
    imageOne: "",
    imageTwo: "",
    coverImage: "",
    projectCardImage: "",
    featuredCardImage: "",
    mediaSize: 0,
    imageThree: "",
  },
  projectBrief: "",
  description: "",
  tag: "",
  executableFileName: "",
  apkSupportedPlatforms: "",
  publishedBy:
    getUserToken()?.given_name + " " + getUserToken()?.family_name ||
    "Publisher",
  hashtags: "",
  internetRequired: false,
  suitableForAge: "",
  languageSupported: [""],
  contentDisclaimer: "",
  appStreamingType: "",
  publishingFor:"",
};

export const ChangeThumbnails = [
  {
    id: 1,
    message: "Replace 3D File",
    icon: boxWhite,
  },
  {
    id: 2,
    message: "Replace Video",
    icon: playWhite,
  },
  {
    id: 3,
    message: "Replace Image",
    icon: imgWhite,
  },
  {
    id: 4,
    message: "Replace Image",
    icon: imgWhite,
  },
  {
    id: 5,
    message: "Replace Image",
    icon: imgWhite,
  },
  {
    id: 6,
    message: "Replace Image",
    icon: imgWhite,
  },
];

export const experinces = [
  {
    id: 1,
    name: "VR",
    value: "vr",
  },
  {
    id: 2,
    name: "AR",
    value: "ar",
  },
  {
    id: 3,
    name: "MR",
    value: "mr",
  },
];

export const accountDetail = {
  accountHolderName: "",
  accountNumber: "",
  accountType: "",
  bankName: "",
  IFSCode: "",
  bankAddress: "",
  bankCity: "",
  bankState: "",
  bankPinCode: "",
};

export const publisherForData = [
  {
    id: "1ss",
    name: "ALL",
  },
  {
    id: "2ss",
    name: "ITI_Only",
  },
];